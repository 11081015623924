import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsDetailsComponent } from './about-us-details/about-us-details.component'
// import { ContactUsComponent } from './contact-us/contact-us.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { VerifDetailComponent } from './verif-detail/verif-detail.component'
// import { BrowseJobComponent } from './browse-job/browse-job.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component'
import { AuthSuccessComponent } from './auth-success/auth-success.component';
export const routes: Routes = [
    // { path: '', redirectTo: 'home' },
    { path: 'home', component: HomeComponent },
    { path: 'reset-password/:id', pathMatch: 'full', component: ResetPasswordComponent },
    { path: 'terms', pathMatch: 'full', component: TermsConditionComponent },
    { path: 'user-verification/:id', pathMatch: 'full', component: AuthSuccessComponent },
];
export const authRouter = RouterModule.forChild(routes);


import { Component, OnInit, Inject } from '@angular/core';
import { ShareService } from '../share.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { PreviewResumePopupComponent } from '../../enterprise/preview-resume-popup/preview-resume-popup.component';
import {AuthenticationService } from '../../app.service';


@Component({
  selector: 'app-pool-jon-seeker',
  templateUrl: './pool-job-seeker.component.html',
  styleUrls: ['./pool-job-seeker.component.css']
})
export class PoolJobSeekerComponent implements OnInit {
  preloader: boolean;
  jobseekerIdSelected: any = [];
  paginator = {
    pageSize: 10,
    length: 0,
    pageIndex: 0,
    pageSizeOptions: [10, 20, 50, 100]
  };
  jobseekerForm: FormGroup;
  listOfAllJobseeker: any;
  listView: boolean;
  jobseekerlist: any;
  showDetails: boolean;
  Savedjobseekerlist: any;
  CheckHistory: any;

  constructor(
    public dialogRef: MatDialogRef<PoolJobSeekerComponent>,
    public dialog: MatDialog,
    private tableService: ShareService,
    private _formBuilder: FormBuilder,
    private tostr: ToastrManager,
 private AuthenticationService: AuthenticationService,
 @Inject(MAT_DIALOG_DATA) public data: any,) {

    this.AuthenticationService.jwtTokenExpCheck();
    this.filterInit();
    this.CheckHistory =this.data.historycandidate;
    this.loadjobseekerlist();
  }

  ngOnInit() {
  }
  filterInit() {
    this.jobseekerForm = this._formBuilder.group({
      PageSize: 10,
      PageNumber: 1,
      pk_pool_Id: this.data.pk_pool_Id

    });
  }
  loadjobseekerlist() {
    this.preloader = true;
    if (this.data.historycandidate) {
      this.tableService.HistoryofSavedPoolSeekerListByPoolId(this.jobseekerForm.value).pipe(first()).subscribe(res => {
        if (res.success) {
          this.jobseekerlist = res.data;
          this.preloader = false;
          if (this.jobseekerlist.length > 0) {
            this.paginator.length = this.jobseekerlist[0].totalCount;
          }
        }
      });
     } else {
      this.tableService.SavedPoolSeekerListByPoolId(this.jobseekerForm.value).pipe(first()).subscribe(res => {
        if (res.success) {
          this.jobseekerlist = res.data;
          this.preloader = false;
          if (this.jobseekerlist.length > 0) {
            this.paginator.length = this.jobseekerlist[0].totalCount;
          }
        }
      });

    }
  }

  getPaginatorData(e: { pageSize: number; pageIndex: number; }) {
    this.paginator.pageIndex = e.pageIndex;
    this.paginator.pageSize = e.pageSize;
    this.jobseekerForm.controls.PageSize.setValue(e.pageSize);
    this.jobseekerForm.controls.PageNumber.setValue(e.pageIndex + 1);
    this.loadjobseekerlist();
  }

  isLogin='true';
  jobseekerpopup(jobseeker){ 
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(PreviewResumePopupComponent, {
      width: 'auto',
      //disableClose: true,
      data: jobseeker
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  }

  onNoClick(): void {
    this.dialogRef.close();   
  }
}

export class Constant {

    public static Currency = 'Currency';
    public static ProfitCenter = 'ProfitCenter';
    public static BusinessUnit = 'BusinessUnit';
    public static RateType = 'RateType';
    public static PayType = 'PayType';
    public static Countries = 'Countries';
    public static States = 'States';
    public static JobType = 'JobType';
    public static Fixed = 'Fixed';
    public static Range = 'Range';
  };
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Toastr, ToastrManager } from 'ng6-toastr-notifications';
import { DialogData } from 'src/app/component/header/header.component';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css']
})
export class UploadDocumentComponent implements OnInit {
  body: FormGroup;
  resumeResponse: any;
  resumeLoader = false;
  sync = false;
  supplierId:'';
  constructor(private formBuilder:FormBuilder,private toastr:ToastrManager,  private API: EnterpriseService,private activatedRoute: ActivatedRoute,
    public dialogRef:MatDialogRef<UploadDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) { 

    this.supplierId = this.activatedRoute.snapshot.params['id'];
    this.body = this.formBuilder.group({
      invoiceid: '',
      resume:''
     
    });
  }

  ngOnInit() {
  }


  uplaodDocument(event) {
    if( event.target.files.length > 0){     
    var aa=event.target.files.length;     
    this.resumeLoader = true;
    const formData = new FormData();
    let id =this.data['staffingagencyId'].substring(6) ;
    formData.append("invoice", event.target.files[0]);   
    formData.append('name',this.data['client'] );
    formData.append('id', this.data['invoiceId']);
    const type = event.target.files[0].name.split('.');
    console.log('type', type[1]);
    if (type[1] === 'docx'
      || type[1] === 'pdf'
      || type[1] === 'doc'
      || type[1] === 'jpg'
      || type[1] === 'jpeg'
      || type[1] === 'png') {

      if (event.target.files[0].size < (2029410 / 2)) {
        this.API.uploadInvoice(formData,this.data['invoiceId']).subscribe(res => {
           
          this.resumeResponse = res;
          if (this.resumeResponse.success != true) {
            this.resumeLoader = false;
          }
          if (this.resumeResponse.success) {
            this.toastr.successToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
            this.dialogRef.close(this.resumeResponse.data);
          }
          
          this.sync = true;
        });
      } else {
        this.toastr.errorToastr('Resume file should be less than 1 MB.');
        this.resumeLoader = false;

      }
    } else {
      this.toastr.warningToastr('Only .pdf,.doc,.docx Support');
      this.resumeLoader = false;
      }
    }
  
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EnterpriseService } from '../enterprise.service';
import { first } from 'rxjs/operators';
import {AuthenticationService } from '../../app.service';
import { ShareService } from 'src/app/share-module/share.service';


@Component({
  selector: 'app-add-agency-group',
  templateUrl: './add-agency-group.component.html',
  styleUrls: ['./add-agency-group.component.css']
})
export class AddAgencyGroupComponent implements OnInit {
  AddAgencyGroupForm: FormGroup;
  issuccess: any;
  submitted: boolean = false;
  isAddform: boolean;
  userData: any;
  btnDisable: boolean = false;

  constructor(private api: EnterpriseService, private _router: Router, private formBuilder: FormBuilder, public dialog: MatDialog,
      private AuthenticationService: AuthenticationService,
      private dialogRef: MatDialogRef<AddAgencyGroupComponent>, private tostr: ToastrManager,private shareservice:ShareService,
     @Inject(MAT_DIALOG_DATA) public data: any) {

    this.AuthenticationService.jwtTokenExpCheck();
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.AddAgencyGroupForm = this.formBuilder.group({
      GroupName: ['', [Validators.required]],
      GroupDescription: ['', [Validators.required]],
      staffingAgencyGroup_Id: [''],
      createdDate:[''],
      usertype:this.userData.data.credentials.userType
    });
    if (this.data === true) {
      this.isAddform = true;
    } else {
      this.isAddform = false;
      this.GroupDataSetForUpdate(this.data);
    }
  }

  get pl() { return this.AddAgencyGroupForm.controls; }
  ngOnInit() {
  }
  clearData() {
    this.AddAgencyGroupForm.reset();
    this.dialogRef.close();
    this._router.navigate(['/ep/enterprise/staffingagencygroup']);

  }
  GroupDataSetForUpdate(id: any) {

    console.log('GroupDataSetForUpdate', id);

    this.api.GetAgencyGroupDetails(id).pipe(first()).subscribe(res => {
      if (res.success) {
        res.data.forEach(element => {
          this.AddAgencyGroupForm.controls['GroupName'].setValue(element.GroupName);
          this.AddAgencyGroupForm.controls['staffingAgencyGroup_Id'].setValue(element.staffingAgencyGroup_Id);
          this.AddAgencyGroupForm.controls['GroupDescription'].setValue(element.description);
        });
      }
    });
  }

  addGroupdetails() {   
    this.submitted = true;    
    if (this.AddAgencyGroupForm.valid) {
      this.btnDisable = true;
      if (this.AddAgencyGroupForm.controls['staffingAgencyGroup_Id'].value) {
        this.AddAgencyGroupForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        this.api.updateAgencyGroupdata(this.AddAgencyGroupForm.value).pipe(first()).subscribe(res => {
          this.issuccess = res;
          if (this.issuccess.success) {
            this.submitted = false;
            this.btnDisable = false;
            this.clearData();
            this.redirect();
           // window.location.reload();

            this.tostr.successToastr(this.issuccess.message, 'Success!');
          } else {
            this.tostr.errorToastr(this.issuccess.message, 'OPPS!');
          }
        });

      } else {
        this.AddAgencyGroupForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        this.api.insertStaffingGroupdata(this.AddAgencyGroupForm.value).pipe(first()).subscribe(res => {
          this.issuccess = res;
          if (this.issuccess.success) {
            this.submitted = false;
            this.clearData();
            this.redirect();
           // window.location.reload();

            this.tostr.successToastr(this.issuccess.message, 'Success!');
          } else {
            this.tostr.errorToastr(this.issuccess.message, 'OPPS!');
          }
        });
      }

    }
  }
  redirect() {    
    if(this.userData.data.credentials.userType == 'AD'){
      this._router.navigate(['/ad/admin/staffingagencygroup']);
    }else if(this.userData.data.credentials.userType == 'EP'){
      this._router.navigate(['/ep/enterprise/staffingagencygroup']);
    } 

  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

 // 20200929: Akhilesh, Special charcter validation, jira no 70
 omit_validate_specialchar(event: { charCode: any; }) {
  const k = event.charCode;   
   if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
     this.tostr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
     return false}
   }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-invoice-interprise-pding',
  templateUrl: './my-invoice-interprise-pding.component.html',
  styleUrls: ['./my-invoice-interprise-pding.component.css']
})
export class MyInvoiceInterprisePdingComponent implements OnInit {
  islstopen: boolean = false;
  invoiceFilter: FormGroup;
  constructor(private _formBuilder: FormBuilder,private _titleService:Title) {
    this.invoiceFilter = this._formBuilder.group({
      resume_status: ''
    })
  }

  ngOnInit() {
  }
  OpenlfMenu() { this.islstopen = !this.islstopen }


}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TableService } from 'src/app/admin/service';
import { AuthenticationService } from 'src/app/app.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { NewEnterpriseService } from 'src/app/enterprise/newenterprise.service';
import { Constant } from 'src/app/share-module/constant/constant.constants';
import { ShareService } from '../../share.service';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
  selector: 'app-preview-job',
  templateUrl: './preview-job.component.html',
  styleUrls: ['./preview-job.component.css']
})


export class PreviewJobComponent implements OnInit {

  enterpriseAddjob: FormGroup;
  jobId: any;
  Ajsubmitted: boolean = false;
  pageValue = false;
  userData: any = {};
  totalOpening = false;
  comparevalError: any;
  monthlist = [];
  isCountrySuccess: boolean = false;
  ListOfAllCountries: any;
  listofAllCountry:any;
  isStateSuccess: boolean = false;
  ListOfAllStates: any;
  isCitiesSuccess: boolean = false;
  ListOfAllCities: any;
  isSearchCitiesSuccess: boolean = false;
  SearchListOfAllCities: any;
  allCountryList : any =[];
  stateLoader:boolean= false;
  cityLoader:boolean= false;
  allStateList: any;
  country_Id: any;
  allCityList: Object;
  state_Id: any;
  fixed = true ;
  // payType = false;
  isfixed = false;
  isPreview = true;
  permission = true;
  constructor(private toastr:ToastrManager,private _formBuilder: FormBuilder , private AuthenticationService:AuthenticationService,private datepipe:DatePipe,
    private ActiveRouter: ActivatedRoute,private enterpriseService:NewEnterpriseService , private API: TableService , private shareservice:ShareService,
    private _router: Router,) {
    this.AuthenticationService.jwtTokenExpCheck();
    var newTitle = 'Verisource | Add New Job';
    debugger;
    // this.jobId = this.ActiveRouter.snapshot.params['id'];
    // this.jobId = (this.jobId !=undefined?1:1);
    this.ActiveRouter.queryParams.subscribe(params => {
      this.jobId = params['id'];
    });
    console.log('==================permission===',this.permission)
    this.userData = JSON.parse(window.localStorage.getItem('udata'));//Manu Datta: 20210224, VerisourceBugSheetAfterMerge/commonLogin 404
      console.log('==========',this.userData)

    this.enterpriseAddjob = this._formBuilder.group({
      "jobTitle": [""],
      "skills": [""],
      "jobDescription": [""],
      "totalOpenings": [""],
      "requiredExpLevelMin": [""],
      "requiredExpLevelMax": [""],
      "jobDuration":  [""],
      "startDate": [""],
      "endDate": [""],
      "street1": [""],
      "street2": [""],
      "country": [""],
      "state": [""],
      "city": [""],
      "postalcode": [""],
      "businessUnit":[""],
      "profitCenter":[""],
      "jobType":[""],
      "secondcountry": [""],
      "secondState":[""],
      "currency":[""],
      "rateType":[""],
      "payType":[],
      "minPay":[""],
      "maxPay":[""],
      "overTimeAllow":[""],
      "jobLocation": [""],    
      "factorPercent": [""],
      "totalJobBudget":[""],
      "note":[""],
      "history":[""],
      "createdDate":[""],
      "jobStatus":[""]

    })

    this.loadLookupData();
   }

  ngOnInit() {
  }

  lookUpInfo : any={};
  allCurrency : any={};
  profitCenter : any={};
  businessUnit : any={};
  rateType : any={};
  payType : any={};
  countries : any ={}
  states : any={}
  jobTypes : any={}
  Countries : any={}
  rateLocationStates : any=[]
  JobDetails : any={}
  rateLocation : any={}
  jobLocation : any={}
  previewCountry='';
  previewState='';
  previewCurrency='';
  previewRateType='';
  previewJobType='';
  previewCurrentCountry='';
  previewwSecondState = '';
  paytypeDisplayName='';
  month:Number;
  days:Number;
  remainingDays:Number;
  loadLookupData(){
  debugger
    this.AuthenticationService.jwtTokenExpCheck();
     this.enterpriseService.loadLookUpData(Number(this.jobId)).subscribe((res: any={}) => {
        if (res.status == 200) {
            if(res.body){
            this.lookUpInfo = res.body;
            console.log('res.data=========',this.lookUpInfo);
            this.allCurrency = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.Currency);
            this.rateType = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.RateType);
            this.payType = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.PayType);
            this.jobTypes = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.JobType);
            this.rateLocation = this.lookUpInfo.rateLocations;
            this.jobLocation = this.lookUpInfo.jobLocations;
            this.profitCenter = this.lookUpInfo.allProfitCenter;
            this.businessUnit = this.lookUpInfo.allBusinessUnit;
            this.countries = this.lookUpInfo.countries;
            this.states = this.lookUpInfo.states;
            if(this.jobId != 0 && this.jobId != undefined){
              const keywords = this.lookUpInfo.jobKeywords.split(',');
            this.enterpriseAddjob.controls['jobTitle'].setValue(this.lookUpInfo.jobTitle);
            this.enterpriseAddjob.controls['jobDescription'].setValue(this.lookUpInfo.jobDescription);
            this.enterpriseAddjob.controls['skills'].setValue( keywords);
            this.enterpriseAddjob.controls['totalOpenings'].setValue(this.lookUpInfo.totalOpenings);
            this.enterpriseAddjob.controls['startDate'].setValue(this.datepipe.transform(this.lookUpInfo.startDate, "yyyy-MM-dd"));
           
            this.enterpriseAddjob.controls['endDate'].setValue(this.datepipe.transform(this.lookUpInfo.endDate, "yyyy-MM-dd"));
            this.enterpriseAddjob.controls['requiredExpLevelMin'].setValue(this.lookUpInfo.requiredExpLevelMin);
            this.enterpriseAddjob.controls['requiredExpLevelMax'].setValue(this.lookUpInfo.requiredExpLevelMax);
            this.month = Math.floor(this.lookUpInfo.jobDuration/30);
            this.remainingDays = Math.floor(this.lookUpInfo.jobDuration % 30);
            this.enterpriseAddjob.controls['jobDuration'].setValue(Number(this.month));
            this.enterpriseAddjob.controls['totalJobBudget'].setValue( this.lookUpInfo.totalJobBudget);
            this.enterpriseAddjob.controls['street1'].setValue(this.lookUpInfo.street1);
            this.enterpriseAddjob.controls['street2'].setValue(this.lookUpInfo.street2);
            this.enterpriseAddjob.controls['country'].setValue(this.jobLocation[0].country);
            this.enterpriseAddjob.controls['state'].setValue(this.jobLocation[0].state);
            this.enterpriseAddjob.controls['city'].setValue(this.jobLocation[0].city);
            this.enterpriseAddjob.controls['secondcountry'].setValue(this.rateLocation[0].country);
            //this.LoadAllState(false);
            this.enterpriseAddjob.controls['secondState'].setValue(this.rateLocation[0].state);
            this.enterpriseAddjob.controls['postalcode'].setValue(this.jobLocation[0].zipCode);
            this.enterpriseAddjob.controls['profitCenter'].setValue(this.lookUpInfo.profitCenter);
            this.enterpriseAddjob.controls['businessUnit'].setValue(this.lookUpInfo.businessUnit);
            this.enterpriseAddjob.controls['jobType'].setValue(this.lookUpInfo.jobType);
            this.enterpriseAddjob.controls['rateType'].setValue(this.lookUpInfo.rateType);
            this.enterpriseAddjob.controls['payType'].setValue(this.lookUpInfo.payType);
            const filteredPayType:any = this.payType.filter(x=>x.lookUpId ==  this.enterpriseAddjob.controls['payType'].value);
            this.onChangeRange(this.lookUpInfo.payType);            
            this.enterpriseAddjob.controls['minPay'].setValue(this.lookUpInfo.minPay);
            this.enterpriseAddjob.controls['maxPay'].setValue(this.lookUpInfo.maxPay);
            this.enterpriseAddjob.controls['overTimeAllow'].setValue(this.lookUpInfo.overtimeAllow.toString());
            this.enterpriseAddjob.controls['factorPercent'].setValue(this.lookUpInfo.factorPercent);
            this.enterpriseAddjob.controls['note'].setValue(this.lookUpInfo.note); 
            this.enterpriseAddjob.controls['currency'].setValue(this.lookUpInfo.currency);  
            this.enterpriseAddjob.controls['jobStatus'].setValue(this.lookUpInfo.jobStatus);
            debugger
            this.previewCountry = this.countries.filter(x=>x.id == this.enterpriseAddjob.controls['country'].value)[0].country_Name;
            this.previewState = this.states.filter(x=>x.id == this.enterpriseAddjob.controls['state'].value)[0].state_Name;
            this.previewCurrentCountry = this.countries.filter(x=>x.id == this.enterpriseAddjob.controls['secondcountry'].value)[0].country_Name;
            this.LoadAllState(false);
            if(this.ratelocationStates.length > 0){
              this.previewwSecondState = this.ratelocationStates.filter(x=>x.id == this.enterpriseAddjob.controls['secondState'].value)[0].state_Name;
            }
            debugger
            this.previewCurrency = this.allCurrency.filter(x=>x.id == this.enterpriseAddjob.controls['currency'].value)[0].displayName;
            this.previewRateType = this.rateType.filter(x=>x.id == this.enterpriseAddjob.controls['rateType'].value)[0].displayName;
            this.previewJobType = this.jobTypes.filter(x=>x.id == this.enterpriseAddjob.controls['jobType'].value)[0].displayName;
            this.paytypeDisplayName = this.payType.filter(x=>x.id ==  this.enterpriseAddjob.controls['payType'].value)[0].displayName;   
            }
            console.log('===================',this.lookUpInfo[0]);

          }
        }
        else if(res.status == 403){
          this._router.navigate(['/errorpage']);
        }
      });
   }

  //  LoadAllState(type:boolean){
  //   return new Promise((resolve, reject) => {
  //     debugger;
  //     this.enterpriseService.LoadAllState(this.enterpriseAddjob.controls['secondcountry'].value).subscribe((resp:any) => {
  //       if (resp) {
  //         this.rateLocationStates = resp;
  //         this.previewwSecondState = this.rateLocationStates.filter(x=>x.id == this.enterpriseAddjob.controls['secondState'].value)[0].state_Name;
  //         resolve(this.rateLocationStates);
  //       } else {
  //         reject("Error!");
  //       }
  //     });
  //   });

  // }

  ratelocationStates : any=[]
  preLoader =false;
  rateStateList: any;
  rateStateloader:boolean =false;
  LoadAllState(type:boolean){
    debugger
    this.preLoader =true;
    this.enterpriseAddjob.controls['secondcountry'].setValue(type==true?this.enterpriseAddjob.controls['country'].value:this.enterpriseAddjob.controls['secondcountry'].value);
    return new Promise((resolve, reject) => {
      debugger;
      this.enterpriseService.LoadAllState(type==true?this.enterpriseAddjob.controls['country'].value:this.enterpriseAddjob.controls['secondcountry'].value).subscribe((res:any) => {
        if(res) {
          // resolve(this.states);
          //this.states = res.data;
          if(type){
            this.states = res;
            if(this.enterpriseAddjob.controls['secondcountry'].value == this.enterpriseAddjob.controls['country'].value){
              this.ratelocationStates = res;
            }
            resolve(this.states);
            this.preLoader = false;
            // this.allStateList=res;
            this.stateLoader = false;
            this.isStateSuccess = true;
          }
          else{
            this.ratelocationStates =res;
            this.previewwSecondState = this.ratelocationStates.filter(x=>x.id == this.enterpriseAddjob.controls['secondState'].value)[0].state_Name;
            this.preLoader =false;
            this.rateStateList =res;
            this.rateStateloader =false;

          }
          // this.enterpriseAddjob.controls['state'].setErrors({ 'incorrect': true });
        } 
        else {
          reject("Error!");
        }
      });
    });

  }

   onChangeRange(field_no: number) {
    
    this.isfixed = field_no == 11 ? true : false;
    if(field_no == 10){
      this.isfixed = false;
      this.enterpriseAddjob.controls["minPay"].setValue((this.lookUpInfo.minPay != null || this.lookUpInfo.minPay != undefined)?this.lookUpInfo.minPay:"");
      this.enterpriseAddjob.controls["maxPay"].setValue((this.lookUpInfo.maxPay != null || this.lookUpInfo.maxPay != undefined)?this.lookUpInfo.maxPay:"");
    }
    else{
      this.isfixed = true;
      this.enterpriseAddjob.controls["minPay"].setValue((this.lookUpInfo.minPay != null || this.lookUpInfo.minPay != undefined)?this.lookUpInfo.minPay:"");
      this.enterpriseAddjob.controls["maxPay"].setValue((this.lookUpInfo.maxPay != null || this.lookUpInfo.minPay != undefined)?this.lookUpInfo.maxPay:"");
    }
  } 

   updateresponse: any;
   updateJobRequestStatus(jobStatus) {
    debugger;
    console.log('==============',this.enterpriseAddjob.value)

    if(this.jobId != undefined){       
        if (this.userData.data.credentials.userType === 'EP') {    
          debugger 
          this.enterpriseService.updateJobStatus(this.jobId,jobStatus).subscribe((res :any)=> {
            if(res.status == 200) {
              this.toastr.successToastr("Job status successfully updated.", "", {
                position: "top-right"
              });
              this._router.navigateByUrl("/jobRequest");
            } 
            else if(res.status == 403){
              this._router.navigate(['/errorpage']);
            }
            else {
              this.toastr.errorToastr("Job status not successfully updated.", "", {
                position: "top-right"
              });
            }
          });
        }

    }      
    }



}

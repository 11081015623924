import {
  Component,
  OnInit,
  Inject,
  Renderer2,
  OnDestroy,
  ViewContainerRef,
  Injectable
} from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  Validators,
  FormGroup,
  FormBuilder
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import {
//   AuthService,
//   FacebookLoginProvider,
//   GoogleLoginProvider,
//   LinkedinLoginProvider
// } from 'angular-6-social-login';
// import { LoginComponent } from '../login/login.component';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../app.service';
import { componentServices } from '../component.service';
import $ from 'jquery';
import { Title } from '@angular/platform-browser';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { JobseekerService } from 'src/app/JobSeeker/jobseeker.service';
import { first } from 'rxjs/operators';
import { TableService } from 'src/app/admin/service/table.service';
import { environment } from 'src/environments/environment';
import { JwtService } from 'src/app/admin/service/jwt.service';
import { decode } from 'punycode';
import { ShareService } from 'src/app/share-module/share.service';
//import { ResetWarningPopUpComponent } from 'src/app/share-module/reset-warning-pop-up/reset-warning-pop-up.component';

export interface DialogData {
  jobseekerId: any;
  animal;
  name;
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html'
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
//@Injectable()
export class HeaderComponent implements OnInit, OnDestroy {

  loginId:any;
  loginId1:any;
  preloader: boolean;
  isaddNewform: boolean = true;
  routerPath = '';
  signupForm: FormGroup;
  signupFormJobSeeker: FormGroup;
  linkActive = 'dashboard';
  forgateRes: any;
  signupFormStaffing: FormGroup;
  signupEnterpriseForm: FormGroup;
  submitted = false;
  CompName = '';
  Website = '';
  firstName = '';
  middlName = '';
  lastName = '';
  email = '';
  password = '';
  loginForm: FormGroup;
  adminloginForm: FormGroup;
  loading = false;
  returnUrl;
  userCredentials = {};
  credentials = {};
  countryList: any = [];
  securityQuestionsList: any = [];
  stateList: any = [];
  stateLoader = false;
  forgotForm: FormGroup;
  resetForm: FormGroup;
  routerClass;
  type: string;
  user: string;
  loginType = '';
  userData: any = {};
  userDetails: any = {};
  userDataShow = false;
  loginMsg = '';
  headerList = 'GN';
  isLogin = 'false';
  loginValue = 'true';
  isPasswordReset = false
  isPasswordResetCheck = 'false'

  entReg: any;
  jobReg: any;
  staffReg: any;
  loginTypeSelet = 'Login';
  notifyenterpriseData: any;
  notifyData: any;
  announceData: any;
  announceToggle = false;
  announceLIstData = false;
  img_path = '/assets/images/user.svg';
  select_img_path = '/assets/images/user.svg';
  localStoragerData: any = {};
  userLoginData: any = {};
  isCollapsed = true;
  isCollapsedMenu = false;
  islogedCollapsedMenu = false;
  isOn = false;
  isOnsignUp = false;
  isOnjobsk = true;
  isOnentp = false;
  isOnstaffg = false;
  isAdmin = true;
  isOnForgot = false;
  isOnreset = false;
  rescetActivityData: any;
  cityList: any = [];
  cityLoader = false;
  profileStatus: string;
  recentAct: boolean = false;
  isrequire = true;
  islstopen: boolean = false;
  announcShow: boolean = false;
  paginator = {
    pageSizeOptions: [5, 10, 20, 100],
    RA: {
      pageSize: 5,
      length: 0,
      pageSizeOptions: [5, 10, 20, 100]

    }, AN: {
      pageSize: 5,
      length: 0,
      pageSizeOptions: [5, 10, 20, 100]
    }
  }
  AdminDetails: any;
  Admin_Img_Path = '/assets/img/user.svg';
  img_data: string;
  imgName: any;
  isImgExits: boolean = false;
  userDataCheck: any = {};
  todayDate = new Date;
  dbdate = new Date;
  diffDays: number;
  showPasswordWarning: boolean = false;
  showPasswordWarningAfterExpire: boolean = false;
  remainingdays: number;
  profileImageName: string;
  profileImagePath: string;
  staffReg1: any;
  issuccess: any;
  aa: any = '';
  dataValue: any;
  countyvalue: Object;
  admincheck: any;
  firstname1: any = '';
  lastname1: any = '';
  profileImage1: any = '';
  registerBy1: any = '';
  img_path2: any;

  constructor(
    private formBuilder: FormBuilder,
    private apiAdmin: TableService,
    private dialog: MatDialog,
    private router: Router,
    private renderer: Renderer2,
    private API: AuthenticationService,
    private componentAPI: componentServices,
    // private socialAuthService: AuthService,
    private toastr: ToastrManager,
    private staffAPI: staffingAgencyServices,
    private eterpriseAPI: EnterpriseService, private datePipe: DatePipe,
    private jwtService: JwtService,
    private JSApi: JobseekerService, private AuthenticationService: AuthenticationService, private shareservice: ShareService) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.profileImageName = localStorage.getItem('profileImageName');
   
    //this.img_path = `${this.API.url}/api/auth/image/${this.profileImageName}`;
   // this.img_path = localStorage.getItem('profileImagePath');
    
   this.select_img_path = localStorage.getItem('selectprofileImagePath');
    if(this.select_img_path != null){
    this.img_path = this.select_img_path;
  }else {
    this.img_path = localStorage.getItem('profileImagePath');
  }
    this.imgName = this.profileImageName;
    //this.ImageFileExits();


    this.API.countries().subscribe((res: any) => {
      if (res.success) {
        this.countryList = res.data;
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });

    this.API.getSecurityQuestions().subscribe((res: any) => {
      if (res.success) {
        this.securityQuestionsList = res.data;
      } else {
      }
    });

    this.locatstoreData().then((response: any) => {
      if (this.isLogin === 'true' && response === null) {
        this.logout();
      } 
     if (this.isLogin === 'true' && response != null) {
        // this.componentAPI.headernotification(response.data.token, response.data.credentials.userType).subscribe((res: any) => {
        //   if (res.message === 'Failed to authenticate token.') {
        //     this.logout();
        //   }
        //   else {return;}
        // });
      } 

    });



    // this.staffingNotificationlist(1, 5);
    // this.enterpriseNotificationlist(1, 5);

    this.getLoginDetail();
   
    this.isLogin = localStorage.getItem('islogin');

    this.isPasswordResetCheck = localStorage.getItem('isPasswordReset');
    if (this.isPasswordResetCheck == 'true') {
      this.isPasswordReset = true
    }
    else {
      this.isPasswordReset = false
    }
    this.signUpFormInit();
    this.forminit();
    this.forgotForm = this.formBuilder.group({
      emailId: ['', [Validators.required, Validators.email]],
      userType: ['']
    });
    this.resetForm = this.formBuilder.group({
      password: [''],
      confirmpassword: ['']
    });
  }

  // toggletabOpen(){this.istabopen = ! this.istabopen;}
  OpenlfMenu() { this.islstopen = !this.islstopen }

  ngOnInit() {
 
    setTimeout(() => {

      this.preloader = true;
      this.router.events.subscribe(val => {
        const a: any = val;
        if (a !== undefined && a.route !== undefined && a.route.hasOwnProperty('url')) {
          this.routerClass = a.route.url.replace('/', '');
        }
        this.isLogin = localStorage.getItem('islogin');
        window.scrollTo(0, 0);
      });
      
      this.userData = JSON.parse(window.localStorage.getItem('udata'));
      this.userDataShow = true;
      if (this.isLogin === 'true') {
        this.headerList = this.userData.data.credentials.userType;
        // NotificATION TRIGGRE ON LOAD
        if (window.location.pathname.includes('dashboard') === true || window.location.pathname == "/") {
          this.preloader = false;
          // this.getNotification(localStorage.getItem('token'));  
          // this.getDocusignStatus();
          switch (this.headerList) {
            case 'SA': {   
              if(!this.docusignstatus){
                this.router.navigate(['/st/staff/docusign']).then(() => {
                  window.location.reload();
                });
              }
              else{
                this.commonPasswordDetails();
                if (this.remainingdays <= 0) { this.router.navigate(['/st/staff/resetpassword']); } else { this.router.navigate(['/st/staff/dashboard']); }             
              }
              // this.router.navigate(['/st/staff/dashboard']);
              this.preloader = false;
              var profileimagename = this.userData.data.credentials.profileImage;
              localStorage.setItem('profileImageName', profileimagename);
              //this.img_path = `${this.API.url}/api/auth/image/${this.userData.data.credentials.profileImage}`;
              var profileImagePath = this.img_path = this.userData.data.credentials.profileImagePath;
              localStorage.setItem('profileImagePath', profileImagePath);
              if(this.img_path !=null){
                this.isImgExits = true;
              }
              this.imgName = this.userData.data.credentials.profileImage;
              //this.ImageFileExits();
              break;
            }
            case 'EP': {
            this.router.navigate(['/dashboard']);
              // if (this.remainingdays <= 0) { this.router.navigate(['/ep/enterprise/resetpassword']); } else { this.router.navigate(['/ep/enterprise/dashboard']); }
             
              this.preloader = false;
              var profileimagename = this.userData.data.credentials.profileImage;
              localStorage.setItem('profileImageName', profileimagename);
              //this.img_path = `${this.API.url}/api/auth/image/${this.userData.data.credentials.profileImage}`;
              var profileImagePath = this.img_path = this.userData.data.credentials.profileImagePath;
              this.imgName = this.userData.data.credentials.profileImage;
              // var profileImagePath = this.img_path = this.userData.data.credentials.profileImagePath;
              localStorage.setItem('profileImagePath', profileImagePath);
              if(this.img_path !=null){
                this.isImgExits = true;
              }
              //this.ImageFileExits();
              break;
            }
            case 'JS': {
             
              //this.router.navigate(['/js/jobseeker/dashboard']);
              if (this.remainingdays <= 0) { this.router.navigate(['/js/jobseeker/resetpassword']); } else { this.router.navigate(['/js/jobseeker/dashboard']); }
              this.preloader = false;
              var profileimagename = this.userData.data.credentials.profileImage;
              localStorage.setItem('profileImageName', profileimagename);
              //this.img_path = `${this.API.url}/api/auth/image/${this.userData.data.credentials.profileImage}`;
          
              this.imgName = this.userData.data.credentials.profileImage;
              var profileImagePath = this.img_path = this.userData.data.credentials.profileImagePath;
              localStorage.setItem('profileImagePath', profileImagePath);
              if(this.img_path !=null){
                this.isImgExits = true;
              }
              //this.ImageFileExits();
              break;
            }
            case 'AD': {
              this.LoadAdminProfile();
              this.router.navigate(['/ad/admin/dashboard']);
              this.preloader = false;
              this.img_data = 'abc';
              var profileimagename = this.userData.data.credentials.profileImage;
              localStorage.setItem('profileImageName', profileimagename);
              //this.img_path = `${this.API.url}/api/auth/image/${this.userData.data.credentials.profileImage}`;
              var profileImagePath = this.img_path = this.userData.data.credentials.profileImagePath;
              localStorage.setItem('profileImagePath', profileImagePath);
              if(this.img_path !=null){
                this.isImgExits = true;
              }
              this.imgName = this.userData.data.credentials.profileImage;
              //this.ImageFileExits();
              break;
            }
          }
        }

        else {
       
          var profileimagename = this.userData.data.credentials.profileImage;
         // localStorage.setItem('profileImageName', profileimagename);
          this.preloader = false;
         // var profileImagePath = this.img_path = this.userData.data.credentials.profileImagePath;
           //   localStorage.setItem('profileImagePath', profileImagePath);
              // if(this.img_path !=null){
              //   this.isImgExits = true;
              // }             
            }
      } else {
        this.preloader = false;
        this.headerList = 'GN';
      }

      //  set header according to login type Ends
    }, 100);

    if (localStorage.getItem('udata') === undefined && this.isLogin === 'true') {
      localStorage.setItem('islogin', 'false');
      localStorage.removeItem('token');
      localStorage.removeItem('udata');
      localStorage.removeItem('profileImagePath');
      localStorage.removeItem('profileImageName');
      localStorage.removeItem('CreatedBy');
      localStorage.removeItem('interpriseId');
      this.router.navigate['/auth/home'];
      this.headerList = 'GN';
      this.routerClass = '';
      this.loginMsg = '';
      this.isLogin = 'false';
      this.loading = false;
      return false;
    }

    this.routerPath = window.location.pathname;
    if (this.isLogin === 'false' && (this.routerPath.includes('/ep/') === true || this.routerPath.includes('/js/') || this.routerPath.includes('/st/') || this.routerPath.includes('/ad/') === true )) {
      localStorage.setItem('islogin', 'false');
      localStorage.removeItem('token');
      localStorage.removeItem('udata');
      this.headerList = 'GN';
      this.routerClass = '';
      this.loginMsg = '';
      this.isLogin = 'false';
      this.loading = false;
      window.location.href = '/auth/home';
    }
    this.headerInit();

    this.getLoginDetail();
    //this.getDocusignStatus();
    this.recentActivityFn(5, 1);
    this.announceMentlist(1, 5)
  }
  LoadAdminProfile() {
    //this.preloader = true;
     
    this.apiAdmin.adminMyProfile().pipe(first()).subscribe(res => {
      if (res.success) {
        // this.showData=true;
        // this.preloader = false;
        this.AdminDetails = res;
        // if (this.AdminDetails.data.profileImage != null) {
        //   //this.Admin_Img_Path = `${environment.url}/api/admin/image/profileimage/${this.AdminDetails.data.profileImage}`;
        //   this.Admin_Img_Path = this.userData.data.credentials.profileImagePath;
        // }
      }
    });
  }

  forminit() {
 
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: [''],
      userType: [''],
      userEmail: [''],
      typeUser: ['']
    });
  }
  // forgot password
  get fg() {
    return this.forgotForm.controls;
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k < 48 && k > 57));
  }

  signUpFormInit() {

    this.signupFormJobSeeker = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      middleName: [''],
      lastName: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: [''],
      contactNo: ['', Validators.required],
      // "isAdmin": [true],
      JobRecomendd: [true],
      rememberme: [true],
      terms: [false, Validators.required],
      createdDate: [''],
      vdJobskrId: ['']
    });

    // staffing Aggengy start
    this.signupFormStaffing = this.formBuilder.group({
      companyName: ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      website: ['', [Validators.required, Validators.pattern("(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
      firstName: ['', [Validators.required]],
      middleName: ['', [Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      lastName: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      streetName: ['', [Validators.required]],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zipcode: ['', Validators.required],
      securityQuestions: ['', Validators.required],
      answer: ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      top_5_Skills: ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      password: [''],
      contactNo: ['', Validators.required],
      JobRecomendd: [true],
      rememberme: [false],
      terms: [false, Validators.required],
      createdDate: [''],
      vdStaffingId: [''],
      createdby: [''],
      registerBy: ['']
    });

    // staffing Aggengy Ends
    // Enterprise start

    this.signupEnterpriseForm = this.formBuilder.group({
      companyName: ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      website: ['', [Validators.required, Validators.pattern("(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
      firstName: ['', [Validators.required]],
      middleName: ['', [Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      lastName: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: [''],
      contactNo: ['', Validators.required],
      isAdmin: [true],
      JobRecomendd: [true],
      rememberme: [true],
      terms: [false, Validators.required],
      securityQuestions: ['', Validators.required],
      answer: ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      createdDate: [''],
      vdEntpId: [''],
      CreatedBy: ['']

    });
    // Enterprise Ends
  }

  OnChangePhoneNo(tab, e) {
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== '' && e.keyCode != 8) {
      if (tab === 'EP') {
        this.signupEnterpriseForm.controls['contactNo'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (tab === 'SA') {
        this.signupFormStaffing.controls['contactNo'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (tab === 'JS') {
        this.signupFormJobSeeker.controls['contactNo'].setValue(npa + '-' + nxx + '-' + last4);
      }
    }
  }
  addForm() { }
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  toggleCollapseMenu() {
    this.isCollapsedMenu = !this.isCollapsedMenu;
  }
  logedCollapseMenu() {
    this.islogedCollapsedMenu = !this.islogedCollapsedMenu;
  }
  parentfun() {
  }
  popUpreset() {
    this.isOnreset = !this.isOnreset;
  }

  Popuptoggle() {
    if (this.loginType !== '') {
      this.isOn = !this.isOn;
      this.isCollapsed = true;
    } else {
      this.toastr.infoToastr('Please Select login Type!', 'Warning!', {
        position: 'top-right'
      });
    }
  }

  async locatstoreData() {
    this.isLogin = await localStorage.getItem('islogin');
    if (localStorage.getItem('udata')) {
      this.localStoragerData = JSON.parse(localStorage.getItem('udata'));
      return Promise.resolve().then(() => {
        return JSON.parse(localStorage.getItem('udata'));
      });
    }
  }

  recentActivityFn(pageSize, pageNumber) {
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.userDataShow = true;
    if (this.isLogin === 'true') {
      this.headerList = this.userData.data.credentials.userType;
      if (this.headerList == 'SA') {
        this.staffAPI.recentActivity(pageSize, pageNumber).subscribe((res: any) => {
          if (res.success) {
            this.rescetActivityData = res;
            this.recentAct = true;
            if (this.rescetActivityData.data.length > 0) {
              this.paginator.RA.length = this.rescetActivityData.data[0].totalCount;
            } else {
              this.paginator.RA.length = 0;
            }
          }
        });
      }

      if (this.headerList == 'EP') {
        // this.eterpriseAPI.recentActivity(pageSize, pageNumber).subscribe((res: any) => {
        //   if (res.success) {
        //     this.rescetActivityData = res;
        //     this.recentAct = true;
        //     if (this.rescetActivityData.data.length > 0) {
        //       this.paginator.RA.length = this.rescetActivityData.data[0].totalCount;
        //     } else {
        //       this.paginator.RA.length = 0;
        //     }
        //   }
        // });
      }

      if (this.headerList == 'JS') {
        this.JSApi.recentActivity(pageSize, pageNumber).subscribe((res: any) => {
          if (res.success) {
            this.rescetActivityData = res;
            this.recentAct = true;
            if (this.rescetActivityData.data.length > 0) {
              this.paginator.RA.length = this.rescetActivityData.data[0].totalCount;
            } else {
              this.paginator.RA.length = 0;
            }
          }
        });
      }
    }
  }

  announceMentlist(pageno, size) {
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.userDataShow = true;
    if (this.isLogin === 'true') {
      this.headerList = this.userData.data.credentials.userType;
      if (this.headerList == 'SA') {
        this.staffAPI.announcement(pageno, size).subscribe((res: any) => {
          if (res.success) {
            this.announceData = res;
            this.announcShow = true;
            if (this.announceData.data.length > 0) {
              this.paginator.AN.length = this.announceData.data[0].totalCount;
            } else {
              this.paginator.AN.length = 0;
            }
          }
        });
      }
      if (this.headerList == 'EP') {
        // this.eterpriseAPI.announcement(pageno, size).subscribe((res: any) => {
        //   if (res.success) {
        //     this.announceData = res;
        //     this.announcShow = true;
        //     if (this.announceData.data.length > 0) {
        //       this.paginator.AN.length = this.announceData.data[0].totalCount;
        //     } else {
        //       this.paginator.AN.length = 0;
        //     }
        //   }
        // });
      }
      if (this.headerList == 'JS') {
        this.JSApi.announcement(pageno, size).subscribe((res: any) => {
          if (res.success) {
            this.announceData = res;
            this.announcShow = true;
            if (this.announceData.data.length > 0) {
              this.paginator.AN.length = this.announceData.data[0].totalCount;
            } else {
              this.paginator.AN.length = 0;
            }
          }
        });
      }
    }
  }
  Popupforgot() {
    this.isOnForgot = !this.isOnForgot;
  }
  PopupsignUptoggle() {
    this.isOnsignUp = !this.isOnsignUp;
  }

  Popupjobsk(val) {
    if (val === 'tab1') {
      // this.selectLoginType('EP');
      // this.loginTypeSelet = 'Enterprise';
      this.loginTypeSelet = 'Client';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnentp = true;
      this.isOnjobsk = false;
      this.isOnstaffg = false;
      this.forminit();
      //this.showUserEmail =false;
      //this.showUser = false;
      this.isAdmin = false;
    } else if (val === 'tab2') {
      // this.selectLoginType('SA');
      // this.loginTypeSelet = 'Staffing Agency';
      this.loginTypeSelet = 'Supplier';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = false;
      this.isOnentp = false;
      this.isOnstaffg = true;
      this.forminit();
      //this.showUserEmail =false;
      //this.showUser = false;
      this.isAdmin = false;
    } else if (val === 'tab3') {
      // this.selectLoginType('JS');
      this.loginTypeSelet = 'Job Seeker';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = true;
      this.isOnentp = false;
      this.isOnstaffg = false;
      this.forminit();
      //this.showUserEmail =false;
      this.isAdmin = false;
    }
    else if (val === 'tab4') {
      this.forminit();
      // this.selectLoginType('ADMIN');
      this.loginTypeSelet = 'Admin';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = true;
      this.isOnentp = false;
      this.isOnstaffg = false;
    }
  }

  PostJobFromGuest(type) {
    this.router.navigateByUrl('/');
    this.isCollapsed = false;
    this.selectLoginType(type);
    this.Popuptoggle();
  }
  ChangeLoginType(val) {
    // this.isCollapsed = false;
    if (val === 'tab1') {
      this.forminit();
      // this.selectLoginType('EP');
      this.loginType = 'EP';
      // this.loginTypeSelet = 'Enterprise';
      this.loginTypeSelet = 'Client';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnentp = true;
      this.isOnjobsk = false;
      this.isOnstaffg = false;
      this.isAdmin = false;
      //this.showUserEmail =false;
      //this.showUser=false;
    } else if (val === 'tab2') {
      this.forminit();
      // this.selectLoginType('SA');
      this.loginType = 'SA'
      this.loginTypeSelet = 'Supplier';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = false;
      this.isOnentp = false;
      this.isOnstaffg = true;
      this.isAdmin = false;
      //this.showUserEmail =false;
      this.signupFormStaffing.controls['securityQuestions'].setValue(this.securityQuestionsList);
    } else if (val === 'tab3') {
      this.forminit();
      console.log('show', this.loginForm)
      // this.selectLoginType('JS');
      this.loginType = 'JS'
      this.loginTypeSelet = 'Job Seeker';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = true;
      this.isOnentp = false;
      this.isOnstaffg = false;
      this.isAdmin = false;
      //this.showUserEmail =false;
    }
    else if (val === 'tab4') {
      this.forminit();
      localStorage.clear();
      // this.selectLoginType('ADMIN');
      this.loginType = 'Admin'
      this.loginTypeSelet = 'Admin';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = false;
      this.isOnentp = false;
      this.isOnstaffg = false;
      this.isAdmin = true;
      this.showUserEmail = false;
      this.disableLogin = true;
      //this.showUser=true;
    }
  }

  selectLoginType = val => {
    this.loginType = val;
    this.isCollapsed = !this.isCollapsed;
    switch (val) {
      case 'EP': {
        this.forminit();
        // this.loginTypeSelet = 'Enterprise';
        this.loginTypeSelet = 'Client';
        this.isOnjobsk = false;
        this.isOnentp = true;
        this.isOnstaffg = false;
        this.isAdmin = false;
        
        this.Popuptoggle();
        break;
      }
      case 'JS': {
        this.forminit();
        this.loginTypeSelet = 'Job Seeker';
        this.isOnjobsk = true;
        this.isOnentp = false;
        this.isOnstaffg = false;
        this.isAdmin = false;
        this.Popuptoggle();
        break;
      }
      case 'SA': {
        this.forminit();
        this.loginTypeSelet = 'Supplier';
        this.isOnjobsk = false;
        this.isOnentp = false;
        this.isOnstaffg = true;
        this.Popuptoggle();
        this.isAdmin = false;
        break;
      }
      case 'ADMIN': {
        this.forminit();
        this.showUserEmail=false;
        this.userEmailCheck = false;
        this.submitted = false;
        this.loginTypeSelet = 'Admin';
        this.isOnjobsk = false;
        this.isOnentp = false;
        this.isOnstaffg = false;
        this.isAdmin = true;
        this.Popuptoggle();
        //this.showUser=true;
        break;
      }
      default: {
        this.loginTypeSelet = 'Login';
        break;
      }
    }
  }

  emitterLogin(val) {
    this.isLogin = val;
  }


  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-close');
  }
  clicked = false;
  // staffing Signup start

  stIds:Number;
  staffingSignup() {
debugger;
    this.submitted = true;
    if (this.signupFormStaffing.controls['terms'].value) {
      if (this.signupFormStaffing.valid) {
        this.signupFormStaffing.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        // this.API.staffRegisterVD(this.signupFormStaffing.value).subscribe(res => {
        //   this.staffReg = res;
        //   console.log('this.staffReg', this.staffReg);
        //   this.signupFormStaffing.controls['vdStaffingId'].setValue(this.staffReg.data)

         // if (this.staffReg.success) {
          this.headerList = 'SA';
          debugger;
            this.API.staffRegister(this.signupFormStaffing.value).subscribe((res:any) => {

              //this.staffReg1 = res;            
              //this.isOnsignUp = false;
              if (res.success && Number(res.data.empId) > 0) {
                this.stIds = res.data.empId;
                console.log('this.signupFormStaffing.value', this.signupFormStaffing.value);

                // const user = this.signupFormStaffing.controls['emailId'].value;
                // const pass = this.signupFormStaffing.controls['password'].value;
                // this.loginForm.controls['email'].setValue(user);
                // this.loginForm.controls['password'].setValue(pass);
                // this.signupFormStaffing.reset();
                // this.isOnsignUp = false;
                // this.onSubmit();

                this.API.staffRegisterVD(this.signupFormStaffing.value,Number(res.data.empId)).subscribe(resp => {
                this.staffReg = resp;
                if (this.staffReg.success) {              
                  debugger;
                  this.headerList = 'SA';
                  this.API.UpdateIdFromVeridial(Number(this.stIds),Number(this.staffReg.data),'SA').subscribe(async (response: any) => {
                  console.log('===========',response);  
                  if(response.success){
                    this.signUpFormInit();
                    this.toastr.successToastr('StaffingAgency successfully created.')
                    this.isOnsignUp = false;
                    this.isOn = true;
                  }     
                  else{
                    this.toastr.errorToastr(response.message, 'Error!');
                  }
                 // this.headerList = 'SA'
                  //this.staffReg = res.data;
                  //this.onSubmit();
                  });  
                 
                }
                else {
                  this.toastr.errorToastr(this.staffReg.message, 'Error!');
                  this.isOnsignUp = true;

                }
                console.log('this.staffReg', this.staffReg);
                this.signupFormStaffing.controls['vdStaffingId'].setValue(this.staffReg.data)
                });
              }
              else {
                this.toastr.errorToastr(res.message, 'Error!');
                this.isOnsignUp = true;
              }
            });

          // } else {
          //   this.toastr.errorToastr(this.staffReg.message, 'Error!');
          //   this.isOnsignUp = true;
          // }
        //});
      } else {
        return false;
      }
    } else {
      this.clicked = false;
      this.toastr.errorToastr('Please accept Terms and Privacy Policy.', 'Alert!', {
        position: 'top-right'
      });
      return false;
    }
  }
  // staffing Signup Ends

  Epsubmitted = false;
  epIds:Number;
  enterpriseSignup() {
    this.Epsubmitted = true;
    if (this.signupEnterpriseForm.controls['terms'].value) {
      if (this.signupEnterpriseForm.valid) {
        this.signupEnterpriseForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));

        // this.API.enterpriseRegisterVD(this.signupEnterpriseForm.value).subscribe((res: any) => {

        //   this.entReg = res;

        //   //this.signupEnterpriseForm.controls['vdEntpId'].setValue(this.entReg.data);
        //   this.signupEnterpriseForm.controls['vdEntpId'].setValue(this.entReg.data);
        //   console.log('this.entReg', this.entReg);
          // if (this.entReg.success) {
            this.API.enterpriseRegister(this.signupEnterpriseForm.value).subscribe(
              (res: any) => {

                if (res.success && Number(res.data.empId) > 0) {
                  //this.isOnsignUp = false;
                  this.epIds = res.data.empId;

                  // window.localStorage.setItem('udata', this.staffReg);
                  // let user = this.signupEnterpriseForm.controls['emailId'].value;
                  // let pass = this.signupEnterpriseForm.controls['password'].value;
                  // this.loginForm.controls['email'].setValue(user);
                  // this.loginForm.controls['password'].setValue(pass);
                  // this.onSubmit();
                  this.API.enterpriseRegisterVD(this.signupEnterpriseForm.value,Number(res.data.empId)).subscribe((res: any) => {
                    this.entReg = res;

                    if (this.entReg.success) {              
                      debugger;
                      this.API.UpdateIdFromVeridial(Number(this.epIds),Number(this.entReg.data),'EP').subscribe(async (response: any) => {
                      console.log('===========',response);                    
                      this.headerList = 'EP'
                      this.signUpFormInit();
                      this.toastr.successToastr('Enterprise successfully created.')
                      this.isOnsignUp = false;
                      this.isOn = true;
                      //this.staffReg = res.data;
                      //this.onSubmit();
                      });  
                     
                    }
                    else {
                      this.toastr.errorToastr(this.jobReg.message, 'Error!');
                      this.isOnsignUp = true;
                    }
                    //this.signupEnterpriseForm.controls['vdEntpId'].setValue(this.entReg.data);
                    this.signupEnterpriseForm.controls['vdEntpId'].setValue(this.entReg.data);
                    console.log('this.entReg', this.entReg);
                  });
                } else {
                  this.toastr.errorToastr(res.message, 'Error!');
                  this.isOnsignUp = true;
                }
              }
            );
          // } else {
          //   this.toastr.errorToastr(this.entReg.message, 'Error!');
          //   this.isOnsignUp = true;
          // }


      } else {
        this.isOnsignUp = true;
        this.toastr.errorToastr('Invalid Input Please try again!', 'Alert!', {
          position: 'top-right'
        });
        return false;
      }
    } else {
      this.toastr.errorToastr('Please accept Terms and Privacy Policy.', 'Alert!', {
        position: 'top-right'
      });
      return false;
    }
  }
  JSsubmitted = false;
  jsIds:Number;
  jobseekerSignup() {

    this.JSsubmitted = true;
    if (this.signupFormJobSeeker.controls['terms'].value) {
      if (this.signupFormJobSeeker.valid) {
        this.signupFormJobSeeker.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));

        // this.API.jobseekerRegisterVD(this.signupFormJobSeeker.value).subscribe((res: any) => {
        //   this.jobReg = res;
        //   this.signupFormJobSeeker.controls['vdJobskrId'].setValue(this.jobReg.data);
        //   if (this.jobReg.success) {
          debugger;
            this.API.jobseekerRegister(this.signupFormJobSeeker.value).subscribe(
              (res: any) => {
                if (res.success && Number(res.data.jsId) > 0) {
                  this.toastr.successToastr('Jobseeker successfully created.')
                  //body.AppRefId = res.data.jsId;
                  this.jsIds = res.data.jsId;
                  this.API.jobseekerRegisterVD(this.signupFormJobSeeker.value,Number(res.data.jsId)).subscribe((resp: any) => {
                    this.jobReg = resp;
                    if (this.jobReg.success) {
               
                      this.API.UpdateIdFromVeridial(Number(this.jsIds),Number(this.jobReg.data),'JS').subscribe(async (response: any) => {
                      console.log('===========',response);
                      //this.signupFormJobSeeker.controls['vdJobskrId'].setValue(this.jobReg.data);

                      //this.editUploadProfile.controls['vdJobskrId'].setValue(this.jobRegID.data);
                      // const body = {
                      //   professional: this.professionalForm.value,
                      //   profile: this.editUploadProfile.value,
                      //   skills: this.skillsForm.value,
                      //   other: this.socialForm.value,
                      //   allJobtype: this.DataST,
                      // };
                      this.headerList = 'JS'
                      debugger
                      //this.staffReg = res.data;
                      //this.staffReg.jobseekerId = response.success==true?response['data'].jobseekerId:res['data'].jobseekerId;
                      //window.localStorage.setItem('udata', JSON.stringify(this.staffReg))
                      let user = this.signupFormJobSeeker.controls['emailId'].value;
                      let pass = this.signupFormJobSeeker.controls['password'].value;
                      this.loginForm.controls['email'].setValue(user);
                      this.loginForm.controls['password'].setValue(pass);
                      //this.onSubmit();
                      });  
                     this.signUpFormInit();
                      this.isOnsignUp = false;
                      this.isOn = true;
                    }
                    else {
                      this.toastr.errorToastr(this.jobReg.message, 'Error!');
                      this.isOnsignUp = true;
                    }
                  });
                  
                  
                } else {
                  this.toastr.errorToastr(res.message, 'Error!');
                }
              });
        //     this.isOnsignUp = false;
        //   }
        //   else {
        //     this.toastr.errorToastr(this.jobReg.message, 'Error!');
        //     this.isOnsignUp = true;
        //   }
        // })


      } else {
        this.toastr.errorToastr('Invalid Input Please try again!', 'Alert!', {
          position: 'top-right'
        });
        return false;
      }
    }
    else {
      this.toastr.errorToastr('Please accept Terms and Privacy Policy.', 'Alert!', {
        position: 'top-right'
      });
      return false;
    }
  }

  // convenience getter for easy access to form fields
  get fl() {
    return this.loginForm.controls;
  }
  get f() {
    return this.signupFormStaffing.controls;
  }
  get fjs() {
    return this.signupFormJobSeeker.controls;
  }
  get fEp() {
    return this.signupEnterpriseForm.controls;
  }
  // openResetWarningPopUp() {
  //   const dialogRef = this.dialog.open(ResetWarningPopUpComponent, {
  //     width: 'auto',
  //     data: this.diffDays
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //   });

  // }
  //login Start
  saveloginUser() {
 
    if (this.userData.data.credentials.userType == 'EP') {
  
      this.signupEnterpriseForm.controls['companyName'].setValue(this.userData.data.credentials.CompName); //Need from token
      this.signupEnterpriseForm.controls['website'].setValue(this.userData.data.credentials.Website);  //Need from token
      this.signupEnterpriseForm.controls['firstName'].setValue(this.userData.data.credentials.firstName);
      this.signupEnterpriseForm.controls['middleName'].setValue('');
      this.signupEnterpriseForm.controls['lastName'].setValue(this.userData.data.credentials.firstName);
      this.signupEnterpriseForm.controls['emailId'].setValue(this.userData.data.credentials.emailId);
      this.signupEnterpriseForm.controls['password'].setValue('Welcome@1');
      this.signupEnterpriseForm.controls['contactNo'].setValue(this.userData.data.credentials.Phone); //Need from token
      this.signupEnterpriseForm.controls['securityQuestions'].setValue('1');
      this.signupEnterpriseForm.controls['answer'].setValue('Test');
      this.signupEnterpriseForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(this.userData.data.credentials.CreatedDate)); //Need from token
      this.signupEnterpriseForm.controls['vdEntpId'].setValue(this.userData.data.credentials.userId);
      this.signupEnterpriseForm.controls['CreatedBy'].setValue(this.userData.data.credentials.CreatedBy);

      console.log('this.signupEnterpriseForm.value', this.signupEnterpriseForm.value);
      // this.signupEnterpriseForm.controls['RegisterBy'].setValue('')
      this.apiAdmin.enterpriseFormByLogin(this.signupEnterpriseForm.value).pipe(first()).subscribe(res => {
       // alert(res)
        console.log('res signupEnterpriseForm', res);

        this.issuccess = res;
        if (this.issuccess.success) {

          console.log('successfully created', this.issuccess.message)

          // this.router.navigate(['/ad/admin/enterprise']);
        } else {
          //write code for logout
          // this.preloader = false;
          this.toastr.errorToastr(this.issuccess.message, 'OPPS!')
        }
      });
    }
    else if (this.userData.data.credentials.userType == 'SA') {      
      
      this.apiAdmin.GetUserDetailsByCreayedById(this.userData.data.credentials.CreatedBy).pipe(first()).subscribe(res => {
    
        this.staffReg1 = res;
        var createdby: any
        if (this.staffReg1.success) {
          
          console.log('res', this.staffReg1.data[0].roleName);
          if (this.staffReg1.data[0].roleName == 'CompanyAdmin') {
            createdby = this.staffReg1.data[0].userId;

          } else if (this.staffReg1.data[0].roleName == 'SuperSubAdmin') {
            createdby = this.staffReg1.data[0].userId;

          }
          console.log('res', res);
          this.signupFormStaffing.controls['companyName'].setValue('TestCompany'); //need
          this.signupFormStaffing.controls['website'].setValue('www.test.com'); //need 
          this.signupFormStaffing.controls['firstName'].setValue(this.userData.data.credentials.firstName);
          this.signupFormStaffing.controls['lastName'].setValue(this.userData.data.credentials.firstName);
          this.signupFormStaffing.controls['emailId'].setValue(this.userData.data.credentials.emailId);
          this.signupFormStaffing.controls['securityQuestions'].setValue('1');
          this.signupFormStaffing.controls['answer'].setValue('Test');
          this.signupFormStaffing.controls['password'].setValue('Welcome@1');
          this.signupFormStaffing.controls['contactNo'].setValue('9999999999'); //need
          this.signupFormStaffing.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date())); //need
          this.signupFormStaffing.controls['vdStaffingId'].setValue(this.userData.data.credentials.userId);
          this.signupFormStaffing.controls['createdby'].setValue(this.staffReg1.data[0].userId);
          this.signupFormStaffing.controls['registerBy'].setValue(this.userData.data.credentials.CreatedBy);
          console.log('lastName',this.signupFormStaffing.value)
          this.apiAdmin.addstaffingagencyByLogin(this.signupFormStaffing.value).pipe(first()).subscribe(res => {
            this.staffReg1 = res;
            
            if (this.staffReg1.success) {
            

              console.log('successfully', res);

            }
            else {
              this.toastr.errorToastr(this.staffReg1.message, 'OPPS!')
              // write code for logout
            }
          });
        }
      });

    }

    // }

  }


  async onSubmit() {
    debugger
    if (this.isAdmin) {
      this.type = this.loginForm.controls['typeUser'].value;
      this.loginForm.controls['userType'].setValue(this.loginForm.controls['typeUser'].value);
    }
    else {
      this.loginForm.controls['userType'].setValue(this.loginType);
    }
    if (this.loginForm.controls['typeUser'].value == 'AD') {
      this.loginForm.controls['userEmail'].setValue(this.loginForm.controls['email'].value);
    }
    this.submitted = true;
    try {
      // console.log("this.loginForm", this.loginForm);
      if (this.loginForm.invalid) {
        this.isOn = true;
        this.toastr.warningToastr('Invalid please try again!', 'Opps!', {
          position: 'top-right'
        });
        return;
      } else {
        this.isOn = false;
        debugger
        await this.logData();
        // this.GetUserById(this.userData.data.credentials);
        this.forminit();
        // if (this.userData.success) { 
        if (this.userData) {          
          this.isLogin = 'true';
          this.userDataShow = true;
          window.localStorage.setItem('islogin', 'true');
          localStorage.setItem('udata', JSON.stringify(this.userData));
          // localStorage.setItem('token', this.userData.token);
          // localStorage.setItem('token', this.userData.data.token);
          // this.loginMsg = this.userData.message;
          if (this.isAdmin) {
            this.user = "AD";
          }
          else {
            this.user = this.loginType;
          }
          // await this.GetCreatedBy(this.userData.data.credentials);
          setInterval(() => {

          switch (this.user) {
            case 'SA': {
            
              this.headerList = 'SA';
              this.getDocusignStatus();

              // this.getNotification(this.userData.data.token);  
              this.getNotification(this.userData.token);            
              this.routerClass = 'DashboardStaffing';
              if(!this.docusignstatus){  
                this.commonPasswordDetails();  
                this.router.navigate(['/st/staff/vendorprofile']).then(() => {
                  window.location.reload();
                }); 
              }
              else{
                this.router.navigate(['/st/staff/dashboard']);
                this.commonPasswordDetails();
                if (this.remainingdays <= 0) { 
                  this.router.navigate(['/st/staff/resetpassword']).then(() => {
                    window.location.reload();
                  }); 
                } else { 
                  this.router.navigate(['/st/staff/dashboard']).then(() => {
                    window.location.reload();
                  });
                 }
              }  
              break;
            }
            case 'JS': {
              window.localStorage.setItem('islogin', 'true');
              this.headerList = 'JS';
              // this.getNotification(this.userData.data.token);
              this.getNotification(this.userData.token);
              this.routerClass = 'DashboardStaffing';
              //this.router.navigate(['/js/jobseeker/dashboard']);
              this.commonPasswordDetails();
              if (this.remainingdays <= 0) { 
                this.router.navigate(['/js/jobseeker/resetpassword']).then(() => {
                  window.location.reload();
                }); 
              }
              else {
                 this.router.navigate(['/js/jobseeker/dashboard']).then(() => {
                  window.location.reload();
                });
              }
              
              break;
            }
            case 'EP': {
              window.localStorage.setItem('islogin', 'true');
              this.headerList = 'EP';
              // this.getNotification(this.userData.data.token); 
              // this.getNotification(this.userData.token);   
              this.routerClass = 'DashboardStaffing';
              this.commonPasswordDetails();
              this.router.navigate(['/dashboard']).then(() => {
                    window.location.reload();
                  });
              // this.remainingdays = 350;
              // if (this.remainingdays <= 0) 
              // { 
              //  this.router.navigate(['/ep/enterprise/resetpassword'])
              // .then(() => {
              //   window.location.reload();
              // }); 
              // } else { 
              //   this.router.navigate(['/ep/enterprise/dashboard']).then(() => {
              //     window.location.reload();
              //   });
              // }
              break;
            }
            case 'AD': {
              window.localStorage.setItem('islogin', 'true');
              // this.getNotification(this.userData.data.token);
              this.getNotification(this.userData.token);

              this.headerList = 'AD';
              this.routerClass = 'DashboardAdmin';
              this.router.navigate(['/ad/admin/dashboard'])
              .then(() => {
              //  this.getNotification(this.userData.data.token);
              this.getNotification(this.userData.token);
                window.location.reload();
              });
              break;
            }
          }
        },300);

        } else {
          this.isOn = true;
          this.loginMsg = this.userData.message;
          this.showUserEmail = false;
          this.userEmailCheck = false;
          return false;
        }
      }
    } catch (error) {
    }
    this.loading = true;
  }
  // This function is use for check expire days and show reset password page 
  commonPasswordDetails() {

    this.userDataCheck = JSON.parse(localStorage.getItem('udata'));
    let newDate = new Date(this.datePipe.transform(this.userDataCheck.data.credentials.PasswordUpdateOn, 'yyyy-MM-dd'));
    var diff = Math.abs(this.todayDate.getTime() - newDate.getTime());
    var aa=this.todayDate.getTime()
    var cc=this.todayDate
    var bb=newDate.getTime()
    this.diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    this.remainingdays = environment.passwordExpiredays - this.diffDays
    if (this.remainingdays <= 0) {

      this.showPasswordWarningAfterExpire = true;
      window.localStorage.setItem('isPasswordReset', 'true');
      this.isPasswordResetCheck = localStorage.getItem('isPasswordReset');
      if (this.isPasswordResetCheck == 'true') { this.isPasswordReset = true } else { this.isPasswordReset = false }
    } else {
      this.showPasswordWarningAfterExpire = false;
    }
  }
  logout() {
    this.headerList = 'GN';
    localStorage.setItem('islogin', 'false');
    localStorage.removeItem('token');
    localStorage.removeItem('udata');
    localStorage.removeItem('profileImagePath');
    localStorage.removeItem('profileImageName');
    localStorage.removeItem('CreatedBy');
    localStorage.removeItem('interpriseId');
    localStorage.removeItem('selectprofileImagePath');
    this.userDetails = {};
    this.router.navigate(['/auth/home']);

    this.toastr.successToastr('Successfully Logout!', 'Success!', {
      position: 'top-right'
    });
    this.routerClass = '';
    this.loginMsg = '';
    this.isLogin = 'false';
    this.loading = false;
    this.forminit();
    window.location.reload();
  }


  logData() {
    return new Promise((resolve, reject) => {
      //Manu Datta: 20210424, Email and Password display original 
      var emailOrig = this.loginForm.controls.email.value;
      var passwordOrig = this.loginForm.controls.password.value;
      //Manu Datta: 20210407, Encryption
      var emailEnc = this.shareservice.encrypt(environment.EncryptKey,this.loginForm.controls.email.value);
      var passwordEnc = this.shareservice.encrypt(environment.EncryptKey,this.loginForm.controls.password.value);
      this.loginForm.controls['email'].setValue(emailOrig);
      this.loginForm.controls['password'].setValue(passwordOrig);
      debugger
      this.API.loginAPI(this.isAdmin, this.loginForm.value).subscribe(
        async res => {
          //Manu Datta: 20210424, Email and Password display original 
          this.loginForm.controls['email'].setValue(emailOrig);
          this.loginForm.controls['password'].setValue(passwordOrig);
          this.userData = res;
          // if(this.userData.success){
          if(this.userData){

          // localStorage.setItem('token', this.userData.data.access_token);  
          localStorage.setItem('token', this.userData.token);          

          const decoded = this.jwtService.getDecodedAccessToken(this.userData.token);    
          console.log('=============decoded==',decoded)           
          // if (decoded.Role == 'Recruiter') {           
          //   // await this.getStaffingdetailsVS()
          //   //       .then( (value:any) =>{
          //   //         this.firstname1=value.firstName;
          //   //         this.lastname1=value.lastName;
          //   //         this.profileImage1=value.profileImage;
          //   //         this.registerBy1=value.registerBy;
          //   //       }).catch( err => {
                   
          //   //       });
                 
          //       // this.credentials =
          //       // {
          //       //   staffingagencyId: 'VKSA-0' + decoded.UserId,
          //       //   userType: "SA",
          //       //   isAdmin: true, //need 
          //       //   firstName: decoded.Name,
          //       //   lastName: decoded.Name, // need 
          //       //   emailId: decoded.Email,   
          //       //   profileImage: this.profileImage1,      
          //       //   profileImagePath: decoded.ProfileImagePath,         
          //       //   profile_status: 'Verified', // need
          //       //   userId: decoded.UserId,
          //       //   CreatedBy: this.registerBy1,
          //       //   UpdatePwdDate: decoded.CreatedDate ,
          //       //   PasswordUpdateOn:decoded.PasswordUpdateOn,
          //       //   AppRefId:decoded.AppRefId,
          //       //   AppType:decoded.AppType,
          //       //   AppRefType:decoded.AppRefType,
          //       //   AppRefTypeCode:decoded.AppRefTypeCode
          //       // }
          //     }

          // else if (decoded.Role == 'SupplierManager') {            
          //   // this.credentials =
          //   // {
          //   //   staffingagencyId: 'VKSA-0' + decoded.UserId,
          //   //   userType: "SA",
          //   //   isAdmin: false, //need 
          //   //   firstName: decoded.Name,
          //   //   lastName: decoded.Name, // need 
          //   //   emailId: decoded.Email,
          //   //   profileImage: '',               // need VD
          //   //   profileImagePath: decoded.ProfileImagePath,
          //   //   profile_status: 'Verified', // need
          //   //   userId: decoded.UserId,
          //   //   UserIdSelf:decoded.UserIdSelf, 
          //   //   CreatedBy: decoded.CreatedBy,
          //   //   UpdatePwdDate: decoded.CreatedDate, // need VD
          //   //   PasswordUpdateOn:decoded.PasswordUpdateOn,
          //   //   AppRefId:decoded.AppRefId,
          //   //   AppType:decoded.AppType,
          //   //   AppRefType:decoded.AppRefType,
          //   //   AppRefTypeCode:decoded.AppRefTypeCode
          //   // }
          // }
          // else if (decoded.Role == 'CompanyAdmin') {
          //   // var enterpriseId ='VKEP-0' + decoded.UserId
            
          //   // await this.getEntrpzdetailsVS()
          //   // .then( (value:any) =>{
          //   //   this.firstname1=value.firstName;
          //   //   this.lastname1=value.lastName;
          //   //   this.profileImage1=value.profileImage;
          //   // }).catch( err => {
            
          //   // }); 
          //   // this.credentials =
          //   // {
          //   //   enterpriseId: 'VKEP-0' + decoded.UserId,
          //   //   userType: "EP",
          //   //   isAdmin: true, //need 
          //   //   firstName: this.firstname1,
          //   //   lastName: this.lastname1, // need 
          //   //   emailId: decoded.Email,
          //   //   profileImage: this.profileImage1,              // need VD
          //   //   profileImagePath: decoded.ProfileImagePath,
          //   //   profile_status: 'Verified', // need
          //   //   userId: decoded.UserId,// decoded.UserId,
          //   //   CompName: decoded.CompName,
          //   //   Website: decoded.Website,
          //   //   Phone: decoded.Phone,
          //   //   CreatedDate: decoded.CreatedDate,
          //   //   CreatedBy: decoded.CreatedBy,
          //   //   UpdatePwdDate: decoded.CreatedDate, // need VD
          //   //   Role:decoded.Role,
          //   //   PasswordUpdateOn:decoded.PasswordUpdateOn,
          //   //   AppRefId:decoded.AppRefId,
          //   //   AppType:decoded.AppType,
          //   //   AppRefType:decoded.AppRefType,
          //   //   AppRefTypeCode:decoded.AppRefTypeCode
          //   //   // website:decoded.website,   // need for create sub admin in client
          //   //   // companyName:decoded.companyName // need for create sub admin in client
          //   // }
           
          // }   
          // else if (decoded.Role == 'ClientHiringManager') {
             
          //   // this.credentials =
          //   // {
          //   //   enterpriseId: 'VKEP-0' + decoded.CreatedBy,
          //   //   userType: "EP",
          //   //   isAdmin: false, //need 
          //   //   firstName: decoded.Name,
          //   //   lastName: decoded.Name, // need 
          //   //   emailId: decoded.Email,
          //   //   profileImage: '',              // need VD
          //   //   profileImagePath: decoded.ProfileImagePath,
          //   //   profile_status: 'Verified', // need
          //   //   userId: decoded.UserId,  
          //   //   UserIdSelf:decoded.UserIdSelf,   
          //   //   CompName: decoded.CompName,
          //   //   Website: decoded.Website,
          //   //   Phone: decoded.Phone,
          //   //   CreatedDate: decoded.CreatedDate,
          //   //   CreatedBy: decoded.CreatedBy,
          //   //   UpdatePwdDate: decoded.CreatedDate, // need VD
          //   //   Role:decoded.Role,
          //   //   PasswordUpdateOn:decoded.PasswordUpdateOn,
          //   //   AppRefId:decoded.AppRefId,
          //   //   AppType:decoded.AppType,
          //   //   AppRefType:decoded.AppRefType,
          //   //   AppRefTypeCode:decoded.AppRefTypeCode
          //   //   // website:decoded.website,   // need for create sub admin in client
          //   //   // companyName:decoded.companyName // need for create sub admin in client
          //   // }
          // }
          // else if (decoded.Role == 'Jobseeker') {
          //   debugger
          //   // await this.getJobseekerdetailsVS()
          //   // .then( (value:any) =>{
          //   //   this.firstname1=value.firstName;
          //   //   this.lastname1=value.lastName;
          //   //   this.profileImage1=value.profileImage;
          //   // }).catch( err => {
            
          //   // });
          //   // this.credentials =
          //   // {
          //   //   jobseekerId: 'VKJS-0' + decoded.UserId,
          //   //   userType: "JS",
          //   //   isAdmin: true, //need 
          //   //   firstName: this.firstname1,
          //   //   lastName: this.lastname1, // need 
          //   //   emailId: decoded.Email,
          //   //   profileImage: '',              // need VD
          //   //   profileImagePath: decoded.ProfileImagePath,
          //   //   profile_status: 'Verified', // need
          //   //   userId: decoded.UserId,// decoded.UserId,
          //   //   CreatedBy: decoded.CreatedBy,
          //   //   UpdatePwdDate: decoded.CreatedDate, // need VD
          //   //   PasswordUpdateOn:decoded.PasswordUpdateOn,
          //   //   AppRefId:decoded.AppRefId,
          //   //   AppType:decoded.AppType,
          //   //   AppRefType:decoded.AppRefType,
          //   //   AppRefTypeCode:decoded.AppRefTypeCode
          //   // }
          // }
          // else if (decoded.Role == 'SuperSubAdmin') {
             
          //   // this.credentials = {
          //   //   adminId: 1,
          //   //   userType: "AD",
          //   //   isAdmin: 'true',
          //   //   firstName: decoded.Name,
          //   //   emailId: decoded.Email,
          //   //   profileImage: decoded.ProfileImage,
          //   //   profileImagePath: decoded.ProfileImagePath,
          //   //   profile_status: "Verified",
          //   //   userId: decoded.UserId,
          //   //   PasswordUpdateOn:decoded.PasswordUpdateOn,
          //   //   AppRefId:decoded.AppRefId,
          //   //   AppType:decoded.AppType,
          //   //   AppRefType:decoded.AppRefType,
          //   //   AppRefTypeCode:decoded.AppRefTypeCode
          //   // }
          // }
          //    

          this.credentials = {
            userType: decoded.UserType,
            firstName: decoded.Name,
            refreshToken: decoded.RefreshToken,
            userImageURL: decoded.UserImageURL,
            refreshTokenExpiryTime: decoded.RefreshTokenExpiryTime,
            enterpriseId: Number(decoded.EnterpriseId),
            lastName: decoded.Surname,
            emailId: decoded.Email,
            Phone: decoded.MobilePhone,
            profileImage: decoded.ProfileImage,
            profileImagePath: decoded.ProfileImagePath,
            profile_status: decoded.ProfileStatus,
            userId: decoded.UserId,
            CreatedDate:decoded.CreatedOn,
            UpdatePwdDate: decoded.CreatedOn,
            PasswordUpdateOn:decoded.PasswordUpdateOn,
            Role:'',
            CreatedBy:decoded.CreatedBy
           }
          const tempvalue = {
            // "token": this.userData.data.access_token,
            "token": this.userData.token,
            "credentials": this.credentials
          }
          console.log('tempvalue', tempvalue)
          this.userData = {
            'success': this.userData.success,
            'message': this.userData.message,
            'data': tempvalue
          }
          
          resolve(res);
        }
        else{

          this.toastr.errorToastr(this.userData.message, 'Error!'+this.isAdmin);
          this.isOn = true;
          return;
        }
        },
        err => {
          reject(err);
            this.showError = true;
            this.toastr.errorToastr('Invalid Credential', 'Error!');   
          
        }
      );
    });
  }

  // getEntrpzdetailsVS(){
  //   return new Promise((resolve, reject) => {
  //   const result = {firstName: '', lastName: '', profileImage: ''} ; 
  //   // this.eterpriseAPI.EntrpzdetailsVS(this.userData.data.access_token).subscribe(res => {
  //     this.eterpriseAPI.EntrpzdetailsVS(this.userData.token).subscribe(res => {
    
  //      this.loginId1=res
  //      if(this.loginId1.success){             
  //      this.loginId=res
  //      result.firstName = this.loginId.data.firstName;
  //      result.lastName = this.loginId.data.lastName;
  //      result.profileImage = this.loginId.data.profileImage;
  //      }
  //     resolve(result) 
  //    },
  //    err =>{
  //     reject(result);
  //    }
  //    )
  //   });
  // }
  getStaffingdetailsVS(){
    return new Promise((resolve, reject) => {
    const result = {firstName: '', lastName: '', profileImage: '',registerBy: ''} ; 
    this.staffAPI.staffingdetailsVS(this.userData.data.access_token).subscribe(res => {
     
       this.loginId1=res
       if(this.loginId1.success){             
       this.loginId=res
       result.firstName = this.loginId.data.firstName;
       result.lastName = this.loginId.data.lastName;
       result.profileImage = this.loginId.data.profileImage;
       result.registerBy = this.loginId.data.registerBy;
       }
      resolve(result) 
     },
     err =>{
      reject(result);
     }
     )
    });
  }


  getJobseekerdetailsVS(){
    return new Promise((resolve, reject) => {
    const result = {firstName: '', lastName: '', profileImage: ''} ; 
    this.JSApi.jobseekerdetailsVS(this.userData.data.access_token).subscribe(res => {
    
       this.loginId1=res
       if(this.loginId1.success){             
       this.loginId=res
       result.firstName = this.loginId.data.firstName;
       result.lastName = this.loginId.data.lastName;
       result.profileImage = this.loginId.data.profileImage;
       }
      resolve(result) 
     },
     err =>{
      reject(result);
     }
     )
    });
  }
  

  userEmailCheck = true;
  isRequired = true;
  getUserEmail() {
    if (this.loginForm.controls['typeUser'].value == 'AD') {
      this.userEmailCheck = true;
      this.isRequired = false;
      this.loginForm.controls['userEmail'].setValue('');
      this.submitted = false;
    }
    else {
      this.userEmailCheck = false;
      this.loginForm.controls['userEmail'].markAsTouched();
      this.isRequired = true;
      this.submitted = true;
    }
  }
  getLoginDetail() {
    if (localStorage.getItem('udata')) {
      this.userLoginData = JSON.parse(localStorage.getItem('udata'));
      if (this.userLoginData != null) {
        if (this.userLoginData.data.credentials.userType == 'EP') {
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }
        if (this.userLoginData.data.credentials.userType == 'JS') {
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }

        if (this.userLoginData.data.credentials.userType == 'AD') {
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }
        if (this.userLoginData.data.credentials.userType == 'SA') {
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }   

        this.API.getLoginDetail(this.userLoginData.data.credentials.staffingagencyId, this.userLoginData.data.credentials.emailId, this.userLoginData.data.credentials.userType).subscribe(
          (res: any) => {

            this.userDetails = res;
            // this.profileStatus = this.userDetails.data[0].profile_status;
            this.profileStatus = 'Verified';

            console.log('profileStatus', this.profileStatus);
          });
        this.userDataCheck = JSON.parse(localStorage.getItem('udata'));
        this.admincheck= this.userLoginData.data.credentials.userType;
        // console.log('udata',JSON.stringify(this.userDataCheck));
        // console.log('this.userDataCheck.data.credentials',this.userDataCheck.data.credentials.UpdatePwdDate);

        let newDate = new Date(this.datePipe.transform(this.userDataCheck.data.credentials.PasswordUpdateOn, 'yyyy-MM-dd'));
        //console.log('this.todayDate.getTime()',this.todayDate);
        //console.log('dbdate',newDate);
        var diff = Math.abs(this.todayDate.getTime() - newDate.getTime());
        this.diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        this.remainingdays = environment.passwordExpiredays - this.diffDays
        //console.log('diffDays', this.remainingdays);
        // if(this.diffDays>environment.passwordExpiredays) 
        if (this.remainingdays <= environment.passwordExpireAlert && this.remainingdays > 0) {
          this.showPasswordWarning = true;
          this.showPasswordWarningAfterExpire = false;
          //this.openResetWarningPopUp();
        }


      }
    }
  }
  NoChnagePassword() {
    this.showPasswordWarning = false;
  }
  YesChnagePassword() {
    this.showPasswordWarning = false;
    if (this.userLoginData.data.credentials.userType == 'EP') {

      this.router.navigate(['/ep/enterprise/updateprofile']);
    }
    if (this.userLoginData.data.credentials.userType == 'JS') {

    }

    if (this.userLoginData.data.credentials.userType == 'SA') {
      this.router.navigate(['/st/staff/profile']);
    }
    //this.router.navigate(['/st/staff/profile']);
  }

  fgSubmitted = false;
  forgotSubmit() {
    this.fgSubmitted = true;
    this.loading = true;
    if (this.forgotForm.valid) {
      let params = {
        Email: this.forgotForm.controls.emailId.value
      }
      this.apiAdmin.resetPasswordVD(params)
        .subscribe((res: Response) => {
          this.loading = false;
          this.forgateRes = res;
          if (this.forgateRes.success) {
            this.toastr.successToastr(
              this.forgateRes.message,
              '',
              'top-right'
            );
            this.loading = false;
            this.forgotForm = this.formBuilder.group({
              emailId: ['']
            });
            this.isOnForgot = false;
            this.fgSubmitted = false;
            window.location.reload();
          } else {
            this.toastr.errorToastr(
              this.forgateRes.message,
              '',
              'top-right'
            );
            this.forgotForm.controls['emailId'].setValue('');
            this.forgotForm.controls['emailId'].setErrors({ 'error': false });
            this.fgSubmitted = false;
            this.loading = false;
            this.isOnForgot = false;
          }
        });
    } else {
      this.toastr.warningToastr('Invalid Input.', 'Error!');
      return false;
    }
  }
  // resetpassword
  get fr() {
    return this.resetForm.controls;
  }

  resetSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetForm.invalid) {
    }
    this.loading = true;
  }


  openDialog(): void {
    this.AuthenticationService.jwtTokenExpCheck();
    this.loginValue = localStorage.getItem('islogin');
    if (this.loginValue != 'false') {
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        width: '250px',
        data: { name: 'adasdas', animal: 'lion' }
      });

      dialogRef.afterClosed().subscribe(() => {
        // this.animal = result;
      });
    }
  }

  // social login
  socialData = {
    firstName: '',
    lastName: '',
    emailId: '',
    userType: '',
    provider: '',
    accessToken: ''
  };

  socialDataRes: any;

  //social sign up process
  public socialSignIn(socialPlatform) {
    let socialPlatformProvider;
    // if (socialPlatform === 'facebook') {
    //   socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    // } else if (socialPlatform === 'google') {
    //   socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    // } else if (socialPlatform === 'linkedin') {
    //   socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID;
    // } else if (socialPlatform === 'twitter') {
    //   socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    // }

    // this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
    //   this.isOn = false;
    //   // Now sign-in with userData
    //   // ...
    //   this.socialData.firstName = userData.name
    //     .split(' ')
    //     .slice(0, -1)
    //     .join(' ');
    //   this.socialData.lastName = userData.name
    //     .split(' ')
    //     .slice(-1)
    //     .join(' ');
    //   this.socialData.emailId = userData.email;
    //   this.socialData.userType = this.loginType;
    //   this.socialData.provider = userData.provider;
    //   this.socialData.accessToken = userData.token;
    //   this.componentAPI.socialLogin(this.socialData).subscribe(res => {
    //     this.socialDataRes = res;
    //     if (this.socialDataRes.success) {
    //       this.isLogin = 'true';
    //       localStorage.setItem('islogin', 'true');
    //       this.userDataShow = true;
    //       localStorage.setItem('udata', JSON.stringify(this.socialDataRes));
    //       localStorage.setItem('token', this.socialDataRes.data.token);

    //       this.router.navigate(['/st/staff/dashboard']);
    //       window.location.reload();
    //     } else {
    //       this.toastr.errorToastr(this.socialDataRes.message, '', {
    //         position: 'top-right'
    //       });
    //     }
    //   });
    //   // }
    // });
  }
  //social sign in or login Ends

  onSignIn(googleUser) {
  }

  getNotification(token) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.componentAPI.headernotification(token, this.headerList).subscribe(res => {
       //this.announceData = res;
      // if (this.announceData.success) {
      //   this.announceLIstData = true;
      // } else {
      //   localStorage.setItem('islogin', 'false');
      //   localStorage.removeItem('token');
      //   localStorage.removeItem('udata');
      //   setTimeout(() => {
      //     this.router.navigate(['/auth/home']);
      //   }, 200);
      // }
    });
  }

  docusignstatus=true;
  getDocusignStatus(){
    this.AuthenticationService.jwtTokenExpCheck();
    this.componentAPI.getDocusignStatus(this.userData.data.credentials.AppRefId).subscribe(res => {
      this.docusignstatus=res.data['docuSignStatus'];
    });
  }

  activeLinks(val) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.linkActive = val;
    if (val !== "jobs") {
      this.isOn = false;
    }
  }
  // jsPhone: any;
  // email validation start
  jsEmail = false;
  epEmail = false;
  saEmail = false;
  emailValidation(type, event) {

    console.log('type, event', type, event);
    this.API.checkmail(type, event.target.value).subscribe((res: any) => {

      console.log('res', res);
      if (res.success) {
        if (res.data.exists) {
          switch (type) {
            case 'SA': {
              this.signupFormStaffing.controls['emailId'].setErrors({ 'errors': true });
              this.saEmail = true;
              break;
            } case 'JS': {
              this.signupFormJobSeeker.controls['emailId'].setErrors({ 'errors': true });
              this.jsEmail = true;
              break;
            } case 'EP': {
              this.signupEnterpriseForm.controls['emailId'].setErrors({ 'errors': true });
              this.epEmail = true;
              break;
            }
          }
        } else {
          switch (type) {
            case 'SA': {
              this.saEmail = false;
              break;
            } case 'JS': {
              this.jsEmail = false;
              break;
            } case 'EP': {
              this.epEmail = false;
              break;
            }
          }
        }
      } else {
        console.log('res.message', res.message);
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }
  saweb = false;
  jsweb = false;
  epweb = false;
  websiteValidation(type, event) {

    console.log('type, event', type, event);
    this.API.checkweb(type, event.target.value).subscribe((res: any) => {

      console.log('res', res);
      if (res.success) {
        if (res.data.exists) {
          switch (type) {
            case 'SA': {
              this.signupFormStaffing.controls['website'].setErrors({ 'errors': true });
              this.saweb = true;
              break;
            } case 'JS': {
              this.signupFormJobSeeker.controls['website'].setErrors({ 'errors': true });
              this.jsweb = true;
              break;
            } case 'EP': {
              this.signupEnterpriseForm.controls['website'].setErrors({ 'errors': true });
              this.epweb = true;
              break;
            }
          }
        } else {
          switch (type) {
            case 'SA': {
              this.saweb = false;
              break;
            } case 'JS': {
              this.jsweb = false;
              break;
            } case 'EP': {
              this.epweb = false;
              break;
            }
          }
        }
      } else {
        console.log('res.message', res.message);
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }
  // email validation Ends
  jsPhone = false;
  epPhone = false;
  saPhone = false;

  showUser = false;
  showUserEmail = false;
  disableLogin = true;
  showError = false;
  passwordValidation(type, event) {
    if(this.loginForm.controls['email'].value != '' && this.loginForm.controls['password'].value != ''){
      let emailuser: any;
      emailuser = this.loginForm.controls['email'].value;
      // this.API.validateAdmin(type, emailuser, event.target.value).subscribe((res: any) => {
      const body = {
        'email': this.shareservice.encrypt(environment.EncryptKey,emailuser),
        'password': this.shareservice.encrypt(environment.EncryptKey,event.target.value)
      }
  
      
      this.API.loginAPI('', body).subscribe((res: any) => {
  
        this.userData = res;
        // if (this.userData.message != 'UserName Invalid' && this.userData.message != 'Invalid username/password') {
        if(this.userData.success) {
        console.log('this.userData.data.access_token', this.userData.data.access_token);
        const decoded = this.jwtService.getDecodedAccessToken(this.userData.data.access_token);
        console.log('decoded', decoded);
        console.log('this.userData old', this.userData);
        
        if (decoded.Role == 'SuperSubAdmin') {
          this.credentials = {
            adminId: 1,
            userType: "AD",
            isAdmin: 'true',
            firstName: decoded.Name,
            emailId: decoded.Email,
            profileImage: decoded.ProfileImage,
            profile_status: "Verified",
            userId: decoded.UserId,
          }
          this.showUserEmail = true;
          this.disableLogin = false;
          this.showError = false;
        } else {
         
          if (!res.hasOwnProperty('data')) {
            this.showError = true;
            this.toastr.errorToastr('Invalid Credential', 'Error!');
          }
          if (!res.success) {
            this.showError = true;
            this.toastr.errorToastr(res.message, 'Error!');
          }
        }
  
        // if (res.hasOwnProperty('data')) {
        //   this.showUserEmail = true;
        //   this.disableLogin = false;
        //   this.showError = false;
        // }
      }else{
        if (!res.hasOwnProperty('data')) {
          this.showError = true;
          this.toastr.errorToastr('Invalid Credential', 'Error!');
        }
        if (!res.success) {
          this.showError = true;
          this.toastr.errorToastr(res.message, 'Error!');
          this.isOn = true;
          return ;
        }
      }
      });
    }
    else{
      this.toastr.warningToastr('Invalid please try again!', 'Opps!', {
        position: 'top-right'
      });
      return;
    }
    
  }


  // phone validation  Start
  phoneValidation(type, event) {
    this.jsPhone = false;
    this.epPhone = false;
    this.saPhone = false;

    this.API.checkphone(type, event.target.value).subscribe((res: any) => {

      console.log('res', res);
      if (res.success) {
        if (res.data.exists) {
          switch (type) {
            case 'SA': {
              this.signupFormStaffing.controls['contactNo'].setErrors({ 'errors': true });
              this.saPhone = true;
              break;
            } case 'JS': {
              this.signupFormJobSeeker.controls['contactNo'].setErrors({ 'errors': true });
              this.jsPhone = true;
              break;
            } case 'EP': {
              this.signupEnterpriseForm.controls['contactNo'].setErrors({ 'errors': true });
              this.epPhone = true;
              break;
            }
          }
        } else {
          switch (type) {
            case 'SA': {
              this.saPhone = false;
              break;
            } case 'JS': {
              this.jsPhone = false;
              break;
            } case 'EP': {
              this.epPhone = false;
              break;
            }
          }
        }
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }
  // email validation Ends
  checkedpolicy(type) {
    switch (type) {
      case 'SA': {
        this.signupFormStaffing.controls['terms'].setValue(this.signupFormStaffing.controls['terms'].value ? false : true)
        break;
      }
      case 'EP': {
        this.signupEnterpriseForm.controls['terms'].setValue(this.signupEnterpriseForm.controls['terms'].value ? false : true)
        break;
      }
      case 'JS': {
        this.signupFormJobSeeker.controls['terms'].setValue(this.signupFormJobSeeker.controls['terms'].value ? false : true)
        break;
      }
    }
  }

  headerInit() {
    $(document).ready(function () {
      $('#home').addClass('active');
      $('body').on('click', '.header_list_sec li a', function () {
        $(window).scrollTop(0);
        if ($(this).parent('li').hasClass('staf_hd') || $(this).parent('li').hasClass('login_hd')) {

        } else {
          $('.header_list_sec li').removeClass('active');
          $(this).parent('li').addClass('active');
        }
      });
    });
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  clearData() {
    this.isOnsignUp = false;
    //this.initForm();
  }
  // GetCreatedBy(data) {
  //   this.apiAdmin.GetCreatedBy(data).pipe(first()).subscribe(res => {
  //     if (res.success) {
  //       // console.log('res createdby ', res.data[0].createdBy)
  //       var aa = res.data[0].Createdby;
  //       localStorage.setItem('CreatedBy', res.data[0].createdBy);
  //     }
  //   });
  // }
  // GetUserById(data) {
  //   this.apiAdmin.GetUserById(data).pipe(first()).subscribe(res => {    
  //     if (res.success) {
  //       this.dataValue = res;
  //     }
  //   });

  // }


  countryChange() {
    let CountryId: any = 0;
    this.countryList.forEach(element => {
      if (this.signupFormStaffing.controls['country'].value === element.name) {
        CountryId = element.Id;
      }
    });
    this.stateLoader = true;
    this.API.state(CountryId).subscribe((res: any) => {
      this.stateLoader = false;
      if (res.success) {
        this.stateList = res.data;
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }

  setpaggination(e, type) {
    if (type === 'RA') {
      this.recentActivityFn(e.pageSize, e.pageIndex + 1);
    }
    if (type === 'AN') {
      this.announceMentlist(e.pageIndex + 1, e.pageSize);
    }
  }
  nextpage() {
    this.router.navigate(['/auth/terms']);
  }

  stateChange() {
    this.cityLoader = true;
    let stateId: any = 0;
    this.stateList.forEach(element => {
      if (this.signupFormStaffing.controls['state'].value === element.name) {
        stateId = element.Id;
      }
    });

    this.API.city(stateId).subscribe((res: any) => {
      this.cityLoader = false;
      if (res.success) {
        this.cityList = res.data;
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    })
  }
  //Akhilesh:20200912, file check exits on root folder
  ImageFileExits() {
    this.eterpriseAPI.fileExists("profileimage", this.imgName).subscribe((res: any) => {
      if (res.data == true) {
        this.isImgExits = true;
      }
    });
  }

  // 20201001: Akhilesh, Special charcter validation, jira no 70
  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;
    if (k == 33 || k == 36 || k == 37 || k == 44 || k == 58 || k == 60 || k == 61 || k == 62 || k == 63 || k == 91 || k == 92 || k == 93 || k == 94 || k == 96 || k == 123 || k == 124 || k == 125 || k == 126) {
      this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .', 'Validation!');
      return false
    }
  }
}
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TableService } from 'src/app/admin/service';
import { AuthenticationService } from 'src/app/app.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { NewEnterpriseService } from 'src/app/enterprise/newenterprise.service';
import { Constant } from 'src/app/share-module/constant/constant.constants';
import { ShareService } from '../../share.service';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpHeaderResponse } from '@angular/common/http';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
@Component({
  selector: 'app-add-new-job',
  templateUrl: './add-new-job.component.html',
  styleUrls: ['./add-new-job.component.css']
})
export class AddNewJobComponent implements OnInit {
  enterpriseAddjob: FormGroup;
  jobId: any;
  Ajsubmitted: boolean = false;
  pageValue = false;
  userData: any = {};
  totalOpening = false;
  comparevalError: any;
  monthlist = [];
  isCountrySuccess: boolean = false;
  ListOfAllCountries: any;
  listofAllCountry:any;
  isStateSuccess: boolean = false;
  isCitiesSuccess: boolean = false;
  ListOfAllCities: any;
  isSearchCitiesSuccess: boolean = false;
  SearchListOfAllCities: any;
  allCountryList : any =[];
  stateLoader:boolean= false;
  rateStateloader:boolean =false;
  cityLoader:boolean= false;
  allStateList: any;
  rateStateList: any;
  country_Id: any;
  allCityList: Object;
  state_Id: any;
  fixed = true ;
  // payType = false;
  isfixed = false;
  isPreview = false;
  isUpdateJobPage =false;
  preLoader =false;
  searchCountryValue=false;
  searchStateValue=false;
  constructor(private toastr:ToastrManager,private _formBuilder: FormBuilder , private AuthenticationService:AuthenticationService,private datepipe:DatePipe,
    private ActiveRouter: ActivatedRoute,private enterpriseService:NewEnterpriseService , private API: TableService , private shareservice:ShareService,
    private _router: Router,) { 
    this.AuthenticationService.jwtTokenExpCheck();
    var newTitle = 'Verisource | Add New Job';
    // this.jobId = this.ActiveRouter.snapshot.params['id'];
    this.ActiveRouter.queryParams.subscribe(params => {
      this.jobId = params['id'];
    });
    this.isUpdateJobPage = (this.jobId == undefined?false:true);
    this.jobId = (this.jobId !=undefined?Number(this.jobId):0);

    //this.jobId = 1;
    this.userData = JSON.parse(window.localStorage.getItem('udata'));//Manu Datta: 20210224, VerisourceBugSheetAfterMerge/commonLogin 404
      console.log('==========',this.userData)
    for (let i = 1; i <= (50); i++) {
      this.monthlist.push(i);
    }


    this.enterpriseAddjob = this._formBuilder.group({
      "jobId": this.jobId,
      "epId":481,
      "jobTitle": ["", [Validators.required]],
      "skills":  this.pageValue == false?["", [Validators.required,Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-, ]+$')]]:["html,css",[Validators.required]],
      "jobDescription": ["", [Validators.required]],
      "totalOpenings": ["", Validators.required],
      "requiredExpLevelMin": ["",Validators.required],
      "requiredExpLevelMax": ["",Validators.required],
      "jobDuration":   this.pageValue == false?["", Validators.required]:[""],
      "startDate": ["", Validators.required],
      "endDate": ["", Validators.required],
      "utcStartDate": [""],
      "utcEndDate": [""],
      "street1": ["",Validators.required],
      "street2": ["",Validators.required],
      "country": ["",Validators.required],
      "state": ["",Validators.required],
      "city": ["",Validators.required],
      "postalcode": ["",Validators.required],
      "businessUnit":["",Validators.required],
      "profitCenter":["",Validators.required],
      "jobType":["",Validators.required],
      // "secondCountry":["",Validators.required],
      "secondcountry": ["", [Validators.required]],
      "secondState":["",Validators.required],
      "currency":["",Validators.required],
      "rateType":["",Validators.required],
      "payType":["",Validators.required],
      "minPay":[""],
      "maxPay":["",Validators.required],
      "overTimeAllow":["",Validators.required],
      // "jobLocation": [""],    
      "factorPercent": ["", [Validators.required]],
      "totalJobBudget":["100000",[Validators.required]],
      "note":[""],
      "history":[""],
      "createdDate":[""],
      "jobStatus":[""],
      "jobLocation":0,
      "rateLocation" :0

    })

    this.loadLookupData();
  }

  ngOnInit() {
    console.log(this.lookUpInfo);
  }

  get aj() {
    return this.enterpriseAddjob.controls;
  }

  lookUpInfo : any={};
  allCurrency : any={};
  profitCenter : any={};
  businessUnit : any={};
  rateType : any={};
  payType : any={};
  countries : any ={}
  states : any={}
  ratelocationStates : any={}
  jobTypes : any={}
  Countries : any={}
  States : any={}
  JobDetails : any={}
  rateLocation : any={}
  jobLocation : any={}
  days:number;
  month:number;

  
  loadLookupData(){
  debugger
    this.AuthenticationService.jwtTokenExpCheck();
     this.enterpriseService.loadLookUpData(this.jobId).subscribe((res: any={}) => {
        //this.preloader = false;
        if (res.status == 200) {
          if(res.body){
          this.lookUpInfo = res.body;
          console.log('res.data=========',this.lookUpInfo);
            this.allCurrency = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.Currency);
            this.rateType = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.RateType);
            this.payType = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.PayType);
            this.jobTypes = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.JobType);
            this.rateLocation = this.lookUpInfo.rateLocations;
            this.jobLocation = this.lookUpInfo.jobLocations;
            this.profitCenter = this.lookUpInfo.allProfitCenter;
            this.businessUnit = this.lookUpInfo.allBusinessUnit;
            this.countries = this.lookUpInfo.countries;
            this.states = this.lookUpInfo.states;
            debugger
            // this.JobDetails = this.lookUpInfo[0].JobDetails[0];
            if(this.jobId != 0 && this.jobId != undefined){
              const keywords = this.lookUpInfo.jobKeywords.split(',');
            // const location = this.lookUpInfo[0].TaggedKeyword.split(',');
            this.enterpriseAddjob.controls['jobTitle'].setValue(this.lookUpInfo.jobTitle);
            this.enterpriseAddjob.controls['jobDescription'].setValue(this.lookUpInfo.jobDescription);
            this.enterpriseAddjob.controls['skills'].setValue( keywords);
            this.enterpriseAddjob.controls['totalOpenings'].setValue(this.lookUpInfo.totalOpenings);
            this.enterpriseAddjob.controls['startDate'].setValue(this.datepipe.transform(this.lookUpInfo.startDate, "yyyy-MM-dd"));
           
            this.enterpriseAddjob.controls['endDate'].setValue(this.datepipe.transform(this.lookUpInfo.endDate, "yyyy-MM-dd"));
            this.enterpriseAddjob.controls['requiredExpLevelMin'].setValue(this.lookUpInfo.requiredExpLevelMin);
            this.enterpriseAddjob.controls['requiredExpLevelMax'].setValue(this.lookUpInfo.requiredExpLevelMax);
            debugger
            this.month = Math.floor(this.lookUpInfo.jobDuration/30);
            this.remainingDays = Math.floor(this.lookUpInfo.jobDuration % 30);
            this.enterpriseAddjob.controls['jobDuration'].setValue(Number(this.month));
            this.enterpriseAddjob.controls['totalJobBudget'].setValue( this.lookUpInfo.totalJobBudget);
            this.enterpriseAddjob.controls['street1'].setValue(this.lookUpInfo.street1);
            this.enterpriseAddjob.controls['street2'].setValue(this.lookUpInfo.street2);
            this.enterpriseAddjob.controls['country'].setValue(this.jobLocation[0].country);
            this.enterpriseAddjob.controls['state'].setValue(this.jobLocation[0].state);
            this.enterpriseAddjob.controls['city'].setValue(this.jobLocation[0].city);
            this.enterpriseAddjob.controls['secondcountry'].setValue(this.rateLocation[0].country);
            this.LoadAllState(false);
            this.enterpriseAddjob.controls['secondState'].setValue(this.rateLocation[0].state);
            this.enterpriseAddjob.controls['postalcode'].setValue(this.jobLocation[0].zipCode);
            this.enterpriseAddjob.controls['profitCenter'].setValue(this.lookUpInfo.profitCenter);
            this.enterpriseAddjob.controls['businessUnit'].setValue(this.lookUpInfo.businessUnit);
            this.enterpriseAddjob.controls['jobType'].setValue(this.lookUpInfo.jobType);
            this.enterpriseAddjob.controls['rateType'].setValue(this.lookUpInfo.rateType);
            this.enterpriseAddjob.controls['payType'].setValue(this.lookUpInfo.payType);
            debugger
            const filteredPayType:any = this.payType.filter(x=>x.lookUpId ==  this.enterpriseAddjob.controls['payType'].value);
            this.onChangeRange(this.lookUpInfo.payType);     
            debugger         
            this.enterpriseAddjob.controls['minPay'].setValue(this.lookUpInfo.minPay);
            this.enterpriseAddjob.controls['maxPay'].setValue(this.lookUpInfo.maxPay);
            this.enterpriseAddjob.controls['overTimeAllow'].setValue(this.lookUpInfo.overtimeAllow.toString());
            this.enterpriseAddjob.controls['factorPercent'].setValue(this.lookUpInfo.factorPercent);
            this.enterpriseAddjob.controls['note'].setValue(this.lookUpInfo.note);
            this.enterpriseAddjob.controls['currency'].setValue(this.lookUpInfo.currency);  
            this.enterpriseAddjob.controls['jobStatus'].setValue(this.lookUpInfo.jobStatus);   
            this.enterpriseAddjob.controls['jobLocation'].setValue(this.lookUpInfo.jobLocation);  
            this.enterpriseAddjob.controls['rateLocation'].setValue(this.lookUpInfo.rateLocation);        
            }
            console.log('===================',this.lookUpInfo);
          }
        } else if(res.status == 403) {
          this._router.navigate(['/errorpage']);
        }
      });
   }

   clearData(){
    this.enterpriseAddjob = this._formBuilder.group({
      "jobId":this.jobId,
      "epId": 481,
      "jobTitle": ["", [Validators.required]],
      "skills":  this.pageValue == false?["", [Validators.required,Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-, ]+$')]]:["html,css",[Validators.required]],
      "jobDescription": ["", [Validators.required]],
      "totalOpenings": ["", Validators.required],
      "requiredExpLevelMin": ["",Validators.required],
      "requiredExpLevelMax": ["",Validators.required],
      "jobDuration":   this.pageValue == false?["", Validators.required]:[""],
      "startDate": ["", Validators.required],
      "endDate": ["", Validators.required],
      "utcStartDate": [""],
      "utcEndDate": [""],
      "street1": ["",Validators.required],
      "street2": ["",Validators.required],
      "country": ["",Validators.required],
      "state": ["",Validators.required],
      "city": ["",Validators.required],
      "postalcode": ["",Validators.required],
      "businessUnit":["",Validators.required],
      "profitCenter":["",Validators.required],
      "jobType":["",Validators.required],
      // "secondCountry":["",Validators.required],
      "secondcountry": ["", [Validators.required]],
      "secondState":["",Validators.required],
      "currency":["",Validators.required],
      "rateType":["",Validators.required],
      "payType":["",Validators.required],
      "minPay":[""],
      "maxPay":["",Validators.required],
      "overTimeAllow":["",Validators.required],
      // "jobLocation": [""],    
      "factorPercent": ["", [Validators.required]],
      "totalJobBudget":["100000",[Validators.required]],
      "note":[""],
      "history":[""],
      "createdDate":[""],
      "jobStatus":[""],
      "jobLocation":0,
      "rateLocation" :0

    })

   }

   updateresponse: any;
   addjob() {
    debugger;
    console.log('==============',this.enterpriseAddjob.value)
    const keepStartDate:any = this.enterpriseAddjob.controls['startDate'].value;
    const keepEndDate:any = this.enterpriseAddjob.controls['endDate'].value;
    if (this.enterpriseAddjob.controls['startDate'].value == "") {
      this.enterpriseAddjob.controls['startDate'].markAsTouched();
    }
    if (this.enterpriseAddjob.controls['endDate'].value == "") {
      this.enterpriseAddjob.controls['endDate'].markAsTouched();
    }
    if(this.jobId != undefined){
          
      this.Ajsubmitted = true;
      if (this.enterpriseAddjob.valid) {
        const skillsList = this.enterpriseAddjob.controls['skills'].value;
        const jobLocationList = this.enterpriseAddjob.controls['jobLocation'].value;
        let keyarr = '';
        let keyarr1 = '';
  
        try {
          let count = 0;
          if (skillsList.length > 1) {
            skillsList.forEach((element: any) => {
              if (count === 0) {
                keyarr = (element.value === undefined ? element : element.value);
              } else {
                keyarr = keyarr + ',' + (element.value === undefined ? element : element.value);
              }
              count++;
            });
  
          } else {
            keyarr = (skillsList[0].value === undefined ? skillsList : skillsList[0].value);
          }
        } catch (error) {
          keyarr = '';
        }
        
  debugger
        // this.enterpriseAddjob.controls['jobLocation'].setValue('');
        this.enterpriseAddjob.controls['skills'].setValue(keyarr);
        this.enterpriseAddjob.controls['startDate'].setValue(new Date(this.datepipe.transform(this.enterpriseAddjob.controls['startDate'].value,'yyyy-MM-dd')).toISOString());
        this.enterpriseAddjob.controls['endDate'].setValue(new Date(this.datepipe.transform(this.enterpriseAddjob.controls['endDate'].value,'yyyy-MM-dd')).toISOString());
        this.enterpriseAddjob.controls['utcStartDate'].setValue(new Date(this.shareservice.convertDateIntoUtc(this.enterpriseAddjob.controls['startDate'].value)).toISOString());
        this.enterpriseAddjob.controls['utcEndDate'].setValue(new Date(this.shareservice.convertDateIntoUtc(this.enterpriseAddjob.controls['endDate'].value)).toISOString());
        //   if (this.enterpriseAddjob.controls['startDate'].value == "") {
      //      this.enterpriseAddjob.controls['startDate'].markAsTouched();
      //   }
      //   if (this.enterpriseAddjob.controls['endDate'].value == "") {
      //     this.enterpriseAddjob.controls['endDate'].markAsTouched();
      //  }
  
      //   this.enterpriseAddjob.controls['startDate'].setErrors(null);
      //   this.enterpriseAddjob.controls['endDate'].setErrors(null);
        // let totalOpenings = this.enterpriseAddjob.controls['totalOpenings'].value;
        // let annualPayMax = this.enterpriseAddjob.controls['annualPayMax'].value;
        // let jobMonths = this.enterpriseAddjob.controls['jobDuration'].value;   
        this.enterpriseAddjob.controls['overTimeAllow'].setValue(Boolean(JSON.parse(this.enterpriseAddjob.controls['overTimeAllow'].value)))
        this.enterpriseAddjob.controls['minPay'].setValue(Number(this.enterpriseAddjob.controls['minPay'].value));
        
        if (this.userData.data.credentials.userType === 'EP') {
          this.enterpriseAddjob.controls['createdDate'].setValue(new Date(this.shareservice.convertDateIntoUtc(new Date())).toISOString());              
          debugger 
          this.enterpriseAddjob.controls['jobDuration'].setValue(Number(this.enterpriseAddjob.controls['jobDuration'].value * 30)+Number(this.remainingDays));              
         debugger
          this.enterpriseService.enterpriseUpdateJob(this.enterpriseAddjob.value,this.jobId).subscribe((res :any)=> {
            if(res.status == 200){
              if (res.body) {
                this.jobId = res.body['data'].jobId;
                if(this.enterpriseAddjob.controls['jobId'].value == 0)
                {
                  this._router.navigate(['/jobRequest/detail'],{ queryParams: { id: this.jobId}});
                }
                this.toastr.successToastr(res.body.message, "", {
                position: "top-right"
              });
                this.loadLookupData();
              }

              // this.jobId = res.data.JobId;
              // this.enterpriseAddjob.controls['jobStatus'].setValue(res.data.JobStatus);
              // this.toastr.successToastr(res.message, "", {
              //   position: "top-right"
              // });
              // this.enterpriseAddjob.controls['startDate'].setValue(keepStartDate); 
              // this.enterpriseAddjob.controls['endDate'].setValue(keepEndDate); 
              // this.enterpriseAddjob.reset();
            } 
            else if(res.status == 403){
              this._router.navigate(['/errorpage']);
            }
            else {
              this.toastr.errorToastr(res.body.message, "", {
                position: "top-right"
              });
            }

          },(errorResponse: any) => {
            debugger
            this.enterpriseAddjob.controls['startDate'].setValue(keepStartDate); 
            this.enterpriseAddjob.controls['endDate'].setValue(keepEndDate); 

            
            if (errorResponse instanceof HttpErrorResponse) {
              // const errorMessages = new Array<{ propName: string; errors: string }>();         
              if (errorResponse.status === 422) {
                // TODO: extract errors here and match onto the form
                const validationErrors = errorResponse['error']['message'].errors;
                for (let err of validationErrors) {
                  const formControl = this.enterpriseAddjob.get(err.param);
                  if (formControl) {
                    formControl.setErrors({
                      serverError: err.msg
                    });
                  }
                }
              }
            }    
            console.log('error===========',FormControl)      
            });
        }
  
      }
      else {
        debugger
        this.enterpriseAddjob.controls['startDate'].setValue(keepStartDate); 
        let msg = `Please Fill ${this.findInvalidControls()}.`;
        let message = msg.split(' ');
        if(message[2] == "startDate."){
          this.toastr.warningToastr('Please Select Start Date', 'Warning!');
        }
        else{
          this.toastr.warningToastr(msg, 'Warning!');
        }
        return false;
      }
    }      
    }


  findInvalidControls() {
    const invalid = [];
    const controls = this.enterpriseAddjob.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
        break;
      }
    }
    return invalid;
  }

  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;   
     if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
       this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
       return false
      }
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  removeZero(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 48 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  compareExpLevel(e) {
    let min = parseInt(this.enterpriseAddjob.controls['requiredExpLevelMin'].value);
    let max = parseInt(this.enterpriseAddjob.controls['requiredExpLevelMax'].value);
    if (!Number.isNaN(max)) {
      if (min > max) {
        this.comparevalError = true;
        this.enterpriseAddjob.controls['requiredExpLevelMax'].setErrors({ 'invalid': true });
      }
      else {
        this.comparevalError = false;
      }
    }
    else {
      this.comparevalError = false;
    }
  }

  salaryValidation(e) {
    if (e.target.value != '') {
      if (e.target.value != 0) {
        console.log('fail', e.target.value)
        this.totalOpening = false;
      } else {
        this.totalOpening = true;
        console.log('come', e.target.value)
        this.enterpriseAddjob.controls['totalOpenings'].setErrors({ 'invalid': true });
      }
    } else {
      console.log('end', e.target.value)
      this.totalOpening = false;
    }
  }

  onChangeRange(field_no: number) {
    debugger;
    // this.isfixed = field_no == 11 ? true : false;
    if(field_no == 10){
      this.isfixed = false;
      this.enterpriseAddjob.controls["minPay"].clearValidators();
      this.enterpriseAddjob.controls["minPay"].setValue('');
      this.enterpriseAddjob.controls["maxPay"].setValue((this.lookUpInfo.maxPay != null || this.lookUpInfo.maxPay != undefined)?this.lookUpInfo.maxPay:"");
    }
    else{
      this.isfixed = true;
      this.enterpriseAddjob.controls["minPay"].setValidators(Validators.required);
      this.enterpriseAddjob.controls["minPay"].setValue(Validators.required);
      this.enterpriseAddjob.controls["minPay"].setValue((this.lookUpInfo.minPay != null || this.lookUpInfo.minPay != undefined)?this.lookUpInfo.minPay:"");
      this.enterpriseAddjob.controls["maxPay"].setValue((this.lookUpInfo.maxPay != null || this.lookUpInfo.minPay != undefined)?this.lookUpInfo.maxPay:"");
    }
  } 

  isShow = false;
  onChange_OverTimeAllow(selectedValue: Boolean) {
    console.log(selectedValue);
    if (selectedValue) {
      this.enterpriseAddjob.controls['factorPercent'].setValidators(Validators.required);
      this.isShow = false;
    }
    else {
      this.enterpriseAddjob.controls['factorPercent'].clearValidators();
      this.enterpriseAddjob.controls['factorPercent'].reset();
      this.isShow = true;
    }

  }

  today: String = new Date().toISOString().split('T')[0];
  from_Date: String = new Date().toISOString().split('T')[0];
  endDate :String = "";
  todayDate: String = "";
  dateForToDate(type: number) {
    debugger
    if (type == 1) {
      this.from_Date  = new Date(this.datepipe.transform(this.enterpriseAddjob.controls['startDate'].value)).toISOString();
    }

    if (type == 2) {
      this.endDate = new Date(this.datepipe.transform(this.enterpriseAddjob.controls['endDate'].value)).toISOString();
      this.calculatedDays = Math.floor((Date.UTC(new Date(this.enterpriseAddjob.controls['endDate'].value).getFullYear(), new Date(this.enterpriseAddjob.controls['endDate'].value).getMonth(),new Date(this.enterpriseAddjob.controls['endDate'].value).getDate()) - Date.UTC(new Date(this.enterpriseAddjob.controls['startDate'].value).getFullYear(), new Date(this.enterpriseAddjob.controls['startDate'].value).getMonth(), new Date(this.enterpriseAddjob.controls['startDate'].value).getDate()) ) /(1000 * 60 * 60 * 24));
      this.enterpriseAddjob.controls['jobDuration'].setValue(Math.floor(Number(this.calculatedDays)/30));
      this.remainingDays = Math.floor(Number(this.calculatedDays) % 30);
    }
    
  }

  LoadAllState(type:boolean){
    debugger
    this.preLoader =true;
    this.enterpriseAddjob.controls['secondcountry'].setValue(type==true?this.enterpriseAddjob.controls['country'].value:this.enterpriseAddjob.controls['secondcountry'].value);
    return new Promise((resolve, reject) => {
      debugger;
      this.enterpriseService.LoadAllState(type==true?this.enterpriseAddjob.controls['country'].value:this.enterpriseAddjob.controls['secondcountry'].value).subscribe((res:any) => {
        if(res) {
          // resolve(this.states);
          //this.states = res.data;
          if(type){
            this.states = res;
            if(this.enterpriseAddjob.controls['secondcountry'].value == this.enterpriseAddjob.controls['country'].value){
              this.ratelocationStates = res;
            }
            resolve(this.states);
            this.preLoader = false;
            // this.allStateList=res;
            this.stateLoader = false;
            this.isStateSuccess = true;
          }
          else{
            this.ratelocationStates =res;
            this.preLoader =false;
            this.rateStateList =res;
            this.rateStateloader =false;

          }
          // this.enterpriseAddjob.controls['state'].setErrors({ 'incorrect': true });
        } 
        else {
          reject("Error!");
        }
      });
    });

  }

  onChangeState(){
    this.enterpriseAddjob.controls['secondState'].setValue(this.enterpriseAddjob.controls['state'].value);
  }
 
  calculatedDays : Number;
  remainingDays : Number;
  // calculateDays(){
  //   // let currentDate = new Date();
  //   // dateSent = new Date(dateSent);
  //   this.calculatedDays = Math.floor((Date.UTC(this.enterpriseAddjob.controls['startDate'].value.getFullYear(), this.enterpriseAddjob.controls['startDate'].value.getMonth(), this.enterpriseAddjob.controls['startDate'].value.getDate()) - Date.UTC(this.enterpriseAddjob.controls['endDate'].value.getFullYear(), this.enterpriseAddjob.controls['endDate'].value.getMonth(), this.enterpriseAddjob.controls['endDate'].value.getDate()) ) /(1000 * 60 * 60 * 24));
  //   this.enterpriseAddjob.controls['jobDuration'].setValue(Math.floor(Number(this.calculatedDays)/30));
  //   this.remainingDays = Math.floor(Number(this.calculatedDays) % 30);
  // }

  changeCalculatedDays(){
    this.remainingDays =0;
  }
}

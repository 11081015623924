import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  loginType: string = 'SP'
  loginData: any = {};
  constructor(private _titleService : Title) {
    this.loginData = JSON.parse(localStorage.getItem('udata'));
    this.loginType = this.loginData.data.credentials.userType;
    // for title set 
    var newTitle = 'Verisource | Notification';
    this._titleService.setTitle( newTitle );
  }

  ngOnInit() {
  }

}

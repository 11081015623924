
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EnterpriseService } from '../enterprise.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentPopupComponent } from '../payment-popup/payment-popup.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { JobseekerService } from '../../JobSeeker/jobseeker.service';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/admin/service/excel.service';
import { AuthenticationService } from '../../app.service';
import { ShareService } from 'src/app/share-module/share.service';

export interface PeriodicElement {
  srNo: String,
  position: String,
  invoiceNo: String,
  jobTitle: String,

  dateofpayment: String,
  payableAmount: String
}



// import { StripeService, StripeCardComponent, ElementOptions, ElementsOptions } from "ngx-stripe";
@Component({
  selector: 'app-my-invoice-interprise-paid',
  templateUrl: './my-invoice-interprise-paid.component.html',
  styleUrls: ['./my-invoice-interprise-paid.component.css']
})
export class MyInvoiceInterprisePaidComponent implements OnInit {
  @ViewChild(MatPaginator,{static : false} ) paginators: MatPaginator;
  selected = 'Date';
  isVisibleFilterBtn = true;
  islstopen: boolean = false;
  invoiceFilter: FormGroup;
  allInvoiceData: any=[];
  csv_allInvoiceData:any=[];
  preLoader: boolean = false;
  activeLinks: string = 'Paid';
  sumAmount: number = 0;
  payOk: boolean = false;
  filterList: any;
  ddlFilterList: any;
  panelOpenState: boolean = false;
  selectTotalAmount: number = 0;
  paginator = {
    pageSize: 5,
    length: 0,
    pageSizeOptions: [5, 10, 25, 100],
    pageIndex: 0
  };

  // // @ViewChild(StripeCardComponent) card: StripeCardComponent;
  // // cardOptions: ElementOptions = {
  // //   style: {
  // //     base: {
  // //       iconColor: '#666EE8',
  // //       color: '#31325F',
  // //       lineHeight: '40px',
  // //       fontWeight: 300,
  // //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  // //       fontSize: '18px',
  // //       '::placeholder': {
  // //         color: '#CFD7E0'
  // //       }
  // //     }
  // //   }
  // // };

  // elementsOptions: ElementsOptions = {
  //   locale: 'es'
  // }

  pay = {
    amount: 2000,
    currency: "usd",
    source: "tok_visa", // obtained with Stripe.js
    metadata: { 'order_id': '6735' }
  }

  stripeTest: FormGroup;
  filterStatus: boolean = false;

  constructor(private _formBuilder: FormBuilder,
    private epAPI: EnterpriseService,
    public dialog: MatDialog,
    private toastr: ToastrManager,
    private JSApi: JobseekerService,
    private excelService: ExcelService,
 private AuthenticationService: AuthenticationService,

    // private stripeService: StripeService,
    private _titleService  :Title,
    private shareservice:ShareService,
    private datepipe:DatePipe
  ) {
    // for title set 
    var newTitle = 'Verisource | My Invoices';
    this._titleService.setTitle( newTitle );

    this.filterInit();
    this.AuthenticationService.jwtTokenExpCheck();
    this.epAPI.getFilterInvoices().subscribe((res: any) => {
      if (res.success) {
        this.filterList = res.data;
        if(res.data.length == 0){
          this.isVisibleFilterBtn = false;
        } 
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    })


  }

  isLogin='true';
  openDialog(): void {
    this.AuthenticationService.jwtTokenExpCheck();
   
    if (this.selectTotalAmount > 0) {
      let invcList: any = [];
      this.selection.selected.forEach(element => {
        invcList.push(element.invoiceNo);
      });
      // console.log()
      this.isLogin = localStorage.getItem('islogin');
      if(this.isLogin != 'false'){
      const dialogRef = this.dialog.open(PaymentPopupComponent, {
        width: '450px',
        data: { invoiceId: invcList, amount: this.selectTotalAmount }
      });

      dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed');
        this.getInvoiceData();
        this.selectTotalAmount = 0;
        this.selection.clear();
        // this.animal = result;
      });
      }
    } else {
      this.toastr.warningToastr('Please select invoice', 'Warning!', {
        timeOut: 1000,
      });
    }

  }


  ngOnInit() {
    this.setFilterType('Pending');

  }

  filterInit() {
    this.invoiceFilter = this._formBuilder.group({
      PageSize: 5,
      PageNumber: 1,
      invoice_status: "Paid",
      generatedDate: '0',
      dateOfPayment: "",
      search: "",
    });
  }



  setFilterType(type) {

    this.AuthenticationService.jwtTokenExpCheck();
    if (type !== 'filter') {
      this.sumAmount = 0;
      this.selectTotalAmount = 0;
      this.selection.clear();
      this.activeLinks = type;
      this.invoiceFilter.controls['invoice_status'].setValue(type);
    }
    this.searchButton = true;
    this.getInvoiceData();
  }

  setFilterstatus(){
    this.filterStatus = true;
  }


  OpenlfMenu() { this.islstopen = !this.islstopen; }

  searchButton = false;
  loadData(){
    this.searchButton= true;
    this.getInvoiceData();
  }

  getInvoiceData() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.preLoader = true;
    this.allInvoiceData = [];
    this.sumAmount = 0;

 

    let d=this.invoiceFilter.controls['dateOfPayment'].value
    if(d !=""){
      var month = d.getMonth() + 1;
    let finalDate = d.getFullYear() + "-" + (month < 10 ? '0' : '') + month + '-' + (d.getDate() < 10 ? '0' : '') + d.getDate();
    this.invoiceFilter.controls['dateOfPayment'].setValue(finalDate);
    }

    // console.log("Setup Invoice : ", this.invoiceFilter.value);
    if(this.searchButton == true){
      this.invoiceFilter.controls['PageNumber'].setValue(1);
    }
    this.epAPI.getallinvoices(this.invoiceFilter.value).subscribe((res: any) => {
      this.preLoader = false;
      if (res.success) {
        this.allInvoiceData = res.data;
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }
        this.searchButton = false;
        console.log(' this.allInvoiceData', this.allInvoiceData);
        this.dataSource = this.allInvoiceData;
        if (this.allInvoiceData.length > 0) {
          this.paginator.length = this.allInvoiceData[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
        this.csv_allInvoiceData =[];
        this.allInvoiceData.forEach(element => {
          let itemNumber =0;
          this.sumAmount = this.sumAmount + element.payableAmount;          
          if(this.activeLinks == 'Pending'){
          this.csv_allInvoiceData.push({
            'Invoice No': element.invoiceNo,
            'Job Name': element.jobTitle,
            'Due Date': this.datepipe.transform(element.duedate ,'MM/dd/yyyy','locale'),
            'Amount': element.payableAmount
           });
         }
          if(this.activeLinks=='Paid'){
            
            this.csv_allInvoiceData.push({
              'Item Number': itemNumber+1,
              'ID': element.srNo,
              'Invoice Number': element.invoiceNo,
              'Job Title': element.jobTitle,
              'Date of Payment': this.datepipe.transform(element.dateofpayment ,'MM/dd/yyyy','locale'),
              'Amount' : element.payableAmount
             });
          }

        });
        this.invoiceFilter.controls['dateOfPayment'].setValue(d);
      } else {
        this.toastr.successToastr(res.message, 'error!')
      }
      // console.log("Invoice List:", this.allInvoiceData);
    })

  }

  // makePayment(amount) {
  //   this.payOk = true;
  //   this.buy(amount);
  // }




  // buy(name) {
  //   this.stripeService
  //     .createToken(this.card.getCard(), {})
  //     .subscribe(result => {
  //       if (result.token) {
  //         // Use the token to create a charge or a customer
  //         // https://stripe.com/docs/charges
  //         console.log(result.token.id);
  //       } else if (result.error) {
  //         // Error creating the token
  //         console.log(result.error.message);
  //       }
  //     });
  // }


  displayedColumns: string[] = ['select', 'invoiceNo', 'jobTitle', 'dateofpayment', 'payableAmount', 'Action'];
  dataSource = this.allInvoiceData;
  selection = new SelectionModel(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // console.log("adasdasda =========>", this.dataSource);
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.forEach(row => this.selection.select(row));
    this.myselection();
    // console.log('All selection :', this.selection.selected)

  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    this.myselection();
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }
  myselection() {
    // console.log('My selection :', this.selection.selected);
    this.selectTotalAmount = 0;
    this.selection.selected.forEach((element) => {
      this.selectTotalAmount = this.selectTotalAmount + element.payableAmount;
    });
  }

  mySelect(row) {

  }
  onChange(row) {
    console.log("adasdasda =========>", row);
    this.selection.toggle(row);
    this.myselection();
  }
  jsonArrayCnvrtVl(val) {
    // console.log('val :---->>', Object.keys(val));
    return Object.keys(val);
  }
  jsonArrayCnvrt(val) {
    return Object.values(val);
  }

  countryData(ddlType, values) {
    this.ddlFilterList = [];
    // console.log("==============?>", ddlType);
    switch (ddlType) {
      case 'country': {
        if (this.invoiceFilter.controls['country'].value.length >= 3) {
          this.JSApi.countries(this.invoiceFilter.controls['country'].value).subscribe((res: any) => {
            if (res.success) {
              // console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }
          });
        }
        break;
      }
      case 'jobLocation': {
        if (this.invoiceFilter.controls['jobLocation'].value.length >= 3) {
          // console.log("Response ------------>");
          this.JSApi.joblocation(this.invoiceFilter.controls['jobLocation'].value).subscribe((res: any) => {
            if (res.success) {
              // console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }

          });
        } else {
          // console.log("no location");
        }
        break;
      }

    }
  }

  clearData() {
    this.ddlFilterList = [];
  }

  setpaggination(e) {
 
    // console.log(e);
    this.searchButton= false;
    // this.invoiceFilter.controls['PageSize'].setValue(e.pageSize);
    // this.invoiceFilter.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.invoiceFilter.controls['PageSize'].setValue(e.pageSize);
    this.invoiceFilter.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.getInvoiceData();
  }

  resetFilter() {
    
    this.filterInit();
    this.paginator.pageSize=5;
    this.setFilterType('Pending');
  }


  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.csv_allInvoiceData, 'sample');
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  clearForm() {

    //window.location.reload();
    this.invoiceFilter = this._formBuilder.group({
      PageSize: 5,
      PageNumber: 1,
      invoice_status: "Pending",
      generatedDate: '0',
      dateOfPayment: "",
      search: "",
    });
    //this.filterStatus = false;
    this.invoiceFilter.controls['invoice_status'].setValue('Pending');
    //this.activeLinks= 'Pending';
    this.setFilterType('Pending');
    this.paginator.pageSize=5;
    //this.loadData();
  }

}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RouterLink, Router } from '@angular/router';
import { EnterpriseService } from '../enterprise.service';
import { AuthenticationService } from '../../app.service'
import { Title } from '@angular/platform-browser';
import { staffingAgencyServices } from '../../staffing-agency/staffing-agency.services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
//import { ResetWarningPopUpComponent } from 'src/app/share-module/reset-warning-pop-up/reset-warning-pop-up.component';
import { environment } from 'src/environments/environment';
import { JwtService } from 'src/app/admin/service/jwt.service';

@Component({
  selector: 'app-enterprise-dashboard',
  templateUrl: './enterprise-dashboard.component.html',
  styleUrls: ['./enterprise-dashboard.component.css']
})
export class EnterpriseDashboardComponent implements OnInit {
  preLoader: boolean = false;
  enterpriserData: any;
  enterpriseDataShow: boolean = false;
  recentAct: boolean = false;
  rescetActivityData: any;
  announcShow: boolean = false;
  announceData: any;
  img_path: string = '';
  staffDate: any;
  userData: any;
  staffDataShow=false;
  paginator = {
    RA: {
      pageSize: 5,
      length: 0,
      pageSizeOptions: [5, 10, 20, 100]
    }, AN: {
      pageSize: 5,
      length: 0,
      pageSizeOptions: [5, 10, 20, 100]
    }
  };

  userDataCheck: any = {};
  todayDate= new Date;
  dbdate=new Date; 
  diffDays: number;

  @Output() myEmitter = new EventEmitter()
  announcementsModel: any;

  // constructor(private myrouter:Router, private eterpriseAPI: EnterpriseService) {

  //  }

  constructor(
    private myrouter: Router,
    private eterpriseAPI: EnterpriseService,
    private API: AuthenticationService,
    private _titleService: Title,
    private staffAPI: staffingAgencyServices,
    private toastr: ToastrManager,
    config: NgbModalConfig, private datePipe: DatePipe, public dialog: MatDialog,
     private modalService: NgbModal, private jwtService:JwtService
  ) {
    var loginData=localStorage.getItem('udata');
    this.userData = JSON.parse(window.localStorage.getItem('udata'));

    // For NgbModal
    config.backdrop = 'static';
    config.keyboard = false;

    // for title set 
    var newTitle = 'Verisource | Dashboard';
    this._titleService.setTitle( newTitle );

    // this.userData = JSON.parse(window.localStorage.getItem('udata'));
    // console.log('User data login >>>>>>>>>>>>>>>>>>>> :', this.userData);
    // localStorage.setItem('islogin', 'true');
    // this.preLoader = true;
    this.preLoader = false;
    this.myEmitter.emit('true');
    this.dashboardButtons();
  if(this.userData.data.credentials.Role=='ClientHiringManager'){
    debugger
    this.eterpriseAPI.ClientHiringManagerDashboard().subscribe(res => {

      this.enterpriserData = res;
      console.log('enterpriserData',res);
      this.img_path = `${this.API.url}/api/enterprise/image/profileimage/${this.enterpriserData.data[0].profileImage}`;
      //  this.getNotification(localStorage.getItem('token'))
      this.data = [{
        kind: '', share: 100, color: '#dddddd'
      }, {
        kind: '', share: this.enterpriserData.data[0].jobsuccessrate,
        color:'#f01860'
      }];

      this.preLoader = false;
      this.enterpriseDataShow = true;
    });
  
    this.eterpriseAPI.ClientHiringManagerDashboard().subscribe((res: any) => {
     
          if (res.success) {
       
        this.staffDate = res;
        console.log('this.staffDate.data',this.staffDate.data)
        this.img_path = `${this.API.url}/api/staffingagency/image/profileimage/${this.staffDate.data[0].profileImage}`;
        this.data = [
          {
            kind: '',
            share: 100,
            color: '#dddddd'
          },
          {
            kind: '',
            share: this.staffDate.data[0].jobsuccessrate,
            color: '#3174bc'
          }
        ];
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
        this.staffDate = JSON.parse(JSON.stringify(localStorage.getItem('udata')));
      }
      this.preLoader = false;
      this.staffDataShow = true;
    });
  }
  else{
  

    // this.eterpriseAPI.enterpriseDashboard().subscribe(res => {
 
    //         this.enterpriserData = res;
    //         console.log('enterpriserData',res);
    //         this.img_path = `${this.API.url}/api/enterprise/image/profileimage/${this.enterpriserData.data[0].profileImage}`;
    //         //  this.getNotification(localStorage.getItem('token'))
    //         this.data = [{
    //           kind: '', share: 100, color: '#dddddd'
    //         }, {
    //           kind: '', share: this.enterpriserData.data[0].jobsuccessrate,
    //           color:'#f01860'
    //         }];
      
    //         this.preLoader = false;
    //         this.enterpriseDataShow = true;
    //       });
        
          // this.eterpriseAPI.enterpriseDashboard().subscribe((res: any) => {
         
          //       if (res.success) {
             
          //     this.staffDate = res;
          //     console.log('this.staffDate.data',this.staffDate.data)
          //     this.img_path = `${this.API.url}/api/staffingagency/image/profileimage/${this.staffDate.data[0].profileImage}`;
          //     this.data = [
          //       {
          //         kind: '',
          //         share: 100,
          //         color: '#dddddd'
          //       },
          //       {
          //         kind: '',
          //         share: this.staffDate.data[0].jobsuccessrate,
          //         color: '#3174bc'
          //       }
          //     ];
          //   } else {
          //     this.toastr.errorToastr(res.message, 'Error!');
          //     this.staffDate = JSON.parse(JSON.stringify(localStorage.getItem('udata')));
          //   }
          //   this.preLoader = false;
          //   this.staffDataShow = true;
          // });
      
  }
    // this.recentActivityfn(5, 1);
    // this.announceMentlist(1, 5);
    this.userDataCheck = JSON.parse(localStorage.getItem('udata'));
    //console.log('udata',JSON.stringify(this.userDataCheck));
    //console.log('this.userDataCheck.data.credentials',this.userDataCheck.data.credentials.UpdatePwdDate);

    let newDate = new Date(this.datePipe.transform(this.userDataCheck.data.credentials.UpdatePwdDate, 'yyyy-MM-dd'));
    //console.log('this.todayDate.getTime()',this.todayDate);
    //console.log('dbdate',newDate);
    var diff = Math.abs(this.todayDate.getTime() - newDate.getTime());
     this.diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
    console.log('diffDays',this.diffDays);
    console.log('environment.passwordExpiredays',environment.passwordExpiredays);
    if(this.diffDays>environment.passwordExpiredays) {
     // this.openResetWarningPopUp();
    }


  }
  // openResetWarningPopUp(){
  //   const dialogRef = this.dialog.open(ResetWarningPopUpComponent, {
  //     width: 'auto',
  //     data: this.diffDays
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //   });

  // }
  public data: any[] = [{
    kind: '', share: 100,
    color: '#ddd'
  }, {
    kind: '', share: 100,
    color:'#f01860'
  }];

  public labelContent(e: any): string {
    return e.category;
  }
  public chartType: string = 'doughnut';

  public chartDatasets: Array<any> = [
    {
      data: [300, 50, 100, 40, 120.100, 120, 30],
      holeSize: 10,
      padding: 3,
      font: 'bold 16px Arial, sans-serif',
      label: 'My First dataset'
    }
  ];

  public chartLabels: Array<any> = ['Red', 'Green', 'Yellow', 'Grey', 'Dark Grey'];

  public chartColors: Array<any> = [
    {
      backgroundColor: ['#105092', '#105092', '#105092', '#105092', '#105092'],
      hoverBackgroundColor: ['#105092', '#105092', '#105092', '#105092', '#105092'],
      borderWidth: 10,
    }
  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }
  ngOnInit() {
    // console.log("enterprise Data :", this.enterpriserData);
    // console.log(localStorage.getItem('islogin'));
  }

  Buttons = [
    { id:'epdiv_timesheet', color:'profile-box color3', title: 'TimeSheet', imageLink: 'assets/images/timesheet-color.svg', routerLink:'/ep/enterprise/timesheetsupplier', dashboardRouterLink:'/ep/enterprise/dashboard', permission:'Permissions.Roles.View' },
    { id:'epdiv_addjob', color:'profile-box color1', title: 'Add Job', imageLink: 'assets/images/addjob.svg', routerLink:'/ep/enterprise/addnewjob', dashboardRouterLink:'/ep/enterprise/dashboard' , permission:'Permissions.Roles.View'},
    { id:'epdiv_addjob', color:'profile-box color1', title: 'Add Job Request', imageLink: 'assets/images/addjob.svg', routerLink:'/jobRequest/detail', dashboardRouterLink:'/ep/enterprise/dashboard' , permission:'Permissions.Roles.View'},
    { id:'epdiv_viewinterview', color:'profile-box color2', title: 'View Interviews', imageLink: 'assets/images/interview-color.svg', routerLink:'/jobRequest', dashboardRouterLink:'/ep/enterprise/dashboard', permission:'Permissions.Roles.View' },
  
    { id:'epdiv_supplier', color:'profile-box color3', title: 'Supplier', imageLink: 'assets/images/timesheet-color.svg', routerLink:'', dashboardRouterLink:'/ep/enterprise/dashboard', permission:'Permissions.Roles.View' },
    { id:'epdiv_addresume', color:'profile-box color4', title: 'Add Resume', imageLink: 'assets/images/addresume-color.svg', routerLink:'', dashboardRouterLink:'/ep/enterprise/dashboard', permission:'Permissions.Roles.View' },
    { id:'epdiv_myjobs', color:'profile-box color5', title: 'My Jobs', imageLink: 'assets/images/myjob-color.svg', routerLink:'/jobRequest', dashboardRouterLink:'/ep/enterprise/dashboard', permission:'Permissions.Roles.View' },
    { id:'epdiv_browsejobs', color:'profile-box color6', title: 'Browse Jobs', imageLink: 'assets/images/browse-job-color.svg', routerLink:'/ep/enterprise/Jobseeker', dashboardRouterLink:'/ep/enterprise/dashboard', permission:'Permissions.Roles.View' },
    
  ];

  Permission:any=[];
  profileBoxButtons:any;
  dashboardButtons(){
    const decoded= this.jwtService.getDecodedAccessToken(this.userData?.data.token); 
    this.Permission = decoded.Permission;
    this.profileBoxButtons = this.Buttons.filter(buttons =>
      this.Permission.some(item2 => buttons.permission === item2)
    );
    console.log('==========',this.profileBoxButtons);

  }

  announceMentlist(pageno, size) {
    this.eterpriseAPI.announcement(pageno, size).subscribe((res: any) => {
      if (res.success) {
        this.announcShow = true;
        this.announceData = res;
        if (this.announceData.data.length > 0) {
          this.paginator.AN.length = this.announceData.data[0].totalCount;

        } else {
          this.paginator.AN.length = 0;
        }
      } else {

      }


      // console.log("announceMent Data", res);
    });
  }
  recentActivityfn(pageSize, pageNumber) {
    this.eterpriseAPI.recentActivity(pageSize, pageNumber).subscribe(res => {

      this.rescetActivityData = res;
      if (this.rescetActivityData.success) {
        this.recentAct = true;
        if (this.rescetActivityData.data.length > 0) {
          this.paginator.RA.length = this.rescetActivityData.data[0].totalCount;
        } else {
          this.paginator.RA.length = 0;
        }
      }
    });
  }
  Popupstaffingform() { }

  setpaggination(e, type) {
    if (type == 'RA') {
      this.recentActivityfn(e.pageSize, e.pageIndex + 1);
    }
    if (type == 'AN') {
      this.announceMentlist(e.pageIndex + 1, e.pageSize);
    }
  }

  openAnnouncementsModel(content, data) {
    this.announcementsModel = data;
    this.modalService.open(content);
  }

  suppliers(){
    this.myrouter.navigateByUrl("/suppliers");
  }
}


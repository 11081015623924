import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EnterpriseService } from '../enterprise.service';
import { first } from 'rxjs/operators';
import { ExcelService } from 'src/app/admin/service/excel.service';
import { PreviewResumePopupComponent } from '../preview-resume-popup/preview-resume-popup.component';
	import {AuthenticationService } from '../../app.service';
import { DatePipe } from '@angular/common';
import { TableService } from 'src/app/admin/service/table.service';
  
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { ShareService } from 'src/app/share-module/share.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-jobseeker-enterprise',
  templateUrl: './jobseeker-enterprise.component.html',
  styleUrls: ['./jobseeker-enterprise.component.css'],
  providers: [    
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class JobseekerEnterpriseComponent implements OnInit {
  // Datepicker takes `Moment` objects instead of `Date` objects.
  date = new FormControl(moment([2017, 0, 1]));
  @ViewChild(MatPaginator,{static : false} ) paginators: MatPaginator;
  preloader = false;
  jobseekerForm: FormGroup;
  isCollapsedMenu = false;
  jobseekerlist: any;
  csv_jobseekerlist:any=[];
  linkActive = 'jobseeker';
  showDetails = false;
  paginator = {
    pageSize: 5,
    length: 0,
    pageSizeOptions: [5, 10, 15, 20],
    pageIndex: 0
  }
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];
  issuccess: any;
  userData:any={};
  constructor(public dialog: MatDialog, private _router: Router,
    private tostr: ToastrManager, private _paramVal: ActivatedRoute,
    private api: EnterpriseService, private formBuilder: FormBuilder, private _titleService: Title, private tableService: TableService, private AuthenticationService: AuthenticationService,
    private excelService: ExcelService,private datepipe:DatePipe,private shareservice:ShareService) {
    //for title set 
    var newTitle = 'Verisource | Job Seeker';
    this._titleService.setTitle(newTitle);
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.jobseekerForm = this.formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      status: 'All',
      PageSize: 5,
      PageNumber: 1,
      profile_status:'All'
    });
  }

  ngOnInit() {
    this.loadjobseekerlist();
  }
  mobMenulist() {
    this.isCollapsedMenu = !this.isCollapsedMenu;
  }

  searchButton = false;
  loadData(){
    this.searchButton= true;
    this.loadjobseekerlist();
  }

  loadjobseekerlist() {

    this.AuthenticationService.jwtTokenExpCheck();
    if (this.jobseekerForm.value.todate != "" && this.jobseekerForm.value.fromdate != null) {
      this.jobseekerForm.controls['fromdate'].setValue(this.shareservice.convertDateIntoUtc(this.datepipe.transform(this.jobseekerForm.value.fromdate,'yyyy-MM-dd')));
      this.jobseekerForm.controls['todate'].setValue(this.shareservice.convertDateIntoUtcForSearch(this.datepipe.transform(this.jobseekerForm.value.todate,'yyyy-MM-dd')));
    }
    this.preloader = true;
    if(this.searchButton == true){
      this.jobseekerForm.controls['PageNumber'].setValue(1);
    }
    this.api.jobseekerlist(this.jobseekerForm.value).pipe(first()).subscribe(res => {
      if (res.success) {
        console.log('jobseekerlist',this.jobseekerlist);
        this.jobseekerlist = res.data;
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }
        this.searchButton = false;
        this.showDetails = true;
        this.preloader = false;
        if (this.jobseekerlist.length > 0) {
          this.paginator.length = this.jobseekerlist[0].totalCount;
        }

        this.jobseekerlist.forEach(element => {
          this.csv_jobseekerlist.push({
              'Job Seeker ID': element.jobseekerId,
              'Job Seeker': element.fullName,
              'Registration Date':  this.datepipe.transform(element.registerDate,'MMM d, y','locale'),
              'Total Applied Jobs': element.totalAppliedJobs,
              'Supplier':element.staffingagencyId,
              'Status': element.status,
              
            });
      });
      }
    });
  }

  resetjobseekerfilterform() {
    this.today = new Date().toISOString().split('T')[0];
    this.from_Date = null;
    this.jobseekerForm = this.formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      status: 'All',
      PageSize: 5,
      PageNumber: 1,
      profile_status:'All'
    });
   this.paginator.pageSize=5;
    this.loadjobseekerlist();
    this.paginators.pageIndex = 0;
  }
  activeLinks(val) {
    this.linkActive = val;
  }
  // jobseekerView(id) {
  //   this._router.navigate(['/jobseeker_detail', id]);
  // }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }


  blockJobSeeker(jobseeker_id: any, status: any) {
  //Active Inactive from Veridial
  this.tableService.updateCompanyStatusVD(jobseeker_id,status,environment.AppRefTypeJobseeker).subscribe(res => {
    this.issuccess = res;
    if (this.issuccess.success) {
    this.api.JobSeekerBlock(jobseeker_id, status).pipe(first()).subscribe(res => {
      if (res.success) {
        this.tostr.successToastr(res.message, 'Success!');
        this.loadjobseekerlist();
      } else {
        this.tostr.errorToastr(res.message, 'OPPS!');
      }
    });
  }
});
}

  getpaginatorData(e) {
    this.searchButton = false;
    // this.jobseekerForm.controls.PageSize.setValue(e.pageSize);
    // this.jobseekerForm.controls.PageNumber.setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.jobseekerForm.controls['PageSize'].setValue(e.pageSize);
    this.jobseekerForm.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.loadjobseekerlist();
  }

  from_Date:String;
dateForToDate(type:number){
  if(type == 1){
    this.from_Date = new Date(this.datepipe.transform(this.jobseekerForm.controls['fromdate'].value)).toISOString();
  }

  if(type == 2){
    this.today = new Date(this.datepipe.transform(this.jobseekerForm.controls['todate'].value)).toISOString();
  }
}
  // jobseekerpopup(jobseeker){ 
  // const dialogRef = this.dialog.open(JobseekerDetailsPopupComponent, { 
  // width: 'auto',
  // //disableClose: true,
  // data: jobseeker
  // });
  // dialogRef.afterClosed().subscribe(result => {
  // });
  // }

  isLogin='true';
  jobseekerpopup(jobseeker) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(PreviewResumePopupComponent, {
      width: 'auto',
      //disableClose: true,
      data: jobseeker
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  }
  updateseeker(jobSeekerId){
    //let isOnreset :boolean;
  //  this.onNoClick();
    this._router.navigate(['/ep/enterprise/jobseeker-view',jobSeekerId]);
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.csv_jobseekerlist, 'sample');
  }

  jobRegID:any;
  registerMissedJobseeekerInVD(jobseeker:any){
    debugger
    const body=
    {
        'profile':{firstName:jobseeker.fullName,emailId:jobseeker.emailId,phone_mobile:jobseeker.contactNo,profileImage:jobseeker.profileImage},
        'id': 0,
        'skillId': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
        'subscriberId': 0,
        'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
        'roleManagerId': 0,
        'AppType': environment.AppType,
        'AppRefId': jobseeker.jobseekerId,
        'AppRefType': environment.AppRefTypeJobseeker
      }
    if (this.userData.data.credentials.userType == 'EP') {
      debugger;
      this.tableService.staffJobseekerCreationVD(body).subscribe((resp:any)=>{
          if (resp.success) {           
            this.jobRegID = resp.data;
            debugger
            this.tableService.UpdateIdFromVeridialForJobseeker(Number(jobseeker.jobseekerId),Number(this.jobRegID)).subscribe(async (response: any) => {
              if(response.success){
                // this.tostr.successToastr(response.message, 'Succcess!', { position: 'top-right' });
                this.loadjobseekerlist();
              }
              
            });   
          }
          else {
            this.tostr.errorToastr('User is already mapped.');
          }
        });                            
    }   
    } 
  


}

import { Injectable, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../app.service';
import { Router } from '@angular/router'
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from '../../environments/environment';
import { ShareService } from '../share-module/share.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class EnterpriseService {
  isfalse: any;
  baseUrl: string = "";
  token: string = "";
  userData: any = {};
  apipath = '';
  isLogin: string = 'false';
  constructor(
    private toastr: ToastrManager,
    private API: AuthenticationService,
    private AuthenticationService: AuthenticationService,
    private http: HttpClient,
    private _router: Router,
    private shareservice:ShareService,
    private datepipe:DatePipe
  ) {
    this.apipath = `${environment.apipath}`
    this.baseUrl = this.API.url;
    this.token = localStorage.getItem('token');
    if (this.token == null) {
      this._router.navigate["/auth/home"];
    }

    this.userData = JSON.parse(window.localStorage.getItem('udata'));
  }
  // Api for Load All Countries Start without token
  LoadAllCountriesApiWithoutToken = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    let localurl = this.baseUrl + "/api/guestuser/countries";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Api for Load All Countries End without token
  // Api for Load All Countries Start without token
  LoadAllSkillApiWithoutToken = (search) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/guestuser/skillkeywords/" + search;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Api for Load All Countries End without token
  // Api for Load All Countries Start without token
  LoadAllCitiesApiWithoutToken = (countryId, search) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/guestuser/location/" + countryId + '/' + search;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Api for Load All Countries End without token
  // Api for Load All Countries Start
  LoadAllCountriesApi = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/staffingagency/countries";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Api for Load All Countries End
  // Api for Load All State Start
  LoadAllStateApi = (countryId) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/states/" + countryId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Api for Load All State End
  // Api for Load All State Start
  LoadAllCitiesApi = (stateId) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/cities/" + stateId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Api for Load All State End



  // Api for Search All State Start
  SearchAllCitiesApi = (countryId, search) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/joblocations/" + countryId + "/" + search;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Api for Search All State End


  // Service for enterpriseDashboard Start
  enterpriseDashboard = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/dashboard";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Service for enterpriseDashboard End

   // Service for ClientHiringManager Dashboard Start
   ClientHiringManagerDashboard = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/hiringmanagerdashboard";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Service for enterpriseDashboard End

  // Service for enterpriseJobPost Start
  enterpriseJobPost = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/postingjob";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, data, httpOptions);
  }

  postEnterpriseProjectJob = (data: any, projectId: any) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + `/api/enterprise/project/${projectId}/postjob`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    }
    return this.http.post(localurl, data, httpOptions);
  }

  // Service for enterpriseJobPost End

  // Service for recentActivity for enterprise Start
  recentActivity(pageSize, pageNumber) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/activities";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    let body = {
      PageSize: pageSize,
      PageNumber: pageNumber
    }

    return this.http.post(localurl, body, httpOptions);
  }
  // Service for recentActivity for enterprise End

  // Service for announcement for enterprise Start
  announcement(pageno, size) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/getannouncement";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    let body = {
      "PageNumber": pageno,
      "PageSize": size
    }
    return this.http.post(localurl, body, httpOptions);
  }
  // Service for announcement for enterprise End


  // Service for EnterpriseViewListJobs for enterprise Start
  EnterpriseViewListJobs(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/myjobs";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, data, httpOptions);
  }

  //get jobseeker status 
  getJobseekerStatus(jobseekerid: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/jobseekerStatus/" + jobseekerid;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Service for EnterpriseViewListJobs for enterprise End


  // // Service for EnterpriseViewListCandidates for enterprise Start

  // // Service for EnterpriseViewListCandidates for enterprise End

  // Service for DeleteJob for enterprise Start
  closeJob(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/closejob/" + data;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);
  }
  // Service for DeleteJob for enterprise End
  // enterprise profile  details api
  Entrpzdetails = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/myprofile";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    let body = {};
    return this.http.get(localurl, httpOptions);
  }

  EntrpzdetailsVS = (token:any) => {
    
    //this.token = localStorage.getItem("token");
    // this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/myprofile";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': token
      })
    };
    let body = {};
    return this.http.get(localurl, httpOptions);
  }

  changePassword(body, Id: string, usertype: string, emailId: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = this.baseUrl + "/api/enterprise/changepassword/" + Id + "/" + usertype + "/" + emailId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }


  //Update client to VD
enterpriseUpdateFormVD = (data,id) => {
 
  console.log('data for ent',data);
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Recruiter/EditRecruiter';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
    const body =       //data;
    {
      
        "userId": id,
        "name": data.firstName,
        "phone": data.contactNo,
        "skillIds": environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
        "modifiedBy": 0,
        "subscriberId": 0,
        "permissionId": environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
        "email": data.emailId,
        "roleManagerId": 0,
        "AppType":environment.AppType,
        "AppRefType":environment.AppRefTypeClient
       
    }
    return this.http.post(url, body, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}
  


  profileBasicUpdate(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/updateprofile";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }
  profileAboutUpdate(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/updateaboutcompany";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }
  profileSocialUpdate(body) {
    let localurl = this.baseUrl + "/api/enterprise/updatelinkedprofile";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }
  staffUpdateProfileImg(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/uploadprofileimage";
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }


//  Update client profile image to VD
 enterpriseProfileImgUpdateVD = (formData) => {
  console.log('data for ent',formData);
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Account/UpdateUserImage';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
   
    return this.http.post(url, formData, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}

//
//  Update client profile image to VD
profileImageVD = (Id) => {
  console.log('data for ent',Id);
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Account/GetUserProfileImage';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
    let body = {
      "UserId": Id,
      "AppType":environment.AppType,
      "AppRefType":environment.AppRefTypeJobseeker
      
    }
    return this.http.post(url, body, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}

//  Update client profile image to VD
deleteProfileImageVD = (Id) => {
  console.log('data for ent',Id);
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Account/DeleteUserProfileImage';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
    let body = {
      "UserId": Id,
      "AppType":environment.AppType,
      "AppRefType":environment.AppRefTypeJobseeker
      
    }
    return this.http.post(url, body, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}

//
//  Update client profile image to VD
deleteJbseekerResumeVD = (Id) => {
  console.log('data for ent',Id);
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Account/DeleteUserResume';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
    let body = {
      "UserId": Id,
      "AppType":environment.AppType,
      "AppRefType":environment.AppRefTypeJobseeker    
    }
    return this.http.post(url, body, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}


//  Update client profile image to VD
jobseekerProfileResume = (Id) => {
  console.log('data for ent',Id);
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Account/GetUserResume';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
    let body = {
      "UserId": Number(Id),
      "appType":environment.AppType,
      "AppRefType":environment.AppRefTypeJobseeker
      
    }
    return this.http.post(url, body, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}

  // Service for EnterpriseViewListJobs for enterprise Start
  LoadSingleJob(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/enterprise/getjob/' + data;
    // const localurl = this.baseUrl + '/api/staffingagency/getjob/' + data;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Service for EnterpriseViewListJobs for enterprise End

  LoadSingleJobDetail(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/guestuser/getjob/' + data;
    // const localurl = this.baseUrl + '/api/staffingagency/getjob/' + data;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }


  // Service for enterpriseUpdateJob Start
  enterpriseUpdateJob = (data, id) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/updatejob/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, data, httpOptions);
  }
  // Service for enterpriseUpdateJob End

  // Service for candidateforJob Start
  candidateforJob = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/getappliedprofile/" + body.jobId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }
  // Service for candidateforJob End

  // Service for candidateforJob Start
  sendemailtocandidate = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/sendmail/" + data.jobseekerId;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    let body = {
      "subject": data.subject,
      "content": data.content,
      "jobId": data.jobId,
      "jobDesc": data.jobDesc,
      "interviewTime": data.interviewTime
    }
    return this.http.post(localurl, body, httpOptions);
  }
  // Service for candidateforJob End

  // Service for changecandidateprofilestatus Start
  changecandidateprofilestatus = (data, actionType) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/enterprise/updateresumestatus/" + data.jobseekerId + "/" + data.srNo;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    let body = {
      "actionType": actionType,
      "jobId": data.jobId
    }
    return this.http.post(localurl, body, httpOptions);
  }
  // Service for changecandidateprofilestatus End


  subenterprises = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/subenterprises`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);
  }

  hiringManager = (enterpriseId) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    //let localurl = `${this.baseUrl}/api/enterprise/subenterprises`;
    //const localurl = this.baseUrl + '/api/enterprise/getjob/' + data;
    const localurl = this.baseUrl + '/api/enterprise/gethiringmanager/' + enterpriseId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);
  }

  //this API use for register enterprise in Veridial
  enterpriseRegisterVD = (data) => {
    const localurl = this.apipath + 'SuperAdmin/AddCompanyW';
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    const body =       //data;
    {
        'Website': data.website,
        'Email': data.emailId,
        'Phone': data.contactNo,
        'CompName': data.companyName,
        'AboutProfile': "about profile1",
        'Address': "delhi",
        'City': "sector 3",
        'CompanyType': 1,
        'CountryId': 2,
        'Ein': "2145456454",
        'FacebookUrl': "facebook1.com",
        'LinkedinUrl': "linkedin1.com",
        'State': "UP",
        'Street': "noida",
        'TwitterUrl': "www.twitter1.com",
        'Zip': "45654"
    }
    return this.http.post(localurl, body, httpOptions);
}
  subenterprises_search = (search : string,searchenterpriseId:string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/subenterprises_search/${search}/${searchenterpriseId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);
  }
//Manu Datta: 20210224, VerisourceBugSheetAfterMerge/commonLogin 404
  get_client_search = (search : string, mspId : string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/admin/get_search_client/${search}/${mspId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);
  }

  get_searchnotifyuser = (user_type,mspId,search : string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/admin/getsearchnotifyuser/${user_type}/${mspId}/${search}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);
  }

  registersubenterprise = (body) => {
    let localurl = `${this.baseUrl}/api/enterprise/registersubenterprise`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }

  
  //ThisAPI use for save Veridial Sub hiring manager

  registersubenterpriseVD = (data: any,appRefId:Number) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    var aa = this.AuthenticationService.jwtTokenExpCheck();

    const localurl = this.apipath + 'Account/AddClientHiringManager';
    const httpOptions = {
        // headers: new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'authenticate': this.token + ''
        // })

        headers :{
          Authorization:"Bearer " + this.token // token
      }

    };   
    const body=
    {
        'id': 0,
        'name': data.firstName,
        'email': data.emailId,
        'phone': data.contactNo,
        'skillId': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
        'subscriberId': 0,
        'permissionId':  environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
        'roleManagerId': 0 ,
        'AppType':environment.AppType,
        'AppRefId':appRefId,
        'AppRefType':environment.AppRefTypeClient  
      }
      console.log('bodycheck',body);
    return this.http.post(localurl, body, httpOptions);
  }

  UpdateIdFromVeridialForEnterprise = (jsId: number,Id:number,type) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${environment.url}/api/admin/veridialidmapwithjobseekerwithoutAuthenticate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    const data =
    {
        'Id':jsId,
        'appRefId':Id,
        'type':type
      }
    return this.http.post(localurl, data, httpOptions);
  }

    //ThisAPI use for save Veridial Sub hiring manager

    updateregistersubenterpriseVD = (data: any, id) => {
      this.token = localStorage.getItem("token");
      this.AuthenticationService.jwtTokenExpCheck();
  
      var aa = this.AuthenticationService.jwtTokenExpCheck();
  
      const localurl = this.apipath + 'Recruiter/EditClientHiringManager';
      const httpOptions = {
          // headers: new HttpHeaders({
          //     'Content-Type': 'application/json',
          //     'authenticate': this.token + ''
          // })
  
          headers :{
            Authorization:"Bearer " + this.token // token
        }
  
      };   
      const body=
      {
          'userId':Number(id),
          'name': data.firstName,
          'email': data.emailId,
          'phone': data.contactNo,
          'skillIds': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
          'subscriberId': 0,
          'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
          'roleManagerId': 0,
          'modifiedBy': 0,
          'AppType':environment.AppType,
          'AppRefType':environment.AppRefTypeClient
        }
        console.log('bodycheck',body);
      return this.http.post(localurl, body, httpOptions);
    }

  updatesubenterprise = (body, id) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/updatesubenterprise/${Number(id)}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }


  searchprofile = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/searchprofile`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }

  gerCandidateList = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/gerCandidateList`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }
  scheduleinterview = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/scheduleinterview`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }
  updateInterview = (body, srNo) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/updatescheduleinterview/${srNo}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }



  getallinvoices = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/getallinvoices`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }



  invoicedetails = (id) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/invoicedetails/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);
  }

  payment = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/payment`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }
  videoToken = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `https://champagne-caterpillar-1040.twil.io/video-token`;
    return this.http.get(localurl);
  }

  interviewList(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/getinterview`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }
  createChatRoom(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/createroom`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }

  inviteUserList() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/getemployees`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }
  generateinvite(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/generateinvite`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);

  }

  joinGroupApi(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/invite`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);

  }

  getfiltersMyjobs() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/getfilters/EP/myjobs`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  filterforstaffing = (data) => {
    this.AuthenticationService.jwtTokenExpCheck();
    this.token = localStorage.getItem("token");
    // let Option = new Http()
    let url = `${this.baseUrl}/api/enterprise/staffingagenciesview`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    let body = {
      "PageSize": data.pageSize,
      "PageNumber": data.PageNumber,
      "totalcandidates": data.totalcandidates,
      "fromdate": data.fromdate,
      "todate": data.todate,
      "search": data.search,
      "status": data.status,
      "action": data.action,
      "jobid": data.jobid,
      "profile_status":data.profile_status,
      "active_status":data.active_status,
      "source":data.source
    }
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  gettimesheetstaffing = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    // let Option = new Http()
    let url = `${this.baseUrl}/api/enterprise/gettimesheetsupplier`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };

    let body = {
      "PageSize": data.pageSize,
      "PageNumber": data.PageNumber,
      "totalcandidates": data.totalcandidates,
      "fromdate": data.fromdate==''?'':(this.datepipe.transform(data.fromdate,'yyyy-M')),
      "todate": data.todate ==''?'':(this.datepipe.transform(data.todate,'yyyy-M')),
      "search": data.search,
      "status": data.status,
      "action": data.action,
      "jobid": data.jobid,
      "profile_status":data.profile_status,
      "employeeId":data.employeeId,
      "manager":data.manager,
      "empName":data.empName,
      "projName":data.projName,
      "sheet_status":data.sheet_status,
      "supname":data.supname,
      "projectid":data.projectid

    }
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  


  filterallstaffing = (data) => {
    
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    // let Option = new Http()
    let url = `${this.baseUrl}/api/enterprise/staffingagenciesallview`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    let body = {
      "PageSize": data.pageSize,
      "PageNumber": data.PageNumber,
      "totalcandidates": data.totalcandidates,
      "fromdate": data.fromdate,
      "todate": data.todate,
      "search": data.search,
      "status": data.status,
      "action": data.action,
      "jobid": data.jobid,
      "createdBy":data.createdBy
     

    }
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  adminfilterforstaffing = (data,enterpriseId) => {
    // let Option = new Http()
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/enterprise/adminstaffingagenciesview`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    let body = {
      "PageSize": data.pageSize,
      "PageNumber": data.PageNumber,
      "totalcandidates": data.totalcandidates,
      "fromdate": data.fromdate,
      "todate": data.todate,
      "search": data.search,
      "status": data.status,
      "action": data.action,
      "jobid": data.jobid,
      "enterpriseId": enterpriseId,
      "supname":data.supname,
      "projectid":data.projectid

    }
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }


  filterforAdmin = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    // let Option = new Http()
    let url = `${this.baseUrl}/api/admin/staffingagenciesview`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    let body = {
      "PageSize": data.pageSize,
      "PageNumber": data.PageNumber,
      "totalcandidates": data.totalcandidates,
      "fromdate": data.fromdate,
      "todate": data.todate,
      "search": data.search,
      "profile_status": data.profile_status,
      "source":data.source,
      "active_status":data.active_status

    }
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  addstaffingagency(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    //let url = this.apipath + 'Account/RecruiterSignUp';

    let url = `${this.baseUrl}/api/enterprise/registerstaffing`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    // const body = data;
    console.log('body', data)

    return this.http.post<any>(url, data, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  //   addstaffingagencyVD = (data) => {
  //     this.token = localStorage.getItem("token");
  //     const localurl = this.apipath + 'Account/RecruiterSignUp'; // this is for VD register

  //     const httpOptions = {
  //         // headers: new HttpHeaders({
  //         //     'Content-Type': 'application/json'
  //         // })
  //         headers :{
  //           Authorization:"Bearer " + this.token // token
  //       }
  //     };
  //     const body = // data;
  //     {
  //         'id': 0,
  //         'name': data.firstName + ' ' + data.lastName,
  //         'email': data.emailId,
  //         'phone': data.contactNo,
  //         'skillId': "127",
  //         'subscriberId': 0,
  //         'permissionId': 108,
  //         'roleManagerId': 0
  //     }
  //     console.log('body', body);
  //      return this.http.post(localurl, body, httpOptions);
  //     //return this.http.post(localurl, body);
  // }


  addstaffingagencyVD = (data,appRefId:Number) => {
    
    console.log('data for ent', data);
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      //  let Option = new Http()
      //  let url = `${environment.url}/api/admin/registerenterprise`
      const url = this.apipath + 'Account/RecruiterSignUp'; // this is for VD register
      const httpOptions = {
        // headers: new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'authenticate': 'Bearer' + this.token + ""
        // })

        headers: {
          Authorization: "Bearer " + this.token // token
        }
      };
      const body = // data;
      {
        'id': 0,
        'name': data.firstName + ' ' + data.lastName,
        'email': data.emailId,
        'phone': data.contactNo,
        'skillId': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
        'subscriberId': 0,
        'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
        'roleManagerId': 0,
        'AppType':environment.AppType,
        'AppRefId':appRefId,
        'AppRefType':environment.AppRefTypeSupplier
      }
      return this.http.post(url, body, httpOptions);
    } else {
      this._router.navigate(['/']);
    }
  }

  addInvitationstaffingagencyVD = (AppRefId,data) => {
    
    // console.log('data for ent', data);
    // this.token = localStorage.getItem("token");
    // this.AuthenticationService.jwtTokenExpCheck();
    // this.isLogin = localStorage.getItem('islogin');
    //if (this.isLogin == 'true') {
      //  let Option = new Http()
      //  let url = `${environment.url}/api/admin/registerenterprise`
      const url = this.apipath + 'Account/RecruiterSignUpW'; // this is for VD register
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })        
      };
      const body = // data;
      {
        'id': 0,
        'name': data.firstNameStaffing + ' ' + data.lastNameStaffing,
        'email': data.staffingemailId,
        'phone': data.contactNo,
        'skillId':  environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
        'subscriberId': data.createdby,
        'permissionId':  environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
        'roleManagerId': 0,
        'AppType':environment.AppType,
        'AppRefId':AppRefId,
        'AppRefType':environment.AppRefTypeSupplier
      }
      return this.http.post(url, body, httpOptions);
    // } else {
    //   this._router.navigate(['/']);
    // }
  }

  staffingBlock(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/admin/staffingblock/` + id
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(staffingblocksec => {
      return staffingblocksec;
    }));
  }
  staffingUnblock(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/admin/staffingunblock/` + id
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };

    return this.http.get<any>(url, httpOptions).pipe(map(staffingunblocksec => {
      return staffingunblocksec;
    }));
  }

  contactExist(newContact) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/checkcontact`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    let body = {
      'newContact': newContact
    }
    return this.http.post(localurl, body, httpOptions);
  }

  getfilterSearchProfile() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/getfilters/EP/searchprofiles`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getfilterSearchCandidate() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/getfilters/EP/searchcandidates`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  getFilterInvoices() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/getfilters/EP/myinvoices`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }


  getInterviewStatus(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/updateresumestatus/${body.jobseekerId}/${body.SrNo}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);

  }


  country_enterprise() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/countries`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }
  nationality_enterprise() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/nationality`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  nationalitySearch(search) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/enterprise/nationalitySearch/${search}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);
  }


  InterviewExit(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/stopinterview`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);

  }

  mailExist(id, email) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/checkemail/${id}/${email}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  phoneExist(id, phone) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/checkmobile/${id}/${phone}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  singleInterview(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/interviewschedulejobs/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }


  resumepreview(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    // 
    const localurl = `${this.baseUrl}/api/enterprise/resumepreview/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  JobseekerDeleteResume(jobSeekerId, resumename) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/deleteresume/${jobSeekerId}/${resumename}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  JobseekerUploadResume(jobSeekerId, data,date:string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/uploadresume/${jobSeekerId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, data, httpOptions);
  }

  jobseekerlist(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/enterprise/jobseekeresview`
    //let url = `${this.baseUrl}/api/admin/jobseekeresview`

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      "PageSize": data.PageSize,
      "PageNumber": data.PageNumber,
      "fromdate": data.fromdate,
      "todate": data.todate,
      "status": data.status,
      "search": data.search,
      "profile_status":data.profile_status
    }

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  JobSeekerBlock(jobseekerId, status) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/admin/candidateblockorunblock/` + jobseekerId + '/' + status
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  // countries API
  country() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/countries`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  // state API
  state(searchText: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/states/${searchText}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }


   //20201104:Akhilesh, Search country API
   searchCountry( data: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/searchCountry/${data}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  
  // Search state API
  searchState(countryId, data: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/searchState/${countryId}/${data}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }



  // Search city API
  searchCity(stateid, data: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/searchCity/${stateid}/${data}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }


  // City API
  city(stateId: any) {

    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/cities/${stateId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  // nationality API
  nationality() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/nationality`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  staffingagencyDetailview(val) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/admin/staffingagencydetails/` + val
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  staffingcandidates(pageno, pagesize, id: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/admin/staffingcandidates/` + id
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    let body = {
      "PageNumber": pageno,
      "PageSize": pagesize
    }
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  candidateDetails(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/admin/candidatedetails/` + id
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res.data;
    }));
  }

  verifyenterpriseDetails(userId, user_type, base_price) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/admin/changeprofilestatus`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    let body = {
      "user_type": user_type,
      "userId": userId,
      "profile_status": "Verified",
      "base_price": base_price
    }

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  // for update staffing agency address details
  UpdateStaffingAgencyAddress(body, id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/admin/updateStaffingAgencyAddress/` + id
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }
  profileBasicUpdates(usertype, body, id , empid: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/admin/updateStaffingAgencyBasicDetails/` + id + '/' + empid + '/' + usertype;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }
  profileBasicUpdatesVD(body, id,type) {
    
   // let idU= id.splite('-0');
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.apipath + 'Recruiter/EditRecruiter';
    const httpOptions = {
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/json',
      //   'authenticate': 'Bearer' + this.token + ""
      // })

      headers: {
        Authorization: "Bearer " + this.token // token
      }
    };
  
    const data = {

      "userId": id,
      "name": body.firstName + body.lastName,
      "phone": body.contactNo,
      "skillIds": environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
      "modifiedBy":Number( body.modifiedBy),
      "subscriberId": 0,
      "permissionId": environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
      "email": body.emailId,
      "roleManagerId": 0,
      "AppType":environment.AppType,
      "AppRefType":type
    }
    return this.http.post(localurl, data, httpOptions);
  }

    //This API used for reset Password by veridial
    resetPasswordVD(formData, emailId) {
      
      this.token = localStorage.getItem("token");
      this.AuthenticationService.jwtTokenExpCheck();
  
      ///let url = `${environment.url}/api/admin/resetpassword/${Id}/${usertype}`;
  
      const localurl = this.apipath + 'Account/ChangePassword';
      const httpOptions = {
        // headers: new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'authenticate': this.token + ""
        // })
        headers :{
          Authorization:"Bearer " + this.token // token
      }
      };
  
      const body=
      {        
          'email': emailId,
          'oldPassword': formData.oldPassword,
          'newPassword': formData.password,
        }
  
      return this.http.post(localurl, body, httpOptions).pipe(map(res => {
        return res;
      }));
    }

  resetPassword(body, Id: string, usertype: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/admin/resetpassword/${Id}/${usertype}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  profileSocialUpdate1(body, id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/admin/updateStaffingAgencySocialLink/` + id
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }


  // nationality API
  searchNationality(data: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/searchNationality/${data}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  //
   //  Update Resume profile image to VD  S3 bucket
 updateResumeVD = (formData) => { 
  console.log('data for ent',formData);
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Account/UpdateResume';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
   
    return this.http.post(url, formData, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}


uploadInvoice(body,invoiceid:any) {
  this.token = localStorage.getItem('token');
  this.AuthenticationService.jwtTokenExpCheck();
  const localurl = this.baseUrl + '/api/staffingagency/uploadInvoice/' + invoiceid ;
  const httpOptions = {
    headers: new HttpHeaders({
      'authenticate': this.token
    })
  };
  return this.http.post(localurl, body, httpOptions);
}

  staffJobseekerProfileImg(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    let localurl = '';
    if (this.userData.data.credentials.userType === 'SA') {
      localurl = this.baseUrl + '/api/staffingagency/uploadnewfile';

    }
    else {
      localurl = this.baseUrl + '/api/enterprise/uploadnewfile';

    }

    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }
  staffJobseekerCreation = (data: any) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = '';
    if (this.userData.data.credentials.userType === 'SA') {
      localurl = this.baseUrl + '/api/staffingagency/uploadresume';

    }
    else {
      localurl = this.baseUrl + '/api/enterprise/uploadresume';

    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, data, httpOptions);
  }
  checkmail(jobseekerId, email) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = '';
    if (jobseekerId !== '') {
      localurl = this.baseUrl + '/api/jobseeker/checkemail/' + jobseekerId + '/' + email;
    } else {
      localurl = this.baseUrl + '/api/staffingagency/checkecandidatemail/' + email;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  checkmobile(jobseekerId, phone) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = '';
    if (jobseekerId !== '') {
      localurl = this.baseUrl + '/api/jobseeker/checkmobile/' + jobseekerId + '/' + phone;
    } else {
      localurl = this.baseUrl + '/api/staffingagency/checkcandidatemobile/' + phone;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  candidatedetails(id: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/candidatedetails/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

    //ThisAPI use for save Veridial Jobseeker

    updatecandidateprofileVD = (data: any,jobseekerId,supplierId) => {
      this.token = localStorage.getItem("token");
      this.AuthenticationService.jwtTokenExpCheck();
  
      var aa = this.AuthenticationService.jwtTokenExpCheck();
  
      const localurl = this.apipath + 'Recruiter/EditJobseeker';
      const httpOptions = {
          // headers: new HttpHeaders({
          //     'Content-Type': 'application/json',
          //     'authenticate': this.token + ''
          // })
  
          headers :{
            Authorization:"Bearer " + this.token // token
        }
  
      };   
      const body=
      {
          'userId': Number(jobseekerId),
          'name': data.profile.firstName,
          'phone': data.profile.phone_mobile,  
          'skillIds': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
          'modifiedBy':supplierId,
          'subscriberId': 0,
          'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
          'email': data.profile.emailId,
          'roleManagerId': 0,
          'AppType':environment.AppType,
          'AppRefType':environment.AppRefTypeJobseeker
        }
        console.log('body',body);
      return this.http.post(localurl, body, httpOptions);
    }

  updatecandidateprofile = (body, id) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = '';
    if (this.userData.data.credentials.userType === 'SA') {
      localurl = `${this.baseUrl}/api/staffingagency/updatecandidateprofile/${id}`;
    } else {
      localurl = `${this.baseUrl}/api/enterprise/updatecandidateprofile/${id}`;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }
  deteleProfileImage = (jobSeekerId, imgProfile) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/deteleProfileImage/${jobSeekerId}/${imgProfile}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // verifyenterpriseDetails(userId, user_type, base_price) {

  //   let url = `${this.baseUrl}/api/admin/changeprofilestatus`;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'authenticate': this.token + ""
  //     })
  //   };
  //   let body = {
  //     "user_type": user_type,
  //     "userId": userId,
  //     "profile_status": "Verified",
  //     "base_price": base_price
  //   }

  //   return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
  //     return res;
  //   }));
  // }
  // candidateDetails(id) {
  //   let url = `${this.baseUrl}/api/admin/candidatedetails/` + id
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'authenticate': this.token + ""
  //     })
  //   };
  //   return this.http.get<any>(url, httpOptions).pipe(map(res => {
  //     return res.data;
  //   }));
  // }

  getProjects = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/projects`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    let body = {
      "PageSize": data.pageSize,
      "PageNumber": data.PageNumber,
      "search": data.search,
      "fromdate": this.shareservice.convertDateIntoUtc(this.datepipe.transform(data.fromdate)),
      "todate": this.shareservice.convertDateIntoUtcForSearch(this.datepipe.transform(data.todate)),
      "totalProjects": data.totalProjects,
      "client": data.client,
      "type":data.type
    }
    return this.http.post(localurl, body, httpOptions);
  }

  //get clients
  getClients = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/clients`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  addProject = (body: any, name, usertype) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/project/${name}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    const data={
      body:body,
      usertype:usertype
    }
    return this.http.post(localurl, data, httpOptions);
  }

  addProjectApproval = (body: any, projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/project/${projectId}/approval`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  addProjectBudget = (body: any, projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/project/${projectId}/budget`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  addProjectBudgetApproval = (body: any, projectId: string, budgetId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/project/${projectId}/budget/${budgetId}/approval`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    let data = {
      //"PageSize": data.PageSize,
      "body": body
    };
    return this.http.post(localurl, data, httpOptions);
  }

  addProjectUser = (body: any, projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/project/${projectId}/user`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  getProjectById = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/project/${projectId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getProjectUsersByProjectId = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/project/${projectId}/users`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getProjectJobsByProjectId = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/project/${projectId}/jobs`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getProjectApprovalsByProjectId = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/project/${projectId}/approvals`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getProjectBudgetsByProjectId = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/project/${projectId}/budgets`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getProjectBudgetApprovalsByProjectIdAndBudgetId = (projectId: string, budgetId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/project/${projectId}/budget/${budgetId}/approvals`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  searchUser = (serachText: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/enterprise/finduser/${serachText}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  SAgroupDetails(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const url = this.baseUrl + '/api/enterprise/getSAGroupdetails';
    console.log('url', url);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    const body = {
      'PageSize': data.PageSize,
      'PageNumber': data.PageNumber,
      'search': data.search.trim(),
      'fromdate': data.fromdate,
      'todate': data.todate,
      'status':data.status
    };

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }

  insertStaffingGroupdata(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = this.baseUrl + '/api/enterprise/insertStaffingGroupdata';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      "groupName": data.GroupName,
      "description": data.GroupDescription,
      "createdDate":data.createdDate,
      "usertype":data.usertype
      // "isactive": data.isactive,
    };

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }

  updateAgencyGroupdata(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const url = this.baseUrl + '/api/enterprise/updateAgencyGroupdata';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    const body = {
      "groupName": data.GroupName,
      "description": data.GroupDescription,
      "staffingAgencyGroup_Id": data.staffingAgencyGroup_Id,
      "createdDate":data.createdDate
    };

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }
  groupBlock(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    console.log("pool block", id);
    let url = this.baseUrl + '/api/enterprise/Groupblock/' + id;
    console.log("pool block", url);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }



  groupUnblock(id) {
    this.AuthenticationService.jwtTokenExpCheck();
    console.log("pool unblock", id);
    let url = this.baseUrl + '/api/enterprise/Groupunblock/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };

    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  GetAgencyGroupDetails(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = this.baseUrl + '/api/enterprise/getAgencyGroupdetailsbyId/' + id;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  SaveAgencyInGroupdetails(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const url = this.baseUrl + '/api/enterprise/SaveAgencyInGroupdata';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }
  GetSAForAgencyGroup = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    // let Option = new Http()
    let url = `${this.baseUrl}/api/enterprise/staffingagenciesForGroup`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    let body = {
      "PageSize": data.pageSize,
      "PageNumber": data.PageNumber,
      "totalcandidates": data.totalcandidates,
      "fromdate": data.fromdate,
      "todate": data.todate,
      "search": data.search,
      "actiontype": data.actiontype,
      "groupId": data.groupId

    }
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  InsertAsignedJobToSA = (body,date:string) => {
    debugger
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    // let Option = new Http()
    let url = `${this.baseUrl}/api/enterprise/InsertAsignedJobToSA`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    let data = {
      "jobid": body.jobid,
      // "staffingagencyId": body.empId,
      "staffingagencyId": body.empId,
      "status": body.status,
      "staffingAgencyGroup_Id": body.staffingAgencyGroup_Id,
      "date": date,
      "assignStatus": body.assignStatus
    }
    return this.http.post<any>(url, data, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  InsertAsignedJobToSAByGroupFunctionality = (body,date:string) => {

    let url = `${this.baseUrl}/api/enterprise/InsertAsignedJobToSAByGroupFunctionality`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let data = {
      "jobid": body.jobid,
      "staffingagencyId": body.empId,
      "status": body.status,
      "staffingAgencyGroup_Id": body.staffingAgencyGroup_Id,
      "date": date,
      "assignStatus": body.assignStatus
    }
    return this.http.post<any>(url, data, httpOptions).pipe(map(res => {
      return res;
    }));
  }


  poolBlock(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const url = `${this.baseUrl}/api/admin/poolblock/` + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  poolUnblock(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const url = `${this.baseUrl}/api/admin/poolunblock/` + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  pooldetails(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const url = `${this.baseUrl}/api/admin/getpooldetails`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    const body = {
      'PageSize': data.PageSize,
      'PageNumber': data.PageNumber,
      'search': data.search.trim(),
      "fromdate": data.fromdate,
      "todate": data.todate,
      "status":data.status,
      "usertype":this.userData.data.credentials.userType
    };
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  GetPoolDetails(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const url = `${this.baseUrl}/api/admin/getpooldetails/` + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  insertPool(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const url = `${this.baseUrl}/api/admin/insertpooldata`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    const body = {
      'poolName': data.PoolName,
      'description': data.PoolDescription,
      'createdDate': data.createdDate,
      'usertype' : this.userData.data.credentials.userType
    };
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  UpdatetPool(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const url = `${this.baseUrl}/api/admin/updatepooldata`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    const body = {
      'poolName': data.PoolName,
      'description': data.PoolDescription,
      'pk_pool_Id': data.pk_pool_Id,
      'createdDate': data.createdDate,
      'usertype' : this.userData.data.credentials.userType
    };
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  parseCandidateResume(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/staffingagency/parseresume';
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }


  resetPasswordEnterpriseFromAdmin(body, Id: string, usertype: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/admin/resetpassword/${Id}/${usertype}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.post(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }


  
 //Akhilesh:20200912, file check exits on root folder
 fileExists(image_type,filename) {
   this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
  let localurl = `${this.baseUrl}/api/enterprise/file_exits/${image_type}/${filename}`;
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'authenticate': this.token
    })
  };
  return this.http.get(localurl, httpOptions);
}

validatespecialchar(k) {
  if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91 || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
     this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Error!');
      this.isfalse = "success";
     return   this.isfalse;
    } 
    
}

updateSubuserEnterpriseVD = (data: any, id) => {
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  var aa = this.AuthenticationService.jwtTokenExpCheck();
  const localurl = this.apipath + 'Recruiter/EditClientHiringManager';
  const httpOptions = {      
      headers :{
        Authorization:"Bearer " + this.token 
    }
  };   
  const body= {
      'userId':Number(id),
      'name': data.firstName,
      'email': data.emailId,
      'phone': data.contactNo,
      'skillIds': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
      'subscriberId': 0,
      'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
      'roleManagerId': 0,
      'modifiedBy': 0,
      'AppType':environment.AppType,
      'AppRefType':environment.AppRefTypeClient
    }
    console.log('bodycheck',body);
  return this.http.post(localurl, body, httpOptions);
}

}

import { Injectable, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../app.service';
import { Router } from '@angular/router'
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from '../../environments/environment';
import { ShareService } from '../share-module/share.service';
import { DatePipe } from '@angular/common';
import { AlertService } from '../admin/service';
import {HttpService} from '../share-module/service/httpservice.service';

@Injectable()
export class NewEnterpriseService {
  isfalse: any;
  baseUrl: string = "";
  token: string = "";
  userData: any = {};
  apipath = '';
  coreApiPath ='';
  isLogin: string = 'false';
  constructor(
    private API: AuthenticationService,
    private AuthenticationService: AuthenticationService,
    private http: HttpClient,
    private _router: Router,
    private httpService : HttpService
  ) {
    this.apipath = `${environment.apipath}`;
    this.coreApiPath = `${environment.coreapipath}`;
    this.baseUrl = this.API.url;
    this.token = localStorage.getItem('token');
    if (this.token == null) {
      this._router.navigate["/auth/home"];
    }

    this.userData = JSON.parse(window.localStorage.getItem('udata'));
  }

  //get clients
  result:any;
  loadLookUpData(jobId:Number){
  
      this.AuthenticationService.jwtTokenExpCheck();
      const localurl = `${this.coreApiPath}/JobRequest/`+jobId;
      return this.httpService.get(localurl);

  }

  enterpriseUpdateJob = (data, id) => {
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/JobRequest`;    
    return this.httpService.post(localurl, data);
  }

  updateJobStatus = (jobId,status) => {
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/JobRequest/`+jobId;
    const body={
      jobStatus:status
    }
    return this.httpService.post(localurl, body);
  }

  LoadAllState = (countryId) => {
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/State/`+countryId;
    return this.httpService.get(localurl);
  }

  EnterpriseViewListJobs(data):Observable<any> {
    debugger
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/JobRequest/GetJobRequests`;
    return this.httpService.post(localurl, data);
    
  }

  getAllSupplier(data) {
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/Suppliers/GetAllSuppliers`;
    return this.httpService.post(localurl,data);
    
  }

  GetAssignJobSuppliers(data) {
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/Suppliers/GetAssignJobSuppliers`;
    return this.httpService.post(localurl, data);
  }

  addstaffingagency(data) {
    debugger
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.coreApiPath}/Suppliers`
    return this.httpService.post(url, data);
  }

  supplierDetailview(supplierId) {
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/Suppliers/`+supplierId;
    return this.httpService.get(localurl);
  }

  getSupplierDetails(supplierId) {
    debugger;
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/Suppliers/`+supplierId;
    return this.httpService.get(localurl);
  }

  profileSocialUpdate1(body, supplierId) {
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/Suppliers/SocialLink`;

    const data = {
      id : supplierId,
      facebookUrl : body.facebookUrl,
      linkedinUrl : body.linkdinUrl,
      twitterUrl : body.twitterUrl
    }
    return this.httpService.post(localurl, data);
  }

  updateUserAddress(body, supplierId) {
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/Suppliers/ProfileAddress`;
    const data ={
      id:supplierId,
      country:body.country,
      state:body.state,
      city:body.city,
      street:body.street,
      zipcode:body.zipCode
    }
    return this.httpService.post(localurl, data);
  }

  VerifyUser(userId, userType) {
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/Suppliers/VerifyUser`;
    let body = {
      "Id":userId,
      "userType": userType,
      "profileStatus": "Verified",
    }

    return this.httpService.post(localurl, body).pipe(map(res => {
      return res;
    }));
  }

  changeUserStatus(userid,userType,status) {
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.coreApiPath}/Suppliers/ChangeUserStatus`;
    let body = {
      "Id":userid,
      "userType": userType,
      "status": status,
    }
    return this.httpService.post(localurl,body);
  }
}


import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { componentServices } from '../../component/component.service'
import { ToastrManager } from 'ng6-toastr-notifications';
import {AuthenticationService } from '../../app.service';

 



@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  loading: boolean = false;
  resetData: any;
  responseData :any;
  verifyUser = false;
  resetRespons = { data: '', message: '', success: false }
  submitted: boolean = false;
  resetForm: FormGroup;
  verifyUserForm : FormGroup;
  userId: string = '';
  magData: string = '';
  counter =0;
  userType = '';
  isDisabled = false;
  passVal: any;
  isShow: boolean = false;
  cPassVal: any;
  constructor(private formbuilder: FormBuilder, private _router: Router, private _paramVal: ActivatedRoute,
      private AuthenticationService: AuthenticationService,
      private componentApi: componentServices, private toastr: ToastrManager,) {
      this.AuthenticationService.jwtTokenExpCheck();
    this.userId = this._paramVal.snapshot.params['id'];
    let user :any
    this.userType = this.userId.substring(0,2);
    if(this.userType == "JS"){
      this.verifyUser = true;
    }
    this.userId = this.userId.substring(2);

    this.resetForm = this.formbuilder.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      confirmpassword: ['', Validators.required]
    });
    
    this.verifyUserForm = this.formbuilder.group({
      securityQuestion:[''],
      answer:['']
    });
    this.componentApi.getsecurityQuestion(this.userType,this.userId).subscribe(res => {
     this.responseData = res;
     if(this.responseData.success){
      this.verifyUserForm.controls['securityQuestion'].setValue(this.responseData['data'].question);
     }
     else{ this.verifyUserForm.controls['securityQuestion'].setValue(''); }
    });
    this.componentApi.getresetValue(this.userType,this.userId).subscribe(res => { 
      let resetData : any;
      resetData = res;
      if(resetData.data['resetValue']){
        this.verifyUserForm.controls['securityQuestion'].setValue('');
        this.isDisabled = true;
      }
    });
  }

  ngOnInit() {
  }

  get fr() {
    return this.resetForm.controls;
  }

  resetSubmit() {
    this.submitted = true;
    if (this.resetForm.valid && (this.resetForm.controls.password.value == this.resetForm.controls.confirmpassword.value)) {
      this.loading = true;
      this.resetData = { password: this.resetForm.controls.password.value, token: this.userId }

      // let params = {
      //   Email: this.ForgetPassForm.controls.EmailId.value
      // }
      // this.userService.resetPasswordVD(params).subscribe(response => {
      //   if (response.success) {
      //     this.commonService.showSnakeBar(response.message);
      //    // this.ForgetPassForm.get('EmailId').patchValue('')
      //   }
      //   else {
      //     this.commonService.showSnakeBar(response.message);
      //   }

      // this.componentApi.resetAPI(this.resetData).subscribe(res => {
      //   // console.log("reset password : ", res);
      //   this.resetData = res;
      //   if (this.resetData.success) {
      //     this.toastr.successToastr('Your password have changed Please login!');
      //     this._router.navigate(['/']);
      //   } else {
      //     this.magData = "Something went wrong!!"
      //     this.loading = false;
      //   }

      // });
    } else {
      this.loading = false;
      this.passVal =  this.resetForm.controls['password'].value;
      this.cPassVal =  this.resetForm.controls['confirmpassword'].value;
      if(this.passVal != '' && this.cPassVal != ''){
          this.isShow = true;
          this.magData = 'Password miss match please try again!'
          return false;
        }
      this.isShow = false;
    }
    this.loading = false;
  }

  verifyUserData : any;
  counterMessage = "";
  verifyuser(){
    this.componentApi.verifySecurityAnswer(this.verifyUserForm.value,this.userId,this.userType).subscribe(res =>{
      this.verifyUserData = res;
      if(this.verifyUserData.success){
        if(this.verifyUserData.data['resetPasswordToken'] == this.userId)
        {
          this.verifyUser = true;
        }
        else{
          this.verifyUserData = false;
        }
      }
      else{
        this.verifyUserForm.controls['answer'].setValue('');
        this.counter = this.counter + 1;
        this.counterMessage = (3- this.counter +' '+ "attempts left");
        if(this.counter == 3){
          this.verifyUserForm.controls['securityQuestion'].setValue('');
          this.isDisabled = true;
          this.componentApi.insertresetValue(this.isDisabled,this.userId,this.userType).subscribe(res =>{
          });
        }
      }   
    });
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

}

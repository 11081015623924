import { Directive, ElementRef, Input, ViewChild } from '@angular/core';
import { ShareService } from '../share.service';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {
  private text: string;
   @Input('appHasPermission') permission: string;
   //@Input() appHasPermission:string;
  constructor(private el: ElementRef, private permissionService: ShareService) { }
  ngOnInit() {
    // this.permission='Add Job'
    const hasPermission : any= this.permissionService.hasPermission(this.permission);
    // if (!hasPermission) {
    //   this.el.nativeElement.style.display = 'none';
    // }
    if (hasPermission == undefined) {
         this.el.nativeElement.style.display = 'none';
       }
      else{
         this.el.nativeElement.style.display = 'block';
      }

  }
}

import { Component, OnInit, Inject, Renderer2, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EnterpriseService } from '../enterprise.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../../app.service';
import { ShareService } from 'src/app/share-module/share.service';
import { TableService } from 'src/app/admin/service';


@Component({
  selector: 'app-enterprise-subadmin',
  templateUrl: './enterprise-subadmin.component.html',
  styleUrls: ['./enterprise-subadmin.component.css']
})
export class EnterpriseSubadminComponent implements OnInit {

  vdEntpId: any;
  staffId: String = '';
  subadminForm: FormGroup;
  submitted: boolean = false;
  submittedEdit: boolean = false;
  email: String = '';
  name: String = '';
  password: String = '';
  loading = false;
  editFormSSubmit: FormGroup;
  fieldBDisabled: boolean = true;
  eyeoff: String = 'password';
  isEditform: boolean = false;
  isaddNewform: boolean = false;
  exitsMail: boolean = false;
  // exitsMail: boolean = false;
  exitsPhone: boolean = false;
  EmpId: String = '';
  subList: any;
  asynlist = false;

  PopupAddForm() { this.isaddNewform = !this.isaddNewform; }
  Popupdisbl() { this.fieldBDisabled = !this.fieldBDisabled; }
  constructor(
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private enterPriseAPI: EnterpriseService,
    private toastr: ToastrManager, private _titleService: Title,
    private shareservice: ShareService,
    private APIA: TableService,
    private AuthenticationService: AuthenticationService,
  ) {
    this.staffId = this.activeRoute.snapshot.params['id'];
    // for title set 
    var newTitle = 'Verisource | User';
    this._titleService.setTitle(newTitle);
  }

  ngOnInit() {
    this.initForm();
    this.editFormSSubmit = this.formBuilder.group({
      'firstName': ['', Validators.required],
      'middleName': [''],
      'lastName': ['', Validators.required],
      'emailId': ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      // 'password': ['', [Validators.required, Validators.minLength(6)]],
      'contactNo': ['', [Validators.required]]
    });
    this.subadminList();
  }
  get f() { return this.subadminForm.controls; }
  get fd() { return this.editFormSSubmit.controls; }
  initForm() {
    this.subadminForm = this.formBuilder.group({
      'firstName': ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      'middleName': [''],
      'lastName': ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      'emailId': ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      'password': [''],
      'contactNo': ['', [Validators.required]],
      'createdDate': [''],
      'vdEntpId': []
    });
  }

  onSubmit() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.submitted = true;
    // stop here if form is invalid
    if (this.subadminForm.invalid) {
      return;
    }
    alert('You have been Successfully Registered.');
  }

  subadminList() {

    this.enterPriseAPI.subenterprises().subscribe(res => {
      this.subList = res;
      console.log('data', res)
      if (this.subList.success) {
        this.asynlist = true;
        // console.log("adasad ===>", this.subList);
      } else {

      }
    });
  }
  changeVissibilty(id) {
    if (!(this.eyeoff === 'text')) {
      this.eyeoff = 'text';
    } else {
      this.eyeoff = 'password';
    }

  }

  addresponse: any;
  addNewSubAdmin() {

    this.AuthenticationService.jwtTokenExpCheck();

    this.submitted = true;
    if (this.subadminForm.valid) {
      this.loading = true;
      this.subadminForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));

      //this.enterPriseAPI.registersubenterpriseVD(this.subadminForm.value).subscribe((res: any) => {

      //   if (res.success) {
      //     this.vdEntpId = res.data;
      //     this.subadminForm.controls['vdEntpId'].setValue(this.vdEntpId);

          this.enterPriseAPI.registersubenterprise(this.subadminForm.value).subscribe((res:any) => {
            // console.log(res);
            this.addresponse = res;
            if (this.addresponse.success && Number(res.data.epId) > 0) {
                this.enterPriseAPI.registersubenterpriseVD(this.subadminForm.value,Number(res.data.epId)).subscribe((resp: any) => {
                if (res.success) {
                   this.vdEntpId = resp.data;
                  // this.subadminForm.controls['vdEntpId'].setValue(this.vdEntpId);
                  this.loading = false;
                  this.isaddNewform = false;
                  this.toastr.successToastr('Successfully Created !', 'Success');  
                  this.subadminList();
                  this.initForm();
                  this.submitted = false;
                  this.enterPriseAPI.UpdateIdFromVeridialForEnterprise(Number(res.data.epId),Number(this.vdEntpId),'EP').subscribe(async (response: any) => {
                    console.log('=========== final ',response);
                  });
                }
                else{
                  this.loading = false;
                  this.toastr.successToastr(resp.message, 'Error!');
                }
                });
            }
            else {
              this.toastr.errorToastr(this.addresponse.message, 'Error!');
              this.loading = false;
              this.isaddNewform = false;
            }
          });
    }
    else {
      this.loading = false;
      return false;
    }
  }

  clearData() {

    this.isaddNewform = false;
    //this.subadminForm.reset();
    this.submitted = false;
    this.initForm();
  }
  updateresposne: any;

  editSubAdmin() {
    // console.log(this.editFormSSubmit);
    console.log("EmpId", this.EmpId)
    this.submittedEdit = true;
    if (this.editFormSSubmit.valid) {
      this.loading = true;
      this.enterPriseAPI.updateSubuserEnterpriseVD(this.editFormSSubmit.value, this.EmpId).subscribe((res: any) => {
        if (res.success) {
          this.enterPriseAPI.updatesubenterprise(this.editFormSSubmit.value, this.EmpId).subscribe(res => {
            this.loading = false;
            this.updateresposne = res;
            if (this.updateresposne.success) {
              this.isEditform = false;
              this.toastr.successToastr(this.updateresposne.message, 'Success!');
              this.subadminList();
              this.submittedEdit = false;
            }
            else{
              this.toastr.errorToastr(this.updateresposne.message, 'Error!');
            }
          });
        }
      })

    }
  }


  editId: string = '';

  PopupEditSub(data) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.submitted = false;
    this.exitsMail = false;
    this.exitsPhone = false;
    // console.log("User Data =>", data);
    this.editFormSSubmit.controls['firstName'].setValue(data.firstName);
    this.editFormSSubmit.controls['lastName'].setValue(data.lastName);
    this.editFormSSubmit.controls['middleName'].setValue(data.middleName);
    // this.editFormSSubmit.controls['password'].setValue(data.password);
    this.editFormSSubmit.controls['emailId'].setValue(data.emailId);
    this.editFormSSubmit.controls['contactNo'].setValue(data.contactNo);
    this.editId = data.enterpriseId;
    this.isEditform = !this.isEditform;
    this.EmpId = data.empId;
  }


  OnChangePhoneNo(field_no, e) {
    let f_val: String = '';
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== '' && e.keyCode != 8) {
      if (field_no === 1) {
        this.subadminForm.controls['contactNo'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (field_no === 2) {
        this.editFormSSubmit.controls['contactNo'].setValue(npa + '-' + nxx + '-' + last4);
      }
    }
    else {

    }

  }

  checkmail(id, event, method) {

    if (method === 'edit') {
      id = this.EmpId;
    } else {
      id = 0;
    }
    if(this.subadminForm.controls['emailId'].value==''){
      this.exitsMail = false;
    }
    else{
    this.enterPriseAPI.mailExist(id, this.subadminForm.controls['emailId'].value).subscribe((res: any) => {

      if (res.success) {
        if (res.data.exists) {
          this.exitsMail = true;
          // for check whether it is add / edit
          if (method === 'add') {
            this.subadminForm.controls['emailId'].setErrors({ 'errors': true });
          } else {
            this.editFormSSubmit.controls['emailId'].setErrors({ 'errors': true });
          }
        } else {
          this.exitsMail = false;
        }
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
  }
  }

  checkPhone(id, event, method) {
    if (method === 'edit') {
      id = this.EmpId;
    } else {
      id = 0;
    }
    this.enterPriseAPI.phoneExist(id, event.target.value).subscribe((res: any) => {
      if (res.success) {
        if (res.data.exists) {
          this.exitsPhone = true;
          if (method === 'add') {
            this.subadminForm.controls['contactNo'].setErrors({ 'error': true });
          } else {
            this.editFormSSubmit.controls['contactNo'].setErrors({ 'error': true });
          }
        } else {
          this.exitsPhone = false;
        }
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
  }

  addnewSub() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isaddNewform = !this.isaddNewform;
    this.submitted = false;
    this.exitsMail = false;
    this.exitsPhone = false;
  }


  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }



  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  // 20200929: Akhilesh, Special charcter validation, jira no 70
  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;
    if (k == 33 || k == 36 || k == 37 || k == 44 || k == 58 || k == 60 || k == 61 || k == 62 || k == 63 || k == 91
      || k == 92 || k == 93 || k == 94 || k == 96 || k == 123 || k == 124 || k == 125 || k == 126) {
      this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .', 'Validation!');
      return false
    }
  }

}

import { Injectable } from '@angular/core';

@Injectable()
export class LoginService {
    isLoggedIn = false;
    constructor() {
    }

    getLoggedIn() {
        return this.isLoggedIn;
    }

    setLoggedIn(val) {
        this.isLoggedIn = val;
    }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/app.service';
import { componentServices } from '../../component.service';
import { ShareService } from 'src/app/share-module/share.service';
import { JobseekerService } from 'src/app/JobSeeker/jobseeker.service';
import { environment } from 'src/environments/environment';
import $ from 'jquery';
import { JwtService } from 'src/app/admin/service/jwt.service';
// import { AuthService } from 'angular-6-social-login';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy  {
  preloader: boolean;
  visible = false;
  activeRoute = '/dashboard';
  linkActive = 'dashboard';
  localStoragerData: any = {};
  status: Boolean = false;
  

  isaddNewform: boolean = true;
  routerPath = '';
  signupForm: FormGroup;
  signupFormJobSeeker: FormGroup;
  forgateRes: any;
  signupFormStaffing: FormGroup;
  signupEnterpriseForm: FormGroup;
  submitted = false;
  CompName = '';
  Website = '';
  firstName = '';
  middlName = '';
  lastName = '';
  email = '';
  password = '';
  loginForm: FormGroup;
  adminloginForm:FormGroup;
  loading = false;
  returnUrl;
  countryList: any = [];
  securityQuestionsList: any = [];
  stateList: any = [];
  stateLoader = false;
  forgotForm: FormGroup;
  resetForm: FormGroup;
  routerClass;
  type:string;
  user:string;
  loginType = '';
  userData: any = {};
  userDetails:any ={};
  userDataShow = false;
  loginMsg = '';
  headerList = 'GN';
  isLogin = 'false';
  staffReg: any;
  loginTypeSelet = 'Login';
  notifyenterpriseData:any;
  notifyData : any;
  announceData: any;
  announceToggle = false;
  announceLIstData = false;
  img_path = '';
  userLoginData : any ={};
  isCollapsed = true;
  isCollapsedMenu = false;
  islogedCollapsedMenu = false;
  isOn = false;
  isOnsignUp = false;
  isOnjobsk = true;
  isOnentp = false;
  isOnstaffg = false;
  isAdmin=true;
  isOnForgot = false;
  isOnreset = false;
  cityList: any = [];
  cityLoader = false;
  profileStatus : string;
  isrequire=true;
  announcShow: boolean = false;
  docusignStatus=true;
  paginator = {
    RA: {
      pageSize: 5,
      length: 2,
      pageSizeOptions: [5, 10, 20, 100]
    }, AN: {
      pageSize: 5,
      length: 2,
      pageSizeOptions: [5, 10, 20, 100]
    }
  }
  isPasswordReset=false
  isPasswordResetCheck='false'
  isTimeSheet: boolean = false;
  jobData: any;
  userDataCheck: any = {};
  todayDate = new Date;
  diffDays: number;
  remainingdays: number;
  showPasswordWarningAfterExpire : boolean=false;

  // Permission:any= [  
  //   "Permissions.Enterprise.View",
  //   "Permissions.Enterprise.Create",
  //   "Permissions.Enterprise.Edit",
  //   "Permissions.Enterprise.Delete",
  //   "Permissions.Enterprise.Search"
    
  // ];
  Menu = [
    { title: 'Dashboard', name: 'dashboard', permission:'Permissions.Dashboards.View', imageLink: '/../assets/images/dashboard.svg', routerLink:'/dashboard', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'SUPPLIER', name: 'epStaffing', permission:'Permissions.Suppliers.View', imageLink: '/../assets/images/icons/agency.svg', routerLink:'/suppliers', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'PROJECTS', name: 'epProjects', permission:'Permissions.Projects.View', imageLink: '/../assets/images/icons/project.svg', routerLink:'/ep/enterprise/projects', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'MY JOBS', name: 'epJobs', permission:'Permissions.JobRequest.View', imageLink: '/../assets/images/icons/myjob.svg', routerLink:'/jobRequest', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'SEARCH JOB SEEKER', name: 'search_profile', permission:'Permissions.Candidates.View', imageLink: '/../assets/images/icons/search-profile.svg', routerLink:'/ep/enterprise/search_profile', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'MY INVOICES', name: 'invoice', permission:'Permissions.MYINVOICES.View', imageLink: '/../assets/images/icons/invoice.svg', routerLink:'/ep/enterprise/MyInvoiceInterprisePding', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'INTERVIEWS', name: 'interview', permission:'Permissions.INTERVIEWS.View', imageLink: '/../assets/images/icons/interview.svg', routerLink:'/ep/enterprise/Interview', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'JOB SEEKER', name: 'Jobseeker', permission:'Permissions.Candidates.View', imageLink: '/../assets/images/icons/job-seeker.svg', routerLink:'/ep/enterprise/Jobseeker', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'Supplier GROUP', name: 'staffingagencygroup', permission:'Permissions.Group.View', imageLink: '/../assets/images/icons/agency-group.svg', routerLink:'/ep/enterprise/staffingagencygroup', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'GROUP', name: 'Group', permission:'Permissions.Group.View', imageLink: '/../assets/images/icons/teamwork.svg', routerLink:'/ep/enterprise/Group', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'Contact us', name: 'contact', permission:'Permissions.ContactUs.View', imageLink: '/../assets/images/icons/support.svg', routerLink:'/ep/enterprise/ContactUs', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'TimeSheet', name: 'timesheetsupplier', permission:'Permissions.Timesheet.View', imageLink: '/../assets/images/timesheet-color.svg', routerLink:'/ep/enterprise/timesheetsupplier', resetpwdRouterLink:'/ep/enterprise/resetpassword' },
    { title: 'Invoice', name: 'invoice', permission:'Permissions.Invoice.View', imageLink: '/../assets/images/invoice.png', routerLink:'/ep/enterprise/invoice', resetpwdRouterLink:'/ep/enterprise/resetpassword' },

    { title: 'VMS Request', name: 'beeline', permission:'Permissions.VmsRequest.View', imageLink: '/../assets/images/icons/request.svg', routerLink:'/st/staff/vms', resetpwdRouterLink:'/st/staff/resetpassword' },
    { title: 'My Jobseekers', name: 'candidate', permission:'Permissions.Candidates.View', imageLink: '/../assets/images/icons/mycandidates.svg', routerLink:'/st/staff/Candidatestaffing', resetpwdRouterLink:'/st/staff/resetpassword' },
    { title: 'Client Jobs', name: 'browsejobs', permission:'Permissions.JobRequest.View', imageLink: '/../assets/images/icons/enterprises.svg', routerLink:'/st/staff/pushedjobs', resetpwdRouterLink:'/st/staff/resetpassword' },
    { title: 'Invoice Report', name: 'invoicereports', permission:'Permissions.InvoiceReport.View', imageLink: '/../assets/images/invoicereport.png', routerLink:'/st/staff/invoicereports', resetpwdRouterLink:'/st/staff/resetpassword' },
    { title: 'Billable Resources', name: 'pushedjobs', permission:'Permissions.Enterprise.View', imageLink: '/../assets/images/job-seeker.svg', routerLink:'/st/staff/pushedjobs', resetpwdRouterLink:'/st/staff/resetpassword' }
  ];

  Permission:any=[];
  sidebarMenu:any;
  @Input() open = new EventEmitter();

  constructor(private _router: Router, private formBuilder: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
    private API: AuthenticationService,
    private componentAPI: componentServices,
    private shareservice:ShareService,
    private toastr: ToastrManager, private AuthenticationService: AuthenticationService, private JSApi:JobseekerService ,private datePipe:DatePipe,private jwtService:JwtService) {
    debugger
      this._router.events.subscribe(val => {
      const a: any = val;
      this.activeRoute = a.url;
      this.userData = JSON.parse(window.localStorage.getItem('udata'));
    // this.AuthenticationService.jwtTokenExpCheck();
    this.setMenu();
    });

 

    this.API.countries().subscribe((res: any) => {
      if (res.success) {
        this.countryList = res.data;
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      } 
    });

    this.API.getSecurityQuestions().subscribe((res: any) => {
      if (res.success) {
        this.securityQuestionsList = res.data;
      } else {   
      } 
    });

    this.locatstoreData().then((response: any) => {
      if (this.isLogin === 'true' && response === null) {
        this.logout();
      } else if (this.isLogin === 'true' && response != null) {
        // this.componentAPI.headernotification(response.data.access_token, response.data.credentials.userType).subscribe((res: any) => { 
        //   if (res.message === 'Failed to authenticate token.') {
        //     this.logout();
        //   }
        // });
      } else {

      }

    });

    this.getJobStatus();
    // this.staffingNotificationlist(1, 5);
    // this.enterpriseNotificationlist(1, 5);

    this.getLoginDetail();

    this.isLogin = localStorage.getItem('islogin');
    this.signUpFormInit();
    this.forminit();
    this.forgotForm = this.formBuilder.group({
      emailId: ['', [Validators.required, Validators.email]],
      userType: ['']
    });
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(6)]]
    });
   // this.isPasswordResetCheck=localStorage.getItem('isPasswordReset');
   // if(this.isPasswordResetCheck=='true') { this.isPasswordReset=true } else { this.isPasswordReset=false }
    if (this.isLogin === 'true') {
      this.commonPasswordDetails();
    }
    // if(this.isPasswordResetCheck=='true') { this.isPasswordReset=true } else { this.isPasswordReset=false }
  }

  setMenu(){
    const decoded= this.jwtService.getDecodedAccessToken(this.userData?.data.token); 
    console.log('token=====================',decoded.Permission);
    this.Permission = decoded.Permission;
  //   this.sidebarMenu =  this.Menu.filter(function(menu){
  //     return this.Permission.filter(function(permission){
  //        return permission == menu.permission;
  //     }).length == 0
  //  });

  this.sidebarMenu = this.Menu.filter(item1 =>
    this.Permission.some(item2 => item1.permission === item2)
  );
   console.log('==========',this.sidebarMenu);
  }

  ngOnInit() {
    debugger
    $('[data-widget="treeview"]').Treeview('init');
    setTimeout(() => {
   this.preloader = true;
      this.router.events.subscribe(val => {
        const a: any = val;
        if (a !== undefined && a.route !== undefined && a.route.hasOwnProperty('url')) {
          this.routerClass = a.route.url.replace('/', '');
        }
        this.isLogin = localStorage.getItem('islogin');
        window.scrollTo(0, 0);
      });
      this.userData = JSON.parse(window.localStorage.getItem('udata'));
      this.userDataShow = true;
      if (this.isLogin === 'true') {
        this.headerList = this.userData.data.credentials.userType;
        console.log('this.headerList',this.headerList);
        // NotificATION TRIGGRE ON LOAD
        if (window.location.pathname.includes('dashboard') === true) {
          this.preloader = false;
           switch (this.headerList) {
            case 'SA': {
              this.router.navigate(['/st/staff/dashboard']);
              break;
            }
            case 'EP': {
              this.router.navigate(['/dashboard']);
              break;
            }
            case 'JS': {
              this.router.navigate(['/js/jobseeker/dashboard']);
              break;
            }
            case 'AD': {
              this.router.navigate(['/ad/admin/dashboard']);
              break;
            }
          }
        }   
         else{
          this.preloader = false;
          this.img_path = `${this.API.url}/api/auth/image/${this.userData.data.credentials.profileImage}`;
        }
      } else {
        this.preloader = false;
        this.headerList = 'GN';
      }

      //  set header according to login type Ends
    }, 100);

    if (localStorage.getItem('udata') === undefined && this.isLogin === 'true') {
      localStorage.setItem('islogin', 'false');
      localStorage.removeItem('token');
      localStorage.removeItem('udata');
      this.router.navigate['/auth/home'];
      this.headerList = 'GN';
      this.routerClass = '';
      this.loginMsg = '';
      this.isLogin = 'false';
      this.loading = false;
      return false;
    }

    this.routerPath = window.location.pathname;
    if (this.isLogin === 'false' && (this.routerPath.includes('/ep/') === true || this.routerPath.includes('/js/') === true || this.routerPath.includes('/st/') || this.routerPath.includes('/ad/')===true)) {
      localStorage.setItem('islogin', 'false');
      localStorage.removeItem('token');
      localStorage.removeItem('udata');
      this.headerList = 'GN';
      this.routerClass = '';
      this.loginMsg = '';
      this.isLogin = 'false';
      this.loading = false;
      window.location.href = '/auth/home';
    }
    this.headerInit();

    this.getLoginDetail();

    this.localStoragerData = JSON.parse(localStorage.getItem('udata'));
    // this.activatedRoute.queryParams.subscribe((p) => {
    //   console.log(p,"paramsss");
    // });
    this.linkActive="";

  }

  forminit(){
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      userType: [''],
      userEmail:[''],
      typeUser:['']
    });
  }
  // forgot password
  get fg() {
    return this.forgotForm.controls;
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k < 48 && k > 57));
  }

  signUpFormInit() {

    this.signupFormJobSeeker = this.formBuilder.group({
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      contactNo: ['', Validators.required],
      // "isAdmin": [true],
      JobRecomendd: [true],
      rememberme: [true],
      terms: [false, Validators.required],
      createdDate:['']
    });

    // staffing Aggengy start
    this.signupFormStaffing = this.formBuilder.group({
      companyName: ['', Validators.required],
      website: ['', [Validators.required, Validators.pattern("(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      streetName: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zipcode: ['', Validators.required],
      securityQuestions:['',Validators.required],
      answer : ['',Validators.required],
      top_5_Skills:['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      contactNo: ['', Validators.required],
      JobRecomendd: [true],
      rememberme: [false],
      terms: [false, Validators.required],
      createdDate:['']
    });

    // staffing Aggengy Ends
    // Enterprise start

    this.signupEnterpriseForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      website: ['', [Validators.required, Validators.pattern("(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      contactNo: ['', Validators.required],
      isAdmin: [true],
      JobRecomendd: [true],
      rememberme: [true],
      terms: [false, Validators.required],
      securityQuestions:['',Validators.required],
      answer : ['',Validators.required],
      createdDate:['']
    });
    // Enterprise Ends
  }

  OnChangePhoneNo(tab, e) {
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if(e.target.value !== ''  && e.keyCode != 8){
      if (tab === 'EP') {
        this.signupEnterpriseForm.controls['contactNo'].setValue('(' + npa + ')' + ' ' + nxx + '-' + last4);
      } else if (tab === 'SA') {
        this.signupFormStaffing.controls['contactNo'].setValue('(' + npa + ')' + ' ' + nxx + '-' + last4);
      } else if (tab === 'JS') {
        this.signupFormJobSeeker.controls['contactNo'].setValue('(' + npa + ')' + ' ' + nxx + '-' + last4);
      }
    }
  }
  addForm() { }
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  toggleCollapseMenu() {
    this.isCollapsedMenu = !this.isCollapsedMenu;
  }
  logedCollapseMenu() {
    this.islogedCollapsedMenu = !this.islogedCollapsedMenu;
  }
  parentfun() {
  }
  popUpreset() {
    this.isOnreset = !this.isOnreset;
  }

  Popuptoggle() {
    if (this.loginType  !==  '') {
      this.isOn = !this.isOn; 
      this.isCollapsed = true;
    } else {
      this.toastr.infoToastr('Please Select login Type!', 'Warning!', {
        position: 'top-right'
      });
    }
  }

  async locatstoreData() {
      this.isLogin = await localStorage.getItem('islogin');
      if(localStorage.getItem('udata')){
      this.localStoragerData = JSON.parse(localStorage.getItem('udata'));
      return Promise.resolve().then(() => {
        return JSON.parse(localStorage.getItem('udata')); 
      });
    }
  }
   
  
  Popupforgot() {
    this.isOnForgot = !this.isOnForgot;
  }
  PopupsignUptoggle() {
    this.isOnsignUp = !this.isOnsignUp;
  }

  Popupjobsk(val) {
    if (val === 'tab1') {
      // this.selectLoginType('EP');
      this.loginTypeSelet = 'Enterprise';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnentp = true;
      this.isOnjobsk = false;
      this.isOnstaffg = false;
      this.forminit();
      this.isAdmin =  false;
    } else if (val === 'tab2') {
      // this.selectLoginType('SA');
      this.loginTypeSelet = 'Staffing Agency';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = false;
      this.isOnentp = false;
      this.isOnstaffg = true;
      this.forminit(); 
      this.isAdmin = false;
    } else if (val === 'tab3') {
      // this.selectLoginType('JS');
      this.loginTypeSelet = 'Job Seeker';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = true;
      this.isOnentp = false;
      this.isOnstaffg = false;
      this.forminit(); 
      this.isAdmin = false;
    }
    else if (val === 'tab4') {
      this.forminit();
      // this.selectLoginType('ADMIN');
      this.loginTypeSelet = 'Admin';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = true;
      this.isOnentp = false;
      this.isOnstaffg = false;
    }
  }

  getJobStatus() {
    // this.JSApi.getJobStatus().subscribe(res => {
    //   this.jobData = res;
    //   if (this.jobData.success) {
    //     if (this.jobData.data.length > 0) {
    //       for (var i = 0; i < this.jobData.data.length; i++) {
    //         if (this.jobData.data[i].jobstatus === 'Onboarded') {
    //           this.isTimeSheet = true;
             
    //         }
    //       }
    //     }
    //   }
    // })
  }

  PostJobFromGuest(type) {
    this.router.navigateByUrl('/');
    this.isCollapsed = false;
    this.selectLoginType(type);
    this.Popuptoggle();
  }
  ChangeLoginType(val) {
    // this.isCollapsed = false;
    if (val === 'tab1') {
      this.forminit();
      // this.selectLoginType('EP');
      this.loginType='EP';
      this.loginTypeSelet = 'Enterprise';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnentp = true;
      this.isOnjobsk = false;
      this.isOnstaffg = false;
      this.isAdmin =false; 
    } else if (val === 'tab2') {
      this.forminit();
      // this.selectLoginType('SA');
      this.loginType='SA'
      this.loginTypeSelet = 'Staffing Agency';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = false;
      this.isOnentp = false;
      this.isOnstaffg = true;
      this.isAdmin =false; 
      this.signupFormStaffing.controls['securityQuestions'].setValue(this.securityQuestionsList);
    } else if (val === 'tab3') {
      this.forminit();
      console.log('show',this.loginForm)
      // this.selectLoginType('JS');
      this.loginType='JS'
      this.loginTypeSelet = 'Job Seeker';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = true;
      this.isOnentp = false;
      this.isOnstaffg = false;
      this.isAdmin =false; 
    }
    else if (val === 'tab4') {
      this.forminit();
      // this.selectLoginType('ADMIN');
      this.loginType='Admin'
      this.loginTypeSelet = 'Admin';
      this.Epsubmitted = false;
      this.submitted = false;
      this.JSsubmitted = false;
      this.signUpFormInit();
      this.isOnjobsk = false;
      this.isOnentp = false;
      this.isOnstaffg = false;
      this.isAdmin =true; 
    }
  }

  selectLoginType = val => {
    this.loginType = val;
    this.isCollapsed = !this.isCollapsed;
    switch (val) {
      case 'EP': {
        this.forminit();
        this.loginTypeSelet = 'Enterprise';
        this.isOnjobsk = false;
        this.isOnentp = true;
        this.isOnstaffg = false;
        this.isAdmin=false;
        this.Popuptoggle();
        break;
      }
      case 'JS': {
        this.forminit();
        this.loginTypeSelet = 'Job Seeker';
        this.isOnjobsk = true;
        this.isOnentp = false;
        this.isOnstaffg = false;
        this.isAdmin=false;
        this.Popuptoggle();
        break;
      }
      case 'SA': {
        this.forminit();
        this.loginTypeSelet = 'Staffing Agency';
        this.isOnjobsk = false;
        this.isOnentp = false;
        this.isOnstaffg = true;
        this.Popuptoggle();
        this.isAdmin=false; 
        break;
      }
      case 'ADMIN': {
        this.forminit();
        this.loginTypeSelet = 'Admin';
        this.isOnjobsk = false;
        this.isOnentp = false;
        this.isOnstaffg = false;
        this.isAdmin=true;
        this.Popuptoggle(); 
        break;
      }
      default: {
        this.loginTypeSelet = 'Login';
        break;
      }
    }
  }

  emitterLogin(val) {
    this.isLogin = val;
  }


  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-close');
  }
  clicked=false;
  // staffing Signup start
  staffingSignup() {
    this.submitted = true;
    if (this.signupFormStaffing.controls['terms'].value) {
      if (this.signupFormStaffing.valid) {
      this.signupFormStaffing.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        this.API.staffRegister(this.signupFormStaffing.value).subscribe(res => {       
          this.staffReg = res;
          if (this.staffReg.success) {
            const user = this.signupFormStaffing.controls['emailId'].value;
            const pass = this.signupFormStaffing.controls['password'].value;
            this.loginForm.controls['email'].setValue(user);
            this.loginForm.controls['password'].setValue(pass);
            this.signupFormStaffing.reset();
            this.isOnsignUp = false;
            this.onSubmit();
          } else {
            this.toastr.errorToastr(this.staffReg.message, 'Error!');
            this.isOnsignUp = true;
          }
        });
      } else {
        return false;
      }
    } else {
      this.clicked=false;
      this.toastr.errorToastr('Please accept Terms and Privacy Policy.', 'Alert!', {
        position: 'top-right'
      });
      return false;
    }
  }
  // staffing Signup Ends

  Epsubmitted = false;
  enterpriseSignup() {
    this.Epsubmitted = true;
    if (this.signupEnterpriseForm.controls['terms'].value) {
      if (this.signupEnterpriseForm.valid) {
       this.signupEnterpriseForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        this.API.enterpriseRegister(this.signupEnterpriseForm.value).subscribe(
          (res: any) => {
            
            if (res.success) {
              this.isOnsignUp = false;
              this.staffReg = res;
              window.localStorage.setItem('udata', this.staffReg);
              let user = this.signupEnterpriseForm.controls['emailId'].value;
              let pass = this.signupEnterpriseForm.controls['password'].value;
              this.loginForm.controls['email'].setValue(user);
              this.loginForm.controls['password'].setValue(pass);
              this.onSubmit();             
            } else {
              this.toastr.errorToastr(res.message, 'Error!');
              this.isOnsignUp = true;
            }
          }
        );

      } else {
        this.isOnsignUp = true;
        return false;
      }
    } else {
      this.toastr.errorToastr('Please accept Terms and Privacy Policy.', 'Alert!', {
        position: 'top-right'
      });
      return false;
    }
  }
  JSsubmitted = false;
  jobseekerSignup() {
    
    this.JSsubmitted = true;
    if (this.signupFormJobSeeker.controls['terms'].value) {
      if (this.signupFormJobSeeker.valid) {
       this.signupFormJobSeeker.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        this.API.jobseekerRegister(this.signupFormJobSeeker.value).subscribe(
          (res: any) => {
            if (res.success) {
              this.headerList = 'JS'
              this.staffReg = res.data;
              window.localStorage.setItem('udata', JSON.stringify(this.staffReg))
              let user = this.signupFormJobSeeker.controls['emailId'].value;
              let pass = this.signupFormJobSeeker.controls['password'].value;
              this.loginForm.controls['email'].setValue(user);
              this.loginForm.controls['password'].setValue(pass);
              this.onSubmit();
            } else {
              this.toastr.errorToastr(res.message, 'Error!');
            }
          });
        this.isOnsignUp = false;
      } else {
        this.toastr.errorToastr('Invalid Input Please try again!', 'Alert!', {
          position: 'top-right'
        });
        return false;
      }
    }
    else {
      this.toastr.errorToastr('Please accept Terms and Privacy Policy.', 'Alert!', {
        position: 'top-right'
      });
      return false;
    }
  }

  // convenience getter for easy access to form fields
  get fl() {
    return this.loginForm.controls;
  }
  get f() {
    return this.signupFormStaffing.controls;
  }
  get fjs() {
    return this.signupFormJobSeeker.controls;
  }
  get fEp() {
    return this.signupEnterpriseForm.controls;
  }
  //login Start
  async onSubmit() {
    if(this.isAdmin){
      this.type=this.loginForm.controls['typeUser'].value;
      this.loginForm.controls['userType'].setValue(this.loginForm.controls['typeUser'].value);
    }  
    else{
      this.loginForm.controls['userType'].setValue(this.loginType);
    }
    this.submitted = true;
      try {
        if (this.loginForm.invalid) {
          this.isOn = true;     
          this.toastr.warningToastr('Invalid please try again!', 'Opps!', {
            position: 'top-right'
          });   
          return;
        } else {
          this.isOn = false;
          await this.logData();
          this.forminit();
          if (this.userData.success) {
            this.isLogin = 'true';
            this.userDataShow = true;
            window.localStorage.setItem('islogin', 'true');
            localStorage.setItem('udata', JSON.stringify(this.userData));
            localStorage.setItem('token', this.userData.data.token);
            this.loginMsg = this.userData.message;
            this.toastr.successToastr(`${this.loginMsg}`, 'Success!', {
              position: 'top-right'
            });
            if(this.isAdmin){
                this.user = this.type;
            }
            else{
              this.user=this.loginType;
            }
            switch (this.user) {
              case 'SA': {
                this.headerList = 'SA';
                 this.routerClass = 'DashboardStaffing';
                this.router.navigate(['/st/staff/dashboard']);
                setTimeout(() => {
                  window.location.reload();
                }, 300);
                break;
              }
              case 'JS': {
                window.localStorage.setItem('islogin', 'true');
                  this.headerList = 'JS';
                this.routerClass = 'DashboardStaffing';
                this.router.navigate(['/js/jobseeker/dashboard']);
                setTimeout(() => {
                  window.location.reload();
                }, 300);
                break;
              }
              case 'EP': {
                this.router.navigate(['/dashboard']);
                window.localStorage.setItem('islogin', 'true');
                 this.headerList = 'EP';
                this.routerClass = 'DashboardStaffing';
                setTimeout(() => {
                  window.location.reload();
                }, 300);
                break;
              }
              case 'AD': {
                this.router.navigate(['/ad/admin/dashboard']);
                window.localStorage.setItem('islogin', 'true');
                 this.headerList = 'EP';
                this.routerClass = 'DashboardStaffing';
                setTimeout(() => {
                  window.location.reload();
                }, 300);
                break;
              }
            }
          } else {
            this.isOn = true;
            this.loginMsg = this.userData.message;
            return false;
          }
        }
      } catch (error) {
        this.logout();
      }
      this.loading = true;
  }
  logout() {
    this.headerList = 'GN';
    localStorage.setItem('islogin', 'false');
    localStorage.removeItem('token');
    localStorage.removeItem('udata');
    this.userDetails = {};
    this.router.navigate(['/auth/home']);

    this.toastr.successToastr('Successfully Logout!', 'Success!', {
      position: 'top-right'
    });
    this.routerClass = '';
    this.loginMsg = '';
    this.isLogin = 'false';
    this.loading = false;
    this.forminit();
    window.location.reload();
  }

  logData() { 
    return new Promise((resolve, reject) => {
      this.API.loginAPI(this.isAdmin ,this.loginForm.value).subscribe(
        res => {
          this.userData = res;
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getLoginDetail(){ 
    if(localStorage.getItem('udata')){
      this.userLoginData = JSON.parse(localStorage.getItem('udata'));
      if(this.userLoginData != null){
        if(this.userLoginData.data.credentials.userType == 'EP'){
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }
        if(this.userLoginData.data.credentials.userType == 'JS'){
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }

        if(this.userLoginData.data.credentials.userType == 'AD'){
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }
        if(this.userLoginData.data.credentials.userType == 'SA'){
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }
        this.API.getLoginDetail(this.userLoginData.data.credentials.staffingagencyId,this.userLoginData.data.credentials.emailId,this.userLoginData.data.credentials.userType).subscribe(
          (res:any) =>{
            this.userDetails = res;
            //this.profileStatus = this.userDetails.data[0].profile_status;  
            this.profileStatus = 'Verified' ;
            if(this.userLoginData.data.credentials.userType == 'SA'){
              this.docusignStatus = this.userDetails.data[0].docuSignStatus;
            }
      });
      } 
    }
  }

  fgSubmitted = false;
  forgotSubmit() {
    this.fgSubmitted = true;
    this.loading = true;
    if (this.forgotForm.valid) {
      this.forgotForm.controls['userType'].setValue(this.loginType);
      this.componentAPI
        .forgotAPI(this.forgotForm.value)
        .subscribe((res: Response) => {
          this.loading = false;
          this.forgateRes = res;
          if (this.forgateRes.success) {
            this.toastr.successToastr(
              this.forgateRes.message,
              '',
              'top-right'
            );
            this.loading = false;
            this.forgotForm = this.formBuilder.group({
              emailId: ['']
            });
            this.isOnForgot = false;
            this.fgSubmitted = false;
            window.location.reload();
          } else {
            this.toastr.errorToastr(
              this.forgateRes.message,
              '',
              'top-right'
            );
            this.forgotForm.controls['emailId'].setValue('');
            this.forgotForm.controls['emailId'].setErrors({ 'error': false });
            this.fgSubmitted = false;
            this.loading = false;
            this.isOnForgot = false;
          }
        });
    } else {
      this.toastr.warningToastr('Invalid Input.', 'Error!');
      return false;
    }
  }
  // resetpassword
  get fr() {
    return this.resetForm.controls;
  }

  resetSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetForm.invalid) {
    }
    this.loading = true;
  }

  headerInit() {
    $(document).ready(function () {
      $('#home').addClass('active');
      $('body').on('click', '.header_list_sec li a', function () {
        $(window).scrollTop(0);
        if ($(this).parent('li').hasClass('staf_hd') || $(this).parent('li').hasClass('login_hd')) {

        } else {
          $('.header_list_sec li').removeClass('active');
          $(this).parent('li').addClass('active');
        }
      });
    });
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
        e.preventDefault();
    }
  }

  clearData() {
    this.isOnsignUp = false;
    //this.initForm();
  } 

  nextpage(){
    this.router.navigate(['/auth/terms']);
  }
  

 
 
  toggle(val) {
    this.visible = true;
  }
  RouterSet = (val, e) => {
    e.preventDefault();
    e.stopPropagation();
    this.activeRoute = val;
  }

//  New changes
  activeLinks(val) {
    this.linkActive = val;
  }

  openMenu() {
    this.status = !this.status;
    var element = document.getElementById("accordionSidebar");
    var element2 = document.getElementById("mainwrapper");
    if (this.status) {
      element.classList.remove("toggled");
      element2.classList.remove("closemenu");
    }
    else {
      element.classList.add("toggled");
      element2.classList.add("closemenu");
    }

  }

  commonPasswordDetails() {
    this.userDataCheck = JSON.parse(localStorage.getItem('udata'));
    console.log('this.user ====', this.userDataCheck)
    let newDate = new Date(this.datePipe.transform(this.userDataCheck.data.credentials.PasswordUpdateOn, 'yyyy-MM-dd'));
    var diff = Math.abs(this.todayDate.getTime() - newDate.getTime());
    this.diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    this.remainingdays=environment.passwordExpiredays-this.diffDays
   if( this.remainingdays<=0) { 
    this.showPasswordWarningAfterExpire=true;
    // window.localStorage.setItem('isPasswordReset', 'true');
    // this.isPasswordResetCheck=localStorage.getItem('isPasswordReset');
    // if(this.isPasswordResetCheck=='true') { this.isPasswordReset=true } else { this.isPasswordReset=false }
    } else {
      this.showPasswordWarningAfterExpire=false;
      this.isPasswordResetCheck=='false';
    }
  }
}

import { Component, OnInit, AfterViewInit, forwardRef } from '@angular/core';
import { FormGroup, FormBuilder, Validator, Validators, RequiredValidator, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { RouterLink, RouterModule, ActivatedRoute } from '@angular/router'
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TableService } from 'src/app/admin/service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
//import * as moment from 'moment';
import {AuthenticationService } from '../../app.service';
import { ShareService } from '../share.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';

interface Data {
  name: string,
  title: string
}
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AddNewJobEnterpriseComponent),
  multi: true
};
const noop = () => {
};
@Component({
  selector: 'app-add-new-job-enterprise',
  templateUrl: './add-new-job-enterprise.component.html',
  styleUrls: ['./add-new-job-enterprise.component.css'],
  providers: [    
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class AddNewJobEnterpriseComponent implements OnInit {
  searchenterpriseId:any;
  isEditProjectJob: boolean = false;
  searchManagerList: any = [];
  clientdetails:any;
  isTrue:boolean= false;
  stateLoader:boolean= false;
  cityLoader:boolean= false;
  vdEntpId:any;
  client_id:any;
  createdby:any;
   // Datepicker takes `Moment` objects instead of `Date` objects.
   date = new FormControl(moment([2017, 0, 1]));
   
  selectedManagerId: string = '';
  countryList: any = [];
  listofManager: any = [];
  allCountryList : any =[];
  selectedManagerEmail: string = '';
  jobAmount: number = 0;
  isRequired: false;
  totalOpening = false;
  comparevalError: any;
  minError: any;
  maxError: any;
  payComparevalError: any;
  listShow: boolean = false;
  candidateList: any;
  tabAll: boolean = true;
  submitted: boolean = false;
  enterpriseAddjob: FormGroup;
  isCountrySuccess: boolean = false;
  ListOfAllCountries: any;
  listofAllCountry:any;
  isStateSuccess: boolean = false;
  ListOfAllStates: any;
  isCitiesSuccess: boolean = false;
  ListOfAllCities: any;
  isSearchCitiesSuccess: boolean = false;
  SearchListOfAllCities: any;
  companies: any[] = [];
  loading = false;
  companiesNames = [];
  RateOption = [{ name: 'W-2', value: 'W-2' }, { name: '1099', value: '1099' }, { name: 'C2C', value: 'C2C' }];
  today: String = new Date().toISOString().split('T')[0];
  projectId: any = undefined;
  preLoader: boolean = false;
  preLoader1: boolean = false;
  isShowJobDuration: boolean = true;
  uploadData: any;
  Ajsubmitted: boolean = false;
  routeSub: Subscription;
  islstopen: boolean = false;
  totalexceed: number = 0;
  totalalljobBudget: number = 0;
  monthlist = [];
  projDetail: any;
  userData: any = {};
  subadminForm: FormGroup;
  exitsMail: boolean = false;
  exitsPhone: boolean = false;
  isaddNewform: boolean = false;
  EmpId: String = '';
  subList: any;
  subManager: any = [];
  allsubList : any;
  asynlist = false;
  isRequire = false;
  allStateList: any;
  country_Id: any;
  allCityList: Object;
  state_Id: any;
  clientId:string;
  AllUsers: any;
  clients:any;
  jobId: any;
  personLoad: boolean = false;
  pageValue:boolean=false;
  sub: any;
  page: number;
  JobData: any;
  pretotalAmount: number = 0;
  joiningdateshow:any;
  updateresponse: any;
  checkdescription:boolean =false;
  isApprovalPopUp: boolean = false;
  allclientList: any =[];
  allclientList1: any =[];
  clientIdSearch: any; //Manu Datta: 20210324, VerisourceBugSheetAfterMerge/commonLogin 480  

  currency = [
    { value: 'USD', name: 'USD',selected:false},
    { value: 'CAD', name: 'CAD',selected:false},
    { value: 'INR', name: 'INR',selected:false},
  ];
  constructor(private projectService: TableService, private API: TableService, private addJobAPI: EnterpriseService,
    private _router: Router,
    private AuthenticationService: AuthenticationService,
    private toastr: ToastrManager, private _formBuilder: FormBuilder,
    private _titleService: Title, private route: ActivatedRoute,
    private datePipe: DatePipe,
    private shareservice:ShareService, 
    private ActiveRouter: ActivatedRoute,
    private enterpriseApis: TableService,) {
    
    this.AuthenticationService.jwtTokenExpCheck();
    var newTitle = 'Verisource | Add New Job';
    this.jobId = this.ActiveRouter.snapshot.params['jobId'];
    if(this.jobId == undefined){
      this.isEditProjectJob=true
    }
    else{
      this.isEditProjectJob=false
    }
    this.userData = JSON.parse(window.localStorage.getItem('udata'));//Manu Datta: 20210224, VerisourceBugSheetAfterMerge/commonLogin 404
    if(this.jobId == undefined){
      this.pageValue = false;
      this.routeSub = this.route.params.subscribe(params => {
        this.projectId = params['id'];
     
        if(this.userData.data.credentials.userType == 'AD'){
          this.clientId = params['clientId'];
        }
      });
    } 
    else{
      this.pageValue = true;
    }
    this._titleService.setTitle(newTitle);

    this.loadAllUserTypewise("EP");
    this.loadClients();
    
    if (this.userData.data.credentials.userType == 'EP') {
      this.isRequire = true;
    }
    else{
       this.isRequire = true;
    }

    if(this.projectId == undefined){
      this.currency = [
        { value: 'USD', name: 'USD',selected: false},
        { value: 'CAD', name: 'CAD',selected: false},
        { value: 'INR', name: 'INR',selected: false}
      ];
      this.enterpriseAddjob = this._formBuilder.group({
        "jobTitle": ['', [Validators.required]],
        "jobLocation": ["", [Validators.required]],
        "jobCountry":  this.pageValue == false?[""]:["", Validators.required],
        "totalOpenings": ["", Validators.required],
        "requiredExpLevelMin": [""],
        "requiredExpLevelMax": [""],
        "jobType": ["Contract Basis"],
        "jobDuration":   this.pageValue == false?["", Validators.required]:[""],
        "overTimeAllow": ["No", Validators.required],
        "overTimePayRate": [],
        "overTimeMax": [],
        "totalJobBudget": [],
        'contractRate': ['W-2'],
        "hiringManager": [""],
        "annualPayMin": ["", [Validators.required, Validators.minLength(1)]],
        "annualPayMax": ["", [Validators.required, Validators.minLength(1)]],
        "joiningDate": ["", Validators.required],
        "jobDescription": ["", [Validators.required]],
        "description": [""],
        "checkdescription": [""],
        "country": [""],
        "state": [""],
        "city": [""],
        "postalcode": [""],
        "street1": ["",Validators.required],
        "street2": [""],
        "skills":  this.pageValue == false?["", [Validators.required,Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-, ]+$')]]:["",[Validators.required]],
        "keywords": [""],
        "note": [""],
        "clientId":[""],
        "createdDate":[''],
        "jobClientId":this.pageValue == false?["",Validators.required]:[""],
        "hiringManagerName": [""],
        "createdBy":[""],
        "registerBy":[""],
        "currency":["USD"]
      })
  
    }
    
    if(this.projectId != undefined){
      this.currency = [
        { value: 'USD', name: 'USD',selected: false},
        { value: 'CAD', name: 'CAD',selected: true},
        { value: 'INR', name: 'INR',selected: true}
      ];
      this.enterpriseAddjob = this._formBuilder.group({
        "jobTitle": ['', [Validators.required]],
        "jobLocation": ["", [Validators.required]],
        "jobCountry":  this.pageValue == false?[""]:["", Validators.required],
        "totalOpenings": ["", Validators.required],
        "requiredExpLevelMin": [""],
        "requiredExpLevelMax": [""],
        "jobType": ["Contract Basis"],
        "jobDuration":   this.pageValue == false?["", Validators.required]:[""],
        "overTimeAllow": ["No", Validators.required],
        "overTimePayRate": [],
        "overTimeMax": [],
        "totalJobBudget": [],
        'contractRate': ['W-2'],
        "hiringManager": [""],
        "annualPayMin": ["", [Validators.required, Validators.minLength(1)]],
        "annualPayMax": ["", [Validators.required, Validators.minLength(1)]],
        "joiningDate": ["", Validators.required],
        "jobDescription": ["", [Validators.required]],
        "description": [""],
        "checkdescription": [""],
        "country": [""],
        "state": [""],
        "city": [""],
        "postalcode": [""],
        "street1": ["",Validators.required],
        "street2": [""],
        "skills":  this.pageValue == false?["", [Validators.required,Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-, ]+$')]]:["",[Validators.required]],
        "keywords": [""],
        "note": [""],
        "clientId":[""],
        "createdDate":[''],
        "jobClientId":[""],
        "hiringManagerName": [""],
        "createdBy":[""],
        "registerBy":[""],
        "currency":["USD"]
      })
  
    }
    for (let i = 1; i <= (50); i++) {
      this.monthlist.push(i);
    }
  }
  ngOnInit() {
  
    if(this.jobId == undefined){
    this.routeSub = this.route.params.subscribe(params => {
      this.projectId = params['id'];
      if(this.userData.data.credentials.userType == 'AD'){
        this.clientId = params['clientId'];
      }
    });
    this.LoadAllCountries();
    
    this.AuthenticationService.jwtTokenExpCheck(); 
      if (this.projectId != undefined) {
        this.getProjectDetails();
        this.getProjectJobsByProjectId();
        //this.LoadSingleJob(this.jobId);
      }
      this.initForm();
      this.subadminList();
    }
    
    if(this.jobId != undefined){
        this.sub = this.ActiveRouter
        .queryParams
        .subscribe(params => {
          // Defaults to 0 if no query param provided.
          this.page = +params['project'] || 0;
          console.log('page=====>', this.page)
        });
      this.routeSub = this.ActiveRouter.params.subscribe(params => {
        this.projectId = params['id'];
        if(this.userData.data.credentials.userType == 'AD'){
          this.clientId = params['clientId'];
        }
      });
      this.LoadAllCountries();
      this.LoadSingleJob(this.jobId);
      this.subadminList();
      if (this.projectId != undefined) {
        this.getProjectDetails();
        this.getProjectJobsByProjectId();
      }
    }
    
    this.getClientDeatils();
    if(this.clientId != undefined){
      this.bindmanagerByEnterpriseId(this.clientId);
    }
  }

  initForm() {
    this.subadminForm = this._formBuilder.group({
      'firstName': ['', Validators.required],
      'middleName': [''],
      'lastName': ['', Validators.required],
      'emailId': ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      'password': [''],
      'contactNo': ['', [Validators.required]],
      'createdDate':[''],
      'vdEntpId':['']
    });
  }


  getProjectDetails() {
    this.AuthenticationService.jwtTokenExpCheck();

    //this.preloader = true;
    this.projectService.getProjectById(this.projectId).subscribe((res: any) => {
      this.projDetail = res.data;
      //this.preloader = false;
      if (res.success) {
        if (res.data.length > 0) {
          //this.projectDetails = res.data[0];
          this.totalexceed = +res.data[0].totalAllocatedBudgetAmt;
          console.log('total exceed======', this.totalexceed)
        }
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  getProjectJobsByProjectId() {
    this.AuthenticationService.jwtTokenExpCheck();

    //this.preloader = true;
    this.projectService.getProjectJobsByProjectId(this.projectId).subscribe((res: any) => {
      //this.preloader = false;
      if (res.success) {
        //this.projectJobs = res.data;
      if(res.data.length > 0){
        for (var i = 0; i < res.data.length; i++) {
          let sum = res.data[i].totalOpenings * res.data[i].annualPayMax * res.data[i].jobDuration * 160;
          this.totalalljobBudget += sum;
        }
      }
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  onSubmit(event) {
    if (event.target.files[0].type === 'application/pdf' || event.target.files[0].type === '') {
      const formData = new FormData();
      formData.append('profileimage', event.target.files[0]);
      if (event.target.files[0].size < (2029410 / 2)) {
        this.preLoader = false;
      } else {
        this.toastr.errorToastr('Profile image should be less than 1 MB.');
      }
    } else {
      this.toastr.errorToastr('Profile Img should be in PDF & text format.', 'Error!');
    }

  }

  OpenlfMenu() { this.islstopen = !this.islstopen }

  get aj() {
    return this.enterpriseAddjob.controls;
  }

  disableHiringManager = false;
  onChange_JobType(field_no: number) {
    
    this.isShowJobDuration = field_no == 1 ? true : false;
    if(field_no == 2){
      this.disableHiringManager = true;
      this.isRequire = false;
      this.enterpriseAddjob.controls["jobDuration"].clearValidators();
        this.enterpriseAddjob.controls["jobDuration"].updateValueAndValidity();
    }
    else{
      this.disableHiringManager = false;
      this.isRequire = true;
    }
  } 

  isShow = false;
  onChange_OverTimeAllow(selectedValue: number) {
    console.log(selectedValue);
    if (selectedValue == 1) {
      this.enterpriseAddjob.controls['overTimePayRate'].setValidators(Validators.required);
      this.enterpriseAddjob.controls['overTimeMax'].setValidators(Validators.required);
      this.isShow = true;
    }
    else {
      this.enterpriseAddjob.controls['overTimePayRate'].clearValidators();
      this.enterpriseAddjob.controls['overTimePayRate'].reset();
      this.enterpriseAddjob.controls['overTimeMax'].clearValidators();
      this.enterpriseAddjob.controls['overTimeMax'].reset();
      this.isShow = false;
    }

  }
  
  addTag(name) {
    return { name: name, tag: true };
  }

  addTagPromise(name) {
    return new Promise((resolve) => {
      this.loading = true;
      setTimeout(() => {
        resolve({ id: 5, name: name, valid: true });
        this.loading = false;
      }, 1000);
    });
  }

  LoadAllCountries() {
    this.AuthenticationService.jwtTokenExpCheck();

    if(this.jobId == undefined){
      this.API.LoadAllCountriesApi().subscribe(res => {
        this.listofAllCountry = res;
        this.ListOfAllCountries = res;
        this.allCountryList = this.ListOfAllCountries;     
        if (this.ListOfAllCountries.success) {
          this.isCountrySuccess = true;
  
        }
      });
    }

    if(this.jobId != undefined){
      return new Promise((resolve, reject) => {

        this.enterpriseApis.LoadAllCountriesApi().subscribe(res => {
          this.ListOfAllCountries = res;
          this.listofAllCountry = res;
          if (this.ListOfAllCountries.success) {
            this.isCountrySuccess = true;
            resolve(this.ListOfAllCountries);
          }
          else {
            reject("Error!");
          }
        });
  
      });
    }
    
  }

  LoadAllState() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.stateLoader = true;
    //let countryId = '';
    if(this.jobId == undefined){
      this.ListOfAllCountries.data.forEach(element => {
        if (element.name == this.aj.country.value) {
          this.country_Id = element.Id;
        }
      });
      this.API.LoadAllStateApi(this.country_Id).subscribe(res => {
        this.ListOfAllStates = res;
        this.allStateList = res;
        if (this.ListOfAllStates.success) {
          this.isStateSuccess = true;
          this.stateLoader = false;
          this.enterpriseAddjob.controls['state'].setErrors({ 'incorrect': true });
        }
      });
    }

    if(this.jobId != undefined){
      if (this.ListOfAllCountries.data.length > 0) {
        this.ListOfAllCountries.data.forEach(element => {
          if (element.name == this.enterpriseAddjob.controls['country'].value) {
            this.country_Id = element.Id;
          }
        });
      }
  
      return new Promise((resolve, reject) => {
        this.enterpriseApis.LoadAllStateApi( this.country_Id).subscribe(res => {
          this.ListOfAllStates = res;
          this.allStateList=res;
          if (this.ListOfAllStates.success) {
            resolve(this.ListOfAllStates);
            this.stateLoader = false;
            this.isStateSuccess = true;
            this.enterpriseAddjob.controls['state'].setErrors({ 'incorrect': true });
          } else {
            reject("Error!");
          }
        });
      });
    }
    
  }

  LoadAllCities() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.cityLoader = true;
    // let stateId='';
    if(this.jobId == undefined){
      this.ListOfAllStates.data.forEach(element => {
        if (element.name == this.aj.state.value) {
          this.state_Id = element.Id;
        }
      });
  
      this.API.LoadAllCitiesApi(this.state_Id).subscribe(res => {
        this.ListOfAllCities = res;
        this.allCityList = res;
        if (this.ListOfAllCities.success) {
          this.cityLoader = false;
          this.isCitiesSuccess = true;
         // this.enterpriseAddjob.controls['state'].setErrors({ 'incorrect': true });
          this.enterpriseAddjob.controls['city'].setErrors({ 'incorrect': true });
          this.enterpriseAddjob.controls['state'].clearValidators();
          this.enterpriseAddjob.controls['state'].updateValueAndValidity();
        }
      });
    }

    if(this.jobId != undefined){
      this.ListOfAllStates.data.forEach(element => {
        if (element.name == this.enterpriseAddjob.controls['state'].value) {
          this.state_Id = element.Id;
        }
      });
  
      return new Promise((resolve, reject) => {
        this.enterpriseApis.LoadAllCitiesApi( this.state_Id).subscribe(res => {
  
          this.ListOfAllCities = res;
          this.allCityList=res;
          if (this.ListOfAllCities.success) {
            resolve(this.ListOfAllCities);
            this.isCitiesSuccess = true;
            this.cityLoader =false;
            this.enterpriseAddjob.controls['city'].setErrors({ 'incorrect': true });
            this.enterpriseAddjob.controls['state'].clearValidators();
            this.enterpriseAddjob.controls['state'].updateValueAndValidity();

          } else {
            reject("Error!");
          }
        });
  
      })
    }
    
  }

  SearchAllCities(val) {
    if (val.term != '' && val.term.length >= 3) {
      // let countryId = '';
      this.ListOfAllCountries.data.forEach(element => {
        if (element.name == this.enterpriseAddjob.controls['jobCountry'].value) {
          this.country_Id = element.Id;
        }
      });
      this.API.SearchAllCitiesApi(this.country_Id, val.term).subscribe(res => {
        this.SearchListOfAllCities = res;
        if (this.SearchListOfAllCities.success) {
          this.setLocationTag(this.SearchListOfAllCities.data);
          this.isSearchCitiesSuccess = true;
        }
      });
    }
  }

  check = false;
  serverError :any;
  addjob() {
console.log('==============',this.enterpriseAddjob.value)
const keepJoiningDate:any = this.enterpriseAddjob.controls['joiningDate'].value;
                
    if(this.jobId == undefined){
      if (this.userData.data.credentials.userType === 'AD') {
        let userid=this.userData.data.credentials.userId
        this.enterpriseAddjob.controls['createdBy'].setValue(userid);
      }
      if (this.userData.data.credentials.userType === 'SA') {
        
  
        this.createdby = JSON.parse((localStorage.getItem('CreatedBy')));
        this.enterpriseAddjob.controls['createdBy'].setValue(this.createdby);
  
      }
      if (this.userData.data.credentials.userType === 'EP') {
        this.createdby = JSON.parse((localStorage.getItem('CreatedBy')));
        this.enterpriseAddjob.controls['createdBy'].setValue(this.createdby);
  
        var ID=this.userData.data.credentials.userId
        this.client_id=ID.substring(6)
        this.enterpriseAddjob.controls['registerBy'].setValue(this.userData.data.credentials.userId);
  
      }
      var street1 = this.enterpriseAddjob.controls['street1'].value;
      this.enterpriseAddjob.controls['street1'].setValue(street1.trim());
      this.AuthenticationService.jwtTokenExpCheck();
      this.Ajsubmitted = true;
      let location = this.enterpriseAddjob.controls['jobLocation'].value;
      let keywordsdata = this.enterpriseAddjob.controls['skills'].value;
            if (this.enterpriseAddjob.controls['joiningDate'].value == '') {
        this.check = true;
      }
      if (this.enterpriseAddjob.controls['joiningDate'].value == "") {
        this.enterpriseAddjob.controls['joiningDate'].markAsTouched();
      }

      if (this.userData.data.credentials.userType === 'AD') {
       
        let totalOpenings = this.enterpriseAddjob.controls['totalOpenings'].value;
        let annualPayMax = this.enterpriseAddjob.controls['annualPayMax'].value;
        let jobMonths = this.enterpriseAddjob.controls['jobDuration'].value;
  
        this.jobAmount = totalOpenings * annualPayMax * jobMonths * 160
        if(this.jobAmount==NaN){
          this.jobAmount=1;
        }
        var preAllJobAmount = +this.jobAmount;
        var alltotaljobAmount = preAllJobAmount + this.totalalljobBudget;
      }
   
      if(this.userData.data.credentials.userType != 'AD'){    
        this.enterpriseAddjob.controls['jobClientId'].clearValidators();
        this.enterpriseAddjob.controls['jobClientId'].reset();
        this.enterpriseAddjob.controls['jobClientId'].updateValueAndValidity();
      }
      var state = this.enterpriseAddjob.controls['state'].value;
      var city = this.enterpriseAddjob.controls['city'].value;
      if(state == ""){
        this.enterpriseAddjob.controls['state'].setValidators(Validators.required);
      }
      if(city == ""){
        //this.enterpriseAddjob.controls['city'].setValidators(Validators.required);
        this.enterpriseAddjob.controls['city'].setErrors({ 'incorrect': true });
      }
      debugger
      this.enterpriseAddjob.controls['skills'].setValue(this.enterpriseAddjob.controls['skills'].value.toString());
      this.enterpriseAddjob.controls['jobLocation'].setValue(this.enterpriseAddjob.controls['jobLocation'].value.toString());
      if (this.enterpriseAddjob.valid) {
        this.enterpriseAddjob.controls['joiningDate'].setValue(this.shareservice.convertDateIntoUtc(this.enterpriseAddjob.controls["joiningDate"].value));  
        if (this.userData.data.credentials.userType == 'AD' && this.clientId != undefined) {
          if (this.projectId != undefined) {
           
            if (alltotaljobAmount <= this.totalexceed) {
              this.enterpriseAddjob.controls['clientId'].setValue(this.clientId);
              this.enterpriseAddjob.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
              debugger;
             
              this.API.postEnterpriseProjectJob(this.enterpriseAddjob.value, this.projectId).subscribe((res: any) => {
  
                if (res.success) {
                  if (this.userData.data.credentials.userType === 'AD') {
                    this.toastr.successToastr(res.message, '', { position: 'top-right' });
                    setTimeout(() => {
                      if (this.projectId!)
                       // this._router.navigate(['/ad/admin/projects']);
                        this._router.navigate(['/ad/admin/project/',this.clientId, this.projectId]);  
                    }, 500);
                  }
                  else if (this.userData.data.credentials.userType === 'EP') {
                    this.toastr.successToastr(res.message, '', { position: 'top-right' });
                    setTimeout(() => {
                      if (this.projectId!)
                        this._router.navigate(['ep/enterprise/myjoblist']);
                    }, 500);
                  }
                } else {
                  this.enterpriseAddjob.controls['joiningDate'].setValue(this.datePipe.transform(this.enterpriseAddjob.controls["joiningDate"].value),'YYYY-MM-DD');  
                  this.toastr.errorToastr(res.message, '', { position: 'top-right' });
                }
  
              },(errorResponse)=>{
                debugger
                this.enterpriseAddjob.controls['joiningDate'].setValue(keepJoiningDate);  
                if (errorResponse instanceof HttpErrorResponse) {
                  // const errorMessages = new Array<{ propName: string; errors: string }>();
            
                  if (errorResponse.status === 422) {
                    // TODO: extract errors here and match onto the form
                 const validationErrors = errorResponse['error']['message'].errors;

                    for (let err of validationErrors) {
                      const formControl = this.enterpriseAddjob.get(err.param);
                      if (formControl) {
                        formControl.setErrors({
                          serverError: err.msg
                        });
                      }
                    }
                  }
                }
                console.log('error===========',FormControl)
              });
            }
            else {
              this.toastr.errorToastr('total job amount less then exceed amount of job', '', { position: 'top-right' });
            }
          }
        }
  
        if (this.userData.data.credentials.userType == 'EP') {
          if (this.projectId != undefined) {

            var ID=this.userData.data.credentials.AppRefId
            this.enterpriseAddjob.controls['clientId'].setValue(ID);
            debugger
            this.enterpriseAddjob.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
            this.addJobAPI.postEnterpriseProjectJob(this.enterpriseAddjob.value, this.projectId).subscribe((res: any) => {
              if (res.success) {
                this.toastr.successToastr(res.message, '', { position: 'top-right' });
                this._router.navigate([`ep/enterprise/project/${this.projectId}`]);
              } else {
                this.toastr.errorToastr(res.message, '', { position: 'top-right' });
              }
            },(errorResponse: any) => {
              debugger
              this.enterpriseAddjob.controls['joiningDate'].setValue(keepJoiningDate); 
               
              if (errorResponse instanceof HttpErrorResponse) {
                // const errorMessages = new Array<{ propName: string; errors: string }>();
                
                if (errorResponse.status === 422) {
                  // TODO: extract errors here and match onto the form
                  const validationErrors = errorResponse['error']['message'].errors;
                  for (let err of validationErrors) {
                    const formControl = this.enterpriseAddjob.get(err.param);
                    if (formControl) {
                      formControl.setErrors({
                        serverError: err.msg
                      });
                    }
                  }
                }
              }
              console.log('error===========',FormControl)
      
             });
          }
          else {  
            this.enterpriseAddjob.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
            var ID=this.userData.data.credentials.AppRefId;
            this.enterpriseAddjob.controls['clientId'].setValue(ID);
            this.API.enterpriseJobPost(this.enterpriseAddjob.value).subscribe(res => {
              this.listShow = true;
              this.candidateList = res;
              if (this.candidateList.success) {
                this.toastr.successToastr(this.candidateList.message, '', { position: 'top-right' });
                this.Ajsubmitted = false;
                this.enterpriseAddjob.reset();
                this._router.navigate(['/ep/enterprise/myjoblist']);
              } else {
                this.toastr.errorToastr(this.candidateList.message, '', { position: 'top-right' });
              }
            });
          }
  
        }
        
        if (this.userData.data.credentials.userType == 'AD' && this.projectId == undefined) {
         this.enterpriseAddjob.controls['clientId'].setValue(this.enterpriseAddjob.controls.jobClientId.value);
          this.enterpriseAddjob.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
          this.API.enterpriseJobPost(this.enterpriseAddjob.value).subscribe(res => {
            
            this.listShow = true;
            this.candidateList = res;
            if (this.candidateList.success) {
              this.toastr.successToastr(this.candidateList.message, '', { position: 'top-right' });
              this.Ajsubmitted = false;
              this.enterpriseAddjob.reset();
              this._router.navigate(['ad/admin/dashboard']);
            } else {
              this.toastr.errorToastr(this.candidateList.message, '', { position: 'top-right' });
            }
          },(errorResponse: any) => {
            debugger
            this.enterpriseAddjob.controls['joiningDate'].setValue(keepJoiningDate); 
            
            if (errorResponse instanceof HttpErrorResponse) {
              // const errorMessages = new Array<{ propName: string; errors: string }>();
        
              if (errorResponse.status === 422) {
                // TODO: extract errors here and match onto the form
                const validationErrors = errorResponse['error']['message'].errors;
                for (let err of validationErrors) {
                  const formControl = this.enterpriseAddjob.get(err.param);
                  if (formControl) {
                    formControl.setErrors({
                      serverError: err.msg
                    });
                  }
                }
              }
            }

            console.log('error===========',FormControl)
    
           });
        }
      }
      else {
        debugger
        this.enterpriseAddjob.controls['joiningDate'].setValue(keepJoiningDate);  
        let msg = `Please Fill ${this.findInvalidControls()}.`;
        let message = msg.split(' ');
        if(message[2] == "joiningDate."){
          this.toastr.warningToastr('Please Select Start Date', 'Warning!');
        }
        else{
          this.toastr.warningToastr(msg, 'Warning!');
        }      
        return false;
      }
  
    }

    if(this.jobId != undefined){
      
      var street1 = this.enterpriseAddjob.controls['street1'].value;
      this.enterpriseAddjob.controls['street1'].setValue(street1.trim());
      var jobDescription=this.enterpriseAddjob.controls['jobDescription'].value;
      this.enterpriseAddjob.controls['jobDescription'].setValue(jobDescription.trim());
      var description=this.enterpriseAddjob.controls['jobDescription'].value;
      if(this.checkdescription==true){
      this.enterpriseAddjob.controls['description'].setValue(description);
      }else{
        this.enterpriseAddjob.controls['description'].setValue(this.JobData.data.description.trim());
      }

      var state = this.enterpriseAddjob.controls['state'].value;
      var city = this.enterpriseAddjob.controls['city'].value;
      if(state == ""){
        this.enterpriseAddjob.controls['state'].setValidators(Validators.required);
      }
      if(city == ""){
        //this.enterpriseAddjob.controls['city'].setValidators(Validators.required);
        this.enterpriseAddjob.controls['city'].setErrors({ 'incorrect': true });
      }
      this.Ajsubmitted = true;
      if (this.enterpriseAddjob.valid) {
        const skillsList = this.enterpriseAddjob.controls['skills'].value;
        const jobLocationList = this.enterpriseAddjob.controls['jobLocation'].value;
        let keyarr = '';
        let keyarr1 = '';
  
        try {
          let count = 0;
          if (skillsList.length > 1) {
            skillsList.forEach((element: any) => {
              if (count === 0) {
                keyarr = (element.value === undefined ? element : element.value);
              } else {
                keyarr = keyarr + ',' + (element.value === undefined ? element : element.value);
              }
              count++;
            });
  
          } else {
            keyarr = (skillsList[0].value === undefined ? skillsList : skillsList[0].value);
          }
        } catch (error) {
          keyarr = '';
        }
        try {
          let count1 = 0;
          if (jobLocationList.length > 1) {
            jobLocationList.forEach((element: any) => {
              if (count1 === 0) {
                keyarr1 = (element.value === undefined ? element : element.value);
              } else {
                keyarr1 = keyarr1 + ',' + (element.value === undefined ? element : element.value);
              }
              count1++;
            });
  
          } else {
            keyarr1 = (jobLocationList[0].value === undefined ? jobLocationList : jobLocationList[0].value);
          }
        } catch (error) {
          keyarr1 = '';
        }
  
        this.enterpriseAddjob.controls['jobLocation'].setValue(keyarr1);
        this.enterpriseAddjob.controls['keywords'].setValue(keyarr);
        this.enterpriseAddjob.controls['joiningDate'].setValue(this.shareservice.convertDateIntoUtc(this.enterpriseAddjob.controls['joiningDate'].value));
        if (this.enterpriseAddjob.controls['joiningDate'].value == "") {
           this.enterpriseAddjob.controls['joiningDate'].markAsTouched();
        }
  
        this.enterpriseAddjob.controls['joiningDate'].setErrors(null);
        let totalOpenings = this.enterpriseAddjob.controls['totalOpenings'].value;
        let annualPayMax = this.enterpriseAddjob.controls['annualPayMax'].value;
        let jobMonths = this.enterpriseAddjob.controls['jobDuration'].value;

        if(jobMonths==NaN){
          jobMonths=1
        }
  
        this.jobAmount = totalOpenings * annualPayMax * jobMonths * 160
  
        let preAmount = this.totalalljobBudget - this.pretotalAmount
  
        var preAllJobAmount = +this.jobAmount;
        let alltotaljobAmount = preAllJobAmount + preAmount;
        if (this.userData.data.credentials.userType === 'AD') {
         
          if (alltotaljobAmount <= this.totalexceed) {
            this.enterpriseAddjob.controls['clientId'].setValue(this.clientId);
            this.enterpriseAddjob.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
            this.enterpriseApis.enterpriseUpdateJob(this.enterpriseAddjob.value, this.jobId).subscribe((res: any) => {
              if (res.success) {
                if (this.userData.data.credentials.userType === 'AD') {
                  this.toastr.successToastr(res.message, 'Done!');
                  this._router.navigate(['/ad/admin/project/',this.clientId, this.projectId]); 
                }
                else if (this.userData.data.credentials.userType === 'EP') {
                  this.toastr.successToastr(res.message, 'Done!');
                  this._router.navigateByUrl("ep/enterprise/myjoblist");
                }
  
              } else {
                this.toastr.errorToastr(res.message, 'Error!');
                return false;
              }
            });
          } else {
            this.toastr.errorToastr('total job amount less then exceed amount of job', '', { position: 'top-right' });
          }
        }
        if (this.userData.data.credentials.userType === 'EP') {
          this.enterpriseAddjob.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
         
         debugger 
         this.enterpriseApis.enterpriseUpdateJob(this.enterpriseAddjob.value, this.jobId).subscribe(res => {

            this.updateresponse = res;
            if (this.updateresponse.success) {
              this.toastr.successToastr(this.updateresponse.message, "", {
                position: "top-right"
              });

              this.enterpriseAddjob.reset();
              this._router.navigateByUrl("/ep/enterprise/myjoblist");
              if (this.page == 1) {
                this._router.navigateByUrl("/ep/enterprise/projects");
              }
  
              else {
                this._router.navigateByUrl("/ep/enterprise/myjoblist");
              }
            } else {
              this.toastr.errorToastr(this.updateresponse.message, "", {
                position: "top-right"
              });
            }
          },(errorResponse: any) => {
            debugger
            this.enterpriseAddjob.controls['joiningDate'].setValue(keepJoiningDate); 
            
            if (errorResponse instanceof HttpErrorResponse) {
              // const errorMessages = new Array<{ propName: string; errors: string }>();
        
              if (errorResponse.status === 422) {
                // TODO: extract errors here and match onto the form
                const validationErrors = errorResponse['error']['message'].errors;
                for (let err of validationErrors) {
                  const formControl = this.enterpriseAddjob.get(err.param);
                  if (formControl) {
                    formControl.setErrors({
                      serverError: err.msg
                    });
                  }
                }
              }
            }

            console.log('error===========',FormControl)
    
           });
        }
  
      }
      else {
        debugger
        this.enterpriseAddjob.controls['joiningDate'].setValue(keepJoiningDate); 
        let msg = `Please Fill ${this.findInvalidControls()}.`;
        let message = msg.split(' ');
        if(message[2] == "joiningDate."){
          this.toastr.warningToastr('Please Select Start Date', 'Warning!');
        }
        else{
          this.toastr.warningToastr(msg, 'Warning!');
        }
        return false;
      }
  
  
    }
    
  }

  setFilter() {

  }
  tabActive(tab) {
    if (tab == '1') {
      this.tabAll = true;
      this.enterpriseAddjob.controls['favourite_status'].value('All');
      this.setFilter();

    } else {
      this.tabAll = false;
      this.enterpriseAddjob.controls['favourite_status'].value('All');
      this.setFilter();
    }
  }

  setLocationTag(data: any) {
    this.companies = [];
    data.forEach((c, i) => {
      this.companies.push(c.name);
    });

  }

  updatejob() {
    this.Ajsubmitted = true;
    let keywordsdata = this.enterpriseAddjob.controls['keywords'].value;
    let location = this.enterpriseAddjob.controls['jobLocation'].value;

    this.enterpriseApis
      .enterpriseUpdateJob(this.enterpriseAddjob.value, this.jobId)
      .subscribe(res => {

        this.updateresponse = res;
        if (this.updateresponse.success) {
          this.toastr.successToastr(this.updateresponse.message, "", {
            position: "top-right"
          });

          this.enterpriseAddjob.reset();
          this._router.navigateByUrl("/ep/enterprise/myjoblist");
        } else {
          this.toastr.errorToastr(this.updateresponse.message, "", {
            position: "top-right"
          });
        }
      });
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.enterpriseAddjob.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
        break;
      }
    }
    return invalid;
  }

  minPayValidation(e) {
    if (e.target.value !== '') {
      if (e.target.value == "0" || e.target.value == "00" || e.target.value == "000" ||
        e.target.value == "0000" || e.target.value == "00000" || e.target.value == "000000" ||
        e.target.value == "0000000" || e.target.value == "00000000" || e.target.value == "000000000" || e.target.value == "0000000000") {
        this.minError = true;
        this.enterpriseAddjob.controls['annualPayMin'].setErrors({ 'invalid': true });
      } else {
        this.minError = false;
      }
    } else {
      this.minError = false;
    }
  }


  maxPayValidation(e) {
    if (e.target.value !== '') {
      if (e.target.value == "0" || e.target.value == "00" || e.target.value == "000" ||
        e.target.value == "0000" || e.target.value == "00000" || e.target.value == "000000" ||
        e.target.value == "0000000" || e.target.value == "00000000" || e.target.value == "000000000" || e.target.value == "0000000000") {
        this.maxError = true;
      } else {
        this.maxError = false;
      }
    } else {
      this.maxError = false;
    }
  }

  compareExpLevel(e) {
    let min = parseInt(this.enterpriseAddjob.controls['requiredExpLevelMin'].value);
    let max = parseInt(this.enterpriseAddjob.controls['requiredExpLevelMax'].value);
    if (max != NaN) {
      if (min > max) {
        this.comparevalError = true;
        this.enterpriseAddjob.controls['requiredExpLevelMax'].setErrors({ 'invalid': true });
      }
      else {
        this.comparevalError = false;
      }
    }
    else {
      this.comparevalError = false;
    }
  }

  salaryValidation(e) {
    if (e.target.value != '') {
      if (e.target.value != 0) {
        console.log('fail', e.target.value)
        this.totalOpening = false;
      } else {
        this.totalOpening = true;
        console.log('come', e.target.value)
        this.enterpriseAddjob.controls['totalOpenings'].setErrors({ 'invalid': true });
      }
    } else {
      console.log('end', e.target.value)
      this.totalOpening = false;
    }
  }

  compareAnnualPay(e) {
    let min = parseInt(this.enterpriseAddjob.controls['annualPayMin'].value);
    let max = parseInt(this.enterpriseAddjob.controls['annualPayMax'].value);
    if (max != NaN) {
      if (min > max) {
        this.payComparevalError = true;
        this.enterpriseAddjob.controls['annualPayMax'].setErrors({ 'invalid': true });
      }
      else {
        this.payComparevalError = false;
      }
    }
    else {
      this.payComparevalError = false;
    }
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  removeZero(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 48 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }



  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }

  showOverRate = false;
  //  ENTERPRISE ADD NEW JOB REMAINING CODE WHEN MERGE PROJECT 
  addnewSub() {
    this.isaddNewform = true;
    this.exitsMail = false;
    this.exitsPhone = false;
  }
  clearData() {
    this.isaddNewform = false;
    this.initForm();
  }
  cancle() {
    if (this.userData.data.credentials.userType === 'AD') {
      this._router.navigate(['/ad/admin/projects']);
    }
    else if (this.userData.data.credentials.userType === 'EP') {
      this._router.navigate(['/ep/enterprise/dashboard']);
    }
  }
  addresponse: any;
  addNewSubAdmin() {
    console.log('this.subadminForm.value', this.subadminForm);
    this.submitted = true;
   
    if (this.userData.data.credentials.userType === 'AD') {
      let userid=this.userData.data.credentials.userId
      this.enterpriseAddjob.controls['createdby'].setValue(userid);
    }
    if (this.userData.data.credentials.userType === 'SA') {
      this.createdby = JSON.parse((localStorage.getItem('CreatedBy')));
      this.enterpriseAddjob.controls['createdBy'].setValue(this.createdby);
    }
    if (this.userData.data.credentials.userType === 'EP') {
      this.createdby = JSON.parse((localStorage.getItem('CreatedBy')));
      this.enterpriseAddjob.controls['createdBy'].setValue(this.createdby);

      var ID=this.userData.data.credentials.userId
      this.client_id=ID.substring(6)
      this.enterpriseAddjob.controls['registerBy'].setValue(this.userData.data.credentials.userId);

    }


    if (this.subadminForm.valid) {
       this.preLoader1 = true;
      this.loading = true;
      if (!this.exitsPhone) {
      this.subadminForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
      // this.addJobAPI.enterpriseRegisterVD(this.subadminForm.value).subscribe((res:any)=>{

      // })
      //todo
      //   this.addJobAPI.registersubenterpriseVD(this.subadminForm.value,0).subscribe((res:any)=>{
      //     if (res.success) {
      // this.vdEntpId=res.data;
      // this.subadminForm.controls['vdEntpId'].setValue(this.vdEntpId);
        this.addJobAPI.registersubenterprise(this.subadminForm.value).subscribe((res :any)=> {
       
           console.log(res);
          this.addresponse = res;
          if (this.addresponse.success &&  Number(res.data.epId) > 0) {
                this.addJobAPI.registersubenterpriseVD(this.subadminForm.value,Number(res.data.epId)).subscribe((res:any)=>{
                if (res.success) {
                  this.loading = false;
                  this.preLoader1 = false;
                  this.isaddNewform = false;
                  this.toastr.successToastr('Successfully Created !', 'Success');
                  this.selectedManagerEmail = this.addresponse.data.emailId;
                  this.subadminList();
                  this.initForm();
                  this.submitted = false;
                // this.vdEntpId=res.data;
                // this.subadminForm.controls['vdEntpId'].setValue(this.vdEntpId);
                }
                else{
                  this.loading = false;
                  this.preLoader1 = false;
                  this.toastr.successToastr(res.message, 'Error');
                }
          });

          }
          else{
          this.loading = false;
          this.preLoader1 = false;
          this.isaddNewform = false;
          this.toastr.errorToastr(this.addresponse.message, 'Error');
          }
        });
      }
    } else {
      this.loading = false;
      this.preLoader1 = false;
      return false;
    }
  }
  subadminList() {
    this.AuthenticationService.jwtTokenExpCheck();
    if(this.jobId == undefined){
      this.addJobAPI.subenterprises().subscribe(res => {
        
        this.subList = res;
        this.allsubList=res;
        let lstManager: any = [];
        if( this.subList.data.length > 0){
        lstManager = this.subList.data;
        //set the last created manager id after checking manager email 
        if (this.selectedManagerEmail != '') {
          lstManager.forEach(element => {
            if (this.selectedManagerEmail == element.emailId) {
              this.selectedManagerId = element.employeeId;
            }
          });
  
          if (this.selectedManagerId != '') {
            this.enterpriseAddjob.controls["hiringManager"].setValue(this.selectedManagerId);
          }
        }
      }
        if (this.subList.success) {
          this.asynlist = true;
        } else {
        }
      });
  
    }

    if(this.jobId != undefined){
      this.addJobAPI.subenterprises().subscribe(res => {
        this.subList = res;
        this.allsubList = res;
        console.log('data', res)
        if (this.subList.success) {
          this.asynlist = true;
        } else {
        }
      });
    }
  }
  OnChangePhoneNo(field_no, e) {
    let f_val: String = '';
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== '' && e.keyCode != 8) {
      if (field_no === 1) {
        this.subadminForm.controls['contactNo'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (field_no === 2) {
      }
    }
    else {

    }

  }
  checkmail(id, event, method) {

    if (method === 'edit') {
      id = this.EmpId;
    } else {
      id = 0;
    }

    this.addJobAPI.mailExist(id, event.target.value).subscribe((res: any) => {

      if (res.success) {
        if (res.data.exists) {
          this.exitsMail = true;
          // for check whether it is add / edit
          if (method === 'add') {
            this.subadminForm.controls['emailId'].setErrors({ 'errors': true });
          } else {
            //this.editFormSSubmit.controls['emailId'].setErrors({ 'errors': true });
          }
        } else {
          this.exitsMail = false;
        }
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
  }
  checkPhone(id, event, method) {
    if (method === 'edit') {
      id = this.EmpId;
    } else {
      id = 0;
    }
    this.addJobAPI.phoneExist(id, event.target.value).subscribe((res: any) => {
      if (res.success) {
        if (res.data.exists) {
          this.exitsPhone = true;
          if (method === 'add') {
            this.subadminForm.controls['contactNo'].setErrors({ 'error': true });
          } else {
            // this.editFormSSubmit.controls['contactNo'].setErrors({ 'error': true });
          }
        } else {
          this.exitsPhone = false;
        }
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
  }

  onBlur(jDate: String) {
    this.enterpriseAddjob.controls['joiningDate'].setValue(jDate);
  }
  dateEvents: string[] = [];
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dateEvents.push(`${type}: ${event.value}`);
    this.enterpriseAddjob.controls['joiningDate'].setValue(event.value);
    console.log(`${type}: ${event.value}`);
  }

  get f() { return this.subadminForm.controls; }

  searchStateValue = false;
  searchState(country_Id, e) {
    if (e.target.value != '') {
      this.API.searchState(country_Id, e.target.value).subscribe((res: any) => {

        if (res.data.length == 0) {
          this.searchStateValue = true;
        }
        if (res.success) {
          this.ListOfAllStates = res;
          if (res.data.length != 0) {
            this.searchStateValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {

      this.ListOfAllStates = this.allStateList;
      this.searchStateValue = false;
    }

  }



  searchCityValue = false;
  searchCity(stateId, e) {
    if (e.target.value != '') {
      this.API.searchCity(stateId, e.target.value).subscribe((res: any) => {

        if (res.data.length == 0) {
          this.searchCityValue = true;
        }
        if (res.success) {
          this.ListOfAllCities = res;
          if (res.data.length != 0) {
            this.searchCityValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.ListOfAllCities = this.allCityList;
      this.searchCityValue = false;
    }

  }

  //Open popup for update description
DescriptionPopup(){
  this.isApprovalPopUp = !this.isApprovalPopUp;
}

sTbState: string = 'invisible';
UpdateDescription(e, el){
  this.sTbState = (this.sTbState === 'invisible' ? 'visible' : 'invisible');
  if (this.sTbState === 'visible') {
    el.focus();
  }

  this.isApprovalPopUp=false;
  var description=this.JobData.data.description
  var checkdes=this.JobData.data.checkdescription
  if(checkdes != true ||checkdes != 1){
      this.checkdescription=true;
  }
}
  LoadSingleJob(jobid) { 
    this.preLoader = true;
    this.enterpriseApis.LoadSingleJob(jobid).subscribe((res: any) => {  
      if (res.success) {
        this.JobData = res;
        this.preLoader = false;
        this.setFormValue(this.JobData.data);     
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
        this.preLoader = false;
      }
    });
  }

  setFormValue(jobdata) {
 
    let location_List = jobdata.jobLocation.split(",");
    this.enterpriseAddjob.controls["jobTitle"].setValue(jobdata.jobTitle);
    this.enterpriseAddjob.controls["jobLocation"].setValue(location_List);
    this.enterpriseAddjob.controls["totalOpenings"].setValue(jobdata.totalOpenings);
    this.enterpriseAddjob.controls["requiredExpLevelMax"].setValue(jobdata.requiredExpMax);
    this.enterpriseAddjob.controls["requiredExpLevelMin"].setValue(jobdata.requiredExpMin);
    this.enterpriseAddjob.controls["jobType"].setValue(jobdata.jobType);
    if (jobdata.jobType === 'Contract Basis') {
      this.isShowJobDuration = true;
      this.enterpriseAddjob.controls["contractRate"].setValue(jobdata.contractRate);
    } else {
      this.isShowJobDuration = false;
    }
debugger;
    this.joiningdateshow =new Date(jobdata.joiningDate).toLocaleString();
    this.enterpriseAddjob.controls["jobDuration"].setValue(parseInt(jobdata.jobDuration));
    this.enterpriseAddjob.controls["hiringManager"].setValue(jobdata.hiringManager);
    this.enterpriseAddjob.controls["hiringManagerName"].setValue(jobdata.hiringManagerName);
    this.enterpriseAddjob.controls["annualPayMax"].setValue(jobdata.annualPayMax);
    this.enterpriseAddjob.controls["annualPayMin"].setValue(jobdata.annualPayMin);
    this.enterpriseAddjob.controls["currency"].setValue(jobdata.currency);
    this.enterpriseAddjob.controls["joiningDate"].setValue(this.datePipe.transform(jobdata.joiningDate, "yyyy-MM-dd"));
    if (jobdata.overTimeAllow == true) {
      this.enterpriseAddjob.controls["overTimeAllow"].setValue('Yes');
      this.isShow = true;
    }
    else {
      this.enterpriseAddjob.controls["overTimeAllow"].setValue('No');
      this.isShow = false;
    }

    this.enterpriseAddjob.controls["overTimePayRate"].setValue(jobdata.overTimePayRate);
    this.enterpriseAddjob.controls["overTimeMax"].setValue(jobdata.overtimeMaximum);
    this.enterpriseAddjob.controls["totalJobBudget"].setValue(jobdata.totalJobBudget);
    this.enterpriseAddjob.controls["jobDescription"].setValue(jobdata.jobDescription);

    this.enterpriseAddjob.controls["jobCountry"].setValue(jobdata.jobCountry);


    let keywords_List = jobdata.keywords !== '' ? jobdata.keywords.split(",") : jobdata.keywords;
    this.enterpriseAddjob.controls["skills"].setValue(keywords_List);

    this.enterpriseAddjob.controls["note"].setValue(jobdata.note);

    this.enterpriseAddjob.controls["postalcode"].setValue(jobdata.postalcode);
    this.enterpriseAddjob.controls["street1"].setValue(jobdata.street1);
    this.enterpriseAddjob.controls["street2"].setValue(jobdata.street2);

    this.LoadAllCountries().then(countryData => {
      this.ListOfAllCountries = countryData;
      this.enterpriseAddjob.controls["jobCountry"].setValue(jobdata.jobCountry);
    })

    this.LoadAllCountries().then(countryData => {
      this.ListOfAllCountries = countryData;
      this.enterpriseAddjob.controls["country"].setValue(jobdata.country);
      //this.enterpriseAddjob.controls["jobCountry"].setValue(jobdata.jobCountry);
      this.LoadAllState()
        .then(stateList => {

          let stateId;
          this.ListOfAllStates = stateList;
          this.enterpriseAddjob.controls["state"].setValue(jobdata.state);
          if (this.ListOfAllStates.data.length > 0) {
            this.ListOfAllStates.data.forEach(element => {
              if (element.name == this.enterpriseAddjob.controls['state'].value) {
                stateId = element.Id;
              }
            });
          }
          this.LoadAllCities().then(cities => {

            this.ListOfAllCities = cities;
            this.enterpriseAddjob.controls["city"].setValue(jobdata.city);
          }).catch(err => {
            this.toastr.errorToastr(err, "Error!");
          });
        }).catch(err => {
          this.toastr.errorToastr(err, "Error!")
        });
    }).catch(err => {

    });
    let prejobDuration = this.enterpriseAddjob.controls["jobDuration"].value
    let premaxPay = this.enterpriseAddjob.controls["annualPayMax"].value
    let pretotalOpening = this.enterpriseAddjob.controls["totalOpenings"].value

    this.pretotalAmount = prejobDuration * premaxPay * pretotalOpening * 160
  }

  overtimeError=false;
  checkOvertimeValue(){
    let min = parseInt(this.enterpriseAddjob.controls['overTimePayRate'].value);
    let max = parseInt(this.enterpriseAddjob.controls['overTimeMax'].value);
    if (max != NaN) {
      if (min > max) {
        this.overtimeError = true;
        this.enterpriseAddjob.controls['overTimeMax'].setErrors({ 'invalid': true });
      }
      else {
        this.overtimeError = false;
        // this.enterpriseAddjob.controls['overTimeMax'].setErrors({ 'invalid': false });
      }
    }
    else {
      this.overtimeError = false;
    }
  }
  // 20200929: Akhilesh, Special charcter validation, jira no 70
  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;   
     if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
       this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
       return false
      }
  }

  loadClients(){

    this.AuthenticationService.jwtTokenExpCheck();
     this.addJobAPI.getClients().subscribe((res: any) => {
        //this.preloader = false;
        if (res.success) {
          if(res.data.length > 0){
          console.log('res.data',res.data);
          this.clients = res.data;
          this.allclientList1 = this.clients;
        }
        } else {
          console.log(res);
        }
      });
   }

     loadAllUserTypewise(user_type) {
     
      this.AuthenticationService.jwtTokenExpCheck();
      this.personLoad = true;
      try {
        this.API.loadAllUserTypeWise(user_type).pipe().subscribe((res: any) => {
          this.personLoad = false;
          if (res.success) {
            if(res.data.length > 0){
            this.AllUsers = res.data;
            this.allclientList = res.data;
            if (this.AllUsers.length > 0) {
             // this.paginator.length = this.AllUsers[0].totalCount;
            }
          }
          } else {
            this.toastr.errorToastr(res.message, 'Error!');
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

 //20201105:Akhilesh,Search country api
  searchCountryValue = false;
  searchCurrentCountry(e) { 
    if (e.target.value != '') {
      this.addJobAPI.searchCountry(e.target.value).subscribe((res: any) => {
        if (res.data.length == 0) {
          this.searchCountryValue = true;
        }
        if (res.success) {
          this.ListOfAllCountries = res;
          if (res.data.length != 0) {
            this.searchCountryValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.ListOfAllCountries = this.allCountryList;
      this.searchCountryValue = false;
    }
  }
  

  searchCountry(e) { 
    if (e.target.value != '') {
      this.addJobAPI.searchCountry(e.target.value).subscribe((res: any) => {
        if (res.data.length == 0) {
          this.searchCountryValue = true;
        }
        if (res.success) {
          this.listofAllCountry = res;
          if (res.data.length != 0) {
            this.searchCountryValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.listofAllCountry = this.allCountryList;
      this.searchCountryValue = false;
    }
  }
  //20201105:Akhilesh,Search country api
  searchManagerValue = false;
  searchmanager(e) { 

    if (e.target.value != '') { 
      //Manu Datta: 20210324, VerisourceBugSheetAfterMerge/commonLogin 480
      //Manu Datta: 20210327, VerisourceBugSheetAfterMerge/commonLogin 48
      if (this.userData.data.credentials.userType === 'AD') {       
        if(this.clientId){
          this.clientIdSearch = this.clientId
        }else{
          this.clientIdSearch = this.enterpriseAddjob.controls['jobClientId'].value ;      
        }
      }else{
        if(this.clientId){
          this.clientIdSearch = this.clientId  
        }else{
          this.clientIdSearch = this.userData.data.credentials.AppRefId        
        }
      }
      
      this.addJobAPI.subenterprises_search(e.target.value,this.clientIdSearch).subscribe((res: any) => {
        if (res.data.length == 0) {
          this.searchManagerValue = true;
        }
        if (res.success) {
          this.listofManager = res.data;
          if (res.data.length != 0) {
            this.searchManagerValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.listofManager = this.searchManagerList;
      this.searchManagerValue = false;
    }
  }

  //20201105:Akhilesh,Search client api
  searchclientValue = false;
  searchClient(e) { 
 
    //Manu Datta: 20210224, VerisourceBugSheetAfterMerge/commonLogin 404
    let mspId: any;
    if (this.userData.data.credentials.CreatedBy){
      mspId = this.userData.data.credentials.CreatedBy;
    }else{
      mspId = this.userData.data.credentials.userId;
    }
    if (e.target.value != '') {
      this.addJobAPI.get_client_search(e.target.value,mspId).subscribe((res: any) => {
        if (res.data.length == 0) {
          this.searchclientValue = true;
        }
        if (res.success) {
          this.clients = res.data;
          console.log('-=-===',this.AllUsers)
          if (res.data.length != 0) {
            this.searchclientValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.clients = this.allclientList1;
      this.searchclientValue = false;
    }
  }
  bindmanager(){
    let enterpriseId: any = 0;  
    // this.clients.forEach(element => {
    //   if (this.enterpriseAddjob.controls['jobClientId'].value === element.enterpriseId) {
    //     enterpriseId=element.enterpriseId;
    //     this.searchenterpriseId=element.enterpriseId;
    //   }
    // });
    this.addJobAPI.hiringManager(this.enterpriseAddjob.controls['jobClientId'].value).subscribe(res => {
  
      //this.subManager = res;
      this.subManager=res
      if (this.subManager.data.length>0) {
        this.listofManager = this.subManager.data;
        this.searchManagerList = this.subManager.data;
      } else {
        this.listofManager = [];
      }
    });
  }


  getClientDeatils() {
    this.AuthenticationService.jwtTokenExpCheck();
    if(this.clientId != undefined){
      this.projectService.enterprisesDetailview(this.clientId).subscribe(res => {
        this.isTrue = true;
        this.clientdetails = res.enterprisedetails;        
      });
    }
    else{
     this.isTrue = false;
    }
  } 
  bindmanagerByEnterpriseId(enterpriseId){

    // let enterpriseId: any = 0;  
    // this.clients.forEach(element => {
    //   if (this.enterpriseAddjob.controls['jobClientId'].value === element.enterpriseId) {
    //     enterpriseId=element.enterpriseId;
    //     this.searchenterpriseId=element.enterpriseId;
    //   }
    // });
    this.addJobAPI.hiringManager(enterpriseId).subscribe(res => {
      //this.isEditProjectJob=false;
      //this.subManager = res;
      this.subManager=res
      if (this.subManager.data.length>0) {
        this.listofManager = this.subManager.data;
        this.searchManagerList = this.subManager.data;
      } else {
        this.listofManager = [];
      }
    });
  }
}



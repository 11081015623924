import { Component, OnInit, Inject } from '@angular/core';
import { ShareService } from '../share.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import {AuthenticationService } from '../../app.service';

 

@Component({
  selector: 'app-pool-job-details',
  templateUrl: './pool-job-details.component.html',
  styleUrls: ['./pool-job-details.component.css']
})
export class PoolJobDetailsComponent implements OnInit {
  paginator = {
    pageSize: 10,
    length: 0,
    pageIndex: 1,
    pageSizeOptions: [10, 20, 50, 100]
  };
  actionName: any;
  seeker: any;
  poolid: any;
  pooljobDetailsForm: FormGroup;
  preloader: boolean;
  statusValue ='';
  pooljobDetailslist: any;
  userData:any={};
  user='';

  constructor(private tableService: ShareService, private _formBuilder: FormBuilder, private tostr: ToastrManager,
              @Inject(MAT_DIALOG_DATA) public data: any, private _router: Router, public dialog: MatDialog,
              private AuthenticationService: AuthenticationService,
              ) {
    this.AuthenticationService.jwtTokenExpCheck();
   
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    if(this.userData.data.credentials.userType === 'EP'){
     this.user = 'VMS';
    }
    if(this.userData.data.credentials.userType === 'AD'){
      this.user = 'VMS';
    }
    this.filterInit();
    this.loadjobseekerlist();
  }
  checkaction() {


    if (this.data.pk_pool_Id === undefined) {
      this.actionName = 'seeker';
      this.seeker = this.data.jobseekerId;
      this.poolid = null;
    } else {
      this.actionName = 'pool';
      this.poolid = this.data.pk_pool_Id;
      this.seeker = null;
    }

  }

  filterInit() {
    if (this.data.pk_pool_Id === undefined) {
      this.actionName = 'seeker';
      this.seeker = this.data.jobseekerId;
      this.poolid = '';
    } else {
      this.actionName = 'pool';
      this.poolid = this.data.pk_pool_Id;
      this.seeker = '';
    }

    this.pooljobDetailsForm = this._formBuilder.group({
      PageSize: 10,
      PageNumber: 1,
      pk_pool_Id: this.poolid,
      action: this.actionName,
      jobseekerId: this.seeker,
      typejob: this.user
    });
  }
  ngOnInit() {
  }
  loadjobseekerlist() {
    
    this.preloader = true;
    this.tableService.poolJobDetailspoolIdList(this.pooljobDetailsForm.value).pipe(first()).subscribe(res => {
      if (res.success) {
        this.pooljobDetailslist = res.data;
        this.preloader = false;
        if (this.pooljobDetailslist.length > 0) {
          this.paginator.length = this.pooljobDetailslist[0].totalCount;
        }
      }
    });
  }

  changeLoadType(type:string){
    this.AuthenticationService.jwtTokenExpCheck();
    if(type=='VMS'){
      this.user='VMS';
      this.pooljobDetailslist =[];
        this.pooljobDetailsForm.controls['typejob'].setValue('VMS');
        this.loadjobseekerlist();
    }
    if(type=='EP'){
      this.user = 'EP';
      this.pooljobDetailslist =[];
      this.pooljobDetailsForm.controls['typejob'].setValue('EP')
      this.loadjobseekerlist();
    }
     
  }
  
  getPaginatorData(e: { pageSize: number; pageIndex: number; }) {
    this.paginator.pageIndex = e.pageIndex;
    this.paginator.pageSize = e.pageSize;
    this.pooljobDetailsForm.controls.PageSize.setValue(e.pageSize);
    this.pooljobDetailsForm.controls.PageNumber.setValue(e.pageIndex + 1);
    this.loadjobseekerlist();
  }
  openjobSeekerStafingStatus(record: any) {
   
    if(this.userData.data.credentials.userType === 'EP'){
      if(this.user == 'VMS'){
        this._router.navigate(['/ep/enterprise/JobseekerStaffingStatus/', record.requestId, record.poolId, record.jobType]);
      }
      if(this.user == 'EP'){
        this._router.navigate(['/ep/enterprise/JobseekerStaffingStatus/', record.jobId, record.poolId, record.jobType]);
      }
    }
    if(this.userData.data.credentials.userType === 'AD'){
      if(this.user == 'VMS'){
      this._router.navigate(['/ad/admin/JobseekerStaffingStatus/', record.requestId, record.poolId, record.jobType]);
      }
      if(this.user == 'EP'){
        this._router.navigate(['/ad/admin/JobseekerStaffingStatus/', record.jobId, record.poolId, record.jobType]);
      }
    }
  }
}
 
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/app.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { NewEnterpriseService } from 'src/app/enterprise/newenterprise.service';

export interface DialogData {
  supplierId: string;
  firstName: string;
  lastName: string;
  contactNo: string;
  emailId: string;
  address: string;
  companyName: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}
@Component({
  selector: 'app-supplier-pop-up',
  templateUrl: './supplier-pop-up.component.html',
  styleUrls: ['./supplier-pop-up.component.css']
})
export class SupplierPopUpComponent implements OnInit{
  baseUrl = '';
  isDataready = false;
  supplierId:any;
  countryName = '';
  stateName = '';
  countryList: any = [];
  stateList: any = [];
  constructor(
    private api: NewEnterpriseService,
    private dialogRef: MatDialogRef<SupplierPopUpComponent>,
    private AuthenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.AuthenticationService.jwtTokenExpCheck();
    }

  ngOnInit() {  
    debugger;
    this.api.getSupplierDetails(this.data.supplierId).subscribe((res:any) => {
      if (res.status == 200) {
        if(res.body){
          this.countryList = res.body.countries;
          this.stateList = res.body.states;
          if(res.body['supplier'].country){
            this.countryName = this.countryList.filter(x=>x.id == res.body['supplier'].country)[0].country_Name;
          }
          if(res.body['supplier'].state){
            this.stateName = this.stateList.filter(x=>x.id == res.body['supplier'].state)[0].state_Name;
          }     
          console.log('res.data.staffingdetails',res.body);
          this.data = res.body.supplier;
        }
       
      }
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}


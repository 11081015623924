import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../app.service';
import { ToastrManager } from 'ng6-toastr-notifications';
@Component({
  selector: 'app-auth-success',
  templateUrl: './auth-success.component.html',
  styleUrls: ['./auth-success.component.css']
})
export class AuthSuccessComponent implements OnInit {
  id: String = '';
  year: number = new Date().getFullYear();
  constructor(
    private _routerActive: ActivatedRoute,
    private _toastr: ToastrManager,
    private authApi: AuthenticationService
  ) {
    this.id = this._routerActive.snapshot.params['id'];
  }

  ngOnInit() {
    /*this.authApi.userVarification(this.id).subscribe((res: any) => {
      console.log(res);
      if (res.success) {
        this._toastr.successToastr(res.message, 'Success');
      } else {
        this._toastr.errorToastr(res.message, 'Error');
      }
    });*/
  }

}

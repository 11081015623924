import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { EnterpriseService } from '../enterprise.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ShareService } from 'src/app/share-module/share.service';

export interface DialogData {
  invoiceId: any;
  amount: number;
}

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment-popup.component.html',
  styleUrls: ['./payment-popup.component.css']
})
export class PaymentPopupComponent implements OnInit {
  cardFrm: FormGroup;
  submitted: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<PaymentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formbuilder: FormBuilder,
    private EpAPI: EnterpriseService,
    private toatr: ToastrManager,
    private shareservice:ShareService
  ) {
    console.log(this.data);
    this.formInit();
  }

  ngOnInit() {
    console.log('Data From Invoice page:', this.data);
  }

  onNoClick(): void { }

  formInit() {
    this.cardFrm = this.formbuilder.group({
      cardNumber: ['', Validators.required],
      cardName: ['', Validators.required],
      exp_month: ['', Validators.required],
      exp_year: ['', Validators.required],
      cvv: ['', Validators.required],
      amount: this.data.amount,
      invoiceNos: [this.data.invoiceId],
      dateofPayment:[''],
      createdDate:['']
    });
  }
  get fn() {
    return this.cardFrm.controls;
  }
  paynow() {
    console.log("Card Name :", this.cardFrm.value);
    this.submitted = true;
    if (this.cardFrm.valid) {
      this.cardFrm.controls['dateofPayment'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
      this.cardFrm.controls['dateofPayment'].setErrors(null);
      this.EpAPI.payment(this.cardFrm.value).subscribe((res: any) => {
        if (res.success) {
          this.toatr.successToastr(res.message, 'Success!');
          this.dialogRef.close();
        } else {
          this.toatr.errorToastr(res.message, 'Error!');

        }
      });


    } else {
      return false;

    }
  }
}

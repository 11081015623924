import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.css']
})
export class TermsConditionComponent implements OnInit {
  baseUrl = '';
  constructor(private _titleService : Title) { 
    this.baseUrl = "/";
    var newTitle = 'Verisource | Terms and Conditions ';
    this._titleService.setTitle( newTitle );
  }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { BrowseJobComponent } from './browse-job/browse-job.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareService } from './share.service';
import { UpdateCandidateComponent } from './update-candidate/update-candidate.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AppNotificationComponent } from './app-notification/app-notification.component'
import { JobseekerService } from '../JobSeeker/jobseeker.service';
import { StickyModule } from 'ng2-sticky-kit';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GroupJobseekerComponent } from './group-jobseeker/group-jobseeker.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { AddpoolSeekerComponent } from './addpool-seeker/addpool-seeker.component';
import { InvitationComponent } from './invitation/invitation.component';
import { PoolJobSeekerComponent } from './pool-job-seeker/pool-job-seeker.component';
import { PoolJobDetailsComponent } from './pool-job-details/pool-job-details.component';
import { JobseekerStaffingStatusComponent } from './jobseeker-staffing-status/jobseeker-staffing-status.component';
import { InvitaionLinkResultComponent } from './invitaion-link-result/invitaion-link-result.component';
//import { StaffingInvitationLinkComponent } from './staffing-invitation-link/staffing-invitation-link.component';
import { ExcelService } from '../admin/service/excel.service';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectComponent } from './project/project.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { SubscribeUnsubscribeComponent } from './subscribe-unsubscribe/subscribe-unsubscribe.component';
import { ProjectapprovalComponent } from './projectapproval/projectapproval.component';
import { ProjectbudgetapprovalComponent } from './projectbudgetapproval/projectbudgetapproval.component';
import { AddNewJobEnterpriseComponent } from './add-new-job-enterprise/add-new-job-enterprise.component';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditJobListComponent } from './edit-job-list/edit-job-list.component';
import { StaffingInvitationLinkComponent } from './staffing-invitation-link/staffing-invitation-link.component';
import { AddContactUsComponent } from './add-contact-us/add-contact-us.component';
import { TimesheetjobseekerComponent } from '../share-module/timesheetjobseeker/timesheetjobseeker.component'
import { ClienttimesheetdetailsComponent } from '../share-module/clienttimesheetdetails/clienttimesheetdetails.component'
import { TimesheetsupplierComponent } from '../share-module/timesheetsupplier/timesheetsupplier.component';

import { ResetPasswordAfterExpireComponent } from './reset-password-after-expire/reset-password-after-expire.component';
import { SuuplierPopupComponent } from './popUp/addSupplier/suuplier-popup/suuplier-popup.component';
import { ClientPopupComponent } from './popUp/addClient/client-popup/client-popup.component';
import { TimesheetdetailsComponent } from './timesheetdetails/timesheetdetails.component'
import { TimesheetFilterComponent } from '../share-module/timesheet-filter/timesheet-filter.component';
import { InvoicereportComponent } from './invoicereport/invoicereport.component';
import { InvoiceDetailComponent } from './popUp/invoice-detail/invoice-detail.component';
import { TimesheetinvoiceComponent } from './timesheetinvoice/timesheetinvoice.component';
import { RaiseInvoicereportComponent } from './raise-invoicereport/raise-invoicereport.component';
import { AddNewJobComponent } from './Job Request/add-new-job/add-new-job.component';
import { PreviewJobComponent } from './Job Request/preview-job/preview-job.component';
import { AddSupplierComponent } from './popUp/NextGen/add-supplier/add-supplier.component';
import { HasPermissionDirective } from './directive/permission-button.directive';
import { ErrorPageComponentComponent } from './NextGen/error-page-component/error-page-component.component';
import {HttpService} from './service/httpservice.service';




@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        MatSelectModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        StickyModule,
        MatDialogModule,
        MatNativeDateModule,
        MatRadioModule,
        MatButtonModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatStepperModule,
        MatIconModule,
        MatTabsModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        TagInputModule
    ],
    declarations: [EditProfileComponent, ProjectsComponent, ProjectComponent, BrowseJobComponent, InvitaionLinkResultComponent, StaffingInvitationLinkComponent,
        UpdateCandidateComponent, ContactUsComponent, AppNotificationComponent, GroupJobseekerComponent, AddGroupComponent,
        AddpoolSeekerComponent, InvitationComponent, PoolJobSeekerComponent, PoolJobDetailsComponent, JobseekerStaffingStatusComponent,
        FeedbackComponent, SubscribeUnsubscribeComponent,
        AddContactUsComponent, ProjectapprovalComponent, ProjectbudgetapprovalComponent, AddNewJobEnterpriseComponent, EditJobListComponent,
        ProjectapprovalComponent, ProjectbudgetapprovalComponent, AddNewJobEnterpriseComponent, EditJobListComponent, TimesheetjobseekerComponent, ClienttimesheetdetailsComponent, TimesheetsupplierComponent, ResetPasswordAfterExpireComponent, SuuplierPopupComponent, ClientPopupComponent, TimesheetdetailsComponent, TimesheetFilterComponent, InvoicereportComponent,
        InvoiceDetailComponent, TimesheetinvoiceComponent, RaiseInvoicereportComponent, AddNewJobComponent, PreviewJobComponent, AddSupplierComponent, HasPermissionDirective, ErrorPageComponentComponent
    ],
    exports: [BrowseJobComponent, UpdateCandidateComponent, ContactUsComponent, AppNotificationComponent, AddNewJobComponent,HasPermissionDirective],
    providers: [ShareService, JobseekerService, MatDatepickerModule, ExcelService,HttpService]
})
export class ShareModuleModule { }

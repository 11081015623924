
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ShareService } from '../../share.service';
import { AuthenticationService } from 'src/app/app.service';
@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.css']
})
export class InvoiceDetailComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  paginator = {
    pageSize: 10,
    length: 0,
    pageIndex: 1,
    pageSizeOptions: [10, 20, 50, 100]
  };
  actionName: any;
  seeker: any;
  poolid: any;
  timesheetform: FormGroup;
  preloader: boolean;
  statusValue ='';
  dataInvoiceFor: any;
  userData:any={};
  user='';

  constructor(private tableService: ShareService, private _formBuilder: FormBuilder, private tostr: ToastrManager,
              @Inject(MAT_DIALOG_DATA) public data: any, private _router: Router, public dialog: MatDialog,
              private AuthenticationService: AuthenticationService,
              ) {
    this.AuthenticationService.jwtTokenExpCheck();
    
    this.userData = JSON.parse(window.localStorage.getItem('udata'));

    this.timesheetform = this._formBuilder.group({
      search: [''],
      PageSize: 5,
      PageNumber: 1,
      employeeId:'',
      manager:'',
      empName:'',
      projName:'',
      fromdate:'',
      status:'',
      jobid:'',
      client:'',
      projectid:'',
      todate:'',
      record:this.data,
      type:1
    });
    this.loadInvoiceFor();
   
  }

  
  ngOnInit() {
  }
  loadInvoiceFor() {
    if(this.searchButton == true){
      this.timesheetform.controls['PageNumber'].setValue(1);
    }
    this.tableService.loadInvoiceFor(this.timesheetform.value,1).pipe(first()).subscribe(res => {
      if (res.success) {
        this.dataInvoiceFor = res.data;
        if (res.data.length > 0) {
          this.paginator.length = res.data[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }
        this.searchButton = false;
        
      }
    });
  }

  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  
  searchButton = false;
  loadData(){
  this.searchButton= true;
  this.loadInvoiceFor();
}
  setpaggination(e) {
    this.searchButton = false;
    this.timesheetform.controls['PageSize'].setValue(e.pageSize);
    this.timesheetform.controls['PageNumber'].setValue(e.pageIndex + 1);
    this.loadInvoiceFor();
  }

  resetList() {
  
    this.timesheetform = this._formBuilder.group({
      search: [''],
      PageSize: 5,
      PageNumber: 1,
      employeeId:'',
      manager:'',
      empName:'',
      projName:'',
      fromdate:'',
      status:'',
      jobid:'',
      client:'',
      projectid:'',
      todate:'',
      record:this.data,
      type:1
    });
    this.paginator.pageSize=5;
    this.searchButton = false;
    this.loadInvoiceFor();
    this.paginators.pageSize = 5;
    this.paginators.pageIndex = 0;
  }

}

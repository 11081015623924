import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
//import { environment } from '../../environments/environment'
import { Title } from '@angular/platform-browser';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { EnterpriseService } from '../enterprise.service';
import { environment } from '../../../environments/environment';
import {AuthenticationService } from '../../app.service';
import { ShareService } from 'src/app/share-module/share.service';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';
import { TableService } from 'src/app/admin/service';
import { NewEnterpriseService } from '../newenterprise.service';

@Component({
  selector: 'app-staffing-agency-details',
  templateUrl: './staffing-agency-details.component.html',
  styleUrls: ['./staffing-agency-details.component.css']
})
export class StaffingAgencyDetailsComponent implements OnInit {
  defaultPageSize=5;
  defaultPageNumber=1;
  paginator = {
    pageSize: this.defaultPageSize,
    length: 0,
    pageSizeOptions: [5, 10, 15, 20],
    pageIndex:0
    
  }
  allCityList: any = [];
  allStateList: any = [];
  allCountryList:any =[];
  basicupdate: any;
  isEditform = false;
  islinkform  = false;
  preloader: boolean = false;
  isverifyBtn: boolean = false;
  issuccess: any;
  staffAgID: String = "";
  staffAData: any;
  ProfileData:any;
  staffADataDetails: any;
  staffAdmindata: any;
  detailPage: boolean = false;
  jobseekerId: String = "";
  synch: boolean = false;
  isCollapsedMenu: boolean = false;
  s_img_Path: string = '';
  c_img_Path: string = '';
  showData:boolean=false;
  showCandidateData:boolean=true;
  addressFrom: FormGroup;
  isAddressFrom  = false;
  Profilelist: any;
  countryList: any = [];
  allcountryList: any = [];
  stateLoader = false;
  stateList: any = [];
  allstateList: any = [];
  cityLoader = false;
  CountryId: any = 0;
  stateId: any = 0;
  resetForm : FormGroup;
  notMatch: boolean = false;
  isAddform: boolean = false;
  cityId: any = 0;
  addFrmUpdateResp: any;
  submitted = false;
  submitted2 = false;
  staffEditForm2: FormGroup;
  staffEditFormDetails: FormGroup;
  loading:boolean=false;
  imgName: any;
  isImgExits: boolean = false;
  userData: any;
  profileLoader: boolean = false;
  resImageData: any;
  imgProfile: any;
  userData1: any;
  imageFileEvent: any;
  countryName = '';
  stateName = '';
  mobMenulist() {
    this.isCollapsedMenu = !this.isCollapsedMenu;
  }

  constructor(private _router: Router, private _paramVal: ActivatedRoute, private tostr: ToastrManager,private _titleService:Title,
  private AuthenticationService: AuthenticationService,
  private _formBuilder: FormBuilder,private api: EnterpriseService,private shareservice:ShareService,
  private staffAPI: staffingAgencyServices,private tableService:TableService, private newEnterpriseApi:NewEnterpriseService) {

    this.AuthenticationService.jwtTokenExpCheck();
    this.userData1 = JSON.parse(window.localStorage.getItem('udata'));
    this._paramVal.queryParams.subscribe(params => {
      this.staffAgID = params['id'];
    });
    this.staffEditForm2 = this._formBuilder.group({
      'twitterUrl': [''],
      'facebookUrl': [''],
      'linkdinUrl': ['']
    });

    
         
    this.staffEditFormDetails = this._formBuilder.group({
      'stId':0,
      'empId':0,
      'companyName': ['',[Validators.required]],
      'firstName': ['', [Validators.required]],
      'middleName': [''],
      'lastName': ['',[Validators.required]],
      'contactNo': ['', Validators.required],
      // 'modifiedBy':[''],
      'emailId': ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      'website': ['', [Validators.required, Validators.pattern("(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
    });

    //for title set 
    var newTitle = 'Verisource | Supplier Details';
    this._titleService.setTitle( newTitle );
    // this.staffAgID = this._paramVal.snapshot.params['id'];

    this.addressFrom = this._formBuilder.group({
      'country': ['', Validators.required],
      'state': ['', Validators.required],
      'city': ['', Validators.required],
      'zipCode': ['', Validators.required],
      'street': ['',  [Validators.required]]
    });

    this.resetForm = this._formBuilder.group({
      
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      re_password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      createdDate:['']
    });
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
  }

  get sf() { return this.staffEditFormDetails.controls; }
  
  ngOnInit() {
    //this.staffAgID = this._paramVal.snapshot.params['id'];
    this.loadStaffingDetails();
    this.profileImage();
    this.loadAllstaffingcandidates(this.defaultPageNumber, this.defaultPageSize);
  }

  profileImage() {  
     
    this.preloader = true;    
      //let id = this.userData.data.credentials.userId;
      this.api.profileImageVD(this.staffAgID).subscribe(res => {        
        
        this.ProfileData = res; 
        if(this.ProfileData.data.length > 0){   
        var profileImagePath = this.s_img_Path =this.ProfileData.data[0].profileImagePath;         
        localStorage.setItem('profileImagePath', profileImagePath);      
       
        this.preloader = false;
        }
      
      });
    
  }

  loadStaffingDetails() {
    this.showData=false;
    this.preloader = true;
    debugger
    this.newEnterpriseApi.getSupplierDetails(this.staffAgID).subscribe((res:any) => {    
      console.log('===========,',res)
      if (res.status == 200) {
        if(res.body){
          this.showData=true;
          this.staffAData = res.body.supplier;
          this.countryList = res.body.countries;
          this.allcountryList = res.body.countries;
          this.stateList = res.body.states;
          this.allstateList = res.body.states;
          if(res.body['supplier'].country != null){
            this.countryName = this.countryList.filter(x=>x.id == Number(this.staffAData.country))[0].country_Name;
          }
          if(res.body.states != null){
            this.stateName = this.stateList.filter(x=>x.id == Number(this.staffAData.state))[0].state_Name;
          }
          console.log('this.staffAData', this.staffAData)
          this.preloader = false;
          if (this.staffAData.profile_status == 'Verified') {
            this.isverifyBtn = true;
          }
          else if (this.staffAData.profile_status == 'Non-Verified') {
            this.isverifyBtn = false;
          }
        }
      }
    });
  }

  loadAllstaffingcandidates(pageno, pagesize) {
    
    this.showCandidateData=false;
    debugger
    this.api.staffingcandidates(pageno, pagesize, this.staffAgID).pipe(first()).subscribe(res => {
      if (res.success) {
        this.showCandidateData=true;
        this.staffAdmindata = res.data;
        if (this.staffAdmindata.length > 0) {
          this.paginator.length = this.staffAdmindata[0].totalCount;
          
        }
      }
    });
  }

  getchildpaginatorData(e) {
    
    this.paginator.pageSize=e.pageSize;
    this.paginator.pageIndex=e.pageIndex;
    this.loadAllstaffingcandidates(e.pageIndex + 1, e.pageSize)
    console.log(e.pageIndex);
    console.log(e.pageSize);

  }
//this function is not use in this component 
  // viewData(id, e) {
  //   this.synch = false;
  //   e.preventDefault();
  //   this.detailPage = true;
  //   this.api.candidateDetails(id).pipe(first()).subscribe(stffingagency => {
  //     this.staffADataDetails = stffingagency;
  //     this.c_img_Path = `${this.staffADataDetails.filePath}${this.staffADataDetails.profileImage}`;
  //     this.synch = true;
  //   });
  // }

  download_doc(data, val) {
    if (val == 'resume') {
      window.location.href = `${data.resumepath}`;
    }
    if (val = 'voice') {
      window.location.href = `${data.voicesamplepath}`;
    }
  }

  jobseekerView(id) {
    this._router.navigate(['/ep/enterprise/jobseeker-view', id])
  }

  verifystaffingAgency(staff) {
    debugger
    this.newEnterpriseApi.VerifyUser(staff,'SA').pipe(first()).subscribe((res:any) => {
      if(res.status == 200){
        if(res.body){
          this.tostr.successToastr('User verfied successfully.', "Success!");
          this.issuccess = false;
          this.isverifyBtn = true;
        }
      } else {
        this.tostr.errorToastr('User not verfied successfully.', 'OPPS!')
      }
    });    
  }

  get addFrm() { return this.addressFrom.controls; }

  modalStaffingAddressPopup() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.addressFrom.controls['country'].setValue(Number(this.staffAData.country));
    this.addressFrom.controls['state'].setValue(Number(this.staffAData.state));
    this.addressFrom.controls['city'].setValue(this.staffAData.city);
    this.addressFrom.controls['zipCode'].setValue(this.staffAData.zipCode);
    this.addressFrom.controls['street'].setValue(this.staffAData.street);
    this.isAddressFrom = !this.isAddressFrom;
}

LoadAllState(){
  debugger
  this.stateLoader = true;
  return new Promise((resolve, reject) => {
    debugger;
    this.newEnterpriseApi.LoadAllState(this.addressFrom.controls['country'].value).subscribe((res:any) => {
      if(res.status == 200) {
        if(res.body){
          this.stateList = res.body;
          this.allstateList = res.body;
          resolve(this.stateList);
          this.stateLoader = false;
          this.stateLoader = false;
        }
       
      } 
      else if(res.status == 403){
        this._router.navigate(['/errorpage']);
      }
      else {
        reject("Error!");
      }
    });
  });

}

removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
  const startPos = e.currentTarget.selectionStart;
  if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
  }
}

SaveOrUpdateAddressDetails() {
  this.AuthenticationService.jwtTokenExpCheck();
  this.isAddressFrom = true;
  debugger
  if (this.addressFrom.valid) {
    this.newEnterpriseApi.updateUserAddress(this.addressFrom.value,this.staffAData.stId).subscribe((res:any) => {
      if(res.status == 200){
        if(res.body){
          this.loadStaffingDetails();
          this.isAddressFrom = false;
          this.submitted = false;
          this.tostr.successToastr('Address update successfully.', '', { position: 'top-right' });        
        }  
        else {
          this.tostr.errorToastr('Address not update successfully.', '', { position: 'top-right' });
        }     
      }    
    });
  } else {
    return false;
  }
}

modal_staffinglink_basicdetails() {
  this.AuthenticationService.jwtTokenExpCheck();
   this.staffEditFormDetails.controls['companyName'].setValue(this.staffAData.companyName);
   this.staffEditFormDetails.controls['firstName'].setValue(this.staffAData.firstName);
   this.staffEditFormDetails.controls['middleName'].setValue(this.staffAData.middleName);
   this.staffEditFormDetails.controls['lastName'].setValue(this.staffAData.lastName);
   this.staffEditFormDetails.controls['emailId'].setValue(this.staffAData.emailId);
   this.staffEditFormDetails.controls['contactNo'].setValue(this.staffAData.contactNo);
   this.staffEditFormDetails.controls['website'].setValue(this.staffAData.website);
   this.isEditform = !this.isEditform;
}


  modal_staffinglink_popup() {
    this.AuthenticationService.jwtTokenExpCheck();
     this.staffEditForm2.controls['twitterUrl'].setValue(this.staffAData.twitterUrl);
     this.staffEditForm2.controls['facebookUrl'].setValue(this.staffAData.facebookUrl);
     this.staffEditForm2.controls['linkdinUrl'].setValue(this.staffAData.linkdinUrl);
    this.islinkform = !this.islinkform;
  }

  sociallinkSubmit() {
    this.AuthenticationService.jwtTokenExpCheck();
    debugger
    if (this.staffEditForm2.valid) {
      this.newEnterpriseApi.profileSocialUpdate1(this.staffEditForm2.value,this.staffAData.stId).subscribe((res : any) => {
        if(res.status == 200){
          if(res.body){
            this.loadStaffingDetails();
            // this.isEditform = false;
             this.islinkform = false;
             this.tostr.successToastr('Social links updated successfully.', '', { position: 'top-right' });
          }
          else {
            this.tostr.errorToastr('Social links not updated successfully.', '', { position: 'top-right' });
          }
         
        }
        
      });
    } else {
      return false;
    }
  }

  staffingFormdetals() {  
    this.AuthenticationService.jwtTokenExpCheck();
    // let userid=this.userData.data.credentials.userId
    this.staffEditFormDetails.controls['stId'].setValue(this.staffAData.stId);
    this.staffEditFormDetails.controls['empId'].setValue(this.staffAData.empId);
    this.submitted2 = true;
    // var employerid: number=Number(this.staffAData.stId)
    debugger
    if (this.staffEditFormDetails.valid) {        
      this.newEnterpriseApi.addstaffingagency(this.staffEditFormDetails.value).subscribe(res => {          
        if (res.status == 200) {
          if(res.body){
            // this.basicupdate = res;
            this.isEditform = false;
            this.islinkform = false;
            this.loadStaffingDetails();     
            this.tostr.successToastr('Supplier details successfully updated.', '', { position: 'top-right' });
          }
          else {
            this.tostr.errorToastr('Something went wrong!', '', { position: 'top-right' });
          }
        } 
      });
    } else {
      return false;
    }
  }


  
  OnChangePhoneNo(field_no, e) {
    let f_val  = '';
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== ''  && e.keyCode != 8) {
    if (field_no === 1) {
      this.staffEditFormDetails.controls['contactNo'].setValue(npa  + '-' + nxx + '-' + last4);
    } else if (field_no === 2) {

    }
  }
  }

  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  
  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }
  
  sfsubmitted=false;
  resetPasswordform(){
    this.AuthenticationService.jwtTokenExpCheck();
    this.sfsubmitted = false;
    this.resetForm = this._formBuilder.group({
      
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      re_password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      createdDate:['']
    });

    this.resetForm.controls['password'].setErrors({ 'invalid': false });
    this.resetForm.controls['re_password'].setErrors({ 'invalid': false });
    this.notMatch = false;
    this.isAddform = !this.isAddform;
  }

  onSubmit() {
 
    this.AuthenticationService.jwtTokenExpCheck();
    this.sfsubmitted = true;
    if (this.resetForm.controls['password'].value === this.resetForm.controls['re_password'].value) {
      this.notMatch = false;
      if (this.resetForm.valid) {
        this.loading = true;
        try {
          let params = {
            Email: this.staffAData['staffingdetails'].emailId,
            Password: this.resetForm.controls['password'].value,
            code: ''
          };
          this.resetForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));

            this.tableService.resetPasswordVDInAdmin(params).subscribe((res: any) => {
            this.loading = false;
            if (res.success) {
              this.tostr.successToastr(res.message, 'Done!');
            } else {
              this.tostr.errorToastr(res.message, 'Error!');
            }
          });
        } catch (error) {
          throw error;
        }
        this.isAddform = false;
      } else {
        return false;
      }
    } else {
      this.notMatch = true;
      return false;
    }
  }

  get f() {
    return this.resetForm.controls;
  }

//Akhilesh:20200912, file check exits on root folder
  ImageFileExits(imgname) {   
    this.api.fileExists("profileimage",imgname).subscribe((res: any) => {
      if (res.data == true) {       
        this.isImgExits = true;
      }
    });
  }
  // 20201010: Akhilesh, Special charcter validation, jira no 118(1)
  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;   
     if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
       this.tostr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
       return false}
     }

     
  searchStateValue = false;
  searchState(countryId, e) {
     
    if (e.target.value != '') {
      this.api.searchState(countryId, e.target.value).subscribe((res: any) => {

        if (res.data.length == 0) {
          this.searchStateValue = true;
        }
        if (res.success) {
          this.stateList = res.data;
          if (res.data.length != 0) {
            this.searchStateValue = false;
          }
        } else {
          this.tostr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {

      this.stateList = this.allstateList;
      this.searchStateValue = false;
    }

  }


//20201104:Akhilesh,Search country api
  searchCountryValue = false;
  searchCountry(e) {
     
    if (e.target.value != '') {
      this.api.searchCountry(e.target.value).subscribe((res: any) => {
        if (res.data.length == 0) {
          this.searchCountryValue = true;
        }
        if (res.success) {
          this.countryList = res.data;
          if (res.data.length != 0) {
            this.searchCountryValue = false;
          }
        } else {
          this.tostr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.countryList = this.allcountryList;
      this.searchCountryValue = false;
    }
  }

  onSelect(event) {  
    if (event.target.files[0].size < (2029410 / 2)) {
    this.imageFileEvent = event;      
        const formData = new FormData();
        formData.append('profileimage', event.target.files[0]);
        if (event.target.files && event.target.files[0]) {
          const file = event.target.files[0];  
          const reader = new FileReader();
          reader.onload = e => this.s_img_Path = reader.result as string;  
          reader.readAsDataURL(file);
      }

        if(this.staffAData.employeeId != undefined){      
          this.profileLoader = true;
          const formData = new FormData();
          formData.append('profileimage', event.target.files[0]);
          if (event.target.files[0].type === 'image/png'
            || event.target.files[0].type === 'image/jpg'
            || event.target.files[0].type === 'image/jpeg') {
            this.profileLoader = true;
            
              //this.API.staffJobseekerProfileImg(formData).subscribe(res => {
                //let id = this.staffAData.employeeId;
                let id = this.userData.data.credentials.userId;
                formData.append("ImageUrl", event.target.files[0]);
                formData.append("UserId", id);        
              
                this.staffAPI.seekerProfileImgUpdateVD(formData).subscribe(res => { 
                this.resImageData = res;
                if (this.resImageData.success) {
                 // this.profileupdateImage();
                  var profileImagePath = this.imgProfile = this.userData1.data.credentials.profileImagePath;
                  localStorage.setItem('profileImagePath', profileImagePath);
                  this.profileLoader = false;
                 // this.myFileInput.nativeElement.value = '';
                  this.tostr.successToastr(this.resImageData.message, '', { position: 'bottom-right' });
                //  this.editUploadProfile.controls['profileImage'].setValue(this.resImageData.data.fileName);
                  this.profileLoader = false;               
                
               
                 // this.profileImage =this.resImageData.data.fileName;
                } else {
                  this.tostr.errorToastr(this.resImageData.message, '', { position: 'bottom-right' });
                }
              });
            
          }
          else {
            this.tostr.errorToastr('Profile image should be in png or jpg format.', 'Error!');
          }
        }
      }
      else {
        this.tostr.errorToastr('Profile image should be less than 1 MB.');
        this.profileLoader = false;
      }
    }

}

import { Component, OnInit } from '@angular/core';
// import { ContactUsComponent } from '../../share-module/contact-us/contact-us.component';
import { from } from 'rxjs';
import { type } from 'os';

@Component({
  selector: 'app-guest-contact-us',
  templateUrl: './guest-contact-us.component.html',
  styleUrls: ['./guest-contact-us.component.css']
})
export class GuestContactUsComponent implements OnInit {

  GNtype: string = '';
  constructor() {
    this.GNtype = "GN"
  }

  ngOnInit() {

  }

}


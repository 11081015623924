import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-yes',
  templateUrl: './yes.component.html',
  styleUrls: ['./yes.component.css']
})
export class YesComponent implements OnInit {

  constructor(private _titleService : Title) { 
  }

  ngOnInit() {
  }

}

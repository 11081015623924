import { Component, OnInit } from '@angular/core';
import { ContactUsComponent } from '../../share-module/contact-us/contact-us.component';
import { from } from 'rxjs';
import { type } from 'os';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-enterprise-contact-us',
  templateUrl: './enterprise-contact-us.component.html',
  styleUrls: ['./enterprise-contact-us.component.css']
})
export class EnterpriseContactUsComponent implements OnInit {
  EPtype:string ='';
  constructor(private _titleService : Title) {
    // for title set 
    var newTitle = 'Verisource | Contact Us';
    this._titleService.setTitle( newTitle );
    this.EPtype = "EP"
   }

  ngOnInit() {
    
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoicereport',
  templateUrl: './invoicereport.component.html',
  styleUrls: ['./invoicereport.component.css']
})
export class InvoicereportComponent implements OnInit {
  public shouldShow = true;
  constructor() { }

  ngOnInit() {
  }

}

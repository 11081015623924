import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { GuestContactUsComponent } from './guest-contact-us/guest-contact-us.component';
import { ShareModuleModule } from '../share-module/share-module.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
// import { GuestBrowseJobComponent } from './guest-browse-job/guest-browse-job.component';
import { YesComponent } from './yes/yes.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { GuestBrowseJobComponent } from './guest-browse-job/guest-browse-job.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnterpriseService } from '../enterprise/enterprise.service';
import { StickyModule } from 'ng2-sticky-kit';
 //import { HeaderComponent } from '../component/header/header.component';
@NgModule({
  imports: [
    CommonModule,
    ShareModuleModule,
    MatExpansionModule,
    StickyModule,
    FormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatPaginatorModule,
  ],
  declarations: [
    ErrorpageComponent,
    YesComponent,
    AboutUsComponent,
    GuestContactUsComponent,
    GuestBrowseJobComponent,
   // HeaderComponent
  ],
  exports: [ErrorpageComponent],
  providers: [EnterpriseService]
})


export class ExtaModule { }

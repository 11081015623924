import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnterpriseService } from '../enterprise.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-invoice-pending-details',
  templateUrl: './invoice-pending-details.component.html',
  styleUrls: ['./invoice-pending-details.component.css']
})
export class InvoicePendingDetailsComponent implements OnInit {

  constructor(private _titleService : Title) { 
    // for title set 
    var newTitle = 'Verisource | Invoice-PendingDetails';
    this._titleService.setTitle( newTitle );
  }

  ngOnInit() {
  }

}

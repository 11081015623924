import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import { TableService } from 'src/app/admin/service';
import { AuthenticationService } from 'src/app/app.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { NewEnterpriseService } from 'src/app/enterprise/newenterprise.service';
import { ShareService } from 'src/app/share-module/share.service';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';
@Component({
  selector: 'app-add-supplier',
  templateUrl: './add-supplier.component.html',
  styleUrls: ['./add-supplier.component.css']
})
export class AddSupplierComponent {
  userData1: any;
  imgProfile: String;
  imageFileEvent: any;
  signupstaffingForm: FormGroup;
  currentUser: any;
  userData: any;
  createdby: any;
  staffReg: any;

  typeUser = '';
  securityQuestionsList: any;
  phoneNumber: string;
  saPhone = false;
  suppNameExist = true;
  suppName: string;
  websiteExist = false;
  website: string;
  issuccess: any;
  submitted = false;
  isAddform: boolean = false;
  isAddAgencyform: boolean = true;
  isApprovalPopUp: boolean = false;
  profileLoader: boolean = false;
  resImageData: any;
  user_id: any;
  supplierId: any;
  loginUserId: any;
  //imgProfile: string;
  constructor(
    private formBuilder: FormBuilder,
    private tostr: ToastrManager,
    private AdminApi: TableService,
    private router: Router,
    private shareservice: ShareService,
    private dialogRef: MatDialogRef<AddSupplierComponent>,
    private AuthenticationService: AuthenticationService,
    private _titleService: Title,
    private enterpriseApis: EnterpriseService,
    private staffAPI: staffingAgencyServices,
    private Api: NewEnterpriseService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userData1 = JSON.parse(window.localStorage.getItem('udata'));
    console.log('user=======', this.data);

    const newTitle = 'Verisource | Admin Dashboard';
    this._titleService.setTitle(newTitle);
    this.currentUser = JSON.parse(localStorage.getItem('udata'));
    console.log('current user=======', this.currentUser);
    this.AuthenticationService.jwtTokenExpCheck();

    this.signupstaffingForm = this.formBuilder.group({
      companyName: ['', [Validators.required]],
      website: ['', [Validators.required, Validators.pattern("(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
      firstName: ['', [Validators.required]],
      middleName: [''],
      lastName: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: [''],
      contactNo: ['', Validators.required],
      registerDate: [''],
      createdby: [''],
    });

    this.userData = JSON.parse(window.localStorage.getItem('udata'));

  }

  ngOnInit() {
  }

  clearData() {
    this.signupstaffingForm.reset();
    this.dialogRef.close('close');
  }


  phoneValidation(type, event) {
    this.saPhone = false;
    this.phoneNumber = this.signupstaffingForm.controls['contactNo'].value;
    this.AdminApi.checkphone('SA', this.phoneNumber).subscribe((res: any) => {
      if (res.success) {
        if (res.data.exists) {
          this.saPhone = true;
          this.signupstaffingForm.controls['contactNo'].setErrors({ 'invalid': true });
        }
        else {
          this.saPhone = false;
        }
      } else {
        this.tostr.errorToastr(res.message, 'Error!');
      }
    });
  }
  //Manu Datta: 20210310, VerisourceBugSheetAfterMerge/commonLogin Company name and website check before page submit
  supplierNameExist(event) {
    this.suppNameExist = false;
    this.suppName = this.signupstaffingForm.controls['companyName'].value;
    this.AdminApi.checkSuplireName(this.suppName).subscribe((res: any) => {
      if (res.success) {
        if (res.data.exists) {
          this.suppNameExist = true;
          this.signupstaffingForm.controls['companyName'].setErrors({ 'invalid': true });
        }
        else {
          this.suppNameExist = false;
        }
      } else {
        this.tostr.errorToastr(res.message, 'Error!');
      }
    });
  }
  //Manu Datta: 20210310, VerisourceBugSheetAfterMerge/commonLogin Company name and website check before page submit
  websiteNameExist(event) {
    this.websiteExist = false;
    this.website = this.signupstaffingForm.controls['website'].value;
    this.AdminApi.checkWebsite(this.website).subscribe((res: any) => {
      if (res.success) {
        if (res.data.exists) {
          this.websiteExist = true;
          this.signupstaffingForm.controls['website'].setErrors({ 'invalid': true });
        }
        else {
          this.websiteExist = false;
        }
      } else {
        this.tostr.errorToastr(res.message, 'Error!');
      }
    });
  }

  //Manu Datta: 20210305, VerisourceBugSheetAfterMerge/commonLogin 413 Same Supplier should be registered through different jobseekers, clients and admin
  checkSupplierExistence(event) {
    this.phoneNumber = this.signupstaffingForm.controls['emailId'].value;
    //Manu Datta: 20210312, VerisourceBugSheetAfterMerge/commonLogin 439
    // if(this.userData.data.credentials.userType=='EP'){
    //   this.loginUserId = this.userData.data.credentials.enterpriseId;
    // }else{
    //   this.loginUserId = this.userData.data.credentials.userId;
    // }
    this.loginUserId = this.userData.data.credentials.AppRefId;
    this.AdminApi.checkSupplierExistence(this.loginUserId, this.phoneNumber, this.userData.data.credentials.AppRefTypeCode).subscribe((res: any) => {
      if (res.success) {
        if (res.data.staffingagencyId && res.data.client_jobseeker_admin_id != this.loginUserId) {
          this.isApprovalPopUp = true;
          this.supplierId = res.data.staffingagencyId;
          // this.signupstaffingForm.controls['emailId'].setErrors({ 'invalid': true });     
        }
        else {
          this.isApprovalPopUp = false;
        }
      } else {
        this.tostr.errorToastr(res.message, 'Error!');
      }
    });
  }
  //Manu Datta: 20210305, VerisourceBugSheetAfterMerge/commonLogin 413 Same Supplier should be registered through different jobseekers, clients and admin
  supplierMapped() {
    let userId: any;
    // if(this.userData.data.credentials.enterpriseId)
    // {
    //   userId = this.userData.data.credentials.enterpriseId
    // }else{
    //   userId = this.userData.data.credentials.userId
    // }
    userId = this.userData.data.credentials.AppRefId;
    //Manu Datta: 20210305, VerisourceBugSheetAfterMerge/commonLogin 413 Same Supplier should be registered through different jobseekers, clients and admin
    this.AdminApi.supplierMapping(userId, this.supplierId).subscribe((res: any) => {
      if (res.success) {
        this.isApprovalPopUp = false;
        this.submitted = false;
        this.isAddform = false;
        this.tostr.successToastr(res.message, "Success!");
        this.dialogRef.close('close');
        if (this.currentUser.data.credentials.userType == 'AD') {
          this.router.navigate(['/ad/admin/staffingagency']);
        } else {
          this.router.navigate(['/ep/enterprise/staffingagency']);
        }
      } else {
        this.tostr.errorToastr(res.message, 'Error!');
      }
    });
  }

  existph = false;
  validateContact: any;
  checkPhone() {
    this.existph = false;
    this.enterpriseApis.contactExist(this.signupstaffingForm.controls['contactNo'].value).subscribe((res: any) => {
      this.validateContact = res;
      if (this.validateContact.data.length > 0 && this.validateContact.data.length != 0) {
        this.existph = true;
        this.signupstaffingForm.controls['contactNo'].setErrors({ 'invalid': true })
      }
    });
  }


  
  stIds: Number
  addstaffingagencydetails() {

    this.AuthenticationService.jwtTokenExpCheck();
    this.submitted = true;
    if (this.signupstaffingForm.valid) {
      if (this.userData.data.credentials.userType == 'EP' || this.userData.data.credentials.userType == 'SA') {
        this.signupstaffingForm.controls['createdby'].setValue('');
      }
      this.signupstaffingForm.controls['registerDate'].setValue(new Date(this.shareservice.convertDateIntoUtc(new Date())).toISOString());
      debugger
      this.Api.addstaffingagency(this.signupstaffingForm.value).subscribe((res: any) => {
        // this.stIds = res.data.staffingEmpId;
        // this.issuccess = res;
        if(res.status == 200) {
          // if (this.imageFileEvent != undefined) {
          //      this.onSelect(this.imageFileEvent);
          // }
          this.submitted = false;
          this.isAddform = false;
          if(res.body){
            this.tostr.successToastr('Supplier created successfully', "Success!");
          }
          this.dialogRef.close('close');
        } 
        else if(res.status == 403){
          this.router.navigate(['/errorpage']);
        }
        else {
          this.tostr.errorToastr(this.issuccess.message, 'OPPS!')
        }
      });
    }

    else {
      return false;
    }

  }




  get sf() { return this.signupstaffingForm.controls; }

  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }

  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;
    if (k == 33 || k == 36 || k == 37 || k == 44 || k == 58 || k == 60 || k == 61 || k == 62 || k == 63 || k == 91 || k == 92 || k == 93 || k == 94 || k == 96 || k == 123 || k == 124 || k == 125 || k == 126) {
      this.tostr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .', 'Validation!');
      return false
    }
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  isRequired = false;
  submited = false;
  OnChangePhoneNo(tab, e) {
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== '' && e.keyCode != 8) {
      if (tab === 'SA') {
        this.signupstaffingForm.controls['contactNo'].setValue(npa + '-' + nxx + '-' + last4);
      }
    } else {
      this.isRequired = false;
    }
  }



  onSelect(event) {

    if (event.target.files[0].size < (2029410 / 2)) {
      this.imageFileEvent = event;
      const formData = new FormData();
      formData.append('profileimage', event.target.files[0]);
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = e => this.imgProfile = reader.result as string;
        reader.readAsDataURL(file);
      }

      if (this.user_id != undefined) {
        this.profileLoader = true;
        const formData = new FormData();
        formData.append('profileimage', event.target.files[0]);
        if (event.target.files[0].type === 'image/png'
          || event.target.files[0].type === 'image/jpg'
          || event.target.files[0].type === 'image/jpeg') {
          this.profileLoader = true;

          //this.API.staffJobseekerProfileImg(formData).subscribe(res => {
          let id = this.user_id.substring(6);
          formData.append("ImageUrl", event.target.files[0]);
          formData.append("UserId", id);

          this.staffAPI.seekerProfileImgUpdateVD(formData).subscribe(res => {
            this.resImageData = res;
            if (this.resImageData.success) {
              // this.profileupdateImage();
              var profileImagePath = this.imgProfile = this.userData1.data.credentials.profileImagePath;
              localStorage.setItem('profileImagePath', profileImagePath);
              this.profileLoader = false;
              // this.myFileInput.nativeElement.value = '';
              this.tostr.successToastr(this.resImageData.message, '', { position: 'bottom-right' });
              //  this.editUploadProfile.controls['profileImage'].setValue(this.resImageData.data.fileName);
              this.profileLoader = false;


              // this.profileImage =this.resImageData.data.fileName;
            } else {
              this.tostr.errorToastr(this.resImageData.message, '', { position: 'bottom-right' });
            }
          });

        }
        else {
          this.tostr.errorToastr('Profile image should be in png or jpg format.', 'Error!');
        }
      }
    }

    else {
      this.tostr.errorToastr('Profile image should be less than 1 MB.');
      this.profileLoader = false;
    }
  }

}

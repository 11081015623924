import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { DialogData } from 'src/app/component/header/header.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EnterpriseService } from '../enterprise.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { JobseekerService } from '../../JobSeeker/jobseeker.service';
import { PreviewResumePopupComponent } from '../preview-resume-popup/preview-resume-popup.component';
import { shceduleInterviewPopup } from '../search-profile/search-profile.component';
import { AuthenticationService } from 'src/app/app.service';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.css']
})
export class CandidatesComponent implements OnInit {

  listShow: boolean = false;
  dataLoad  = false;
  preLoader: boolean = false;
  searchFilter: FormGroup;
  isVisibleFilterBtn= true; 
  ddlFilterList: any = [];
  filterList: any;
  paginator = {
    pageSize: 20,
    length: 0,
    pageSizeOptions: [5, 10, 20, 100]
  };
  filterListData: any;

  panelOpenState: boolean = false;
  constructor(
    private _fromBuilder: FormBuilder,
    private enterPriseAPI: EnterpriseService,
    private JSApi: JobseekerService,
    private toastr: ToastrManager,
    private datepipe:DatePipe,
    public dialog: MatDialog,private _titleService : Title,private AuthenticationService:AuthenticationService
    ) {

    // for title set 
    var newTitle = 'Verisource | Search Profile';
    this._titleService.setTitle( newTitle );
    this.formInit();
    this.enterPriseAPI.getfilterSearchCandidate().subscribe((res: any) => {
      if (res.success) {
        this.filterList = res.data;
        if(res.data.length == 0){
          this.isVisibleFilterBtn = false;
        } 
      } else {
        this.toastr.errorToastr(res.message, "Error!");
      }
    });

    this.setFilter();

  }

  formInit() {
    this.searchFilter = this._fromBuilder.group({
      PageSize: 20,
      PageNumber: 1,
      resume_status: "All",
      secialization: '',
      search: "",
      resumeStatus: "All",
      profileStatus: 'All',
      highestDegree: 'Any',
      minExperience: 0,
      maxExperience: 0,
      usMasterDegree: 'All',
      citizenship: '',
      skills: '',
      minUsaExperience: 0,
      maxUsaExperience: 0,
      country: '',
      state:'',
      zipCode:''
    });
  }

  ngOnInit() {
  }

  setFilter() {
    this.preLoader = true;
    this.enterPriseAPI.gerCandidateList(this.searchFilter.value).subscribe((res: any) => {

      this.preLoader = false;
      this.listShow = true;
      this.dataLoad  = true;
      if (res.success) {
        this.filterListData = res;
        console.log(this.filterListData);
        if (this.filterListData.data.length > 0) {
          this.paginator.length = this.filterListData.data[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
        console.log("Filter Response =>", this.filterListData);
      }
    });
  }

  isLogin='true';
  openDialog(id): void {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(shceduleInterviewPopup, {
      width: 'auto',
      data: { jobseekerId: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  }
  previewDialog(details): void {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRefDetail = this.dialog.open(PreviewResumePopupComponent, {
      width: 'auto',
      data: details
    });

    dialogRefDetail.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  }

  jsonArrayCnvrtVl(val) {
    // console.log('val :---->>', Object.keys(val));
    return Object.keys(val);
  }
  jsonArrayCnvrt(val) {
    return Object.values(val);
  }

  countryData(ddlType, values) {
    this.ddlFilterList = [];
    // console.log("==============?>", ddlType);
    switch (ddlType) {
      case 'country': {
        if (this.searchFilter.controls['country'].value.length >= 1) {
          this.enterPriseAPI.country_enterprise().subscribe((res: any) => {
            if (res.success) {
              console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }
          });
        }
        break;
      }
      case 'jobLocation': {
        if (this.searchFilter.controls['jobLocation'].value.length >= 3) {
          console.log("Response ------------>");
          this.JSApi.joblocation(this.searchFilter.controls['jobLocation'].value).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }

          });
        } else {
          console.log("dssadsa");
        }
        break;
      }
      case 'citizenship': {
        if (this.searchFilter.controls['citizenship'].value.length >= 1) {
          this.enterPriseAPI.nationalitySearch(this.searchFilter.controls['citizenship'].value).subscribe((res: any) => {
            if (res.success) {
              console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }
          });
        }
        break;
      }

    }
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  clearData() {
    this.ddlFilterList = [];
  }

  setpaggination(e) {
    this.searchFilter.controls['PageSize'].setValue(e.pageSize);
    this.searchFilter.controls['PageNumber'].setValue(e.pageIndex + 1);
    this.setFilter();
  }
  reset() {
    this.formInit();
    this.setFilter();

  }
}
/*
@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
    <section class="interview-schedule"  *ngIf="!preloader">
      <form [formGroup]="scheduleForm">
        <div class="row">
          <div class="col-md-6">
            <ul>    
                <li class="job-items" *ngFor="let items of JobList.data">
                    <div class="custom-radio">
                    <input type="radio" name="jobId" formControlName="jobId" value="{{items.jobId}}" id="{{items.jobId}}" />
                    <label for="{{items.jobId}}"> <h1>{{items.jobTitle}}</h1>
                    <p>Location: {{items.jobLocation}} &nbsp; | &nbsp; Skills: {{items.keywords}} </p>
                    &nbsp;<i class="badge badge-success">{{items.jobId}}</i></label>
                    </div>
                </li>    
                <li *ngIf="JobList.data.length == 0">
                  <p>Jobs not found or applied for all jobs.</p>
                </li>         
            </ul>
          </div>
           <div class="col-md-6 text-center">
              <div class="date_container">
                 <div class="date-time">
                 <owl-date-time-inline formControlName="scheduleDate"  min="{{today}}"></owl-date-time-inline>
               </div>
               <div class="btn-wrapper">
                    <button class="btn btn_up_cv" (click)="GetTime()">Schedule</button>
               </div>
              </div>
          </div>
        </div> 
        </form>       
    </section>
    <mat-spinner *ngIf="preloader"></mat-spinner>
  `,
  styleUrls: ['./candidates.component.css']

})
export class shceduleInterviewPopup {
  today: String = new Date().toISOString().split('T')[0];
  JobList: any;
  selectedMoment: Date = new Date();
  preloader: boolean = false;
  scheduleForm: FormGroup;
  MyjobFilter = {
    PageNumber: 1,
    PageSize: 500,
    country: "",
    jobLocation: "",
    jobType: "All",
    maxAnnualPay: 0,
    maxExperience: 0,
    minAnnualPay: 0,
    minExperience: 0,
    orderBy: "Date",
    postedDate: 0,
    search: ""
  }

  constructor(
    public dialogRef: MatDialogRef<shceduleInterviewPopup>,
    private datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private enterpriseApis: EnterpriseService,
    private toastr: ToastrManager
  ) {

    this.scheduleForm = this._formBuilder.group({
      jobId: data.jobseekerId,
      jobseekerId: '',
      resume_status: 'Interview Schedule',
      scheduleDate: ''
    });
    this.preloader = true;
    // picker.open()
    try {
      this.enterpriseApis.singleInterview(data.jobseekerId).subscribe((res: any) => {
        // this.listShow = true;
        this.preloader = false;
        if (res.success) {
          this.JobList = res;
          console.log(this.JobList.data.length)
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });

    } catch (error) {
      this.toastr.errorToastr(error, 'Error!');
    }



  }

  onNoClick(): void {
    this.dialogRef.close();
  }

    
  GetTime() {    
    console.log(this.selectedMoment);
    // this.scheduleForm.controls['scheduleDate'].setValue(this.selectedMoment);
    try {
      if (this.scheduleForm.value.jobId !== '' && this.scheduleForm.value.scheduleDate !== '') {
        let mycase: Date = this.scheduleForm.value.scheduleDate;
        // console.log('mycase :---->', mycase)
        // let mycas = `${mycase}`;
        // let mycas = this.datePipe.transform(mycase, 'yyyy-MM-dd') + ' ' + this.datePipe.transform(mycase, 'hh:mm');
      //  let mycas = this.datePipe.transform(mycase, 'yyyy-MM-dd') + ' ' + mycase.getHours() + ':' + mycase.getMinutes();
        this.scheduleForm.controls['jobseekerId'].setValue(this.data.jobseekerId);
        this.scheduleForm.controls['scheduleDate'].setValue(mycase);

        const d = new Date(this.scheduleForm.value.scheduleDate);
        let scheduleDate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        this.scheduleForm.value.scheduleDate = scheduleDate;

        console.log('After Change Calender :', this.scheduleForm.value);
        // this.body.jobId = ;
        this.enterpriseApis.scheduleinterview(this.scheduleForm.value).subscribe((res: any) => {
          if (res.success) {
            console.log(res);
            this.toastr.successToastr(res.message, 'Success!');
            this.dialogRef.close();
            window.location.reload();
          } else {
            this.toastr.errorToastr(res.message, 'Error!');
          }
        });
      } else {
        this.toastr.warningToastr('Please Select Job & Scheduling time.', 'Warning!')
        return false;
      }

    } catch (error) {
      this.toastr.errorToastr(error, 'Error!');
    }



  }
}*/
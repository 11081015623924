import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterModule, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EnterpriseService } from '../enterprise.service';
import { FormGroup, FormBuilder, Validator, Validators, RequiredValidator } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AuthenticationService } from '../../app.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreviewResumePopupComponent } from '../preview-resume-popup/preview-resume-popup.component';
import { Title } from '@angular/platform-browser';
import { isThisSecond } from 'date-fns';
import { ExcelService } from 'src/app/admin/service/excel.service';
import { EmailPopupComponent } from '../email-popup/email-popup.component';
import { ShareService } from 'src/app/share-module/share.service';
@Component({
  selector: 'app-enterprise-view-list-candidates',
  templateUrl: './enterprise-view-list-candidates.component.html',
  styleUrls: ['./enterprise-view-list-candidates.component.css']
})
export class EnterpriseViewListCandidatesComponent implements OnInit {
  notUpdate: boolean = true;
  today: String = new Date().toISOString().split('T')[0];
  submitted = false;
  isOnreset: boolean = false;
  iswithdraw: boolean = false;
  issendml: boolean = false;
  selectedMoment: Date = new Date();
  openDatepicker: boolean = false;
  profileLoader: boolean = false;
  jobseekerId: string = '';
  disabled: boolean = false;
  jobId: string = '';
  jobTitle: string = '';
  candidateList: any;
  candidateDataList: any = [];
  candidateListSearch: FormGroup;
  sendEmailToCandidate: FormGroup;
  emailsuccess: any;
  statuschangesucces: any;
  filePath: String = '';
  paginator = {
    pageSize: 5,
    length: 0,
    pageSizeOptions: [5, 10, 25, 100]
  }
  islstopen;
  userData: any = {};
  jobseeker = '';
  staffingAgencyId = '';
  response: any;
  resume_path: any;

  constructor(
    private enterpriseApis: EnterpriseService,
    private API: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe,
    private excelService: ExcelService,
    private ActiveRouter: ActivatedRoute, private _formBuilder: FormBuilder, private toastr: ToastrManager, private _titleService: Title, private shareservice:ShareService) {

    // for title set 
    var newTitle = 'Verisource | View-ListCandidates';
    this._titleService.setTitle(newTitle);
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    if (this.userData.data.credentials.userType == 'AD') {
      this.jobseeker = this.ActiveRouter.snapshot.params.jobseekerId;
      this.staffingAgencyId = this.ActiveRouter.snapshot.params.staffingAgencyId;
    }
    this.jobId = this.ActiveRouter.snapshot.params.jobId;
    this.jobTitle = this.ActiveRouter.snapshot.params.jobTitle;
    this.candidateListSearch = this._formBuilder.group({
      PageSize: 5,
      PageNumber: 1,
      jobId: [this.jobId],
      search: [''],
      Schedule_date: new Date(),
      ScheduleDate: '',
      jobseekerId: this.jobseeker != '' ? this.jobseeker : '',
      staffingAgencyId: this.staffingAgencyId != '' ? this.staffingAgencyId : '',
      resume_status: ['']

    });
    this.sendEmailToCandidate = this._formBuilder.group({
      "jobseekerId": [''],
      "to": ['', [Validators.required, Validators.email]],
      "subject": ['', [Validators.required]],
      "content": ['', [Validators.required]],
    });

    //this.sendEmailToCandidate.controls['confirmPassword'].setErrors({ 'invalid': false });
    // console.log('this.candidateListSearch  >>>>>>>', this.candidateListSearch);
    this.filePath = `${this.API.url}/api/enterprise/image/resume/`;
  }

  get a() { return this.sendEmailToCandidate.controls; }

  popUpverif() { this.isOnreset = !this.isOnreset; }
  popUpwdraw() { this.iswithdraw = !this.iswithdraw; }
  isLogin='true';
  popUpsend(Candidate) {
   
    this.API.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    console.log('popUpsend',Candidate);
    // this.sendEmailToCandidate = this._formBuilder.group({
    //   "jobseekerId": [''],
    //   "to": ['', [Validators.required, Validators.email]],
    //   "subject": ['', [Validators.required]],
    //   "content": ['', [Validators.required]],
    // });
    // this.sendEmailToCandidate.controls['subject'].setErrors({ 'invalid': false });
    // this.sendEmailToCandidate.controls['content'].setErrors({ 'invalid': false });

    const dialogRefDetail = this.dialog.open(EmailPopupComponent, {
      width: 'auto',
      data: Candidate
    });

    dialogRefDetail.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
    });
  }

    // this.issendml = !this.issendml;
    // this.sendEmailToCandidate.controls['jobseekerId'].setValue(Candidate.jobseekerId);
    // this.sendEmailToCandidate.controls['to'].setValue(Candidate.emailId);


    // this.sendEmailToCandidate.controls.content.setValidators(Validators.required);
    // this.sendEmailToCandidate.controls.subject.setValidators(Validators.required);
    // console.log('this.sendEmailToCandidate >>>>>>>', this.sendEmailToCandidate);
  }

  formInit() {
    this.candidateListSearch = this._formBuilder.group({
      PageSize: 20,
      PageNumber: 1,
      jobId: [this.jobId],
      search: [''],
      Schedule_date: new Date(),
      ScheduleDate: '',
      jobseekerId: this.jobseeker != '' ? this.jobseeker : '',
      staffingAgencyId: this.staffingAgencyId != '' ? this.staffingAgencyId : '',
      resume_status: ['']
    });

  }

  ngOnInit() {

    this.LoadCandidateForJob(this.candidateListSearch.value);
  }
  LoadCandidateForJob(data) {
    
    // console.log('jobid >>>>>>>', data);
    this.enterpriseApis.candidateforJob(this.candidateListSearch.value).subscribe((res: any) => {
      // console.log('LoadJobList >>>>>>>>>>>>>   :', res);
      if (res.success) {
        this.candidateDataList =[];
        console.log('res', res.data);
        this.candidateList = res;
        for (var i = 0; i < this.candidateList.data.length; i++) {
          if (this.candidateList.data[i].resume_status == "Onboarded") {
            this.notUpdate = false;
          }
        }
        if (this.candidateList.data.length > 0) {
          this.paginator.length = res.data[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
//Start s3 resume download list page :Akhilesh
console.log("candidateList",this.candidateList.data)
 
this.candidateList.data.forEach(element => {
  let id = element.jobseekerId.substring(6);
  var resumepath = '';   
  this.enterpriseApis.jobseekerProfileResume(id).subscribe(res => {
    this.response = res;            
    if (this.response.data.length > 0){
     resumepath = this.resume_path = this.response.data[0].filePath;
    this.candidateDataList.push({
       "jobId": element.jobId,
      "srNo": element.srNo,
      "jobseekerId": element.jobseekerId,
      "fullName": element.fullName,
      "emailId": element.emailId,     
      "verification": element.verification,
      "appliedDate": element.appliedDate,
      "postedBy": element.postedBy,
      "Job_rate": element.Job_rate,
      "staffingPayRate": element.staffingPayRate,
      "jobDescription": element.jobDescription,
      "resume_status": element.resume_status,
      "resumeuploaded_date": element.resumeuploaded_date,      
      "user_profile": element.user_profile,
      "scheduleDate": element.scheduleDate,
      "totalCount":element.totalCount,
      "resume":resumepath,
    });   
  }    else{
    this.candidateDataList.push({
      "jobId": element.jobId,
      "srNo": element.srNo,
      "jobseekerId": element.jobseekerId,
      "fullName": element.fullName,
      "emailId": element.emailId,     
      "verification": element.verification,
      "appliedDate": element.appliedDate,
      "postedBy": element.postedBy,
      "Job_rate": element.Job_rate,
      "staffingPayRate": element.staffingPayRate,
      "jobDescription": element.jobDescription,
      "resume_status": element.resume_status,
      "resumeuploaded_date": element.resumeuploaded_date,      
      "user_profile": element.user_profile,
      "scheduleDate": element.scheduleDate,
      "totalCount":element.totalCount,
      "resume":null,
   });
  } 
   });
 });        
 
 console.log("jobseekerRecord",this.candidateDataList);
//End

      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
      // console.log('candidateList >>>>>>>>>>>>..', this.candidateList);
    });
  }

  sendemail() {
    // this.sendEmailToCandidate.controls['subject'].setErrors({ 'invalid': true });
    // this.sendEmailToCandidate.controls['content'].setErrors({ 'invalid': true });
    this.submitted = true;
    console.log(this.sendEmailToCandidate);
    if (this.sendEmailToCandidate.valid) {

      this.enterpriseApis.sendemailtocandidate(this.sendEmailToCandidate.value).subscribe(res => {

        this.emailsuccess = res;
        // console.log('emailsuccess >>>>>>>>>>>>..', this.emailsuccess);
        if (this.emailsuccess.success) {
          // console.log('emailsuccess >>>>>>>>>>>>..', this.emailsuccess);
          this.toastr.successToastr(this.emailsuccess.message, '', { position: 'bottom-right' });
          // this.profileLoader = false;
          this.sendEmailToCandidate.reset();
          this.issendml = false;
          // window.location.reload();
        } else {
          // console.log('emailsuccess >>>>>>>>>>>>..', this.emailsuccess);
          this.toastr.errorToastr(this.emailsuccess.message, '', { position: 'bottom-right' });
        }
      });
    }
    else {
      return false;
    }
  }



  status(data) {
    if (data == 'Proposed' || data == 'Rejected') {
      return true;
    }
    else {
      return false;
    }
  }
  status1(data) {
    if (data == 'Proposed' || data == 'Shortlisted' || data == 'Schedule Interview' || data == 'On hold') {
      return true;
    }
    else {
      return false;
    }
  }
  status2(data) {
    if (data == 'Shortlisted' || data == 'Schedule Interview' || data == 'On hold') {
      return true;
    }
    else {
      return false;
    }
  }

  changejobStatus(data, actionType) {
 
    this.enterpriseApis.changecandidateprofilestatus(data, actionType).subscribe(res => {
      this.statuschangesucces = res;
      if (this.statuschangesucces.success) {
        console.log('statuschangesucces >>>>>>>>>>>>..', this.statuschangesucces);
        this.profileLoader = true;
        this.toastr.successToastr(this.statuschangesucces.message, '', { position: 'bottom-right' });
        this.LoadCandidateForJob(this.candidateListSearch.value);
      } else {
        this.toastr.errorToastr(this.statuschangesucces.message, '', { position: 'bottom-right' });
      }
    });
  }

  // cont = 0;
  onDateChange(jobSeekerId, srNo) {
 
    // this.cont++;
    const mycase: Date = this.candidateListSearch.value.Schedule_date;
    const mycas = this.shareservice.convertDateIntoUtc(this.datePipe.transform(mycase, 'yyyy-MM-dd') + ' ' + mycase.getHours() + ':' + mycase.getMinutes());
    this.candidateListSearch.controls['resume_status'].setValue('Interview Schedule');
    this.candidateListSearch.controls['jobseekerId'].setValue(jobSeekerId);
    this.candidateListSearch.controls['ScheduleDate'].setValue(mycas);
    // console.log(mycas);
    // if (this.cont <= 1) {
    const sdasdasd = {
      srNo: srNo,
      jobId: this.jobId,
      scheduleDate: mycas
    };
    this.enterpriseApis.updateInterview(sdasdasd, srNo).subscribe((res: any) => {

      if (res.success) {
        this.candidateListSearch.controls['resume_status'].setValue('');
        this.LoadCandidateForJob(this.candidateListSearch.value);
        this.toastr.successToastr(res.message, 'Success!');
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }



  setpaggination(e) {
    this.candidateListSearch.controls['PageSize'].setValue(e.pageSize);
    this.candidateListSearch.controls['PageNumber'].setValue(e.pageIndex + 1);
    this.LoadCandidateForJob(this.candidateListSearch.value);

  }

  previewDialog(details): void {
    this.API.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRefDetail = this.dialog.open(PreviewResumePopupComponent, {
      width: 'auto',
      data: details
    });

    dialogRefDetail.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  // resetform() {
  //   console.log('reset');
  //   this.issendml = false
  // }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.candidateList.data, 'sample');
  }

}

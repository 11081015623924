import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  routerClass: string;
  isLogin: boolean;
  year: number = new Date().getFullYear();
  // isLoginF: string = 'false';
  constructor(private router: Router) {
    this.isLogin = localStorage.getItem('islogin') == 'true' ? true : false;
    // this.router.events.subscribe(val => {
    //   let a = JSON.parse(JSON.stringify(val))
    //   console.log('Router URL SON.parse(JSON.stringify(val)) ==========>', a);
    //   this.routerClass = a.url.replace('/', '');
    //   console.log('Router URL  ==========>', this.routerClass);
    //   // this.routerClass= val.NavigationEnd.url;
    //   this.isLogin = localStorage.getItem('islogin') == 'true' ? true : false;

    // })

  }

  ngOnInit() {

  }

  logData(e) {
    // console.log('footer Login ====================>', e)
    // this.isLoginF = e;
    if (e === 'false') {
      this.isLogin = false;
    } else { this.isLogin = true }
  }


}

import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './auth/home/home.component';
// import { ErrorpageComponent } from './exta/errorpage/errorpage.component';
import { GuestBrowseJobComponent } from './exta/guest-browse-job/guest-browse-job.component';
import { GuestContactUsComponent } from './exta/guest-contact-us/guest-contact-us.component';

import { AboutUsComponent } from './exta/about-us/about-us.component';
import { InvitaionLinkResultComponent } from './share-module/invitaion-link-result/invitaion-link-result.component';
import { StaffingInvitationLinkComponent } from './share-module/staffing-invitation-link/staffing-invitation-link.component';
import { SubscribeUnsubscribeComponent } from './share-module/subscribe-unsubscribe/subscribe-unsubscribe.component';
import { ProjectbudgetapprovalComponent } from './share-module/projectbudgetapproval/projectbudgetapproval.component';
import { ProjectapprovalComponent } from './share-module/projectapproval/projectapproval.component';
import { FeedbackComponent } from './share-module/feedback/feedback.component';
import { PreviewJobComponent } from './share-module/Job Request/preview-job/preview-job.component';
import { MyJobListEnterpriseComponent } from './enterprise/my-job-list-enterprise/my-job-list-enterprise.component';
import { AddNewJobComponent } from './share-module/Job Request/add-new-job/add-new-job.component';
import { EnterpriseDashboardComponent } from './enterprise/enterprise-dashboard/enterprise-dashboard.component';
import { StaffingAgencyEnterpriseComponent } from './enterprise/staffing-agency-enterprise/staffing-agency-enterprise.component';
import { StaffingAgencyDetailsComponent } from './enterprise/staffing-agency-details/staffing-agency-details.component';
import { JobRequestListComponent } from './enterprise/job-request-list/job-request-list.component';
import { JobseekerEnterpriseComponent } from './enterprise/jobseeker-enterprise/jobseeker-enterprise.component';
import { StaffingAgencyComponent } from './enterprise/NextGen/staffing-agency/staffing-agency.component';
import { ErrorPageComponentComponent } from './share-module/NextGen/error-page-component/error-page-component.component';
import { AssignJobToAgencyComponentComponent } from './enterprise/NextGen/assign-job-to-agency-component/assign-job-to-agency-component.component';

// Lazy loading ------------------

export const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent },
  {  
    path: 'st',
    // loadChildren:'./staffing-agency/staffing-agency.module#StaffingAgencyModule'
    loadChildren: () => import('./staffing-agency/staffing-agency.module').then(m => m.StaffingAgencyModule),
  },
  { path: 'auth', 
  // loadChildren: './auth/auth.module#AuthModule' 
  loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
},
  {
    path: 'ep',
    // loadChildren: './enterprise/enterprise.module#EnterpriseModule'
    loadChildren: () => import('./enterprise/enterprise.module').then(m => m.EnterpriseModule), 
  },
  {
    path: 'js',
    // loadChildren: './JobSeeker/job-seeker.module#JobSeekerModule'
    loadChildren: () => import('./JobSeeker/job-seeker.module').then(m => m.JobSeekerModule),
  },
  {
    path: 'ad',
    // loadChildren: './admin/admin.module#AdminModule'
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  { path: 'jobs', component: GuestBrowseJobComponent },
  { path: 'contactus', component: GuestContactUsComponent },
  { path: 'invitation_result/:id/:type/:createdby', component: InvitaionLinkResultComponent},
  { path: 'invitation_result/:id/:type', component: InvitaionLinkResultComponent},
  { path: 'invitation_result/:id', component: InvitaionLinkResultComponent},
  { path: 'Staffinginvitation_result/:id/:type',component: StaffingInvitationLinkComponent},
  { path: 'Staffinginvitation_result/:id',component: StaffingInvitationLinkComponent},
  { path: 'sus', pathMatch:'full', component: SubscribeUnsubscribeComponent },
  { path: 'approveprojectbudget/:id', component: ProjectbudgetapprovalComponent },
  { path: 'approveproject/:id', component: ProjectapprovalComponent },
  { path:'feed_back/:emailid',component:FeedbackComponent},
  
  // New Vms changes 
  { path: 'dashboard', pathMatch: 'full', component: EnterpriseDashboardComponent },
  { path: 'jobRequest/detail', pathMatch: 'full', component:  AddNewJobComponent},
  // { path: 'jobRequest/detail/id/:id', component: AddNewJobComponent },
  { path: 'jobRequest/detail', component: AddNewJobComponent },
  // { path: 'jobRequest/Preview/id/:id', component: PreviewJobComponent },
  { path: 'jobRequest/Preview', component: PreviewJobComponent },
  { path: 'jobRequest', component: JobRequestListComponent },
  { path: 'suppliers', component: StaffingAgencyComponent },
  // { path: 'supplier/id/:id', pathMatch: 'full', component: StaffingAgencyDetailsComponent },
  { path: 'supplier/detail', pathMatch: 'full', component: StaffingAgencyDetailsComponent },
  { path: 'assignJobToAgency', pathMatch: 'full', component: AssignJobToAgencyComponentComponent },
  { path: 'errorpage', pathMatch: 'full', component: ErrorPageComponentComponent },
  {path: 'ep',loadChildren: () => import('./enterprise/enterprise.module').then(m => m.EnterpriseModule), },
  {
    path: 'js',
    // loadChildren: './JobSeeker/job-seeker.module#JobSeekerModule'
    loadChildren: () => import('./JobSeeker/job-seeker.module').then(m => m.JobSeekerModule),

  },
  {
    path: 'ad',
    // loadChildren: './admin/admin.module#AdminModule'
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),

  },
  { path: 'aboutus', pathMatch: 'full', component: AboutUsComponent },
  // { path: '**', component: ErrorpageComponent }


];
export const appRouteModule = RouterModule.forRoot(routes); 

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { EnterpriseService } from './enterprise.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { appRouteModule } from '../../app-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { CountDownModule } from 'ng6-countdown/dist/ng6-countdown-lib';
import { RouterModule } from '@angular/router';
import { enterpriseRouteModule } from './enterprise.routing';
import { ShareModuleModule } from '../share-module/share-module.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatDialogModule } from '@angular/material/dialog';
import { MyJobListEnterpriseComponent } from './my-job-list-enterprise/my-job-list-enterprise.component';
import { EnterpriseDashboardComponent } from './enterprise-dashboard/enterprise-dashboard.component';
import { EnterpriseViewListCandidatesComponent } from './enterprise-view-list-candidates/enterprise-view-list-candidates.component';
import { EnterpriseComponent } from './enterprise.component';
import { ProfileForEnterpriseComponent } from './profile-for-enterprise/profile-for-enterprise.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MyInvoiceInterprisePdingComponent } from './my-invoice-interprise-pding/my-invoice-interprise-pding.component';
import { MyInvoiceInterprisePaidComponent } from './my-invoice-interprise-paid/my-invoice-interprise-paid.component';
import { InvoiceViewDetailsComponent } from './invoice-view-details/invoice-view-details.component';
import { EnterpriseSubadminComponent } from './enterprise-subadmin/enterprise-subadmin.component';
import { InvoicePendingDetailsComponent } from './invoice-pending-details/invoice-pending-details.component';
import { InterviewComponent } from './interview/interview.component';
import { SearchProfileComponent, shceduleInterviewPopup } from './search-profile/search-profile.component';
import { MatTableModule } from '@angular/material/table';
// import { PreviewResumePopupComponent } from './preview-resume-popup/preview-resume-popup.component';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
// import { NgxStripeModule } from 'ngx-stripe';
// import { ContactUsComponent } from '../../share-module/contact-us/contact-us.component';
import { NotificationComponent } from './notification/notification.component';
import { EnterpriseContactUsComponent } from './enterprise-contact-us/enterprise-contact-us.component';
import { PaymentPopupComponent } from './payment-popup/payment-popup.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { DemoComponent } from './component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { JobseekerService } from '../JobSeeker/jobseeker.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PreviewResumePopupComponent } from './preview-resume-popup/preview-resume-popup.component';
import { JobDetailPopupComponent } from './job-detail-popup/job-detail-popup.component';
import { StickyModule } from 'ng2-sticky-kit';
import { Title } from '@angular/platform-browser';
import { CandidatesComponent } from './candidates/candidates.component';
import { AllEntannouncementsComponent } from './all-entannouncements/all-entannouncements.component';
// import { StaffingagencyeneterpriseComponent } from './staffingagencyeneterprise/staffingagencyeneterprise.component';
import { StaffingAgencyEnterpriseComponent } from './staffing-agency-enterprise/staffing-agency-enterprise.component';
import { StaffingAgencyDetailsComponent } from './staffing-agency-details/staffing-agency-details.component';
import { JobseekerEnterpriseComponent } from './jobseeker-enterprise/jobseeker-enterprise.component';
import { CandidateProfileCreateComponent } from './candidate-profile-create/candidate-profile-create.component';
import { MatStepperModule } from '@angular/material/stepper';
import { CandidateProfileUpdateComponent } from './candidate-profile-update/candidate-profile-update.component';
import { JobSeekerViewDetailsComponent } from './job-seeker-view-details/job-seeker-view-details.component';
// import { ProjectsComponent } from '.././projects/projects.component';
//import { ProjectComponent } from './project/project.component';
import { StaffingAgencyGroupComponent } from './staffing-agency-group/staffing-agency-group.component';
import { AddAgencyInGroupComponent } from './add-agency-in-group/add-agency-in-group.component';
import { AddAgencyGroupComponent } from './add-agency-group/add-agency-group.component';
import { AssignJobToAgencyComponent } from './assign-job-to-agency/assign-job-to-agency.component';
import { AssignJobToAgencyGroupComponent } from './assign-job-to-agency-group/assign-job-to-agency-group.component';
import { StaffingAgencyPopUpComponent } from './staffing-agency-pop-up/staffing-agency-pop-up.component';
import { TextMaskModule } from 'angular2-text-mask';
//import { TimesheetsupplierComponent } from '../share-module/timesheetsupplier/timesheetsupplier.component';
//import { ClienttimesheetdetailsComponent } from './clienttimesheetdetails/clienttimesheetdetails.component';
import { EmailPopupComponent } from './email-popup/email-popup.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { SupplierGroupDetailsComponent } from './supplier-group-details/supplier-group-details.component';
import { NewEnterpriseService } from 'src/app/enterprise/newenterprise.service';
import { JobRequestListComponent } from './job-request-list/job-request-list.component';
import { StaffingAgencyComponent } from './NextGen/staffing-agency/staffing-agency.component';
import { JobDetailPopUpComponent } from './NextGen/staffing-agency/PopUp/job-detail-pop-up/job-detail-pop-up.component';
import { SupplierPopUpComponent } from './NextGen/staffing-agency/PopUp/supplier-pop-up/supplier-pop-up.component';
import { AssignJobToAgencyComponentComponent } from './NextGen/assign-job-to-agency-component/assign-job-to-agency-component.component';

@NgModule({
    imports: [
        CommonModule,
        // appRouteModule,
        InfiniteScrollModule,
        FormsModule,
        TagInputModule,
        MatAutocompleteModule,
        MatInputModule,
        MatPaginatorModule,
        RouterModule,
        NgSelectModule,
        MatExpansionModule,
        // CountDownModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        NgbModule.forRoot(),
        MatSelectModule,
        enterpriseRouteModule,
        // NgxStripeModule.forRoot('pk_test_Z0jVYjuqQXmBQoGmnN225roo'),
        ReactiveFormsModule,
        MatDialogModule,
        MatTableModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        OwlDateTimeModule,
        MatCheckboxModule,
        OwlNativeDateTimeModule,
        ShareModuleModule,
        StickyModule,
        MatStepperModule,
        MatIconModule,
        MatTabsModule,
        TextMaskModule,
        NgxDocViewerModule
    ],
    declarations: [MyJobListEnterpriseComponent,
        EnterpriseDashboardComponent, EnterpriseViewListCandidatesComponent,
        EnterpriseComponent, ProfileForEnterpriseComponent,
        MyInvoiceInterprisePdingComponent, MyInvoiceInterprisePaidComponent,
        InvoiceViewDetailsComponent, EnterpriseSubadminComponent,
        SearchProfileComponent, shceduleInterviewPopup, InvoicePendingDetailsComponent,
        InterviewComponent, NotificationComponent, EnterpriseContactUsComponent, PaymentPopupComponent,
        PreviewResumePopupComponent, JobDetailPopupComponent, CandidatesComponent, AllEntannouncementsComponent,
        StaffingAgencyEnterpriseComponent, StaffingAgencyDetailsComponent, JobseekerEnterpriseComponent,
        CandidateProfileCreateComponent, CandidateProfileUpdateComponent, JobSeekerViewDetailsComponent,
        StaffingAgencyGroupComponent, AddAgencyInGroupComponent, AddAgencyGroupComponent, AssignJobToAgencyComponent,
        AssignJobToAgencyGroupComponent, StaffingAgencyPopUpComponent,
        //ClienttimesheetdetailsComponent, 
        EmailPopupComponent, SupplierGroupDetailsComponent, JobRequestListComponent, StaffingAgencyComponent, JobDetailPopUpComponent, SupplierPopUpComponent, AssignJobToAgencyComponentComponent,
        // ContactUsComponent
    ],
    exports: [PreviewResumePopupComponent, JobDetailPopupComponent,SupplierPopUpComponent],
    providers: [EnterpriseService, EnterpriseViewListCandidatesComponent, DatePipe, JobseekerService, Title, NewEnterpriseService]
})
export class EnterpriseModule { }

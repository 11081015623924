import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RouterLink, Router } from '@angular/router';
import { EnterpriseService } from '../enterprise.service';
import { AuthenticationService } from '../../app.service'
import { Title } from '@angular/platform-browser';
import { staffingAgencyServices } from '../../staffing-agency/staffing-agency.services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ShareService } from 'src/app/share-module/share.service';

@Component({
  selector: 'app-all-entannouncements',
  templateUrl: './all-entannouncements.component.html',
  styleUrls: ['./all-entannouncements.component.css']
})
export class AllEntannouncementsComponent implements OnInit {

  announcShow: boolean = false;
  announceData: any;

  paginator = {
    AN: {
      pageSize: 5,
      length: 0,
      pageSizeOptions: [5, 10, 20, 100]
    }
  };

  constructor(
    private myrouter: Router,
    private eterpriseAPI: EnterpriseService,
    private API: AuthenticationService,
    private _titleService: Title,
    private staffAPI: staffingAgencyServices,
    private toastr: ToastrManager,
    private shareservice:ShareService) { }

  ngOnInit() {
    this.announceMentlist(1, 10);
  }

  announceMentlist(pageno, size) {
    this.eterpriseAPI.announcement(pageno, size).subscribe((res: any) => {
      if (res.success) {
        this.announcShow = true;
        this.announceData = res;
        if (this.announceData.data.length > 0) {
          this.paginator.AN.length = this.announceData.data[0].totalCount;

        } else {
          this.paginator.AN.length = 0;
        }
      } else {

      }
      // console.log("announceMent Data", res);
    });
  }

  setpaggination(e, type) {
    if (type == 'AN') {
      this.announceMentlist(e.pageIndex + 1, e.pageSize);
    }
  }


}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {AuthenticationService } from '../../app.service';


@Injectable({
  providedIn: 'root'
})

export class TableService {
  apipath = '';
  isLogin: string = 'false';
  //apipath = '';
  token: String = "";
  listDataEnterP: any;
  userData:any={};
  constructor(private http: HttpClient,
        private AuthenticationService: AuthenticationService,
        private _router: Router,
      private datePipe: DatePipe) {
        this.apipath = `${environment.apipath}`
    this.AuthenticationService.jwtTokenExpCheck();
    this.apipath = `${environment.apipath}`;
    this.token = localStorage.getItem('token');
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    //console.log('call constrcter');
  }


  // CheckTokenAuthentication(tab) {
  //   if (tab == 1) {
  //     this.token = '';
  //     this._router.navigate(['/']);
  //   } else if (tab == 2) {
  //     this.token = localStorage.getItem('token');
  //     if (this.token == null) {
  //       this.CheckTokenAuthentication(1);
  //     }
  //   }

  // }

  // Admin Dashboard api Start
  AdminDashboardApi = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {

      let url = `${environment.url}/api/admin/dashboard`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else {
      this._router.navigate(['/']);
    }
  }
  // Admin Dashboard api End
  // Admin Update My Profile api Start
  adminMyProfile = () => {
    
    this.token = localStorage.getItem('token');
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin === 'true') {
    let url = `${environment.url}/api/admin/admindetails`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
    } else {
      this._router.navigate(['/']);
    }
  }
  // Admin Update My Profile api End       
  // Admin Upload Img api Start
  UploadAdminImg(resumename) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/uploadprofileimage`;
      const httpOptions = {
        headers: new HttpHeaders({
          // 'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.post<any>(url, resumename, httpOptions).pipe(map(res => {
        return res;
      }));
    } else {
      this._router.navigate(['/']);
    }
  }
//Upload multiple project document
  UploadAdminProjectdoc(resumename) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/uploadprojectdoc`;
      const httpOptions = {
        headers: new HttpHeaders({
          // 'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.post<any>(url, resumename, httpOptions).pipe(map(res => {
        return res;
      }));
    } else {
      this._router.navigate(['/']);
    }
  }

  //Upload project budget document
  UploadAdminProjectBudgetdoc(docname) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/uploadprojectbudgetdoc`;
      const httpOptions = {
        headers: new HttpHeaders({
          // 'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.post<any>(url, docname, httpOptions).pipe(map(res => {
        return res;
      }));
    } else {
      this._router.navigate(['/']);
    }
  }
  // Admin Upload Img api End
  // Update Admin Bacis Details api Start
  UpdateBasicDetailsApi = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/updateprofile`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "adminName": data.adminName,
        "emailId": data.emailId
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else {
      this._router.navigate(['/']);
    }
  }
  // Update Admin Bacis Details api End
  // Update Admin Bacis Details api Start
  UpdatePasswordApi = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/updatepassword`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "password": data.newpassword,
        "oldPassword":data.oldpassword
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else {
      this._router.navigate(['/']);
    }
  }

  enterpriseRegisterVD = (data) => {
    
    const localurl = this.apipath + 'SuperAdmin/AddCompanyW';
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      // let Option = new Http()
      let url = `${environment.url}/api/admin/registerenterprise`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
    const body =       //data;
    {
        'Website': data.website,
        'Email': data.emailId,
        'Phone': data.contactNo,
        'CompName': data.companyName,
        'AboutProfile': "about profile1",
        'Address': "delhi",
        'City': "sector 3",
        'CompanyType': 1,
        'CountryId': 2,
        'Ein': "2145456454",
        'FacebookUrl': "facebook1.com",
        'LinkedinUrl': "linkedin1.com",
        'State': "UP",
        'Street': "noida",
        'TwitterUrl': "www.twitter1.com",
        'Zip': "45654"
    }
  
    return this.http.post(localurl, body, httpOptions);
  }
}

  // Update Admin Bacis Details api End 
  enterpriseForm = (data) => {

    console.log('data for ent',data);
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/registerenterprise`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.post<any>(url, data, httpOptions).pipe(map(enterpriseadminform => {
        return enterpriseadminform;
      }));
    } else {
      this._router.navigate(['/']);
    }
  }

  updateEmployeeIdByVeridialUserId = (UserId,Id,type) => {

    console.log('data for ent',UserId,Id);
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/updateemployeeId`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      const body ={
        userId:UserId,
        id:Id,
        type:type
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(enterpriseadminform => {
        return enterpriseadminform;
      }));
    } else {
      this._router.navigate(['/']);
    }
  }

  enterpriseFormByLogin = (data) => {
   

    console.log('data for ent',data);
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/registerenterpriseByLogin`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.post<any>(url, data, httpOptions).pipe(map(enterpriseadminform => {
        return enterpriseadminform;
      }));
    } else {
      this._router.navigate(['/']);
    }
  }
//Update client to VD
enterpriseUpdateFormVD = (data,id) => {
   
  console.log('data for ent',data);
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Recruiter/EditRecruiter';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
    const body =       //data;
    {
      
        "userId": id,
        "name": data.firstName,
        "phone": data.contactNo,
        "skillIds": environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
        "modifiedBy": 0,
        "subscriberId": 0,
        "permissionId": environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
        "email": data.emailId,
        "roleManagerId": 0,
        "AppType":environment.AppType,
        "AppRefType":environment.AppRefTypeClient,
        "IsVeridial":false

       
    }
    return this.http.post(url, body, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}

  enterpriseFormVD = (data,appRefId:Number) => {
    
    console.log('data for ent',data);
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
    //  let Option = new Http()
    //  let url = `${environment.url}/api/admin/registerenterprise`
      const url = this.apipath + 'SuperAdmin/AddCompany';
      const httpOptions = {
        // headers: new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'authenticate': 'Bearer' + this.token + ""
        // })
     
        headers :{
          Authorization:"Bearer " + this.token // token
      }
      };
      const body =       //data;
      {
          'Website': data.website,
          'Email': data.emailId,
          'Phone': data.contactNo,
          'CompName': data.companyName,
          'AboutProfile': "about profile1",
          'Address': "delhi",
          'City': "sector 3",
          'CompanyType': 1,
          'CountryId': 2,
          'Ein': "2145456454",
          'FacebookUrl': "facebook1.com",
          'LinkedinUrl': "linkedin1.com",
          'State': "UP",
          'Street': "noida",
          'TwitterUrl': "www.twitter1.com",
          'Zip': "45654",
          'AppType':environment.AppType,
          'AppRefId':appRefId,
          'AppRefType':environment.AppRefTypeClient,
          'IsVerified':false
      }
      return this.http.post(url, body, httpOptions);
    } else {
      this._router.navigate(['/']);
    }
  }

  updateCompanyStatusVD = (id,status,appRefType) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      let vdStatus;
      if (status == 'Active'){
        vdStatus=10;
      }else if(status == 'Inactive'){
        vdStatus=11; 
      }
      const url = this.apipath + 'Account/UpdateUserStatus';
      const httpOptions = {
        headers :{
          Authorization:"Bearer " + this.token // token
      }
      };
      const body =       //data;
      {
          'userId': id,
          'statusId': vdStatus,
          'AppType': environment.AppType,
          'AppRefType':appRefType,
          'IsVeridial':true
      }
      return this.http.post(url, body, httpOptions);
    } else {
      this._router.navigate(['/']);
    }
  }



 
  enterprisesview() {
    
    // let url = `${environment.url}/api/admin/enterprisesview`
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'authenticate': this.token + ""
    //   })
    // };

    // return this.http.get<any>(url, httpOptions).pipe(map(enterpriselist => {
    //   return enterpriselist.data;
    // }));
  }
  enterprisesDetailview(val) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/enterprisedetails/${val}`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': `${this.token}`
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res.data;
      }));
    } else {
      this._router.navigate(['/']);
    }
  }
  enterprisesDetailviewprofl(pageno, pagesize, status, search, id: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin === 'true') {
      let url = `${environment.url}/api/admin/enterprisejobs/` + id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageNumber": pageno,
        "PageSize": pagesize,
        "jobstatus": status,
        "search": search
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(enterprisadminprofile => {
        return enterprisadminprofile.data;
      }));
    } else { this._router.navigate(['/']); }
  }
  showallNotificationAnnouncement(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getallnotifyannounces`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  pushnotificationannouncement(notifyId: any, data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/generatenotify`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      let body = {
        "notifyID": notifyId,
        "heading": data.heading,
        "description": data.description,
        "isjobseeker": data.isjobseeker,
        "isenterprise": data.isenterprise,
        "isstaffing": data.isstaffing,
        "isnotify": data.isnotify,
        "isannounce": data.isannounce,
        "notify_type": data.published_to,
        "notify_userId": data.user_name,
        "createdDate":data.createdDate
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  loadAllUserTypeWise(user_type) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getnotifyusers/` + user_type;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
 //Get user details by id
 getUserDetails = ( data) => {
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
   console.log("contactusForm",data);
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/get_user_details/` + data.user_name + '/'+ data.user_type;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  enterpriseJobDescription(jobId, enterpriseId) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getjob/` + jobId + '/' + enterpriseId;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  profileEPSocialUpdate(body, id: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let localurl = `${environment.url}/api/admin/updatelinkedprofile/` + id;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      return this.http.post(localurl, body, httpOptions);
    }
    else { this._router.navigate(['/']); }
  }

  phoneExist(userType, newContact, old) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let localurl = `${environment.url}/api/admin/checkmobile`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        'oldContact': old,
        'newContact': newContact,
        'userType': userType
      }
      return this.http.post(localurl, body, httpOptions);
    }
    else { this._router.navigate(['/']); }
  }

  phoneExist1(userType, newContact) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let localurl = `${environment.url}/api/admin/checkmobile`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        'newContact': newContact,
        'userType': userType
      }
      return this.http.post(localurl, body, httpOptions);
    }
    else { this._router.navigate(['/']); }
  }

  profileBasicUpdate(usertype, body, id: string, userId: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let localurl = `${environment.url}/api/admin/updateprofile/` + id + '/' + usertype;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      return this.http.post(localurl, body, httpOptions);
    }
    else { this._router.navigate(['/']); }
  }

  checkOldPassword(email: string, password: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let localurl = `${environment.url}/api/admin/checkOldPassword/` + email + '/' + password;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      return this.http.get(localurl, httpOptions);
    }
    else { this._router.navigate(['/']); }
  }

  mailExist(id, email) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let localurl = `${environment.url}/api/enterprise/checkemail/` + id + '/' + email;
      const httpOptions = {
        headers: new HttpHeaders({
          'authenticate': this.token + ""
        })
      };

      return this.http.get(localurl, httpOptions);
    }
    else { this._router.navigate(['/']); }
  }

  verifyenterpriseDetails(userId, user_type, base_price) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/changeprofilestatus`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "user_type": user_type,
        "userId": userId,
        "profile_status": "Verified",
        "base_price": base_price
      }

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }



  // for update Supplier address details
  profileSocialUpdate(body, id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      const localurl = `${environment.url}/api/admin/updateStaffingAgencySocialLink/` + id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.post(localurl, body, httpOptions);
    }
  }

  profileBasicUpdates(usertype, body, id, userId) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      const localurl = `${environment.url}/api/admin/updateStaffingAgencyBasicDetails/` + id + '/'+ userId + '/' + usertype;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.post(localurl, body, httpOptions);
    }
  }


  appliedcandidate(body, jobId) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/pushedresumesonjob/` + jobId;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      // let body = {
      //   "PageNumber": 1,
      //   "PageSize": 5,
      //   "resume_status": "Proposed",
      //   "search": ""
      // }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  nonappliedcandidate(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/jobcandidates`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "JobId": data.JobId,
        "PageNumber": data.PageNumber,
        "PageSize": data.PageSize,
        "jobTitle": data.jobTitle,
        // "profile_status": data.profile_status,
        "resume_status": data.resume_status,
        "search": data.search,
      }
      return this.http.post<any>(url, data, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  pushresumeofcandidate(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin === 'true') {
      let url = `${environment.url}/api/admin/pushjobs`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = { postedjobs: data };
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }


  enterprisesViewsec(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/pushedresumesonjob/` + id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageNumber": 1,
        "PageSize": 5
      }
      return this.http.post<any>(url, body, httpOptions)
      // .pipe(map(enterprises => {
      //   return enterprises.data;
      // }));
    } else { this._router.navigate(['/']); }
  }
  staffingagenciesview() {
    // this.isLogin = localStorage.getItem('islogin');     if (this.isLogin == 'true') {let url = `${environment.url}/api/admin/staffingagenciesview`
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'authenticate': this.token + ""
    //   })
    // };
    // return this.http.get<any>(url, httpOptions).pipe(map(staffingagencylist => {
    //   return staffingagencylist.data;
    // }));
  }
  
  addstaffingagency(data) {
   
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/registerstaffing`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      return this.http.post<any>(url, data, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  addstaffingagencyByLogin(data) {  
    
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/registerstaffingByLogin`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      return this.http.post<any>(url, data, httpOptions).pipe(map(res => {66666666
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  GetUserDetailsByCreayedById(userId){

    console.log('data userId',userId);
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
    //  let Option = new Http()
    //  let url = `${environment.url}/api/admin/registerenterprise`
      const url = this.apipath + 'Account/GetUserDetailsbYId';
      const httpOptions = {
        // headers: new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'authenticate': 'Bearer' + this.token + ""
        // })
     
        headers :{
          Authorization:"Bearer " + this.token // token
      }
      };
      const body =       //data;
      {
          'userId': userId,
          'appType':environment.AppType
      }
      return this.http.post(url, body, httpOptions);
    } else {
      this._router.navigate(['/']);
    }
  }




  staffingagencyDetailview(val) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin === 'true') {
      let url = `${environment.url}/api/admin/staffingagencydetails/` + val
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  staffingcandidates(pageno, pagesize, id: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/staffingcandidates/` + id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageNumber": pageno,
        "PageSize": pagesize
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  deactivateEnterprise(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/enterprisedetails/` + id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      return this.http.put<any>(url, httpOptions).pipe(map(enterpriselist => {
        return enterpriselist.data;
      }));
    } else { this._router.navigate(['/']); }
  }

  candidateDetails(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/candidatedetails/` + id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res.data;
      }));
    } else { this._router.navigate(['/']); }
  }

  // for update Supplier address details
  UpdateStaffingAgencyAddress(body, id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      const localurl = `${environment.url}/api/admin/updateStaffingAgencyAddress/` + id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.post(localurl, body, httpOptions);
    }
  }



  enterpriseBlock(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/enterpriseblock/` + id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      return this.http.get<any>(url, httpOptions).pipe(map(enterprisblocksec => {
        return enterprisblocksec;
      }));
    } else { this._router.navigate(['/']); }
  }
  staffingBlock(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/staffingblock/` + id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(staffingblocksec => {
        return staffingblocksec;
      }));
    } else { this._router.navigate(['/']); }
  }
  enterpriseUnblock(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/enterpriseunblock/` + id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      return this.http.get<any>(url, httpOptions).pipe(map(enterprisunblocksec => {
        return enterprisunblocksec;
      }));
    } else { this._router.navigate(['/']); }
  }
  staffingUnblock(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/staffingunblock/` + id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      return this.http.get<any>(url, httpOptions).pipe(map(staffingunblocksec => {
        return staffingunblocksec;
      }));
    } else { this._router.navigate(['/']); }
  }

  filterforenterprise = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {

      // let Option = new Http()
      let url = `${environment.url}/api/admin/enterprisesview`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "status": data.status,
        "fromdate": data.fromdate,
        "todate": data.todate,
        "search": data.search,
        "profile_status": data.profile_status

      }

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    }
    else {
      this._router.navigate(['/']);
    }
  }

  getlisttimesheet = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
      
      // let Option = new Http()
      let url = `${environment.url}/api/admin/getdetailtimesheet`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "status": data.status,
        "fromdate": data.fromdate,
        "todate": data.todate,
        "search": data.search,
        "profile_status": data.profile_status,
        "employeeId":data.employeeId,
        "manager":data.manager,
        "empName":data.empName,
        "projName":data.projName,
        "sheet_status":data.sheet_status,
        "client":data.client,
        "supplier":data.supplier,
        "projectid":data.projectid,
        "jobid":data.jobid

      }

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    }
    else {
      this._router.navigate(['/']);
    }
  }

  filterforstaffing = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    // let Option = new Http()
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/staffingagenciesview`

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.pageSize,
        "PageNumber": data.PageNumber,
        "totalcandidates": data.totalcandidates,
        "fromdate": data.fromdate,
        "todate": data.todate,
        "search": data.search

      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  loadallcontacts = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getsupports`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "user_type": data.user_type,
        "search": data.search,
        "fromdate": data.fromdate,
        "todate": data.todate,
        "status":data.status
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }



  loadallcontacts_replymessage(data, tokenId) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getsupports_replymessage/` + data.user_type + '/' + tokenId
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }


  replytoquery = (data, tokenId) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/replysupports/` + tokenId
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "reply": data.reply,
        "support_status": data.support_status,
        "createdDate":data.createdDate
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  jobseekerlist(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/jobseekeresview`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "fromdate": data.fromdate,
        "todate": data.todate,
        "status": data.status,
        "search": data.search,
        "action": data.action,
        "profile_status" : data.profile_status,
        "Skillsearch1" : data.Skillsearch1,
        "Skillsearch2" : data.Skillsearch2,
        "Skillsearch3" : data.Skillsearch3,
        "Skillsearch4" : data.Skillsearch4,
        "Skillsearch5" : data.Skillsearch5,
        "skillcounter" : data.skillcounter      
      }

      if (data.vmsrid != undefined) {
        body['vmsrid'] = data.vmsrid;
      }

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  JobSeekerBlock(jobseekerId, status) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/candidateblockorunblock/` + jobseekerId + '/' + status
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  paymentlist(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getallinvoices`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "invoice_status": data.invoice_status,
        "generated_date": data.generated_date,
        "search": data.search,
        "fromdate": data.fromdate,
        "todate": data.todate
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  loadenterprise() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getenterprises`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get(url, httpOptions);
    } else { this._router.navigate(['/']); }
  }
  loadenterpriseJob(enterpriseId) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getjobs/` + enterpriseId;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get(url, httpOptions);
    } else { this._router.navigate(['/']); }
  }
  loadenterpriseJobSeekerList(jobId) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getcandidateonjob/` + jobId;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get(url, httpOptions);
    } else { this._router.navigate(['/']); }
  }
  createNewInvoice(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/generateinvoice`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      let body = {
        "dateofpayment": data.dateofpayment,
        "payableAmount": data.payableAmount,
        "jobId": data.jobId,
        "enterpriseId": data.enterpriseId,
        "jobseekerId": data.jobseekerId,
        "createdDate":data.createdDate
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  updateBasePriceForEnterprise(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/updatebaseprice`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "enterpriseId": data.enterpriseId,
        "base_price": data.base_price
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }



  // addBasePriceForEnterprise(enterpriseId,data) {
  //
  //   this.isLogin = localStorage.getItem('islogin');     if (this.isLogin == 'true') {let url = `${environment.url}/api/admin/addbaseprice`
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'authenticate': this.token + ""
  //     })
  //   };
  //   let body = {
  //     "enterpriseId": enterpriseId,
  //     "base_price": data.base_price
  //   }
  //   return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
  //     return res;
  //   }));
  // }

  getinvoiceDetails(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getinvoicedetails/` + data.enterpriseId + '/' + data.invoiceNo;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  // filger API Start
  //Manu Datta: 20210402, VerisourceBugSheetAfterMerge/commonLogin 214
  getheadertag(mspId, userType) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getheadertag/${mspId}/${userType}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  getfilters(userType, headertagname) {

    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getfilters/${userType}/${headertagname}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  updatefiltervisibility(srNo, isVisible) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/updatefiltervisibility/${srNo}/${isVisible}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  // filter API Ends
  forgotPassword(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/auth/forgotadminpassword`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.post(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  resetPassword(body, token) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/auth/resetadminpassword/${token}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.post(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  //This API used for reset Password by veridial
  resetPasswordEnterpriseFromAdminVD(formData, emailId) {
    
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    ///let url = `${environment.url}/api/admin/resetpassword/${Id}/${usertype}`;

    const localurl = this.apipath + 'Account/ChangePassword';
    const httpOptions = {
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/json',
      //   'authenticate': this.token + ""
      // })
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };

    const body=
    {        
        'email': emailId,
        'oldPassword': formData.oldPassword,
        'newPassword': formData.password,
      }

    return this.http.post(localurl, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }


  resetPasswordVD(formData) {
    const localurl = this.apipath + 'Account/ForgotPassword';
    return this.http.post(localurl, formData).pipe(map(res => {
      return res;
    }));
  }

  resetPasswordVDInAdmin(formData) {

    // console.log('data userId',userId);
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {
    const localurl = this.apipath + 'Account/ResetPasswordInAdmin';
      const httpOptions = {   
        headers :{
          Authorization:"Bearer " + this.token // token
      }
      };

      return this.http.post(localurl, formData,httpOptions).pipe(map(res => {
        return res;
      }));
      // return this.http.post(url, body, httpOptions);
    } else {
      this._router.navigate(['/']);
    }
  }

  resetPasswordEnterpriseFromAdmin(body, Id: string, usertype: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/resetpassword/${Id}/${usertype}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.post(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }


  // get total count of jobs 
  getTotalJob_Count(jobType:boolean){
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/getTotalJob_Count/${jobType}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  /* here we get the all beelineData form api*/
  LoadBeelineData = (userJob:string,data,typePopup:string,jobseeker:string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin === 'true') {
      const url = `${environment.url}/api/beeline/requestDetails`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          authenticate: this.token + ''
        })
      };

      const body = {
        PageSize: data.PageSize,
        PageNumber: data.PageNumber,
        JobStatus: data.JobStatus,
        fromdate: data.fromdate,
        todate: data.todate,
        search: data.search,
        totalapplied: data.totalapplied,
        user:userJob,
        type:typePopup,
        jobseekerId:jobseeker,
        createdby:data.createdBy,
        pool_Id:data.pool_Id,
        vkJobStatusAD:data.vkJobStatusAD,
        VMSJobStatus:data.VMSJobStatus,
        Clientsearch:data.Clientsearch,
        Citysearch:data.Citysearch,
        Titlesearch:data.Titlesearch
      };

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }


  LoadEnterpriseData = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin === 'true') {
      const url = `${environment.url}/api/beeline/requestEnterpriseDetails`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          authenticate: this.token + ''
        })
      };

      const body = {
        PageSize: data.PageSize,
        PageNumber: data.PageNumber,
        JobStatus: data.JobStatus,
        fromdate: data.fromdate,
        todate: data.todate,
        search: data.search,
        totalapplied: data.totalapplied,
        createdBy:data.createdby
      };

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  LoadBeelineCandidateData = (data, beelineRequestId,type:number) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin === 'true') {
      const url = `${environment.url}/api/beeline/candidates`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          authenticate: '' + this.token
        })
      };
      //data.beelineRequestId = beelineRequestId;
      const body = {
        PageSize: data.PageSize,
        PageNumber: data.PageNumber,
        search: data.search,
        beelineRequestId:beelineRequestId,
        type:type
      };

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }


  filterforBeelineStaffingAgency = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin === 'true') {
      const url = `${environment.url}/api/beeline/staffingAgency`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          authenticate: this.token + ''
        })
      };
      const body = {
        PageSize: data.pageSize,
        PageNumber: data.PageNumber,
        totalcandidates: data.totalcandidates,
        fromdate: data.fromdate,
        todate: data.todate,
        // fromdate: this.datePipe.transform(data.fromdate, 'yyyy-MM-dd'),
        // todate: this.datePipe.transform(data.fromdate, 'yyyy-MM-dd'),
        status: data.status,
        search: data.search,
        beelineRequestId: data.beelineRequestId
      };
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }


  assignStaffingAgencyToBeelineRequest(data,createdDate:string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin === 'true') {
      const url = `${environment.url}/api/beeline/assignStaffing`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          authenticate: this.token + ''
        })
      };
      const body = {
        beelineRequestId: data.beelineRequestId,
        staffingagencyId: data.staffingagencyId,
        assignStatus: data.assignStatus,
        createdDate: createdDate    
      };
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  updateCandidatesStatusForBeelineJob(isStatusChange: string, data, sourceId: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin === 'true') {
      const url = `${environment.url}/api/beeline/updateAndSubmitCandidatesStatus/${isStatusChange}/${sourceId}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          authenticate: this.token + ''
        })
      };
      return this.http.post<any>(url, data, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  LoadVMSCandidateDetail = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin != 'true') {
      this._router.navigate(['/']);
    }
    const url = `${environment.url}/api/beeline/candidate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authenticate: this.token + ''
      })
    };
    return this.http.post<any>(url, data, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  updateVMSChangeRequest = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin !== 'true') {
      this._router.navigate(['/']);
    }
    const url = `${environment.url}/api/beeline/updateVMSChangeRequest`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authenticate: this.token + ''
      })
    };
    return this.http.post<any>(url, data, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  updateCandidateResume = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/beeline/uploadresume`;
    const httpOptions = {
      headers: new HttpHeaders({
        authenticate: this.token + ''
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }


  updateVKVMSJobStatus = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/beeline/updateVKVMSJobStatus`;
    const httpOptions = {
      headers: new HttpHeaders({
        authenticate: this.token + ''
      })
    };
    return this.http.post<any>(localurl, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  getAdminNoteHistory = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/beeline/getAdminNoteHistory`;
    const httpOptions = {
      headers: new HttpHeaders({
        authenticate: this.token + ''
      })
    };
    return this.http.post<any>(localurl, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

 

  checksamail = (type, email) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/auth/checkemail/${type}/${email}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  checkmail(id, email) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = '';
    if (id !== '') {
      localurl = `${environment.url}/api/admin/checkemail/${id}/${email}`;
    } else {
      localurl = `${environment.url}/api/admin/checkemail/${id}/${email}`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authenticate: this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  checkJSmail(jobseekerId, email) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = '';
    if (jobseekerId !== '') {
      localurl = `${environment.url}/api/jobseeker/checkemail/${jobseekerId}/${email}`;
    } else {
      localurl = `${environment.url}/api/staffingagency/checkecandidatemail/${email}`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authenticate: this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  country() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${environment.url}/api/jobseeker/countries`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authenticate: this.token + ''
      })
    };

    return this.http.get(localurl, httpOptions);

  }
  state(countryId) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${environment.url}/api/staffingagency/states/${countryId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authenticate: this.token + ''
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  city(stateId) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${environment.url}/api/staffingagency/cities/${stateId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authenticate: this.token + ''
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  nationality() {
   
		this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${environment.url}/api/jobseeker/nationality`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authenticate: this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  // jobseeker basic details in profile page api Start
  jobseekerBasicDetails(body, id: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${environment.url}/api/admin/updateprofile/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authenticate: this.token + ''
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }


  jobseekerCheckPhoneNo(isSelectedJSid, obj) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let localurl = `${environment.url}/api/jobseeker/checkmobile/` + isSelectedJSid + '/' + obj;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get(localurl, httpOptions);
    }
    else { this._router.navigate(['/']); }
  };

  pooldetails(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/getpooldetails`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      "PageSize": data.PageSize,
      "PageNumber": data.PageNumber,
      "search": data.search.trim(),
      "fromdate": data.fromdate,
      "todate": data.todate,
    };

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }
  insertPool(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/insertpooldata`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      "poolName": data.PoolName,
      "description": data.PoolDescription,
      // "isactive": data.isactive,
    };

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }

  UpdatetPool(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/updatepooldata`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      "poolName": data.PoolName,
      "description": data.PoolDescription,
      "pk_pool_Id": data.pk_pool_Id,
      "usertype": this.userData.data.credentials.userType
    };

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }
  poolBlock(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/poolblock/` + id;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }



  poolUnblock(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/poolunblock/` + id;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };

      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  GetPoolDetails(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin === 'true') {
      let url = `${environment.url}/api/admin/getpooldetails/` + id;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  getbeelinedetails = (sa_id:string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/beeline/detail/${sa_id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        authenticate: this.token + ''
      })
    };
    return this.http.get<any>(localurl, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  saveJobseekerPooldetails(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/savejobseekerPool`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }
  getfiltersMyCandidates() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    console.log( 'islogin check',this.isLogin);
     if (this.isLogin === 'true') {
    const localurl = `${environment.url}/api/staffingagency/getfilters/AD/mycandidatesAD`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  } else { this._router.navigate(['/']); }
  }
  // country() {
  //   const localurl = `${environment.url}/api/staffingagency/countries`;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'authenticate': this.token + ''
  //     })
  //   };
  //   return this.http.get(localurl, httpOptions);
  // }



  jobseekerCheckEmail(isSelectedJSid, obj) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let localurl = `${environment.url}/api/jobseeker/checkemail/` + isSelectedJSid + '/' + obj;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get(localurl, httpOptions);
    }
    else { this._router.navigate(['/']); }
  };




  state_staff(search) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localURL = `${environment.url}/api/staffingagency/statefilter/${search}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localURL, httpOptions);
  }
  mycandidateList(body: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    console.log('this.isLogin',this.isLogin);
     if (this.isLogin === 'true') {
    const localurl = `${environment.url}/api/admin/getJobseekerListForGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post<any>(localurl, body, httpOptions).pipe(map(res => {
      return res;
    }));
  } else { this._router.navigate(['/']); }
  }


  getSavedJobseekerIdInpool(poolid) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/admin/getsavedjobseekerInPool/ ` + poolid;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get<any>(localurl, httpOptions).pipe(map(res => {
      return res;
    }));
    
  }



  SavedPoolSeekerListByPoolId(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin === 'true') {
      let url = `${environment.url}/api/admin/savedpoolseekerList`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "pk_pool_Id": data.pk_pool_Id,
      };

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  HistoryofSavedPoolSeekerListByPoolId(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin === 'true') {
      let url = `${environment.url}/api/admin/historysavedpoolseekerList`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "pk_pool_Id": data.pk_pool_Id,
      };

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }



  poolJobDetailspoolIdList(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin === 'true') {
      let url = `${environment.url}/api/admin/poolJobDetailspoolIdList`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "pk_pool_Id": data.pk_pool_Id,
        "action": data.action,
        "jobseekerId": data.jobseekerId,
      };

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  // LoadSingleJob(data,Logintype) {
  //   this.token = localStorage.getItem("token");
  //   this.AuthenticationService.jwtTokenExpCheck();
  //   const localurl = `${environment.url}/api/enterprise/getjob/`+data+'/'+Logintype;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'authenticate': this.token + ""
  //     })
  //   };
  //   return this.http.get(localurl, httpOptions);
  // }

  LoadSingleJob(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/getjob/`+data;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  enterpriseUpdateJob = (data, id) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/updatejob/` + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };

    return this.http.post(localurl, data, httpOptions);
  }

  jobseekerstatuslist(type: string, data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/jobseekerstatuslist`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "jobtype": data.jobtype,
        "jobid": data.jobid,
        "poolid": data.poolid,
        "type": type,
        "fromdate": data.fromdate,
        "todate": data.todate,
        "status": data.status,
        "jobseekerStatus":data.jobseekerStatus,
        "search": data.search
      };

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }


  JobseekerUploadResume(jobSeekerId, data,date:string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/uploadresume/${jobSeekerId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token + ""
      })
    };
    return this.http.post(localurl, data, httpOptions);
  }

  JobseekerDeleteResume(jobSeekerId, resumename) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/deleteresume/${jobSeekerId}/${resumename}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token + ""
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  resumepreview(id) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/resumepreview/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  assignedSeekerdetails(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    let url = `${environment.url}/api/admin/getAssigedSeekerdetails`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      "PageSize": data.PageSize,
      "PageNumber": data.PageNumber,
      "search": data.search.trim(),
    };

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }
  AssigncandidateList(body: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/admin/getAssigncandidateList`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post<any>(localurl, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  insertUpdateAssignJobseeker(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/InsertUpdateAssignjobseeker`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }
  GetAssignedSeekerList(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/getAssignedSeekerList`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }
  staffJobseekerProfileImg(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/uploadnewfile`;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token + ''
      })
    };
    return this.http.post<any>(localurl, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  //Update jobseeker data
  staffJobseekerupdateVD = (data: any,id) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    var aa = this.AuthenticationService.jwtTokenExpCheck();

    const localurl = this.apipath + 'Recruiter/EditJobseeker';
    const httpOptions = {
        // headers: new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'authenticate': this.token + ''
        // })

        headers :{
          Authorization:"Bearer " + this.token // token
      }

    };   
    const body=
    {
        'userId': id,
        'name': data.profile.firstName,
        'email': data.profile.emailId,
        'phone': data.profile.phone_mobile,
        'skillIds': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
        'ModifiedBy':0,
        'subscriberId': 0,
        'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
        'roleManagerId': 0,
        'appType':environment.AppType, 
        'AppRefType': environment.AppRefTypeJobseeker,
        'IsVeridial':false   
      }
      console.log('bodycheck',body);
    return this.http.post(localurl, body, httpOptions);
  }
  //ThisAPI use for save Veridial Jobseeker

  staffJobseekerCreationVD = (data: any) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.apipath + 'Account/JobseekerSignUp';
    const httpOptions = {
        // headers: new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'authenticate': this.token + ''
        // })

        headers :{
          Authorization:"Bearer " + this.token // token
      }

    };   
    const body=
    {
        'id': 0,
        'name': data.profile.firstName,
        'email': data.profile.emailId,
        'phone': data.profile.phone_mobile,
        'skillId': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
        'subscriberId': 0,
        'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
        'roleManagerId': 0,
        'AppType':environment.AppType,
        'AppRefId':data.AppRefId,
        'AppRefType':data.AppRefType,
        'ProfileImage':data.profile.profileImage
      }
      console.log('bodycheck',body);
    return this.http.post(localurl, body, httpOptions);
  }

  staffJobseekerCreation = (data: any) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${environment.url}/api/admin/uploadresume`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };

    return this.http.post(localurl, data, httpOptions);
  }

  UpdateIdFromVeridialForJobseeker = (jsId: number,Id:number) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${environment.url}/api/admin/veridialidmapwithjobseeker`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    const data =
    {
        'Id':jsId,
        'appRefId':Id,
        'type':'JS'
      }
    return this.http.post(localurl, data, httpOptions);
  }

  checkmobile(jobseekerId, phone) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = '';
    if (jobseekerId !== '') {
      localurl = `${environment.url}/api/jobseeker/checkmobile/${jobseekerId}/${phone}`;
    } else {
      localurl = `${environment.url}/api/staffingagency/checkcandidatemobile/${phone}`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  // nationality API
  searchNationality(data: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/staffingagency/searchNationality/${data}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  searchEnterprise(data: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/get_searchEnterprise/${data}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }



  // Search state API
  searchState(countryId, data: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/staffingagency/searchState/${countryId}/${data}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  // Search city API
  searchCity(stateid, data: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/staffingagency/searchCity/${stateid}/${data}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  mailcheck(jobseekerId, email) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = '';
    if (jobseekerId !== '') {
      localurl = `${environment.url}/api/jobseeker/checkemail/${jobseekerId}/${email}`;
    } else {
      localurl = `${environment.url}/api/staffingagency/checkecandidatemail/${email}`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  //Create Service for update candidate

  candidatedetails(id: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/staffingagency/candidatedetails/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getSecurityQuestions = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/guestuser/getSecurityQuestions`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  updatecandidateprofile = (body, id) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/admin/updatecandidateprofile/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token + ''
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  deteleProfileImage = (jobSeekerId, imgProfile) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/staffingagency/deteleProfileImage/${jobSeekerId}/${imgProfile}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  getProjects = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/projects`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      "PageSize": data.pageSize,
      "PageNumber": data.PageNumber,
      "search": data.search,
      "fromdate": data.fromdate,
      "todate": data.todate
    }
    return this.http.post(localurl, body, httpOptions);
  }

  addProject = (body: any, name) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${name}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  addProjectApproval = (body: any, projectId: string,lista:any) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}/approval`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    if(this.userData.data.credentials.userType === 'EP'){
     
      let data = {
        "body":body
      };
      return this.http.post(localurl, data, httpOptions);
    }
    if(this.userData.data.credentials.userType === 'AD'){
      let data = {
        "body":body,
        "docList":lista
      };
      return this.http.post(localurl, data, httpOptions);
    }
   
  }

  addProjectBudget = (body: any, projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}/budget`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  addProjectBudgetApproval = (bodydata: any,data:any, projectId: string, budgetId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}/budget/${budgetId}/approval/`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      //"PageSize": data.PageSize,
      "body":bodydata,
      "documentList":data
    };
    return this.http.post(localurl, body, httpOptions);
  }

  addProjectUser = (body: any, projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}/user`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  getProjectById = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getProjectUsersByProjectId = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}/users`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getProjectJobsByProjectId = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}/jobs`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getProjectApprovalsByProjectId = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}/approvals`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getProjectBudgetApprovalsByProjectId = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}/budgetapprovals`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getProjectBudgetsByProjectId = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}/budgets`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  //This is use for get project document by projectId 
  getProjectDocumentByProjectId = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/projectdocument/${projectId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

   //This is use for get project document by projectId
   getProjectBudgetDocumentByProjectId = (projectId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/projectbudgetdocument/${projectId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getProjectBudgetApprovalsByProjectIdAndBudgetId = (projectId: string, budgetId: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}/budget/${budgetId}/approvals`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  searchUser = (serachText: string) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/finduser/${serachText}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  parseCandidateResume(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    const localurl = `${environment.url}/api/staffingagency/parseresume`;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token + ''
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  LoadAllCountriesApi = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    const localurl = `${environment.url}/api/staffingagency/countries`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  LoadAllStateApi = (countryId) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    const localurl = `${environment.url}/api/enterprise/states/${countryId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  LoadAllCitiesApi = (stateId) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    const localurl = `${environment.url}/api/enterprise/cities/${stateId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  SearchAllCitiesApi = (countryId, search) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    const localurl = `${environment.url}/api/enterprise/joblocations/${countryId}/${search}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  postEnterpriseProjectJob = (data: any, projectId: any) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/project/${projectId}/postjob`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    }
    return this.http.post(localurl, data, httpOptions);
  }

  enterpriseJobPost = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/postingjob`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post(localurl, data, httpOptions);
  }

  checkphone = (type, mobile) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/auth/checkmobile/${type}/${mobile}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  getStaffingAgencyCount = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/StaffingAgencyCount`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }
//Manu Datta: 20210305, VerisourceBugSheetAfterMerge/commonLogin 413 Same Supplier should be registered through different jobseekers, clients and admin
  checkSupplierExistence = (id, email,type) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/auth/checkSupplierExistence/${id}/${email}/${type}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  //Manu Datta: 20210310, VerisourceBugSheetAfterMerge/commonLogin Company name and website check before page submit
  checkSuplireName = (suppName) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/auth/checksupplier/${suppName}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }
//Manu Datta: 20210310, VerisourceBugSheetAfterMerge/commonLogin Company name and website check before page submit
  checkWebsite = (website) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/auth/checkwebsite/${website}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  

  

  supplierMapping = (userId, supplierId) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/auth/supplierMapping/${userId}/${supplierId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }


  getEnterpriseCount = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/enterpriseCount`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  getJobseekerCount = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/jobseekerCount`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  getJobs = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/jobsCount`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  getmonthlyJobs = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/admin/jobsmonthlyCount`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ""
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  findBestMatch = (jobID: string, checkedCandidateList: any) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let data = {
      jobID: '',
      checkedCandidateList: { shortListedCandidatesList: [], searchCandidateList: [] },
      PageSize: 10, PageNumber: 1, fromdate: '', todate: '', status: '', search: ''
    };
    data.jobID = jobID;
    data.checkedCandidateList = checkedCandidateList;
    const localurl = `${environment.url}/api/enterprise/findBestMatch`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post<any>(localurl, data, httpOptions).pipe(map(res => {
      return res;
    }));
  }


  updateReadStatus = (tokenId) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin == 'true') {
      let url = `${environment.url}/api/admin/UpdateReadStatussupports/` + tokenId
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get(url, httpOptions);
    } else { this._router.navigate(['/']); }
  }

  getAssignedjobseekersByEntId(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin === 'true') {
      let url = `${environment.url}/api/admin/getAssignedjobseekers`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "enterpriseId": data.enterpriseId,
      };

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }

  HistoryofGetAssignedjobseekersByEntId(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin'); if (this.isLogin === 'true') {
      let url = `${environment.url}/api/admin/getAssignedjobseekersHistory`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "enterpriseId": data.enterpriseId,
      };

      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
    } else { this._router.navigate(['/']); }
  }
  
  getProjectApprovalByEmailId = (Id , projectId, status) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/enterprise/UpdateprojectApproval/${Id}/${projectId}/${status}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  getProjectBudgetApprovalByEmailId = (Id , budgetId, status) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${environment.url}/api/enterprise/UpdatebudgetprojectApproval/${Id}/${budgetId}/${status}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  approvproject(code: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/approvproject/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  approvBudgetProject(code: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/approvBudgetProject/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // getProjectApprovalByEmailId = (projectId,Id, status) => {
  //   const localurl = `${environment.url}/api/enterprise/UpdateprojectApproval/${projectId}/${Id}/${status}`;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   };
  //   return this.http.get(localurl, httpOptions);
  // }
  

  // delete project document
  deleteProjectDocById = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/enterprise/deletedoc`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      "Id": data.Id,
      "Path": data.path,
    };
    
    //return this.http.get(localurl, httpOptions);

    return this.http.post<any>(localurl, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

    // delete budget project document
    deleteProjectBudgetDocById = (data) => {
      this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
      const localurl = `${environment.url}/api/enterprise/deletebudgetdoc`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ''
        })
      };
      let body = {
        "Id": data.Id,
        "Path": data.path,
      };
      
      //return this.http.get(localurl, httpOptions);
  
      return this.http.post<any>(localurl, body, httpOptions).pipe(map(res => {
        return res;
      }));
    }
    private _GetCreatedBy = (data) => {
      
    // console.log('data', data);
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${environment.url}/api/admin/getCreatedBy`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      "Id": data.AppRefId,
      "Path": data.path,
    };

    //return this.http.get(localurl, httpOptions);
    return this.http.post<any>(localurl, data, httpOptions).pipe(map(res => {
      return res;
    }));
  };
  public get GetCreatedBy() {
    return this._GetCreatedBy;
  }
  public set GetCreatedBy(value) {
    this._GetCreatedBy = value;
  }

    GetUserById = (data) => {
      
      var usertype=data.userType;
      var Id='';
      if(usertype=='EP')   {
      Id=data.AppRefId;
      }else  if(usertype=='SA')  {
        Id=data.staffingagencyId
      }
      
      this.token = localStorage.getItem("token");
      //this.AuthenticationService.jwtTokenExpCheck();
      const localurl = `${environment.url}/api/admin/getUserById/`+usertype+'/'+Id
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ''
        })
      };
       //return this.http.get(localurl, httpOptions);
      return this.http.get<any>(localurl, httpOptions).pipe(map(res => {
        return res;
      }));
      }

      getLoginDetailVS(enterpriseId: string,token:string) {
       
        // let Option = new Http()
        //this.token = localStorage.getItem("token");
        this.AuthenticationService.jwtTokenExpCheck();
        const localurl =`${environment.url}/api/enterprise/getLoginDetailVS`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                //'authenticate': token + ''
            })
        };
        const body = enterpriseId
        return this.http.post(localurl, body, httpOptions);
    }
     
      
     
      // console.log('data',data)
      // this.token = localStorage.getItem("token");
      // this.AuthenticationService.jwtTokenExpCheck();
      // const localurl = `${environment.url}/api/admin/getUserById`;
      // const httpOptions = {
      //   headers: new HttpHeaders({
      //     'Content-Type': 'application/json',
      //     'authenticate': this.token + ''
      //   })
      // };
      // //return this.http.get(localurl, httpOptions);
  
      // return this.http.post<any>(localurl, data, httpOptions).pipe(map(res => {
      //   return res;
      // }));
    
   
      getClientDetailsApi = (clientId) => {
        this.token = localStorage.getItem("token");
        this.AuthenticationService.jwtTokenExpCheck();    
        const localurl = `${environment.url}/api/enterprise/states/${clientId}`;
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'authenticate': this.token + ''
          })
        };
        return this.http.get(localurl, httpOptions);
      }  

}
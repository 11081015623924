import { Injectable, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../app.service'

@Injectable()

export class componentServices {

    url: String;
    token: String;


    constructor(private mainAPI: AuthenticationService,
         private http: HttpClient,
         private AuthenticationService: AuthenticationService) {
        this.url = this.mainAPI.url;
        this.token = localStorage.getItem('token');
    }

    forgotAPI = (data: {}) => {
        // let Option = new Http()
        let localurl = this.url + "/api/auth/forgot"
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.post(localurl, data, httpOptions);
    }
    resetAPI = (data: { password: '', token: '' }) => {
        // let Option = new Http()
        let localurl = this.url + "/api/auth/reset/" + data.token
        // console.log("URL:" + localurl + " Data : " + data)
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        let body = { password: data.password }

        return this.http.post(localurl, body, httpOptions);
    }
    verifySecurityAnswer =(data:any,userId:string,userType:string) =>{
        let localurl = this.url + "/api/auth/verifySecurityAnswer"
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = { answer: data.answer ,question:data.securityQuestion, userId:userId ,userType:userType}
        return this.http.post(localurl, body, httpOptions);
    }
    
    insertresetValue =(resetValue:boolean,userId:any,userType:string) =>{
        let localurl = this.url + "/api/auth/insertresetValue"
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = { resetValue:resetValue ,userId:userId ,userType:userType}
        return this.http.post(localurl, body, httpOptions);
    }
    getsecurityQuestion=(userType:string,userId:string)=>{
        let localurl = this.url + "/api/auth/getSecurityQuestion"
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = { userId: userId ,userType:userType}
        return this.http.post(localurl, body, httpOptions);
    }
    

    getresetValue=(userType:string,userId:string)=>{
        let localurl = this.url + "/api/auth/getresetValue"
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = { userId: userId,userType:userType}
        return this.http.post(localurl, body, httpOptions);
    }
    socialLogin = (body) => {
        let localurl = `${this.url}/api/auth/socialsignin`
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.post(localurl, body, httpOptions);
    }


    headerAnnounce(token) {
        let localurl = this.url + "/api/staffingagency/getannouncement";
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'authenticate': `${token}`
            })
        };
        let body = {
            "PageNumber": 1,
            "PageSize": 6
        }
        return this.http.post(localurl, body, httpOptions);
    }


    headernotification(token, type) {
        
		this.AuthenticationService.jwtTokenExpCheck();

        let localurl: string = '';
        if (type == 'SA') {
            localurl = `${this.url}/api/staffingagency/getnotifications`;
        }
        if (type == 'EP') {
            localurl = `${this.url}/api/enterprise/getnotifications`;
        }
        if (type == 'JS') {
            localurl = `${this.url}/api/enterprise/getnotifications`;
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'authenticate': `${this.token}`
            })
        };    
        let body = {
            PageSize: 10,
            PageNumber: 1
        }
        // console.log("----------", type, localurl, body, httpOptions);
        return this.http.post(localurl, body, httpOptions);


    }

    getDocusignStatus(apprefId){
        this.AuthenticationService.jwtTokenExpCheck();

        let localurl: string = '';
            localurl = `${this.url}/api/staffingagency/getDocusignStatus`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'authenticate': `${this.token}`
            })
        };  
        let body = {
            "empId":apprefId
          };
      
          return this.http.post<any>(localurl, body, httpOptions);  
    }



}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { TableService } from 'src/app/admin/service';
import { Router, ActivatedRoute } from '@angular/router';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { ExcelService } from 'src/app/admin/service/excel.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatePipe } from '@angular/common';
import { ShareService } from '../share.service';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/app.service';

import moment, { Moment } from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../timesheetjobseeker/timesheetjobseeker.component';

@Component({
  selector: 'app-timesheetdetails',
  templateUrl: './timesheetdetails.component.html',
  styleUrls: ['./timesheetdetails.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class TimesheetdetailsComponent implements OnInit {
  date = new FormControl(moment());
  userType:any;
  userData: any;
  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  exitsMail  = false;
  isRequired = false;
  preloader: boolean = false;
  enterprises = [];
  enterpriseId: String = "";
  entblocksec: any;
  issuccess: any;
  entunblocksec: any;
  isAddform: boolean = false;
  signupEnterpriseForm: FormGroup;
  isCollapsedMenu: boolean = false;
  securityQuestionsList: any = [];
  defaultPageSize=5;
  paginator = {
    pageSize: this.defaultPageSize,
    length: 0,
    pageSizeOptions: [5, 10, 15, 20]
  }
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];
  entrPData: any;
  pageEvent: PageEvent;
  mobMenulist() {
    this.isCollapsedMenu = !this.isCollapsedMenu;
  }
  submitted = false;
  body: FormGroup;
  constructor(private tableService: TableService, private _router: Router, private tostr: ToastrManager, private _paramVal: ActivatedRoute, 
    private api: TableService,public dialog: MatDialog, private formBuilder: FormBuilder, 
 private AuthenticationService: AuthenticationService,
 private excelService: ExcelService,private _titleService : Title,private _route: Router,private shareservice:ShareService, private datepipe:DatePipe) {

   
   //for title set 
   var newTitle = 'Verisource | Enterprise';
   this._titleService.setTitle( newTitle );

    this.body = this.formBuilder.group({
      status: 'All',
      fromdate: '',
      todate: '',
      search: '',
      PageSize: 5,
      PageNumber: 1,
      employeeId:'',
      manager:'',
      empName:'',
      projName:'',
      sheet_status:'',
      jobid:'',
      client:'',
      supplier:'',
      projectid:''
    });
    
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.userType=this.userData.data.credentials.userType;
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.csv_timesheet, 'sample');
  }

  ngOnInit() {
    this.loadAllEnterprises();
 
  }

  searchButton = false;
  LoadData(){
    this.searchButton= true;
    this.loadAllEnterprises();
  }

  csv_timesheet=[];
  loadAllEnterprises() {
    if(this.body.value.todate !="" && this.body.value.fromdate != null){
      const d = new Date(this.body.value.todate);
      const f = new Date(this.body.value.fromdate);
      let finalDate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      let formDate = f.getFullYear() + "-" + (f.getMonth() + 1) + "-" + f.getDate();
      this.body.value.todate = this.shareservice.convertDateIntoUtc(finalDate);
      this.body.value.fromdate =this.shareservice.convertDateIntoUtc(formDate)
    }
    this.preloader = true;
    this.body.controls['fromdate'].setErrors(null);
    this.body.controls['todate'].setErrors(null);
    if(this.searchButton == true){
      this.body.controls['PageNumber'].setValue(1);
    }
    if(this.body.controls['todate'].value >= this.body.controls['fromdate'].value){
      this.tableService.getlisttimesheet(this.body.value).pipe(first()).subscribe((res: any) => {
        this.preloader = false;
        if (res.success) {
          this.enterprises = res.data;
         
          if (this.enterprises.length) {
            this.paginator.length = this.enterprises[0].totalCount;
          }
          else{
            this.paginator.length = 0;
          }
          if(this.searchButton == true)
          {
            this.paginators.pageIndex = 0;
          }
          this.searchButton = false;
          this.csv_timesheet=[];
          this.enterprises.forEach(element => {
              this.csv_timesheet.push({
                  'VK Employee ID': element.employeeId,
                  'Employee Name': element.employeeName,
                  'Manager': element.managerName,
                  'Client Name': element.client,
                  'Supplier Name':element.supplier,
                  'Job Id':element.JobId,
                  'Project No.':element.projectid,
                  'Project Name':element.projname,
                  'Month':element.period_Date,
                  'Reg Hrs':element.TotalHours,
                  'Rate':element.job_rate,
                  'OT Hrs':element.ot_totalHour,
                  'OT Rate':element.overTimePayRate,
                  'Cur.':element.currency,
                  'Cost':element.totalamount,
                  'Status':element.timesheetstatus != 'yes'?'UnApproved':'Approved'
                });        
          });
        } else {
          
          this.tostr.errorToastr(res.message, 'Error!')
        }
      });
    }
    else{
      this.tostr.errorToastr('From date should be less than To date', "error!");
    }
    
  }


  resetEnterpriseFilter() {
    this.today = new Date().toISOString().split('T')[0];
    this.from_Date = null;
    this.body = this.formBuilder.group({
      status: 'All',
      fromdate: '',
      todate: '',
      search: '',
      PageSize: 5,
      PageNumber: 1,
      employeeId:'',
      manager:'',
      empName:'',
      projName:'',
      sheet_status:'',
      jobid:'',
      client:'',
      supplier:'',
      projectid:''
    });
    this.paginator.pageSize = 5;
    this.loadAllEnterprises();
    this.paginators.pageIndex = 0; 
    this.paginators.pageSize = 5; 
  }


  getPaginatorData(e) {
    this.body.controls['PageSize'].setValue(e.pageSize);
    this.body.controls['PageNumber'].setValue(e.pageIndex + 1);
    this.searchButton = false;
    this.loadAllEnterprises();
  }

  clearData() {
    this.isAddform = false;
    this.ngOnInit();
  }

  timesheetsupplier(enterprise){
var id=enterprise.enterpriseId;
this._route.navigate(['/ad/admin/suppliertimesheet/'+id]);
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  from_Date:String;

dateForToDate(type:number){
  if(type == 1){
    this.from_Date = new Date(this.datepipe.transform(this.body.controls['fromdate'].value)).toISOString();
  }

  if(type == 2){
    this.today = new Date(this.datepipe.transform(this.body.controls['todate'].value)).toISOString();

  }
}

timesheetDetails(details){
  let jobseekerId=details.employeeId;
  if(this.userType=="SA"){
  this._route.navigate(['/st/staff/timesheetdetails/',details.period_Date,details.jobId,jobseekerId]);
  }else if(this.userType=="EP"){
   this._route.navigate(['/ep/enterprise/timesheetdetails/',details.period_Date,details.jobId,jobseekerId]);
  }else if(this.userType=="AD"){
   this._route.navigate(['/ad/admin/timesheetdetails/',details.period_Date,details.JobId,jobseekerId]);
  }
   }

   omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }

  chosenYearHandler(normalizedYear: Moment,value:string) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    if(value == 'fromdate'){
      this.body.controls['fromdate'].setValue(ctrlValue);
    } 
    if(value == 'todate'){
      this.body.controls['todate'].setValue(ctrlValue);
    } 
    
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>,value:string) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    if(value == 'fromdate'){
      this.body.controls['fromdate'].setValue(this.datepipe.transform(ctrlValue['_d'],'yyyy-MM'));
    } 
    if(value == 'todate'){
      this.body.controls['todate'].setValue(this.datepipe.transform(ctrlValue['_d'],'yyyy-MM'));
    } 
    datepicker.close();
  }
}

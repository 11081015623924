import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validator, FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { staffingAgencyServices } from '../../staffing-agency/staffing-agency.services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { JobseekerService } from '../../JobSeeker/jobseeker.service';
import { PreviewResumePopupComponent } from '../../enterprise/preview-resume-popup/preview-resume-popup.component';
import { StickyModule } from 'ng2-sticky-kit';
import { Title } from '@angular/platform-browser';
import { ShareService } from '../../share-module/share.service';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/admin/service/excel.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-timesheetjobseeker',
  templateUrl: './timesheetjobseeker.component.html',
  styleUrls: ['./timesheetjobseeker.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class TimesheetjobseekerComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  date = new FormControl(moment());
  userType:any;
  userData: any;
  id:any;
  islstopen = false;  
  preLoader = false;
  listShow = false;
  filterOption: FormGroup;
  isSuccess: any;
  filterList: any;
  candidateList: any;
  csv_timesheet=[];

  paginator = {
    pageSize: 5,
    length: 0,
    pageIndex: 0,
    pageSizeOptions: [5,10, 20, 50, 100]
  };
  
  constructor(    
    private shareServices: ShareService,
    private candidateAPI: staffingAgencyServices,
    private toastr: ToastrManager,
    private _formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private JSApi: JobseekerService,
    private dialog: MatDialog,
    private _route: Router,
    private _activated: ActivatedRoute,
    private datepipe:DatePipe,
    private _titleService : Title,
    private excelService:ExcelService) {
      this.id = this._activated.snapshot.params['id'];
      this.filterInit();
     }

  ngOnInit() {
    this.candidatList();
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.userType=this.userData.data.credentials.userType;
  }
/* #region Main */

filterInit() {
  this.filterOption = this._formBuilder.group({
    resumeStatus: ['All'],
    profileStatus: 'All',
    highestDegree: 'Any',
    minExperience: 0,
    usMasterDegree: 'All',
    country: '',
    citizenship: '',
    usaExperience: '',
    minUSAExperience: 0,
    maxUSAExperience: 0,
    skills: '',
    maxExperience: 0,
    favourite_status: ['All'],
    search: [''],
    PageSize: 5,
    PageNumber: 1,
    zipCode: '',
    state: '',
    staffingAgencyId:'',
    employeeId:'',
    manager:'',
    empName:'',
    projName:'',
    fromdate:'',
    status:'',
    jobid:'',
    client:'',
    projectid:'',
    todate:''
  });
}
/* #endregion */
OpenlfMenu() { this.islstopen = !this.islstopen; }
resetList() {
  
  this.filterInit();
  this.paginator.pageSize=5;
  this.candidatList();
  this.paginators.pageSize = 5;
  this.paginators.pageIndex = 0;
}
setpaggination(e) {
  this.filterOption.controls['PageSize'].setValue(e.pageSize);
  this.filterOption.controls['PageNumber'].setValue(e.pageIndex + 1);
  this.candidatList();
}

exportAsXLSX(): void {
  this.excelService.exportAsExcelFile(this.csv_timesheet, 'sample');
}

searchButton = false;
loadData(){
  this.searchButton= true;
  this.candidatList();
}
  candidatList() {

    this.preLoader = true;
    if(this.filterOption.controls['todate'].value >= this.filterOption.controls['fromdate'].value){
      if(this.id!=undefined && this.id!=null){
      this.filterOption.controls['staffingAgencyId'].setValue(this.id)
      
      if(this.searchButton == true){
        this.filterOption.controls['PageNumber'].setValue(1);
      }
      this.candidateAPI.clientcandidateList(this.filterOption.value).subscribe((res: any) => {
        this.preLoader = false;
        if (res.success) {
          this.listShow = true;
          this.candidateList = res;
         
         
          if (res.data.length > 0) {
            this.paginator.length = res.data[0].totalCount;
          } else {
            this.paginator.length = 0;
          }
          if(this.searchButton == true)
          {
            this.paginators.pageIndex = 0;
          }
          this.searchButton = false;         
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else{
      this.candidateAPI.getverifiedtimesheetcandidateList(this.filterOption.value).subscribe((res: any) => {
        this.preLoader = false;
        if (res.success) {
          this.listShow = true;
          this.candidateList = res;
          if(this.searchButton == true)
          {
             this.paginators.pageIndex = 0;
          }
          this.searchButton = false;
          if (res.data.length > 0) {
            this.paginator.length = res.data[0].totalCount;
          } else {
            this.paginator.length = 0;
          }
          this.csv_timesheet=[];
          this.candidateList['data'].forEach(element => {
            this.csv_timesheet.push({
                'VK Employee ID': element.employeeId,
                'Employee Name': element.employeeName,
                'Manager': element.managerName,
                'Client Name':element.client,
                'Job Id':element.jobId,
                'Project No.':element.projectid,
                'Project Name':element.projname,
                'Month':this.datepipe.transform(element.period_Date,'MMM y'),
                'Reg Hrs':element.TotalHours,
                'Rate':element.job_rate,
                'OT Hrs':element.ot_totalHour,
                'OT Rate':element.overtime_payrate,
                'Cur.':element.currency,
                'Cost':element.totalamount,
                'Status':'UnApproved'
              });
        
        });
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    }
    else{
      this.toastr.errorToastr('From date should be less than To date', "error!");
    }
   
  }

  setFilter() {
    this.candidatList();
  }

  timesheetDetails(details){
 let jobseekerId=details.employeeId;
 if(this.userType=="SA"){
 this._route.navigate(['/st/staff/timesheetdetails/',details.period_Date,details.jobId,jobseekerId]);
 }else if(this.userType=="EP"){
  this._route.navigate(['/ep/enterprise/timesheetdetails/',details.period_Date,details.jobId,jobseekerId]);
 }else if(this.userType=="AD"){
  this._route.navigate(['/ad/admin/timesheetdetails/',details.period_Date,details.jobId,jobseekerId]);
 }
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }
 
  chosenYearHandler(normalizedYear: Moment,value:string) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    if(value == 'fromdate'){
      this.filterOption.controls['fromdate'].setValue(ctrlValue);
    }
    if(value == 'todate'){
      this.filterOption.controls['todate'].setValue(ctrlValue);
    }
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>,value:string) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    if(value == 'fromdate'){
      this.filterOption.controls['fromdate'].setValue(this.datepipe.transform(ctrlValue['_d'],'yyyy-MM')); 
    } 
    if(value == 'todate'){
      this.filterOption.controls['todate'].setValue(this.datepipe.transform(ctrlValue['_d'],'yyyy-MM')); 
    }    
    datepicker.close();
  }

  selectData_forInvoice:any=[];
  checkedUnChecked(candidate: { selected: any; }, event: { checked: any; }) {
    candidate.selected = event.checked;

  }

  selectdataTimesheet(data:any, event) {
    if (event.checked) {
      this.selectData_forInvoice.push({
        employeeid: data.employeeId,
        supplierid:data.staffingagencyId,
        clientid:data.enterpriseId,
        manager:data.managerName,
        jobid:data.jobId,
        period:data.period_Date,
        status:'Pending',
        typeofinvoice:'Primary',
        createddate:this.shareServices.convertDateIntoUtc(new Date()),
        createdby:data.staffingagencyId

      });
    } else {
      for (let i = 0; i < this.selectData_forInvoice.length; i++) {
        if ((this.selectData_forInvoice[i].employeeid === data.employeeId) && (this.selectData_forInvoice[i].supplierid === data.staffingagencyId) && (this.selectData_forInvoice[i].clientid === data.enterpriseId) && (this.selectData_forInvoice[i].manager === data.managerName) && (this.selectData_forInvoice[i].jobid === data.jobId) &&  (this.selectData_forInvoice[i].period === data.period_Date)) {
          this.selectData_forInvoice.splice(i, 1);
        }
      }
    }

  }



  createInvoice(record:any){
    if(this.selectData_forInvoice.length > 0 && this.filterOption.controls['client'].value !=''){

      this.selectData_forInvoice.forEach(element => {
        this.candidateAPI.createInvoice(element).subscribe((res: any) => {
          if(res.success){
            this.candidatList();
            if(res.data.length > 0){
              this.toastr.successToastr('Invoice created successfully', "Success!");
            }
          }

        });
    });

    }
    else{
      this.toastr.errorToastr('please select client and records', "Error!");
    }
  }
}

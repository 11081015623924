import { Injectable, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { JwtService } from './admin/service/jwt.service';
// import { HeaderComponent } from '../app/component/header/header.component';
import { Router } from '@angular/router';


@Injectable()

export class AuthenticationService {
    endPoint = '';
    url = '';
    apipath = '';
    @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
    userData: any = {};
    newtoken: any;
    coreApiPath ='';
    constructor(private http: HttpClient, private jwtService: JwtService
        , private router: Router,) {
        this.endPoint = `${environment.endPoint}`;
        this.url = `${environment.url}`;
        this.apipath = `${environment.apipath}`;
        this.coreApiPath = `${environment.coreapipath}`;
        // console.log('test 123')
    }
    // this is for check and refresh token 
    jwtTokenExpCheck() {
        const now = Date.now().valueOf() / 1000;
        const token = localStorage.getItem('token'); // this.jwtService.getToken(); udata
        const udata = localStorage.getItem('udata');
        // console.log('token', token);
        if (token != null) {
            const decoded = this.jwtService.getDecodedAccessToken(token);
            // console.log('decoded.exp', decoded, 'udata', udata);
            const diffValue = (decoded.exp - now);
            const diffmint = diffValue / 60;
            console.log('diffmint', diffmint);
            if (diffmint <= 5 && diffmint >= 2) {
                // if (diffmint <= 26 && diffmint >= 1) {



                this.logData(decoded);
                //  window.location.reload();   
            }
            if (diffmint <= 1) {

                localStorage.setItem('islogin', 'false');
                localStorage.removeItem('token');
                localStorage.removeItem('udata');
                this.router.navigate(['/auth/home']);

            }
        }

    }
    checkToken() {
        const token = localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            this.userVarification(token).subscribe(
                res => {
                    this.userData = res;
                    //  console.log('token',res);
                    //  localStorage.setItem('token', JSON.stringify(this.userData.data.token));
                    // console.log('this.userData', res, 'this.userData1', JSON.stringify(this.userData.data.token));
                    //  alert(JSON.stringify(this.userData.data.token))
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
    }
    logData(decoded) {

        return new Promise((resolve, reject) => {
            this.refreshAPI(decoded).subscribe(
                res => {
                    this.userData = res;
                    localStorage.removeItem('token');
                    localStorage.setItem('token', this.userData.data.token);
                    console.log('new token',)
                    // console.log('this.userData', res, 'this.userData1', JSON.stringify(this.userData.data.token));
                    //  alert(JSON.stringify(this.userData.data.token))
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
    }


    logout(): void {


        this.getLoggedInName.emit('SignIn');
    }

    refreshAPI(data) {
        const localurl = this.url + '/api/auth/RefreshToken';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = {
            'isAdmin': data.isAdmin,
            'emailId': data.emailId,
            'firstName': data.firstName,
            'userType': data.userType,
            'lastName': data.lastName,
            'checkUser': data.typeUser,
            'id': data.id,
            'profile_status': data.profile_status,
            'userId': data.userId,
        };
        return this.http.post(localurl, body, httpOptions);


    }
    loginAPI = (isAdmin: any, data) => {  
        debugger
        // let Option = new Http()
        //const localurl = this.url + '/api/auth/login';  //  this details for login user by VS

        // const localurl = this.apipath + 'Authentication/Token';  // this details for login user by VD
        const localurl = `${this.coreApiPath}/Token`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        const body = {
            'email':data.email,
            'password':data.password
        }
        return this.http.post(localurl, body);
        // if (isAdmin && data.typeUser != 'AD') {

        //     const body = {
        //         'isAdmin': isAdmin,
        //         'emailId': data.email,
        //         'password': data.password,
        //         'userType': 'AD',
        //         'userEmail': data.userEmail,
        //         'checkUser': data.typeUser
        //         //'apptype':'Verisource'
        //     };
        //     return this.http.post(localurl, body, httpOptions);
        // }
        // else {
            

        //     const body = {
        //         'userName': data.email,    // 'userName'  this details for login user by VD
        //         'password': data.password ,//  'password' this details for login user by VD
        //         'apptype':environment.AppType
        //         // 'emailId': data.email,  //  this details for login user by VS
        //         // 'password': data.password   //  this details for login user by VS
        //         // ,'userType': data.userType   //  this details for login user by VS
        //     };
        //     // return this.http.post(localurl, body,httpOptions);    //  this details for login user by VS
        //     return this.http.post(localurl, body);     //  this details for login user by VD

        // }
    }
    //for login deatils
    getLoginDetail(agencyId: string, email: string, userType: string) {
        // let Option = new Http()
        const localurl = this.url + '/api/auth/loginDetails';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = {
            'emailId': email,
            'agencyId': agencyId,
            'userType': userType
        };
        return this.http.post(localurl, body, httpOptions);
    }

    //Get Login Details from verisource



    staffRegisterVD = (data,appRefId:Number) => {
        const localurl = this.apipath + 'Account/RecruiterSignUpW'; // this is for VD register

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = // data;
        {
            'id': 0,
            'name': data.firstName + ' ' + data.lastName,
            'email': data.emailId,
            'phone': data.contactNo,
            'skillId': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
            'subscriberId': data.createdby==''?0:Number(data.createdby),
            'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
            'roleManagerId': 0,
            'AppType':environment.AppType,
            'AppRefId':appRefId,
            'AppRefType':environment.AppRefTypeSupplier
        }
        console.log('body', body);
        // return this.http.post(localurl, body, httpOptions);
        return this.http.post(localurl, body);
    }
    staffRegister = (data) => {
        const localurl = this.url + '/api/staffingagency/register'; // this is for VS register
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = data;
        console.log('body', body);
        return this.http.post(localurl, body, httpOptions);
    }

//this API use for register enterprise in Veridial
    enterpriseRegisterVD = (data,appRefId:Number) => {
        
        const localurl = this.apipath + 'SuperAdmin/AddCompanyW';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body =       //data;
        {
            'Website': data.website,
            'Email': data.emailId,
            'Phone': data.contactNo,
            'CompName': data.companyName,
            'AboutProfile': "about profile1",
            'Address': "delhi",
            'City': "sector 3",
            'CompanyType': 1,
            'CountryId': 2,
            'Ein': "2145456454",
            'FacebookUrl': "facebook1.com",
            'LinkedinUrl': "linkedin1.com",
            'State': "UP",
            'Street': "noida",
            'TwitterUrl': "www.twitter1.com",
            'Zip': "45654",
            'AppType':environment.AppType,
            'AppRefId':appRefId,
            'AppRefType':environment.AppRefTypeClient,
            'IsVerified':false,

        }
        return this.http.post(localurl, body, httpOptions);
    }

    enterpriseRegister = (data) => {
        const localurl = this.url + '/api/enterprise/register';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = data;
        return this.http.post(localurl, body, httpOptions);
    }



    searchjobs = () => {
        const localurl = this.url + '/api/staffingagency/getjobss';
        return this.http.get(localurl);
    }



    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }

    jobseekerRegisterVD = (data,appRefId:Number) => {
        const localurl = this.apipath + 'Account/JobseekerSignUpW';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        // const body = {
        //     'firstName': data.firstName,
        //     'middleName': data.middleName,
        //     'lastName': data.lastName,
        //     'emailId': data.emailId,
        //     'contactNo': data.contactNo,
        //     'password': data.password
        // };
//this perameter used for veridial API
        const body=
        {
            'id': 0,
            'name': data.firstName + ' ' + data.lastName,
            'email': data.emailId,
            'phone': data.contactNo,
            'skillId':  environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
            'subscriberId': 0,
            'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
            'roleManagerId': 1,
            'AppType':environment.AppType,
            'AppRefId':appRefId,
            'AppRefType':environment.AppRefTypeJobseeker
            // 'RoleLevel':{
            // 'Id': 110,
            // 'RoleId': 109
            // }
            //'CreatedBy':2558
          }

        // {
        //     'id': 0,
        //     'name': "Manu",
        //     'email': "manu01@yopmail.com",
        //     'phone': "1234567890",
        //     'skillId': "128",
        //     'subscriberId': 0,
        //     'permissionId': 110,
        //     'roleManagerId':1
        //   }

        return this.http.post(localurl, body, httpOptions);
    }

    jobseekerRegister = (data) => {
        const localurl = this.url + '/api/jobseeker/register';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = {
            'firstName': data.firstName,
            'middleName': data.middleName,
            'lastName': data.lastName,
            'emailId': data.emailId,
            'contactNo': data.contactNo,
            'password': data.password,
            'createdDate': data.createdDate,
            'vdJobskrId':data.vdJobskrId
        };
        return this.http.post(localurl, body, httpOptions);
    }

    UpdateIdFromVeridial = (Id: number,appRefId:number,type:string) => {

        let localurl = `${environment.url}/api/admin/veridialidmapwithjobseekerwithoutAuthenticate`;
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        };
        const data=
        {
            'Id':Id,
            'appRefId':appRefId,
            'type':type
          }
        return this.http.post(localurl, data, httpOptions);
      }

      UpdateIdFromVeridialForEnterprise = (epId: number,Id:number) => {

        let localurl = `${environment.url}/api/admin/veridialidmapwithjobseekerwithoutAuthenticate`;
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        };
        const data=
        {
            'Id':epId,
            'appRefId':Id,
            'type':'EP'
          }
        return this.http.post(localurl, data, httpOptions);
    }

    getfiltersGuestBrowsejobs() {
        const localurl = `${this.url}/api/guestuser/getfilters/GU/searchjobs`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.get(localurl, httpOptions);
    }

    joblocation = (search: string) => {
        const localurl = this.url + '/api/guestuser/joblocation/' + search;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',

            })
        };
        return this.http.get(localurl, httpOptions);
    }

    checkmail = (type, email) => {
        const localurl = `${this.url}/api/auth/checkemail/${type}/${email}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(localurl, httpOptions);
    }
    checkweb = (type, web) => {
        const localurl = `${this.url}/api/auth/checkweb/${type}/${web}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(localurl, httpOptions);
    }
    checkSuplireName = (id, name) => {
        const localurl = `${this.url}/api/auth/checkSuplireName/${id}/${name}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(localurl, httpOptions);
    }
    validateAdmin = (type, email, password) => {
        const localurl = `${this.url}/api/auth/validateAdmn`;
        // const localurl = this.url + '/api/auth/validateAdmn';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = {
            'type': type,
            'email': email,
            'password': password
        }
        return this.http.post(localurl, body, httpOptions);
    }

    checkphone = (type, mobile) => {
        const localurl = `${this.url}/api/auth/checkmobile/${type}/${mobile}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(localurl, httpOptions);
    }

    countries = () => {
        const localurl = this.url + '/api/guestuser/countries';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(localurl, httpOptions);
    }

    getSecurityQuestions = () => {
        const localurl = this.url + '/api/guestuser/getSecurityQuestions';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(localurl, httpOptions);
    }

    // state API
    state(searchText: string) {
        const localurl = this.url + '/api/guestuser/states/' + searchText;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(localurl, httpOptions);
    }

    // City API
    city(stateId: any) {
        const localurl = this.url + '/api/guestuser/cities/' + stateId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(localurl, httpOptions);
    }

    userVarification = (token) => {
        const localurl = `${this.url}/api/jobseeker/verification/${token}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(localurl, httpOptions);
    }
}

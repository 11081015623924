import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { EnterpriseService } from '../../enterprise/enterprise.service';
import { first } from 'rxjs/operators';
import { AddGroupComponent } from '../add-group/add-group.component';
import { InvitationComponent } from '../invitation/invitation.component';
import { PoolJobSeekerComponent } from '../pool-job-seeker/pool-job-seeker.component';
import { PoolJobDetailsComponent } from '../pool-job-details/pool-job-details.component';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/admin/service/excel.service';

import * as moment from 'moment';
import {AuthenticationService } from '../../app.service';
  
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { ShareService } from '../share.service';


@Component({
  selector: 'app-group-jobseeker',
  templateUrl: './group-jobseeker.component.html',
  styleUrls: ['./group-jobseeker.component.css'],
  providers: [    
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class GroupJobseekerComponent implements OnInit {
  // Datepicker takes `Moment` objects instead of `Date` objects.
  date = new FormControl(moment([2017, 0, 1]));
  @ViewChild(MatPaginator,{static : false} ) paginators: MatPaginator;
  poolForm: FormGroup;
  poollist: any;
  preloader: boolean;
  showDetails: boolean;
  blocksec: any;
  unblocksec: any;
  isAddform: boolean = true;
  csv_poollist:any=[];
  paginator = {
    pageSize: 10,
    length: 0,
    pageSizeOptions: [10, 15, 20],
    pageIndex: 0
  };
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];
  userData:any={};
  constructor(public dialog: MatDialog, private enterPriseAPI: EnterpriseService, private _titleService: Title,
    private formBuilder: FormBuilder, private tostr: ToastrManager, private _router: Router,private datePipe:
   DatePipe,private excelService : ExcelService,  private AuthenticationService: AuthenticationService, private shareservice:ShareService) {
    const newTitle = 'Verisource | Group ';
    this._titleService.setTitle(newTitle);
    this.userData=JSON.parse(window.localStorage.getItem('udata'));
    this.poolForm = this.formBuilder.group({
      search: '',
      PageSize: 10,
      PageNumber: 1,
      fromdate: '',
      todate: '',
      status: 'All',
    });
     this.loadpoollist();
  }

  ngOnInit() {
   // this.loadpoollist();
  }
                   
  get aj() {
    return this.poolForm.controls;
  }

  searchButton = false;
  loadData(){
    this.searchButton= true;
    this.loadpoollist();
  }

  loadpoollist() {

    this.AuthenticationService.jwtTokenExpCheck();

    this.preloader = true;
    this.poolForm.controls["fromdate"].setValue(this.datePipe.transform(this.poolForm.controls["fromdate"].value, "yyyy-MM-dd")) 
    this.poolForm.controls["todate"].setValue(this.datePipe.transform(this.poolForm.controls["todate"].value, "yyyy-MM-dd 23:59:59")) 
    if(this.searchButton == true){
      this.poolForm.controls['PageNumber'].setValue(1);
    }
    this.poolForm.controls["fromdate"].setValue(this.shareservice.convertDateIntoUtc( this.poolForm.controls["fromdate"].value))
    this.poolForm.controls["todate"].setValue(this.shareservice.convertDateIntoUtc( this.poolForm.controls["todate"].value))

    this.enterPriseAPI.pooldetails(this.poolForm.value).pipe(first()).subscribe(res => {
    
      if (res.success) {
        console.log('res.data',res.data);
        this.poollist = res.data;
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }
        this.showDetails = true;
        this.preloader = false;
        if (this.poollist.length > 0) {
          this.paginator.length = this.poollist[0].totalCount;
        }
        this.poollist.forEach(element => {
          this.csv_poollist.push({
              'Group ID': element.poolValue_Id,
              'Name': element.poolName,
              'Job Seeker': element.totaljobseeker,
              'Created Date': element.createdDate,
              'Description':element.description,
              'Status':element.status,
              'History':element.totalPooljobseekerHistory
            });
      
      });
      }
    });
  }

  getpaginatorData(e) {
    this.searchButton = false;
    // this.poolForm.controls.PageSize.setValue(e.pageSize);
    // this.poolForm.controls.PageNumber.setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.poolForm.controls['PageSize'].setValue(e.pageSize);
    this.poolForm.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.loadpoollist();
  }

  isLogin='true';
  openDialog() {

    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(AddGroupComponent, {
      width: 'auto',
      data: this.isAddform = true
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.loadpoollist();
    });
  }
  }

  openAddSeekers(record: any) {

    console.log(record);
    localStorage.removeItem('CurrentGroupRequest');
    localStorage.setItem('CurrentGroupRequest', JSON.stringify(record));

    if(this.userData.data.credentials.userType === 'AD'){
      this._router.navigate(['/ad/admin/addseeker/', record.pk_pool_Id]);
    }
    if(this.userData.data.credentials.userType === 'EP')
    this._router.navigate(['/ep/enterprise/addseeker/', record.pk_pool_Id]);
  }
  openUpdateSeekers(record: any) {
    localStorage.removeItem('CurrentGroupRequest');
    localStorage.setItem('CurrentGroupRequest', JSON.stringify(record));
    if(this.userData.data.credentials.userType === 'AD'){
    this._router.navigate(['/ad/admin/updateseeker/', 'updateseeker', record.pk_pool_Id]);
    }
    if(this.userData.data.credentials.userType === 'EP'){
      this._router.navigate(['/ep/enterprise/updateseeker/', 'updateseeker', record.pk_pool_Id]);
    }
  }

  candidateList(record: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    console.log("record", record);
    const dialogRef = this.dialog.open(PoolJobSeekerComponent, {
      width: 'auto',
      data: record,
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  }
  candidateListHistory(record: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    console.log("record", record);
    record = ({
      historycandidate: true,
      pk_pool_Id: record.pk_pool_Id,
      poolName: record.poolName,
    });
    console.log("record1", record);

    const dialogRef = this.dialog.open(PoolJobSeekerComponent, {
      width: 'auto',
      data: record,
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  }
  openUpdateDialog(record: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(AddGroupComponent, {
      width: 'auto',
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.issuccess){
        this.loadpoollist();
      }

      console.log('The dialog was closed');
    });
  }

  }
  
  // convert table data into ecel file and able to download 
  exportAsXLSX(): void {

    this.AuthenticationService.jwtTokenExpCheck();
     this.excelService.exportAsExcelFile(this.csv_poollist, 'sample');
  }

  polljobDetailsHistory(record: any) {
  
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(PoolJobDetailsComponent, {
      width: 'auto',
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  }


  clearPoolData(){
    this.today = new Date().toISOString().split('T')[0];
    this.from_Date = null;
    this.poolForm = this.formBuilder.group({
      search: '',
      PageSize: 10,
      PageNumber: 1,
      fromdate: '',
      todate: '',
      status: 'All'
    });
    this.paginator.pageSize=10;
    this.loadpoollist();
    this.paginators.pageIndex = 0;
  }
  
  blockpool(id) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.enterPriseAPI.poolBlock(id).pipe(first()).subscribe(res => {
      this.blocksec = res;
      if (this.blocksec.success) {
        this.tostr.successToastr(this.blocksec.message, 'Success!');
        this.loadpoollist();
      } else {
        this.tostr.errorToastr(this.blocksec.message, 'OPPS!');
      }
    });
  }

  unblockpool(id) {
    this.AuthenticationService.jwtTokenExpCheck();
    console.log('unblock', id);
    this.enterPriseAPI.poolUnblock(id).pipe(first()).subscribe(res => {
      this.unblocksec = res;
      if (this.unblocksec.success) {
        this.tostr.successToastr(this.unblocksec.message, "Success!");
        this.loadpoollist();
      } else {
        this.tostr.errorToastr(this.unblocksec.message, 'OPPS!')
      }

    });
  }
  openJobInvitationDialog(record: any) {
  
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    console.log(record);
    const dialogRef = this.dialog.open(InvitationComponent, {
      height: '95%',
      width: '95%',
      data: {data:record,type:'admin'}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  getErrorMessage(pickerInput: string): string {
    // if (!pickerInput || pickerInput === '' ) {
    //   return 'Please choose a date.';
    // }
    return this.isMyDateFormat(pickerInput);
  }
  /**
   * An example of custom validator on a date string.
   * Valid date is a string, which is:
   * 1, in the form of YYYY-MM-DD
   * 2, later than today
   * 3, not an invalid value like 2018-20-81
   * @param date a date string
   */
  isMyDateFormat(date: string): string {
    if (date.length !== 10) {
      return 'Invalid input: Please input a string in the form of YYYY-MM-DD';
    } else {
      const da = date.split('-');
      if (da.length !== 3 || da[0].length !== 4 || da[1].length !== 2 || da[2].length !== 2) {
        return 'Invalid input: Please input a string in the form of YYYY-MM-DD';
      } else if (moment(date).isValid()) {
        return 'Invalid date: Please input a date no later than today';
      } else if (!moment(date).isValid()) {
        return 'Invalid date: Please input a date with a valid month and date.';
      }
    }
    return 'Unknown error.';
  }

  from_Date:String;
dateForToDate(type:number){
  if(type == 1){
    this.from_Date = new Date(this.datePipe.transform(this.poolForm.controls['fromdate'].value)).toISOString();
  }

  if(type == 2){
    this.today = new Date(this.datePipe.transform(this.poolForm.controls['todate'].value)).toISOString();
  }
}
}

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/app.service';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';
import { ShareService } from '../share.service';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-raise-invoicereport',
  templateUrl: './raise-invoicereport.component.html',
  styleUrls: ['./raise-invoicereport.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class RaiseInvoicereportComponent implements OnInit {

  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  date = new FormControl(moment());
  body: FormGroup;
  invoicereport:any=[];
  invoicedetail:any=[];
  raiseInvoice=false;
  invoicePart=true;
  filename="";
  paginator = {
    pageSize: 5,
    length: 0,
    pageIndex: 0,
    pageSizeOptions: [5,10, 20, 50, 100]
  };
  userData:any={};
  constructor(private shareservices: ShareService,private AuthenticationService: AuthenticationService,
    private staffingservice: staffingAgencyServices,private tostr: ToastrManager,private formBuilder: FormBuilder, private datepipe:DatePipe) { 
      this.userData = JSON.parse(window.localStorage.getItem('udata'));
      this.body = this.formBuilder.group({
        fromdate: '',
        todate: '',
        search: '',
        PageSize: 5,
        PageNumber: 1,
        invoiceid:'',
        status:'',
        source:'',
        invoicetype:'',
        currency:'',
        amount:0,
        client:'',
        clientemail:'',
        usertype:this.userData.data.credentials.userType
      });

      this.loadAllInvoice_Report();
  }

  ngOnInit() {
  }


  loadAllInvoice_Report() {
    if(this.body.controls['todate'].value >= this.body.controls['fromdate'].value){
      if(this.searchButton == true){
        this.body.controls['PageNumber'].setValue(1);
      }
      this.body.value.fromdate=(this.datepipe.transform(this.body.value.fromdate,'yyyy-M'))
      this.body.value.todate=(this.datepipe.transform(this.body.value.todate,'yyyy-M'))
      this.staffingservice.getRaiseInvoiceReport(this.body.value).pipe(first()).subscribe((res: any) => {

        if (res.success) {
         this.invoicereport = res.data;

         if (res.data.length > 0) {    
          this.paginator.length = res.data[0].totalCount;
          if(this.searchButton == true)
          {
            this.paginators.pageIndex = 0;
          }
          this.searchButton = false;
        } else {
          this.paginator.length = 0;
        }
       
        } else {
          
          this.tostr.errorToastr(res.message, 'Error!')
        }
      });
    }
    else{
      this.tostr.errorToastr('From date should be less than To date', "error!");
    }
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  resetList() {
  
    this.body = this.formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      PageSize: 5,
      PageNumber: 1,
      invoiceid:'',
      status:'',
      source:'',
      invoicetype:'',
      currency:'',
      amount:0,
      client:'',
      clientemail:'',
      usertype:this.userData.data.credentials.userType
    });
    this.searchButton = false;
    this.paginator.pageSize=5;
    this.loadAllInvoice_Report();
    this.paginators.pageSize = 5;
    this.paginators.pageIndex = 0;
  }

  searchButton = false;
  loadData(){
  this.searchButton= true;
  this.loadAllInvoice_Report();
}

setpaggination(e) {
  this.body.controls['PageSize'].setValue(e.pageSize);
  this.body.controls['PageNumber'].setValue(e.pageIndex + 1);
  this.searchButton = false;
  this.loadAllInvoice_Report();
}

chosenYearHandler(normalizedYear: Moment,value:string) {
  const ctrlValue = this.date.value;
  ctrlValue.year(normalizedYear.year());
  if(value == 'fromdate'){
    this.body.controls['fromdate'].setValue(ctrlValue);
  }
  if(value == 'todate'){
    this.body.controls['todate'].setValue(ctrlValue);
  }
}

chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>,value:string) {
  const ctrlValue = this.date.value;
  ctrlValue.month(normalizedMonth.month());
  if(value == 'fromdate'){
    this.body.controls['fromdate'].setValue(this.datepipe.transform(ctrlValue['_d'],'yyyy-MM')); 
  } 
  if(value == 'todate'){
    this.body.controls['todate'].setValue(this.datepipe.transform(ctrlValue['_d'],'yyyy-MM')); 
  }    
  datepicker.close();
}











}

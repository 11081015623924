import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RouterLink, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { authComponentClass } from './auth.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AboutUsDetailsComponent } from './about-us-details/about-us-details.component';
// import { ContactUsComponent } from '../share-module/contact-us/contact-us.component';
import { VerifDetailComponent } from './verif-detail/verif-detail.component';
import { authRouter } from './auth.routing';
// import { BrowseJobComponent } from './browse-job/browse-job.component';
import { ShareModuleModule } from '../share-module/share-module.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { EnterpriseModule } from '../enterprise/enterprise.module';
import { HttpClientModule } from '@angular/common/http';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { AuthSuccessComponent } from './auth-success/auth-success.component';
import { Title } from '@angular/platform-browser';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    authRouter,
    HttpClientModule,
    NgSelectModule,
    ShareModuleModule, EnterpriseModule
  ],
  declarations: [HomeComponent, authComponentClass, ResetPasswordComponent,
    AboutUsDetailsComponent, VerifDetailComponent, TermsConditionComponent, AuthSuccessComponent,
    // BrowseJobComponent,
    //  ContactUsComponent
  ],
  exports: [authComponentClass],
  providers : [Title]
})
export class AuthModule { }

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/app.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { NewEnterpriseService } from 'src/app/enterprise/newenterprise.service';
import { Constant } from 'src/app/share-module/constant/constant.constants';

export interface DialogData {
  jobId: string;
  date: Date;
  // time: Time
}

@Component({
  selector: 'app-job-detail-pop-up',
  templateUrl: './job-detail-pop-up.component.html',
  styleUrls: ['./job-detail-pop-up.component.css']
})
export class JobDetailPopUpComponent implements OnInit {
  baseUrl: String = '';
  isDataready: boolean = false;
  jobData: any;
  readmore:boolean = false;

  lookUpInfo : any={};
  allCurrency : any={};
  profitCenter : any={};
  businessUnit : any={};
  rateType : any={};
  payType : any={};
  countries : any ={}
  states : any={}
  jobTypes : any={}
  Countries : any={}
  rateLocationStates : any={}
  JobDetails : any={}
  rateLocation : any={}
  jobLocation : any={}
  month:Number;
  days:Number;
  remainingDays:Number;
  previewCountry='';
  previewState='';
  previewCurrency='';
  previewRateType='';
  previewJobType='';
  previewCurrentCountry='';
  previewSecondState = '';
  paytypeDisplayName='';

  constructor(
    private mainApi: AuthenticationService,
    private dialogRef: MatDialogRef<JobDetailPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private AuthenticationService: AuthenticationService,
    private epAPI: EnterpriseService,
    private enterpriseService : NewEnterpriseService,private router:Router
  ) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.baseUrl = `${this.mainApi.url}/api/enterprise/image/resume/`;
  }

  ngOnInit() {
    this.loadLookupData();
  }
  initData() {
    try {
      this.isDataready = true;
      this.enterpriseService.loadLookUpData(Number(this.data.jobId)).subscribe((res: any) => {
        if(res.status == 200){
          this.jobData = res.body;
          this.isDataready = false;
        }
        console.log(res);
      });
    } catch (error) {
      // console.log('Error catch :', error);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  loadLookupData(){
    debugger
      this.AuthenticationService.jwtTokenExpCheck();
      this.isDataready = true;
       this.enterpriseService.loadLookUpData(Number(this.data.jobId)).subscribe((res: any={}) => {
          //this.preloader = false;
          if (res.status == 200) {
            this.isDataready = false;
              if(res.body){
              this.lookUpInfo = res.body;
              console.log('res.data=========',this.lookUpInfo);
              this.allCurrency = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.Currency);
              this.rateType = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.RateType);
              this.payType = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.PayType);
              this.jobTypes = this.lookUpInfo.lookUp.filter(x =>x.lookUpCode == Constant.JobType);
              this.rateLocation = this.lookUpInfo.rateLocations;
              this.jobLocation = this.lookUpInfo.jobLocations;
              this.profitCenter = this.lookUpInfo.allProfitCenter;
              this.businessUnit = this.lookUpInfo.allBusinessUnit;
              this.countries = this.lookUpInfo.countries;
              this.states = this.lookUpInfo.states;
              if(Number(this.data.jobId) != 0 ){
                const keywords = this.lookUpInfo.jobKeywords.split(',');
              this.month = Math.floor(this.lookUpInfo.jobDuration/30);
                    
              this.previewCountry = this.countries.filter(x=>x.id == this.jobLocation[0].country)[0].country_Name;
              this.previewState = this.states.filter(x=>x.id == this.jobLocation[0].state)[0].state_Name;
              this.previewCurrentCountry = this.countries.filter(x=>x.id == this.rateLocation[0].country)[0].country_Name;
              this.LoadAllState(false);
              if(this.ratelocationStates.length > 0){
                this.previewSecondState = this.ratelocationStates.filter(x=>x.id == this.rateLocation[0].state)[0].state_Name;
              }
              this.previewCurrency = this.allCurrency.filter(x=>x.id == this.lookUpInfo.currency)[0].displayName;
              this.previewRateType = this.rateType.filter(x=>x.id == this.lookUpInfo.rateType)[0].displayName;
              this.previewJobType = this.jobTypes.filter(x=>x.id == this.lookUpInfo.jobType)[0].displayName;
              this.paytypeDisplayName = this.payType.filter(x=>x.id == this.lookUpInfo.payType)[0].displayName;   
              }
              console.log('===================',this.lookUpInfo[0]);
          
          // } else {
          //   console.log(res);
          // }
            }
          }
            else{
              
            }
        });
     }
  
    //  LoadAllState(type:boolean){
    //   return new Promise((resolve, reject) => {
    //     debugger;
    //     this.enterpriseService.LoadAllState(this.enterpriseAddjob.controls['secondcountry'].value).subscribe((resp:any) => {
    //       if (resp) {
    //         this.rateLocationStates = resp;
    //         this.previewwSecondState = this.rateLocationStates.filter(x=>x.id == this.enterpriseAddjob.controls['secondState'].value)[0].state_Name;
    //         resolve(this.rateLocationStates);
    //       } else {
    //         reject("Error!");
    //       }
    //     });
    //   });
  
    // }

  ratelocationStates : any={}
  preLoader =false;
  rateStateList: any;
  rateStateloader:boolean =false;
  stateLoader:boolean= false;
  isStateSuccess: boolean = false;
  LoadAllState(type:boolean){
    debugger
    this.preLoader =true;
    // this.enterpriseAddjob.controls['secondcountry'].setValue(type==true?this.enterpriseAddjob.controls['country'].value:this.enterpriseAddjob.controls['secondcountry'].value);
    return new Promise((resolve, reject) => {
      debugger;
      this.enterpriseService.LoadAllState(type==true?this.jobLocation[0].country:this.rateLocation[0].country).subscribe((res:any) => {
        if(res.status == 200) {
          // resolve(this.states);
          //this.states = res.data;
          if(type){
            this.states = res;
            if(this.jobLocation[0].country == this.rateLocation[0].country){
              this.ratelocationStates = res;
            }
            resolve(this.states);
            this.preLoader = false;
            // this.allStateList=res;
            this.stateLoader = false;
            this.isStateSuccess = true;
          }
          else{
            this.ratelocationStates =res;
            this.previewSecondState = this.ratelocationStates.filter(x=>x.id == this.rateLocation[0].state)[0].state_Name;
            this.preLoader =false;
            this.rateStateList =res;
            this.rateStateloader =false;
          }
          // this.enterpriseAddjob.controls['state'].setErrors({ 'incorrect': true });
        } 
        else if(res.status == 403){
          this.router.navigate(['/errorpage']);
        }
        else {
          reject("Error!");
        }
      });
    });

  }



}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormGroup, FormBuilder, Validators, EmailValidator, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TableService } from 'src/app/admin/service';
import { ExcelService } from 'src/app/admin/service/excel.service';
import { environment } from 'src/environments/environment';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import {AuthenticationService } from '../../app.service';
import { ShareService } from '../share.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
  providers: [    
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class ProjectComponent implements OnInit {
  @ViewChild('myfile',{static:false}) myfile: ElementRef;
   // Datepicker takes `Moment` objects instead of `Date` objects.
   date = new FormControl(moment([2017, 0, 1]));
  totalbudget:number=0;
  total:number = 0;
  Isjob: boolean = false;
  docIdSelected: any = [];
  areAllSelected = false;
  private routeSub: Subscription;
  private projectId: string;
  private budgetId: string;
  isRequired = false;
  preloader: boolean = false;
  currentProjectNo: string;
  isAddApprovalform: boolean = false;
  searchForm: FormGroup;
  projectApprovalForm: FormGroup;
  projectApprovalForm1: FormGroup;
  addManagerOrAdminForm: FormGroup;
  projectBudgetForm: FormGroup;
  budgetApprovalForm: FormGroup;
  isApprovalPopUp: boolean = false;
  isApprovalPopUp1: boolean = false;
  isApprovalPopUp2: boolean = false;
  isAddBudgetForm: boolean = false;
  isBudgetApprovalPopUp: boolean = false;
  isAddManagerOrAdminForm: boolean = false;
  submitted: boolean = false;
  budgetApprovalSubmitted: boolean = false;
  budgetFromSubmitted: boolean = false;
  userFromSubmitted: boolean = false;
  docResponse: any;
  Id: any;
  status: any;
  projDetail: any;
  resumePath = '';
  budgetdocPath = '';
  projectDetails = {
    projectName: '',
    projectNo: '',
    totalAllocatedBudgetAmt: '',
    fromDate: '',
    toDate: '',
    mspFees: '',
    vmsFees: '',
    otherFees: '',
    saMarkupFees: '',
    manager: '',
    admin: ''
  };
  budgets = [];
  document = [];
  budgetdocument = [];
  projectUsers = [];
  projectApprovedByUsers = [];
  projectBudgetApproved = [];
  projectBudgetApprovedByUsers = [];
  selectedBudget: any = {};
  projectJobs = [];
  paginator = {
    pageSize: 5,
    length: 0,
    pageSizeOptions: [5, 10, 15, 20]
  }
  userData:any={};
  projecturl :any;
  dashboardUrl:any;
  clientId:string;
  constructor(private projectService: TableService,private enterpriseService:EnterpriseService,
     private datePipe: DatePipe, private excelService: ExcelService, private route: ActivatedRoute,
      private _route: Router, private formBuilder: FormBuilder, private toastr: ToastrManager,
     private AuthenticationService: AuthenticationService,private shareservice:ShareService
) {
  this.AuthenticationService.jwtTokenExpCheck();
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    if(this.userData.data.credentials.userType === 'EP'){
      this.Isjob = true;
      this.projecturl = ['/ep/enterprise/projects'];
      this.dashboardUrl = ['/ep/enterprise/dashboard'];
    }
    if(this.userData.data.credentials.userType === 'AD'){
      this.projecturl = ['/ad/admin/projects'];
      this.dashboardUrl = ['/ad/admin/dashboard'];
    }
    this.projectApprovalForm = this.formBuilder.group({
      approvedByName: ['', [Validators.required]],
      approvedByEmailId: ['', Validators.required],
      approvedByContactNumber: ['', Validators.required],
      approvedByDesignation: ['', [Validators.required]],
      approvedByDepartment: ['', [Validators.required]],
      createdDate:['']
 
    });

    this.resumePath = `${environment.url}/api/admin/image/projectdocument/`;
    this.budgetdocPath = `${environment.url}/api/admin/image/budgetdocument/`;
    this.projectApprovalForm1 = this.formBuilder.group({
      projDocumentFile: [''],
    });

    this.projectBudgetForm = this.formBuilder.group({
      budgetAmt: ['', Validators.required],
      budgetDocumentFile: [''],
      budgetDescription: ['',[Validators.required]],
      budgetFromDate: ['', Validators.required],
      budgetToDate: ['', Validators.required],
      createdDate:['']
    });
    this.resetBudgetfrm();
    this.resetApprovalfrm();

    this.addManagerOrAdminForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      userEmailId: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      userContactNumber: ['', Validators.required],
      userRole: ['', Validators.required],
      userFromDate: ['', Validators.required],
      // ,userToDate: ['', Validators.required]
      createdDate:['']
    });
  }

  resetBudgetfrm() {
    this.budgetApprovalForm = this.formBuilder.group({
      budgetApprovedByName: ['', [Validators.required]],
      budgetApprovedByEmailId: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      budgetApprovedByContactNumber: ['', Validators.required],
      budgetApprovedByDesignation: ['', [Validators.required]],
      budgetApprovedByDepartment: ['', [Validators.required]],
      createdDate:['']
    });
  }

  resetApprovalfrm() {
    this.projectApprovalForm = this.formBuilder.group({
      approvedByName: ['', [Validators.required]],
      approvedByEmailId: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      approvedByContactNumber: ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      approvedByDesignation: ['', [Validators.required]],
      approvedByDepartment: ['', [Validators.required]],
      createdDate:['']
    });
  }


  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.projectId = params['id'];
      if(this.userData.data.credentials.userType === 'AD'){
        this.clientId = params['clientId'];
      }

      this.getProjectDetails();
      this.getProjectBudgetsByProjectId();
      this.getProjectUsersByProjectId();
      this.getProjectJobsByProjectId();
    
        this.getProjectApprovalsByProjectId();
        this.getProjectDocumentByProjectId();
        this.getProjectBudgetDocumentByProjectId();
        this.getProjectBudgetApprovalsByProjectId();
      
      
    });
  }

  get approvalForm() { return this.projectApprovalForm.controls; }
  get budgetForm() { return this.projectBudgetForm.controls; }
  get budgetApprovalFrm() { return this.budgetApprovalForm.controls; }
  get uf() { return this.addManagerOrAdminForm.controls; }

  showProjectApprovalPopUp() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isApprovalPopUp = !this.isApprovalPopUp;
    this.resetBudgetApprovalForm(this.projectApprovalForm);
    this.getProjectApprovalsByProjectId();
  }

  showProjectAttachmentPopUp() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.lastdocname='';
    this.isApprovalPopUp1 = !this.isApprovalPopUp1;
    this.resetBudgetApprovalForm(this.projectApprovalForm1);
    this.getProjectDocumentByProjectId();
    //this.getProjectApprovalsByProjectId();
  }

  showProjectBudgetAttachmentPopUp() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isApprovalPopUp2 = !this.isApprovalPopUp2;
    this.resetBudgetApprovalForm(this.projectApprovalForm1);
    this.getProjectBudgetDocumentByProjectId();
    //this.getProjectApprovalsByProjectId();
  }

  showAddProjectApprovalPopUp() {
    this.isAddApprovalform = !this.isAddApprovalform;
    this.resetBudgetApprovalForm(this.projectBudgetForm);
  }

  showAddBudgetPopUp() {
    this.budgetFromSubmitted = false;
    this.projectBudgetForm = this.formBuilder.group({
      budgetAmt: ['', Validators.required],
      budgetDocumentFile: [''],
      budgetDescription: ['',[Validators.required]],
      budgetFromDate: ['', Validators.required],
      budgetToDate: ['', Validators.required],
      createdDate:['']
    });
    this.isAddBudgetForm = !this.isAddBudgetForm;
    this.resetBudgetApprovalForm(this.projectBudgetForm);
  }

  showBudgetApprovalPopUp(budget: any) {
    this.selectedBudget = budget;
    this.budgetId = budget.projectBudgetId;
    this.isBudgetApprovalPopUp = !this.isBudgetApprovalPopUp;
    this.resetBudgetApprovalForm(this.budgetApprovalForm);
    this.getProjectBudgetApprovalsByProjectIdAndBudgetId();
    this.getProjectBudgetDocumentByProjectId();
  }

  showAddManagerOrAdminForm() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.userFromSubmitted = false;
    this.addManagerOrAdminForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      userEmailId: ['', [Validators.required,Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      userContactNumber: ['', Validators.required],
      userRole: ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      userFromDate: ['', Validators.required],
      // ,userToDate: ['', Validators.required]
      createdDate:['']
    });
    this.isAddManagerOrAdminForm = !this.isAddManagerOrAdminForm;
    this.resetBudgetApprovalForm(this.addManagerOrAdminForm);
  }

  showAddProjectJob() {
    
    if(this.userData.data.credentials.userType == 'EP'){
      this._route.navigate(['/ep/enterprise/project/', this.projectId, 'job']);  
    }
    if(this.userData.data.credentials.userType == 'AD'){
      this._route.navigate(['/ad/admin/project/',this.clientId, this.projectId, 'job']);  
    }
  }
  getProjectDetails() {
    this.preloader = true;
    this.projectService.getProjectById(this.projectId).subscribe((res: any) => {
      this.projDetail = res.data;
      this.preloader = false;
      if (res.success) {
        if (res.data.length > 0) {
          this.projectDetails = res.data[0];
          this.totalbudget=+this.projectDetails.totalAllocatedBudgetAmt;
        }
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  OnChangePhoneNo(type: string, e) {
    if (this.projectApprovalForm.controls['approvedByContactNumber'].value != '' || this.addManagerOrAdminForm.controls['userContactNumber'].value != '' || this.budgetApprovalForm.controls['budgetApprovedByContactNumber'].value) {
      let f_val = '';
      var r = /(\D+)/g,
        npa = '',
        nxx = '',
        last4 = '';
      e.value = e.target.value.replace(r, '');
      npa = e.value.substr(0, 3);
      nxx = e.value.substr(3, 3);
      last4 = e.value.substr(6, 4);
      if (e.target.value !== '' && e.keyCode != 8) {
      if (type == 'A') {
        this.projectApprovalForm.controls['approvedByContactNumber'].setValue(npa + '-' + nxx + '-' + last4);
      }
      if (type == 'M') {
        this.addManagerOrAdminForm.controls['userContactNumber'].setValue(npa + '-' + nxx + '-' + last4);
      }
      if (type == 'SA') {
        this.budgetApprovalForm.controls['budgetApprovedByContactNumber'].setValue(npa + '-' + nxx + '-' + last4);
      }
    }
    }
  }

  getProjectBudgetsByProjectId() {
    this.preloader = true;
    this.projectService.getProjectBudgetsByProjectId(this.projectId).subscribe((res: any) => {
      this.preloader = false;
      if (res.success) {
        this.budgets = res.data;
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  getProjectUsersByProjectId() {
    this.preloader = true;
    this.projectService.getProjectUsersByProjectId(this.projectId).subscribe((res: any) => {
      this.preloader = false;
      if (res.success) {
        this.projectUsers = res.data;
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }
  
  getProjectJobsByProjectId() {
    this.preloader = true;
    this.projectService.getProjectJobsByProjectId(this.projectId).subscribe((res: any) => {
      this.preloader = false;
      if (res.success) {
        this.projectJobs = res.data;
        
        for(var i=0;i<this.projectJobs.length;i++){
        let sum=this.projectJobs[i].totalOpenings*this.projectJobs[i].annualPayMax*this.projectJobs[i].jobDuration*160;
        this.total += sum;
        }
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }



  getProjectApprovalsByProjectId() {
    this.preloader = true;
    this.projectService.getProjectApprovalsByProjectId(this.projectId).subscribe((res: any) => {
      this.preloader = false;
      if (res.success) {
        this.projectApprovedByUsers = res.data;
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  getProjectBudgetApprovalsByProjectId() {
    this.preloader = true;
    this.projectService.getProjectBudgetApprovalsByProjectId(this.projectId).subscribe((res: any) => {
      this.preloader = false;
      if (res.success) {
        this.projectBudgetApproved = res.data;
        for (var i = 0; i < this.projectBudgetApproved.length; i++) {
          if (this.projectBudgetApproved[i].approvalStatus == 'Approved') {
            this.Isjob = true;
          }
        }
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  getProjectBudgetApprovalsByProjectIdAndBudgetId() {
    this.preloader = true;
    this.projectService.getProjectBudgetApprovalsByProjectIdAndBudgetId(this.projectId, this.budgetId).subscribe((res: any) => {
      this.preloader = false;
      if (res.success) {
        this.projectBudgetApprovedByUsers = res.data;
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  onProjectBudgetFormSubmit() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.budgetFromSubmitted = true;
    if (this.projectBudgetForm.controls['budgetFromDate'].value == "") {
      this.projectBudgetForm.controls['budgetFromDate'].markAsTouched();
    }
    if (this.projectBudgetForm.controls['budgetToDate'].value == "") {
      this.projectBudgetForm.controls['budgetToDate'].markAsTouched();
    }
      if (this.projectBudgetForm.valid) {
      this.projectBudgetForm.controls['budgetFromDate'].setValue(this.shareservice.convertDateIntoUtc(this.projectBudgetForm.controls["budgetFromDate"].value));
      this.projectBudgetForm.controls['budgetToDate'].setValue(this.shareservice.convertDateIntoUtc(this.projectBudgetForm.controls["budgetToDate"].value));
      this.projectBudgetForm.controls['budgetFromDate'].setErrors(null);
      this.projectBudgetForm.controls['budgetToDate'].setErrors(null);
      this.projectBudgetForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
      this.projectService.addProjectBudget(this.projectBudgetForm.value, this.projectId).subscribe((res: any) => {
        if (res.success) {
          this.toastr.successToastr(res.message);
          this.resetBudgetApprovalForm(this.projectBudgetForm);
          this.isAddBudgetForm = !this.isAddBudgetForm;
          this.getProjectBudgetsByProjectId();
          this.getProjectDetails();
        } else {
          this.toastr.errorToastr(res.message);
        }
      });
    } else {
      this.toastr.errorToastr("Fill all required fields");
    }
  }

  datalist1: any = [];
  newData1: any = []
  onBudgetApprovalFrmSubmit(event) {

    this.AuthenticationService.jwtTokenExpCheck();
      this.budgetApprovalSubmitted = true
      if (this.budgetApprovalForm.valid) {
      this.projectApprovalForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        const possible = '4334fcs2';
        const lengthOfCode = 40;
        // call makeRandom to get verification code for jobseeker
        let verifycode = this.makeRandom(lengthOfCode, possible);
        // call makeRandom to get rejectcode for jobseeker
        let rejectcode = this.makeRandom(lengthOfCode, possible);
        let budgetApprovedByName = this.budgetApprovalForm.controls.budgetApprovedByName.value;
        let budgetApprovedByEmailId = this.budgetApprovalForm.controls.budgetApprovedByEmailId.value;
        let budgetApprovedByContactNumber = this.budgetApprovalForm.controls.budgetApprovedByContactNumber.value;
        let budgetApprovedByDesignation = this.budgetApprovalForm.controls.budgetApprovedByDesignation.value;
        let budgetApprovedByDepartment = this.budgetApprovalForm.controls.budgetApprovedByDepartment.value;
        let projectName = this.projectDetails.projectName;
        let projectBudget = this.selectedBudget.budgetAmount;
        let createdDate =   this.projectApprovalForm.controls['createdDate'].value;
        for (let data of this.docIdSelected) {
          this.datalist1 = this.budgetdocPath + data.docName1
          this.newData1.push({
            "path": this.datalist1
          });
        }
        this.budgetApprovalForm = this.formBuilder.group({
          rejectcode,
          verifycode,
          budgetApprovedByName,
          budgetApprovedByEmailId,
          budgetApprovedByContactNumber,
          budgetApprovedByDesignation,
          budgetApprovedByDepartment,
          projectName,
          projectBudget,
          createdDate
        })
        if(this.userData.data.credentials.userType === 'AD'){
        this.projectService.addProjectBudgetApproval(this.budgetApprovalForm.value, this.newData1, this.projectId, this.budgetId).subscribe((res: any) => {
          if (res.success) {
            this.docIdSelected.length = 0;
            this.newData1.length = 0;
            this.isBudgetApprovalPopUp = false;
            this.budgetApprovalSubmitted = false;
            this.getProjectBudgetApprovalsByProjectIdAndBudgetId();
            this.getProjectBudgetApprovalsByProjectId();
            this.resetBudgetfrm();
            this.resetBudgetApprovalForm(this.budgetApprovalForm);
            this.toastr.successToastr(res.message, '', { position: 'top-right' });
          } else {
            this.toastr.errorToastr(res.message, '', { position: 'top-right' });
          }
        });
      }

      if(this.userData.data.credentials.userType === 'EP'){
        this.budgetApprovalSubmitted = true;
      
          this.enterpriseService.addProjectBudgetApproval(this.budgetApprovalForm.value, this.projectId, this.budgetId).subscribe((res: any) => {
            if (res.success) {
              this.isBudgetApprovalPopUp = false;
              this.budgetApprovalSubmitted = false;
              this.getProjectBudgetApprovalsByProjectIdAndBudgetId();
              this.getProjectBudgetApprovalsByProjectId();
              this.resetBudgetfrm();
              this.resetBudgetApprovalForm(this.budgetApprovalForm);
              this.toastr.successToastr(res.message, '', { position: 'top-right' });
            } else {
              this.toastr.errorToastr(res.message, '', { position: 'top-right' });
            }
          });
      
      } 
  
    }
    else {
      this.toastr.errorToastr("Fill all required fields");
    }
   
    // if(this.userData.data.credentials.userType === 'EP'){
    //   this.budgetApprovalSubmitted = true;
    //   if (this.budgetApprovalForm.valid) {
    //     this.enterpriseService.addProjectBudgetApproval(this.budgetApprovalForm.value, this.projectId, this.budgetId).subscribe((res: any) => {
    //       if (res.success) {
    //         this.getProjectBudgetApprovalsByProjectIdAndBudgetId();
    //         this.resetBudgetApprovalForm(this.budgetApprovalForm);
    //       } else {
    //         this.toastr.errorToastr(res.message);
    //       }
    //     });
    //   } else {
    //     this.toastr.errorToastr("Fill all required fields");
    //   }
  
  }
  onManagerOrAdminFormSubmit() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.userFromSubmitted = true;
    if (this.addManagerOrAdminForm.valid) {
      this.addManagerOrAdminForm.controls['userFromDate'].setValue(this.shareservice.convertDateIntoUtc(this.addManagerOrAdminForm.controls["userFromDate"].value));
      this.addManagerOrAdminForm.controls['userFromDate'].setErrors(null);
      this.addManagerOrAdminForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
    
      this.projectService.addProjectUser(this.addManagerOrAdminForm.value, this.projectId).subscribe((res: any) => {
        if (res.success) {
          this.getProjectUsersByProjectId()
          this.isAddManagerOrAdminForm = false;
          this.resetBudgetApprovalForm(this.addManagerOrAdminForm);
          this.userFromSubmitted = false;
          this.toastr.successToastr(res.message, '', { position: 'top-right' });
        } else {
          this.toastr.errorToastr(res.message);
        }
      });
    } else {
      this.toastr.errorToastr("Fill all required fields");
    }
  }

  resetBudgetApprovalForm(formObj: FormGroup) {
    for (let name in formObj.controls) {
      formObj.controls[name].setValue('');
      formObj.controls[name].setErrors(null);
    }
  }
  showJobDetails(job: any) {
    if(this.userData.data.credentials.userType == 'AD'){
      this._route.navigate(['/ad/admin/EditJobList/',this.clientId,this.projectId,job]);
    }
    
    if(this.userData.data.credentials.userType == 'EP'){
      this._route.navigate(['/ep/enterprise/EditJobList/',this.projectId,job ],{ queryParams: { project: 1}});
    }
  }

  getProjectApprovalByEmailId() {
    this.preloader = true;
    this.projectService.getProjectApprovalByEmailId(this.projectId, this.Id, this.status).subscribe((res: any) => {
      this.preloader = false;
      if (res.success) {
        console.log(res.data);
        this.projectJobs = res.data;
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  //use for upload document
  lastdocname='';
  docUpload(event) {
    if (event.target.files[0].size < (4058820 / 2)) { 
      const type = event.target.files[0].name.split('.');     
      if (type[1] === 'docx'
      || type[1] === 'pdf'
      || type[1] === 'doc') { 
      if(this.lastdocname == event.target.files[0].name){
        this.toastr.errorToastr('Please do not upload same document', 'Error!');  
        this.myfile.nativeElement.value='';
      }
      else{
        this.preloader = true;
        let mydata = new FormData();
        this.lastdocname = event.target.files[0].name;
        mydata.append('projectdocument', event.target.files[0]);
        mydata.append('type', event.target.files[0].type);
        mydata.append('projectName', this.projDetail[0].projectName);
        mydata.append('projectId', this.projDetail[0].projectNo);
        this.projectService.UploadAdminProjectdoc(mydata).subscribe(res => {
          this.preloader = false;
          this.docResponse = res;
          if (this.docResponse.success) {
            this.getProjectDocumentByProjectId();
            this.toastr.successToastr(this.docResponse.message, '', { position: 'top-right' });
            this.myfile.nativeElement.value='';
          } else {
            this.toastr.errorToastr(this.docResponse.message, '', { position: 'top-right' });
          }
        });
      }
    }    
    else{
      this.toastr.errorToastr('Only .pdf,.doc,.docx Support', 'Error!');
    }
    }
    else {
      this.toastr.errorToastr('Project document should be less than 2 MB.', '', { position: 'top-right', timeOut: 3000 });
    }

  }


  addField() {
    if (this.formModelArray.length < 4) {
      const data = {
        projDocumentFile: ''
      }
      this.formModelArray.push(data)
    }
  }

  formModelArray: Array<any> = [
    {
      projDocumentFile: ''
    }
  ]

  //Get project document List
  getProjectDocumentByProjectId() {
    this.preloader = true;
    this.projectService.getProjectDocumentByProjectId(this.projectId).subscribe((res: any) => {
      this.preloader = false;
      if (res.success) {
        this.document = res.data;
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  //Get budget project document List
  getProjectBudgetDocumentByProjectId() {
    this.preloader = true;
    this.projectService.getProjectBudgetDocumentByProjectId(this.projectId).subscribe((res: any) => {
      this.preloader = false;
      if (res.success) {
        this.budgetdocument = res.data;
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  checkedUnChecked(documents: { docId: any; }, event: { checked: any; }) {
    let notChecked = 0;
    this.document.forEach((item: { docId: any; selected: any; }) => {
      if (item.docId === documents.docId) {
        item.selected = event.checked;
      }
      if (!item.selected) {
        notChecked++;
      }
    });
    this.areAllSelected = (notChecked === 0);
  }

  checkedUnCheckedBudgetDocuments(documents: { docId: any; }, event: { checked: any; }) {
    let notChecked = 0;
    this.document.forEach((item: { docId: any; selected: any; }) => {
      if (item.docId === documents.docId) {
        item.selected = event.checked;
      }
      if (!item.selected) {
        notChecked++;
      }
    });
    this.areAllSelected = (notChecked === 0);
  }

  selectDoc(data: { docId: any; docName: any; }, event: { checked: any; }) {
    if (event.checked) {
      this.docIdSelected.push({
        docName: data.docName,
      });
    } else {
      for (let i = 0; i < this.document.length; i++) {
        if (this.document[i].docId === data.docId) {
          this.docIdSelected.splice(i, 1);
        }
      }
    }
  }
  //this is use for select budget documents
  selectBudgetDoc(data: { docId: any; docName: any; }, event: { checked: any; }) {
    if (event.checked) {
      this.docIdSelected.push({
        docName1: data.docName,
      });
    } else {
      for (let i = 0; i < this.budgetdocument.length; i++) {
        if (this.budgetdocument[i].docId === data.docId) {
          this.docIdSelected.splice(i, 1);
        }
      }
    }
  }

  resetAddField() {
    if (this.formModelArray.length > 1) {
      this.formModelArray.splice(1, this.formModelArray.length);
    }
  }

  datalist: any = [];
  newData: any = []
  onProjectApprovalSubmit() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.submitted = true;
    if (this.projectApprovalForm.valid) {
      const possible = '4334fcs2';
      const lengthOfCode = 40;
      this.projectApprovalForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
      // call makeRandom to get verification code for jobseeker
      let verifycode = this.makeRandom(lengthOfCode, possible);
      // call makeRandom to get rejectcode for jobseeker
      let rejectcode = this.makeRandom(lengthOfCode, possible);
      let approvedByName = this.projectApprovalForm.controls.approvedByName.value;
      let approvedByEmailId = this.projectApprovalForm.controls.approvedByEmailId.value;
      let approvedByContactNumber = this.projectApprovalForm.controls.approvedByContactNumber.value;
      let approvedByDesignation = this.projectApprovalForm.controls.approvedByDesignation.value;
      let approvedByDepartment = this.projectApprovalForm.controls.approvedByDepartment.value;

      let projectName = this.projectDetails.projectName;
      let fromDate = this.projectDetails.fromDate;
      let toDate = this.projectDetails.toDate;
      let createdDate =   this.projectApprovalForm.controls['createdDate'].value;
      for (let data of this.docIdSelected) {
        this.datalist = this.resumePath + data.docName
        this.newData.push({
          "path": this.datalist
        });
      }
      this.projectApprovalForm = this.formBuilder.group({
        rejectcode,
        verifycode,
        approvedByName,
        approvedByEmailId,
        approvedByContactNumber,
        approvedByDesignation,
        approvedByDepartment,
        projectName,
        fromDate,
        toDate,
        createdDate
      })
    
      this.projectService.addProjectApproval(this.projectApprovalForm.value, this.projectId, this.newData).subscribe((res: any) => {
        if (res.success) {
          this.document.forEach((item: { selected: boolean; }) => {
            item.selected = false;
          });
          this.docIdSelected.length = 0;
          this.newData.length = 0;
          this.getProjectApprovalsByProjectId();
          this.resetApprovalfrm();
          this.resetBudgetApprovalForm(this.projectApprovalForm);
          this.isApprovalPopUp = !this.isApprovalPopUp;
          this.toastr.successToastr(res.message, '', { position: 'top-right' });        
        } else {
          this.toastr.errorToastr(res.message, '', { position: 'top-right' });
        }
      });
    } else {
      this.toastr.errorToastr("Fill all required fields");
    }
  }
  //This function is use for select all document

  // toggleAllSelection() {
  //   this.areAllSelected = !this.areAllSelected;
  //   const isAllSelected = this.areAllSelected;
  //   this.document.forEach((item: { selected: boolean; }) => {
  //     item.selected = isAllSelected;
  //   });
  // }

  allUnchecked() {
    this.document.forEach((item: { selected: boolean; }) => {
      item.selected = false;
    });
  }
  //This funcion use for upload project budget document
  checkLastFile='';
  budgetdocUpload(event) {
    if(this.checkLastFile == event.target.files[0].name){
      this.toastr.errorToastr('Please do not upload same document', 'Error!');
      this.myfile.nativeElement.value='';
    }
    if (event.target.files[0].size < (4058820 / 2)) {
      const type = event.target.files[0].name.split('.');     
      if (type[1] === 'docx'
      || type[1] === 'pdf'
      || type[1] === 'doc') { 
      this.preloader = true;
      let mydata = new FormData();
      mydata.append('budgetdocument', event.target.files[0]);
      mydata.append('type', event.target.files[0].type);
      mydata.append('projectName', this.projDetail[0].projectName);
      mydata.append('projectId', this.projDetail[0].projectNo);
      this.projectService.UploadAdminProjectBudgetdoc(mydata).subscribe(res => {
        this.preloader = false;
        this.docResponse = res;
        if (this.docResponse.success) {
          this.getProjectBudgetDocumentByProjectId();
          this.toastr.successToastr(this.docResponse.message, '', { position: 'top-right' });
          this.checkLastFile=event.target.files[0].name;
          this.myfile.nativeElement.value='';
        } else {
          this.toastr.errorToastr(this.docResponse.message, '', { position: 'top-right' });
        }
      });
    }
    else{
      this.toastr.errorToastr('Only .pdf,.doc,.docx Support', 'Error!');
    }
    }
    else {
      this.toastr.errorToastr('Project budget document should be less than 2 MB.', '', { position: 'top-right', timeOut: 3000 });
    }

  }
  
  deleteProjectDoc(Id,Path){
    let data={
      Id:Id,
      path:Path
    }
    this.projectService.deleteProjectDocById(data).subscribe(res => {
      if (res.success) {
        this.getProjectDocumentByProjectId();
        this.toastr.successToastr(res.message, '', { position: 'top-right' });
      } else {
        this.toastr.errorToastr(res.message, '', { position: 'top-right' });
      }
    });
  }

  deleteBudgetProjectDoc(Id,Path){
    let data={
      Id:Id,
      path:Path
    }
    this.projectService.deleteProjectBudgetDocById(data).subscribe(res => {
      if (res.success) {
        this.getProjectBudgetDocumentByProjectId();
        this.toastr.successToastr(res.message, '', { position: 'top-right' });
      } else {
        this.toastr.errorToastr(res.message, '', { position: 'top-right' });
      }
    });
  }

  // 20200929: Akhilesh, Special charcter validation, jira no 70
  omit_validate_specialchar(event: { charCode: any; }) {  

    const k = event.charCode;   
     if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  
      || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){  
       this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
       return false}
     }
   //2020091001: Akhilesh, Validate special charcter and number
     omit_special_char_number(event: { charCode: any; }) {
      const k = event.charCode;
      return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
    }
    reload(){
      
      window.location.reload();
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ShareService } from '../share.service';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { PreviewResumePopupComponent } from 'src/app/enterprise/preview-resume-popup/preview-resume-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import {AuthenticationService } from '../../app.service';



@Component({
  selector: 'app-jobseeker-staffing-status',
  templateUrl: './jobseeker-staffing-status.component.html',
  styleUrls: ['./jobseeker-staffing-status.component.css']
})
export class JobseekerStaffingStatusComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  jobid: any;
  Jobtype: any;
  poolid: any;
  preloader: boolean;
  jobseekerForm: any;
  statusValue ='';
  jobseekerlist: any;
  showDetails: boolean;
  checkJobId='';
  paginator = {
    pageSize: 20,
    length: 0,
    pageSizeOptions: [5,10, 20, 50],
    pageIndex: 0
  };
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];

  constructor(private paramval: ActivatedRoute,public dialog: MatDialog, private datePipe:DatePipe , 
      private AuthenticationService: AuthenticationService,
      private formBuilder: FormBuilder, private api: ShareService,
       private _titleService: Title, private shareservice:ShareService) {
    var newTitle = 'Verisource | Jobseeker Job Status Details';
    this._titleService.setTitle(newTitle);
    this.jobid = this.paramval.snapshot.params.jobid;
    this.checkJobId =this.paramval.snapshot.params.jobid.split('-');
    this.Jobtype = this.paramval.snapshot.params.jobtype;
    this.poolid = this.paramval.snapshot.params.poolid;
  }

  ngOnInit() {
    this.fillvalue();
    this.loadjobseekerlist();
  }
  fillvalue() {
    this.jobseekerForm = this.formBuilder.group({
      jobid: this.jobid,
      poolid: this.poolid,
      jobtype: this.Jobtype,
      PageSize: 20,
      PageNumber: 1,
      fromdate: '',
      todate: '',
      search: '',
      status: 'All',
      jobseekerStatus:'All',
      checkId:this.checkJobId[0]
    });
    console.log(" Jobseeker Job Status Details", this.jobseekerForm.value);

  }

  
  searchButton = false;
  loadData(){
    this.searchButton= true;
    this.loadjobseekerlist();
  }

  loadjobseekerlist() {
 this.AuthenticationService.jwtTokenExpCheck();

    console.log("this.jobseekerForm.value",this.jobseekerForm.value);
    this.preloader = true;
    if(this.searchButton == true){
      this.jobseekerForm.controls['PageNumber'].setValue(1);
    }
    this.jobseekerForm.value.fromdate=(this.shareservice.convertDateIntoUtc(this.datePipe.transform(this.jobseekerForm.controls["fromdate"].value, "yyyy-MM-dd")))
    this.jobseekerForm.value.todate=(this.shareservice.convertDateIntoUtcForSearch(this.datePipe.transform(this.jobseekerForm.controls["todate"].value, "yyyy-MM-dd ")))
    this.jobseekerForm.controls["fromdate"].setErrors(null);
    this.jobseekerForm.controls["todate"].setErrors(null);
    this.api.jobseekerstatuslist('EP',this.jobseekerForm.value).pipe(first()).subscribe(res => {
      if (res.success) {
        this.jobseekerlist = res.data;
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }
        this.searchButton = false;
        console.log("jobseekerlist", this.jobseekerlist);
        this.showDetails = true;
        this.preloader = false;
        if (this.jobseekerlist.length > 0) {
          this.paginator.length = this.jobseekerlist[0].totalCount;
        }
      }
    });
  }

  resetjobseekerfilterform(){
    this.today = new Date().toISOString().split('T')[0];
    this.from_Date = null;
    this.preloader = false;
    this.jobseekerForm = this.formBuilder.group({
      jobid: this.jobid,
      poolid: this.poolid,
      jobtype: this.Jobtype,
      PageSize: 5,
      PageNumber: 1,
      fromdate: '',
      todate: '',
      search: '',
      status: 'All',
      jobseekerStatus:'All',
      checkId:this.checkJobId[0]
    });
    this.paginator.pageSize=5;
    this.loadjobseekerlist();
    this.paginators.pageIndex = 0;
  }

  isLogin='true';
  jobseekerpopup(jobseeker) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){

    const dialogRef = this.dialog.open(PreviewResumePopupComponent, {
      width: 'auto',
      //disableClose: true,
      data: jobseeker
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  }

  from_Date:String;
dateForToDate(type:number){
  if(type == 1){
    this.from_Date = new Date(this.datePipe.transform(this.jobseekerForm.controls['fromdate'].value)).toISOString();
  }

  if(type == 2){
    this.today = new Date(this.datePipe.transform(this.jobseekerForm.controls['todate'].value)).toISOString();
  }
}
  getpaginatorData(e){
    this.searchButton = false;
    // this.jobseekerForm.controls.PageSize.setValue(e.pageSize);
    // this.jobseekerForm.controls.PageNumber.setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.jobseekerForm.controls['PageSize'].setValue(e.pageSize);
    this.jobseekerForm.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.loadjobseekerlist();
  }
}


import { Component, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { EnterpriseService } from '../../enterprise.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { StaffingAgencyPopUpComponent } from '../../staffing-agency-pop-up/staffing-agency-pop-up.component';
import {AuthenticationService } from '../../../app.service';
import { ShareService } from 'src/app/share-module/share.service';
import { DatePipe } from '@angular/common';
  
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { NewEnterpriseService } from '../../newenterprise.service';
import { SupplierPopUpComponent } from '../staffing-agency/PopUp/supplier-pop-up/supplier-pop-up.component';
@Component({
  selector: 'app-assign-job-to-agency-component',
  templateUrl: './assign-job-to-agency-component.component.html',
  styleUrls: ['./assign-job-to-agency-component.component.css']
})
export class AssignJobToAgencyComponentComponent implements OnInit {
  date = new FormControl(moment([2017, 0, 1]));
  @ViewChild(MatPaginator,{static : false} ) paginators: MatPaginator;
  SAIdSelected: any = [];
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];
  listOfAllRequest: any;
  preloader: boolean;
  listView: boolean;
  body: FormGroup;
  staffingagencyId:any;
  paginator = {
    pageSize: 5,
    length: 0,
    pageIndex: 0,
    pageSizeOptions: [10, 20, 50, 100]
  };
  areAllSelected = false;
  data: any;
  constructor(public dialog: MatDialog, private _titleService: Title, private formBuilder: FormBuilder,
    private tostr: ToastrManager, private _router: Router, private enterPriseAPI: EnterpriseService,
    private AuthenticationService: AuthenticationService,
    private shareservice:ShareService,private datepipe:DatePipe,
    private newEnterpriseApi:NewEnterpriseService
    ) {
    this.data = JSON.parse(localStorage.getItem('CurrentGroupRequest'));
    
    this.body = this.formBuilder.group({
      fromdate: null,
      todate: null,
      search: '',
      totalcandidates: '0-All',
      pageSize: 5,
      PageNumber: 1,
      action: 'notAll',
      active_Status: 'All',
      jobid: this.data.jobId,
      profile_status:'All'
    });

  }

  ngOnInit() {
    
    this.loadsalist();

  }
  loadsalist() {

    this.AuthenticationService.jwtTokenExpCheck();
    // this.body.controls['fromdate'].setValue(new Date(this.shareservice.convertDateIntoUtc(this.datepipe.transform(this.body.value.fromdate,'MM/dd/yyyy'))).toISOString());
    // this.body.controls['todate'].setValue(new Date(this.shareservice.convertDateIntoUtcForSearch(this.datepipe.transform(this.body.value.todate,'MM/dd/yyyy'))).toISOString());  
    this.preloader = true;
    if(this.searchButton == true){
      this.body.controls['PageNumber'].setValue(1);
    }
    debugger;
    this.newEnterpriseApi.GetAssignJobSuppliers(this.body.value).subscribe((res:any) => {
      const tempSAIdSelected = this.SAIdSelected;
      if (res.status == 200) {
        console.log('listOfAllRequest', res.body);
        this.preloader = false;
        this.listOfAllRequest = res.body.supplier;
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }
        this.searchButton = false;
        this.listView = true;
        if (res.body['supplier'].length > 0) {
          this.paginator.length = res.body.totalCount;
        } else {
          this.paginator.length = 0;
        }
        // const isAllSelected = this.areAllSelected;
        // this.listOfAllRequest.data.forEach((item: { selected: boolean; assignStatus2: any }) => {
        //   item.selected = isAllSelected;
          
        //   if (item.assignStatus2 === null) {
        //     item.assignStatus2 = 'Not Assign';
        //    }
        //   //  else {
        //   //    item.assignStatus2 = 'Assign';
        //   //  }
        // });
        // this.listOfAllRequest.data.forEach(function (candidate: { selected: boolean; staffingagencyId: string }) {
        //   // here we check condition and select previous data
        //   if (tempSAIdSelected.length > 0) {
        //     tempSAIdSelected.forEach(function (Value) {
        //       if (candidate.staffingagencyId === Value.staffingagencyId) {
        //         candidate.selected = true;
        //       }
        //     });
        //   } else {
        //     candidate.selected = false;
        //   }
        // });
        console.log('data', this.listOfAllRequest);
      }
    });
  }
  saAssignStatus(staffingagency) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.listOfAllRequest.data.map(ele => {
      if (ele.staffingagencyId === staffingagency.staffingagencyId) {
         
        if (ele.assignStatus2 === "Assign") {
          ele.assignStatus2 = "Not Assign";
        } else if (ele.assignStatus2 === "Not Assign") {
          ele.assignStatus2 = "Assign";
        }
      }
    })
  }

  searchButton = false;
  loadData(){
    this.searchButton= true;
    this.loadsalist();
  }
 
  toggleAllSelection() {
    this.areAllSelected = !this.areAllSelected;
    const isAllSelected = this.areAllSelected;
    this.listOfAllRequest.data.forEach((item: { selected: boolean; }) => {
      item.selected = isAllSelected;
    });
  }
  checkedUnChecked(candidate: { staffingagencyId: any; }, event: { checked: any; }) {
    let notChecked = 0;
    this.listOfAllRequest.data.forEach((item: { staffingagencyId: any; selected: any; }) => {
      if (item.staffingagencyId === candidate.staffingagencyId) {
        item.selected = event.checked;
      }
      if (!item.selected) {
        notChecked++;
      }
    });
    this.areAllSelected = (notChecked === 0);
  }

  getPaginatorData(e) {
  
    this.searchButton = false;
    // this.body.controls['pageSize'].setValue(e.pageSize);
    // this.body.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.body.controls['pageSize'].setValue(e.pageSize);
    this.body.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.loadsalist();
  }
  AssignJobSA(record : any) {    
    let selectedCount1 = 0;
    const recordlenth = record.length;
    record.forEach((count) => {
      if (count.selected === false) {
        selectedCount1 = selectedCount1 + 1;
      }
    });
  
    if (recordlenth !== selectedCount1) {
      record.forEach((value) => {
        if (value.selected) {
          value.jobid = this.data.jobId;
          value.status = true;
          value.assignStatus = value.assignStatus2;
          console.log('value', value);

          // if(value.assignStatus == true){
          //   value.assignStatus2 = "Assign"
          // }
          // else(value.assignStatus == false)
          // {
          //   value.assignStatus2 = "Not Assign"
          // }
        // }
        //  else{
        //    value.jobid = this.data.jobId;
        //    value.status = false;
        //    console.log('value', value);
        //  }
          this.enterPriseAPI.InsertAsignedJobToSA(value,this.shareservice.convertDateIntoUtc(new Date())).pipe(first()).subscribe(res => {
          });
        }
      });
      this.tostr.successToastr('Job is assigned to supplier successfully.', 'Success!');
      this.loadsalist();
    } else {
      this.tostr.errorToastr('Please Select At least One Supplier');
      return false;

    }
  }



  checkUncheckHeader(e: { checked: any; }, staffingAgency: { staffingagencyId: any; }) {
    let notChecked = 0;
    this.listOfAllRequest.data.forEach((item: { staffingagencyId: any; selected: any; assignStatus: any; }) => {
      if (item.staffingagencyId === staffingAgency.staffingagencyId) {
        item.selected = e.checked;
      }
      if (!item.selected) {
        notChecked++;
      }
    });
    this.areAllSelected = (notChecked === 0);
  }

  selectJob(data, event) {
    if (event.checked) {
      this.SAIdSelected.push({
        'staffingagencyId': data.staffingagencyId,
      });
    } else {
      for (let i = 0; i < this.SAIdSelected.length; i++) {
        console.log(this.SAIdSelected[i].staffingagencyId);
        if (this.SAIdSelected[i].staffingagencyId === data.staffingagencyId) {
          this.SAIdSelected.splice(i, 1);
        }
      }
      console.log("After Unselected job", this.SAIdSelected);
    }
  }
  staffingdetlform() {
   
    this.today = new Date().toISOString().split('T')[0];
    this.from_Date = null;
    this.body.controls['search'].setValue('');
    this.body.controls['todate'].setValue('');
    this.body.controls['fromdate'].setValue('');
    this.body.controls['status'].setValue('All');
    this.paginator.pageSize=5;
    this.loadsalist();
    this.paginators.pageIndex = 0;

  }

  isLogin = 'true';
  openStaffingAgencyDialog(supplierId: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(SupplierPopUpComponent, {
      width: '500px',
      disableClose: true,
      data: { supplierId }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
    }
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  from_Date:String;
dateForToDate(type:number){
  if(type == 1){
    this.from_Date = new Date(this.datepipe.transform(this.body.controls['fromdate'].value)).toISOString();
  }

  if(type == 2){
    this.today = new Date(this.datepipe.transform(this.body.controls['todate'].value)).toISOString();
  }
}

}

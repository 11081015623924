
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JwtService } from '../../admin/service/jwt.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
  export class HttpService {
    coreApiUrl ='';
    token='';
    constructor(
      private http: HttpClient,
      private jwtService: JwtService
    ) {
      this.coreApiUrl = `${environment.coreapipath}`;
      this.token = localStorage.getItem('token');
     }

    private setHeaders(): HttpHeaders {
      const headersConfig = {       
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }
      return new HttpHeaders(headersConfig);
    };

    private formatErrors(error: any) {
      return throwError(error);
    }
  
    get(path: string, searchParams: HttpParams = new HttpParams()): Observable<HttpResponse<Object>> {
      debugger
      console.log('========',path)
      return this.http.get<any>(`${path}`, { headers: this.setHeaders() ,observe:'response', params: searchParams }
      ).pipe(catchError(this.formatErrors));
    }
  
    put(path: string, body: object = {}): Observable<HttpResponse<Object>> {
      return this.http.put<any>(`${path}`, JSON.stringify(body), { headers: this.setHeaders() ,observe:'response' })
        .pipe(catchError(this.formatErrors));
    }
  
    post(path: string, body: Object = {}): Observable<HttpResponse<Object>> {
      return this.http.post<any>(`${path}`, JSON.stringify(body), { headers: this.setHeaders() ,observe:'response'})
        .pipe(catchError(this.formatErrors));
    }
  
    delete(path: string): Observable<HttpResponse<Object>>  {
      return this.http.delete<any>(`${path}`, { headers: this.setHeaders() ,observe:'response'})
        .pipe(catchError(this.formatErrors));
    }
  
  
  }
import { Component, OnInit, Input } from '@angular/core';
import { Router, RouterLink, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { JobseekerService } from '../../JobSeeker/jobseeker.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AuthenticationService } from '../../app.service';
import { EnterpriseService } from '../../enterprise/enterprise.service';
import { JobDetailPopupComponent } from '../../enterprise/job-detail-popup/job-detail-popup.component';
import { Title } from '@angular/platform-browser';
import { HeaderComponent } from 'src/app/component/header/header.component';
import { ShareService } from 'src/app/share-module/share.service';
@Component({
  selector: 'app-guest-browse-job',
  templateUrl: './guest-browse-job.component.html',
  styleUrls: ['./guest-browse-job.component.css']
})
export class GuestBrowseJobComponent implements OnInit {
  preLoader: boolean = false;
  GuestBrowseJobForm: FormGroup;
  joblistData: any;
  asyncData: boolean = false;
  routeData: any;
  routeData1: any;
  filterList: any;
  ddlFilterList: any = [];
  istabopen: string = 'Relevance';
  islstopen: boolean = false;
  isOnreset: boolean = false;
  readmore: any = {};


  paginator = {
    pageSize: 20,
    length: 0,
    pageSizeOptions: [5, 10, 20, 100]
  }



  constructor(
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private JSApi: JobseekerService,
    private toastr: ToastrManager,
    private API: AuthenticationService,
    private epApi: EnterpriseService,
    private dialog: MatDialog,
    private _titleService : Title,
    private headerComponent : HeaderComponent,
    private shareservice:ShareService
  ) {
    var newTitle = 'Verisource | Browse Jobs';
    this._titleService.setTitle( newTitle );

    this.readmore = {};
    this.GuestBrowseJobForm = this._formBuilder.group({
      "PageSize": 20,
      "PageNumber": 1,
      "postedDate": 0,
      "jobType": "All",
      "country": "",
      "searchlocation": '',
      "minExperience": 0,
      "maxExperience": 0,
      "minAnnualPay": 0,
      "maxAnnualPay": 0,
      "jobLocation": "",
      "search": "",
      "jobRequirement": 'All',
      "orderBy": "Date",
      "state": '',
      "zipCode": '',
    });
    // console.log("Start");
    this.API.getfiltersGuestBrowsejobs().subscribe((res: any) => {
      this.preLoader = false;
      if (res.success) {
        this.filterList = res.data
        // console.log("Filter List for Guest user : ", this.filterList);
      } else {
        this.toastr.errorToastr(res.message, 'Error!')
      }
    });

    // console.log("Ends");
  }


  ngOnInit() {
    this.routeData = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.routeData1 = params;
    });
    // console.log("this.routeData1 >> ", this.routeData1);
    if (this.routeData1 != null) {
      if (this.routeData1.search != null) {
        this.GuestBrowseJobForm.controls['search'].setValue(this.routeData1.search);
      }
      if (this.routeData1.searchlocation != null) {
        this.GuestBrowseJobForm.controls['searchlocation'].setValue(this.routeData1.searchlocation);
      }
    }
    this.LoadGuestJoblistData();
  }


  // toggletabOpen() { this.istabopen = !this.istabopen; }
  OpenlfMenu() { this.islstopen = !this.islstopen; }
  // popUpreset() { this.isOnreset = !this.isOnreset; }
  reset_BrowseJobFrom() {
    this.router.navigate(['/browsejob']);
    this.GuestBrowseJobForm = this._formBuilder.group({
      "PageSize": 20,
      "PageNumber": 1,
      "postedDate": 30,
      "jobType": "All",
      "country": "",
      "searchlocation": '',
      "minExperience": 0,
      "maxExperience": 0,
      "minAnnualPay": 0,
      "maxAnnualPay": 0,
      "jobLocation": "",
      "search": "",
      "jobRequirement": '',
      "orderBy": "Date",
      "state": '',
      "zipCode": '',

    });
    this.paginator.pageSize=20;
    // this.LoadGuestJoblistData();


  }

  toggletabOpen(val) {
    this.istabopen = val;
    this.GuestBrowseJobForm.controls['orderBy'].setValue(val);
    this.LoadGuestJoblistData();
  }
  LoadGuestJoblistData() {

    this.preLoader = true;
    // console.log('this.GuestBrowseJobForm.value >>>>', this.GuestBrowseJobForm.value);
    this.JSApi.GuestBrowsejobsListAPI(this.GuestBrowseJobForm.value).subscribe(res => {
      this.preLoader = false;
      this.asyncData = true;
      this.joblistData = res;
      // console.log('this.joblistData', this.joblistData);
      if (this.joblistData.success) {
        if (this.joblistData.data.length > 0) {
          this.paginator.length = this.joblistData.data[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
        // this.toastr.successToastr(this.joblistData.message, "", { position: "top-right" });
      } else {
        this.toastr.errorToastr(this.joblistData.message, "", { position: "top-right" });
      }
    });
  }
  CheckLoginForApplyOnJob() {
    this.toastr.errorToastr('You have not login yet please login', "", { position: "top-right" });
    // this.router.navigateByUrl('/');

  }
  applyOnJob(jobId) {
    this.JSApi.ApplyOnJobAPI(jobId,this.shareservice.convertDateIntoUtc(new Date())).subscribe(res => {

      this.joblistData = res;
      // console.log('this.joblistData', this.joblistData);
      if (this.joblistData.success) {
        this.LoadGuestJoblistData();
        this.isOnreset = true;
        // this.toastr.successToastr(this.joblistData.message, "", { position: "top-right" });
      } else {
        this.toastr.errorToastr(this.joblistData.message, "", { position: "top-right" });
      }
    });
  }



  jsonArrayCnvrtVl(val) {
    // console.log('val :---->>', Object.keys(val));
    return Object.keys(val);
  }
  jsonArrayCnvrt(val) {
    return Object.values(val);
  }

  countryData(ddlType, values) {
    this.ddlFilterList = [];
    // console.log("==============?>", ddlType);
    switch (ddlType) {
      case 'country': {
        if (this.GuestBrowseJobForm.controls['country'].value.length >= 3) {
          this.epApi.LoadAllCountriesApiWithoutToken().subscribe((res: any) => {
            if (res.success) {
              // console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }
          });
        }
        break;
      }
      case 'jobLocation': {
        if (this.GuestBrowseJobForm.controls['jobLocation'].value.length >= 3) {
          // console.log("Response ------------>");
          this.API.joblocation(this.GuestBrowseJobForm.controls['jobLocation'].value).subscribe((res: any) => {
            if (res.success) {
              // console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }

          });
        } else {
          // console.log("dssadsa");
        }
        break;
      }

    }
  }

  clearData() {
    this.ddlFilterList = [];
  }

  // filterReset() {
  //   this.filterInit(); 
  //   this.JoblistDatafc();
  // }

  setpaggination(e) {
    // console.log(e);
    this.GuestBrowseJobForm.controls['PageSize'].setValue(e.pageSize);
    this.GuestBrowseJobForm.controls['PageNumber'].setValue(e.pageIndex + 1);
    this.LoadGuestJoblistData();
  }

  isLogin='true';
  openDialog(id): void {
    this.API.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(JobDetailPopupComponent, {
      width: 'auto',
      data: { jobId: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  }


   ChangeLoginType(val) {
   this.headerComponent.ChangeLoginType(val);
   }
   removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
}

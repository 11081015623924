import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/app.service';
import { InvoiceDetailComponent } from 'src/app/share-module/popUp/invoice-detail/invoice-detail.component';
import { ShareService } from 'src/app/share-module/share.service';
import { UploadDocumentComponent } from '../../staffing-agency/popup/upload-document/upload-document.component';
import { staffingAgencyServices } from '../../staffing-agency/staffing-agency.services';

@Component({
  selector: 'app-timesheetinvoice',
  templateUrl: './timesheetinvoice.component.html',
  styleUrls: ['./timesheetinvoice.component.css']
})
export class TimesheetinvoiceComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  body: FormGroup;
  invoicereport:any=[];
  invoicedetail:any=[];
  raiseInvoice=false;
  invoicePart=true;
  mailinvoice=false;
  mailInvoiceForm:FormGroup;
  filename="";
  paginator = {
    pageSize: 5,
    length: 0,
    pageIndex: 0,
    pageSizeOptions: [5,10, 20, 50, 100]
  };
  first=true;
  second=false;
  third=false;
  fourth=false;
  userData:any={};
  constructor(private shareservices: ShareService,private AuthenticationService: AuthenticationService,public dialog: MatDialog,
    private staffingservice: staffingAgencyServices,private tostr: ToastrManager,private formBuilder: FormBuilder, private datepipe:DatePipe) { 
      this.userData = JSON.parse(window.localStorage.getItem('udata'));
      this.body = this.formBuilder.group({
        fromdate: '',
        todate: '',
        search: '',
        PageSize: 5,
        PageNumber: 1,
        invoiceid:'',
        status:'',
        source:'',
        invoicetype:'',
        currency:'',
        usertype:this.userData.data.credentials.userType,
        mspId: this.userData.data.credentials.userId //Manu Datta: 20210402, VerisourceBugSheetAfterMerge/commonLogin 214
      });

      this.mailInvoiceForm=this.formBuilder.group({
        email:''
      });
      this.loadAllInvoice();
  }

  ngOnInit() {
  }


  loadAllInvoice() {
    this.first=true;
    this.second=false;
    this.third=false;
    this.fourth=false;
      if(this.searchButton == true){
        this.body.controls['PageNumber'].setValue(1);
      }
      this.staffingservice.getInvoiceReport(this.body.value).pipe(first()).subscribe((res: any) => {

        if (res.success) {
         this.invoicereport = res.data;
         this.filename='';

         if (res.data.length > 0) {
          this.paginator.length = res.data[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }
        this.searchButton = false;
        } else {
          
          this.tostr.errorToastr(res.message, 'Error!')
        }
      });
  }

  invoicedata:any;
  openRaiseInvoice(){

    this.first=false;
    this.second=false;
    this.third=false;
    this.fourth=true;
    this.detail = false;
    this.invoicePart=false;
    this.raiseInvoice=true;
    this.shareservices.loadInvoiceFor(this.invoicedetail,2).pipe(first()).subscribe(res => {
      if (res.success) {
        this.invoicedata = res.data;

        if(this.invoicedetail.raiseInvoicedate != null && this.invoicedetail.raiseInvoicedate != ''){
          this.raisevalue = true;
        }
      }
    });

  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  resetList() {
  
    this.body = this.formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      PageSize: 5,
      PageNumber: 1,
      invoiceid:'',
      status:'',
      source:'',
      invoicetype:'',
      currency:'',
      usertype:this.userData.data.credentials.userType
    });
    this.paginator.pageSize=5;
    this.searchButton = false;
    this.loadAllInvoice();
    this.paginators.pageSize = 5;
    this.paginators.pageIndex = 0;
  }

  searchButton = false;
  loadData(){
  this.searchButton= true;
  this.loadAllInvoice();
}

setpaggination(e) {
  this.searchButton = false;
  this.body.controls['PageSize'].setValue(e.pageSize);
  this.body.controls['PageNumber'].setValue(e.pageIndex + 1);
  this.loadAllInvoice();
}

detail=false;
invoicefilepath:'';
resume_path=''
getinvoiceDetail(data:any){
    this.first=false;
    this.second=true;
    this.third=false;
    this.fourth=false;
  this.invoicedetail=data;
  this.invoicePart=false;
  this.detail = true;
 
  this.staffingservice.getinvoicefile(this.invoicedetail.invoicefile).subscribe(res => {      
    this.invoicefilepath = res['data']['path'];  
  });


}
raisevalue:boolean=false;
generateInvoiceByMail(){
  let maildata={
    bcc:this.mailInvoiceForm.controls['email'].value,
    invoicedetail:this.invoicedetail,
    invoicedata:this.invoicedata,
    period:this.datepipe.transform(this.invoicedetail['invoiceperiod'],'MMM y'),
    supplieremail:this.userData.data.credentials.emailId
  }
  this.staffingservice.generateInvoiceByMail(maildata).pipe(first()).subscribe((res: any) => {

    if (res.success) {
     this.tostr.successToastr(res.message, 'Success!');
     this.mailinvoice=false;
     this.raisevalue =true;
    } else {
      
      this.tostr.errorToastr(res.message, 'Error!')
    }
  });
}

omit_validate_specialchar(event: { charCode: any; }) {
  const k = event.charCode;   
   if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
     this.tostr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
     return false}
   }

mailinvoicePopUp(){
  this.mailinvoice=true;
  this.mailInvoiceForm=this.formBuilder.group({
    email:''
  });
  
}


isLogin='true';
openStaffingAgencyDialog() {
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  this.first=false;
  this.second=false;
  this.third=true;
  this.fourth=false;
  if(this.isLogin != 'false'){
  const dialogRef = this.dialog.open(UploadDocumentComponent, {
    width: '500px',
    disableClose: true,
    data: this.invoicedetail
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result !=undefined && result != null){
      this.filename=result;

      this.staffingservice.getinvoicefile(this.filename).subscribe(res => {      
        this.invoicefilepath = res['data']['path'];  
      });
    }
    console.log('The dialog was closed');
  });
}
}

viewInvoiceFor(record:any) {
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if(this.isLogin != 'false'){
  const dialogRef = this.dialog.open(InvoiceDetailComponent, {
    width: 'auto',
    disableClose: true,
    data: record
  });
  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
}
}

AcceptInvoice(invoice){
  var data={
    'status':'Accept',
    'InvoiceId':invoice.invoiceId
  }
  this.staffingservice.AcceptInvoice(data).subscribe((res: any) => {
    if(res.success){
      this.loadAllInvoice();
      this.tostr.successToastr('Invoice status successfully updated');
  }
  else {
    this.tostr.errorToastr(res.message, 'Error!')
  }
  }) 
  }
  RejectInvoice(invoice){
    var data={
      'status':'Reject',
      'InvoiceId':invoice.invoiceId,
      'period':this.datepipe.transform(invoice.invoiceperiod,'MMM y'),
      'jobid':invoice.jobId,
      'jobseekerId':invoice.employeeId,
      'supplier':invoice.staffing,
      'supplieremail':invoice.staffingemail,
      'Client':invoice.client,
      'record':invoice.totalrecord,
      'type':invoice.type_ofInvoice,
      'currency':invoice.currency,
      'amount':invoice.totalamount,
      'msp':invoice.msp,
      'vms':invoice.vms,
      'cost':invoice.totalfee,
      'discount':invoice.discount,
      'taxes':invoice.taxes,
      'noofemployee':invoice.noofemployee

    }
    this.staffingservice.AcceptInvoice(data).subscribe((res: any) => {
      if(res.success){
        this.loadAllInvoice();
        this.tostr.successToastr('Invoice cancelled successfully');
    }
    else {
      this.tostr.errorToastr(res.message, 'Error!')
    }
    }) 
  }
}

import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { RouterLink, RouterModule, Router } from '@angular/router';
import { EnterpriseService } from '../enterprise.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { JobseekerService } from '../../JobSeeker/jobseeker.service';
import { DatePipe } from "@angular/common";
// import { from } from 'rxjs';
import { JobDetailPopupComponent } from '../job-detail-popup/job-detail-popup.component';
import { Title } from '@angular/platform-browser';
import { AssignJobToAgencyComponent } from '../assign-job-to-agency/assign-job-to-agency.component';
import { AssignJobToAgencyGroupComponent } from '../assign-job-to-agency-group/assign-job-to-agency-group.component';
import { ExcelService } from 'src/app/admin/service/excel.service';
import {AuthenticationService } from '../../app.service';
import { ShareService } from 'src/app/share-module/share.service';


const Sticky = require('sticky-js');
const sticky = new Sticky('.sticky-filter');
export interface DialogData {
  jobseekerId: string;
  date: Date;
  // time: Time;
}

@Component({
  selector: 'app-my-job-list-enterprise',
  templateUrl: './my-job-list-enterprise.component.html',
  styleUrls: ['./my-job-list-enterprise.component.css']
})
export class MyJobListEnterpriseComponent implements OnInit {
  @ViewChild(MatPaginator,{static : false} ) paginators: MatPaginator;
  pageNo: number = 1;
  skillMultSearch: string = '';
  MyjobFilter: FormGroup;
  listView: boolean = false;
  preLoader: boolean = false;
  dataType: string;
  joblistData: any;
  ddlFilterList: any;
  synData: boolean = false;
  filterList: any;
  istabopen: string = 'Date';
  isVisibleFilterBtn = true;
  readmore: any = {};
  userData: any = {};
  paginator = {
    pageSize: 5,
    length: 0,
    pageSizeOptions: [5, 10, 20, 100],
    pageIndex: 0
  };
  
  constructor(
    private enterpriseApis: EnterpriseService,
    private _formBuilder: FormBuilder, private toastr: ToastrManager,
    public dialog: MatDialog,private AuthenticationService: AuthenticationService,
    private datepipe: DatePipe,
    private JSApi: JobseekerService,
    private _titleService: Title
    , private _router: Router,
    private excelService: ExcelService,
    private shareservice:ShareService
  ) {
    // for title set 
    var newTitle = 'Verisource | My Jobs';
    this._titleService.setTitle(newTitle);
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.readmore = {};
    this.formInit();
    this.enterpriseApis.getfiltersMyjobs().subscribe((res: any) => {
      if (res.success) {
        this.filterList = res.data;
        if (res.data.length == 0) {
          this.isVisibleFilterBtn = false;
        }
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });


  }

  islstopen: boolean = false;
  isOnreset: boolean = false;
  JobList: any;
  csv_JobList:any=[];
  jobid: any;
  // toggletabOpen(){this.istabopen = ! this.istabopen;}
  OpenlfMenu() { this.islstopen = !this.islstopen }
  popUpreset(jobid) {
    this.jobid = jobid;
    this.isOnreset = !this.isOnreset;
  }


  formInit() {
    this.MyjobFilter = this._formBuilder.group({
      "PageSize": [5],
      "PageNumber": [1],
      "postedDate": '0',
      "jobType": ["All"],
      "minAnnualPay": [0],
      "maxAnnualPay": [0],
      "search": [""],
      "country": '',
      "jobLocation": [''],
      'orderBy': "Date",
      "minExperience": 0,
      "maxExperience": 0,
      "state": '',
      "zipCode": '',
      "user":'EP',
      "pool_Id" :'',
      "Skillsearch": '',
      "Skillsearch1": '',
      "Skillsearch2": '',
      "Skillsearch3": '',
      "Skillsearch4": '',
      "Skillsearch5": '',
      "skillcounter": ''
    });
  }

  ngOnInit() {
    this.LoadJobList(1);
  }

  isLogin='true';
  openDialog(id): void {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(JobDetailPopupComponent, {
      width: 'auto',
      data: { jobId: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  }
  openSADialog(record): void {
    localStorage.removeItem('CurrentGroupRequest');
    localStorage.setItem('CurrentGroupRequest', JSON.stringify(record));
    this._router.navigate(['ep/enterprise/assignJobToAgency']);

    // const dialogRef = this.dialog.open(AssignJobToAgencyComponent, {
    //   width: 'auto',
    //   data: record
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   // console.log('The dialog was closed');
    //   // this.animal = result;
    // });
  }
  openSAGroupDialog(record): void {
    
    localStorage.removeItem('CurrentGroupRequest');
    localStorage.setItem('CurrentGroupRequest', JSON.stringify(record));
    this._router.navigate(['ep/enterprise/assignJobToAgencyGroup']);
    // const dialogRef = this.dialog.open(AssignJobToAgencyGroupComponent, {
    //   width: 'auto',
    //   data: record
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   // console.log('The dialog was closed');
    //   // this.animal = result;
    // });
  }

dateFormat(date){
  return this.datepipe.transform(date,'MM/dd/yy');
}


  resetData() {
    this.formInit();
    this.LoadJobList(1);
  }

  setpaggination(e) {
 
    this.searchButton = false;
    // console.log(e);
    // this.MyjobFilter.controls['PageSize'].setValue(e.pageSize);
    // this.MyjobFilter.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.MyjobFilter.controls['PageSize'].setValue(e.pageSize);
    this.MyjobFilter.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.LoadJobList(1);
  }
  
  searchButton = false;
  loadData(pageNos){
    this.searchButton= true;
    this.LoadJobList(pageNos);
  }
  LoadJobList(pageNos) {   
  
    this.AuthenticationService.jwtTokenExpCheck();
    this.preLoader = true;
    if(this.searchButton == true){
      this.MyjobFilter.controls['PageNumber'].setValue(1);
    }

    this.skillMultSearch = this.MyjobFilter.controls['Skillsearch'].value;
    var searchData = this.MyjobFilter.controls['Skillsearch'].value;

    var counter = searchData.length;

    // if(counter>0){
    //   this.MyjobFilter.controls['skillcounter'].setValue(counter);
    // }
    if (counter == 0) {
      this.MyjobFilter.controls['skillcounter'].setValue(counter);
      this.MyjobFilter.controls['Skillsearch1'].setValue('');
      this.MyjobFilter.controls['Skillsearch2'].setValue('');
      this.MyjobFilter.controls['Skillsearch3'].setValue('');
      this.MyjobFilter.controls['Skillsearch4'].setValue('');
      this.MyjobFilter.controls['Skillsearch5'].setValue('');
    }
    else if (counter == 1) {
      this.MyjobFilter.controls['skillcounter'].setValue(counter);
      this.MyjobFilter.controls['Skillsearch1'].setValue(searchData[0]);
      this.MyjobFilter.controls['Skillsearch2'].setValue('');
      this.MyjobFilter.controls['Skillsearch3'].setValue('');
      this.MyjobFilter.controls['Skillsearch4'].setValue('');
      this.MyjobFilter.controls['Skillsearch5'].setValue('');
    }
    else if (counter == 2) {
      this.MyjobFilter.controls['skillcounter'].setValue(counter);
      this.MyjobFilter.controls['Skillsearch1'].setValue(searchData[0]);
      this.MyjobFilter.controls['Skillsearch2'].setValue(searchData[1]);
      this.MyjobFilter.controls['Skillsearch3'].setValue('');
      this.MyjobFilter.controls['Skillsearch4'].setValue('');
      this.MyjobFilter.controls['Skillsearch5'].setValue('');
    } else if (counter == 3) {
      this.MyjobFilter.controls['skillcounter'].setValue(counter);
      this.MyjobFilter.controls['Skillsearch1'].setValue(searchData[0]);
      this.MyjobFilter.controls['Skillsearch2'].setValue(searchData[1]);
      this.MyjobFilter.controls['Skillsearch3'].setValue(searchData[2]);
      this.MyjobFilter.controls['Skillsearch4'].setValue('');
      this.MyjobFilter.controls['Skillsearch5'].setValue('');
    } else if (counter == 4) {
      this.MyjobFilter.controls['skillcounter'].setValue(counter);
      this.MyjobFilter.controls['Skillsearch1'].setValue(searchData[0]);
      this.MyjobFilter.controls['Skillsearch2'].setValue(searchData[1]);
      this.MyjobFilter.controls['Skillsearch3'].setValue(searchData[2]);
      this.MyjobFilter.controls['Skillsearch4'].setValue(searchData[3]);
      this.MyjobFilter.controls['Skillsearch5'].setValue('');
    } else if (counter == 5) {
      this.MyjobFilter.controls['skillcounter'].setValue(counter);
      this.MyjobFilter.controls['Skillsearch1'].setValue(searchData[0]);
      this.MyjobFilter.controls['Skillsearch2'].setValue(searchData[1]);
      this.MyjobFilter.controls['Skillsearch3'].setValue(searchData[2]);
      this.MyjobFilter.controls['Skillsearch4'].setValue(searchData[3]);
      this.MyjobFilter.controls['Skillsearch5'].setValue(searchData[4]);
    }

    this.enterpriseApis.EnterpriseViewListJobs(this.MyjobFilter.value).subscribe((res: any) => {
      this.preLoader = false;
      this.synData = true;
      if (res.success) {
        this.JobList = res;
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }
        this.searchButton = false;
        console.log( this.JobList);
        if (this.JobList.data.length > 0) {
          this.paginator.length = this.JobList.data[0].totalCount;
         } 
         else {
           this.paginator.length = 0;
        }     
        this.csv_JobList=[];
        this.JobList.data.forEach(element => {
          this.csv_JobList.push({
              'Job Title': element.jobTitle,
              'Job Status': element.job_status,
              'Created':  this.datepipe.transform(element.jobpostedDate,'shortDate','locale'),
              'Released': element.jobposted,
              'Experience':(element.requiredExpMin)-(element.requiredExpMin),

              'Skills': element.keywords,
              'Location': element.jobLocation,
              'Max Bill Rate': element.annualPayMax,
              'Start Date':this.datepipe.transform(element.joiningDate,'MMM d, y','locale'),
              'Job Seekers':element.noOfPushedprofiles,
              'Suppliers':''
              
            });
      });
        console.log("Filter Response =>", this.csv_JobList)
      }
    });
  }



  toggletabOpen(val) {
    this.searchButton = true;
    this.istabopen = val;
    this.MyjobFilter.controls['orderBy'].setValue(val);
    this.LoadJobList(this.pageNo);
  }
  DeleteEnterpriseJob() {
    this.enterpriseApis.closeJob(this.jobid).subscribe(res => {
      this.JobList = res;
      if (this.JobList.success) {
        this.isOnreset = false;
        this.LoadJobList(this.pageNo);
        this.toastr.successToastr(this.JobList.message, 'Succcess!', { position: 'bottom-right' });
        // window.location.reload();
      } else {
        this.toastr.errorToastr(this.JobList.message, 'Error!', { position: 'bottom-right' });
      }
    });
  }







  jsonArrayCnvrtVl(val) {
    // console.log('val :---->>', Object.keys(val));
    return Object.keys(val);
  }
  jsonArrayCnvrt(val) {
    return Object.values(val);
  }

  countryData(ddlType, values) {
    this.ddlFilterList = [];
    // console.log("==============?>", ddlType);
    switch (ddlType) {
      case 'country': {
        if (this.MyjobFilter.controls['country'].value.length >= 3) {
          this.JSApi.countries(this.MyjobFilter.controls['country'].value).subscribe((res: any) => {
            if (res.success) {
              // console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }
          });
        }
        break;
      }
      case 'jobLocation': {
        if (this.MyjobFilter.controls['jobLocation'].value.length >= 3) {
          // console.log("Response ------------>");
          this.JSApi.joblocation(this.MyjobFilter.controls['jobLocation'].value).subscribe((res: any) => {
            if (res.success) {
              // console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }

          });
        } else {
          // console.log("dssadsa");
        }
        break;
      }

    }
  }

  clearData() {
    this.ddlFilterList = [];
  }


  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    //In chrome and some browser scroll is given to body tag
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if (pos == max) {
      //Do your action here
      // console.log("List End here")
    }
  }


  pageEvent(val) {
    console.log(val);
  }



  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.csv_JobList, 'sample');
  }


  clearForm(){
 
    this.MyjobFilter = this._formBuilder.group({
      "PageSize": [5],
      "PageNumber": [1],
      "postedDate": '0',
      "jobType": ["All"],
      "minAnnualPay": [0],
      "maxAnnualPay": [0],
      "search": [""],
      "country": '',
      "jobLocation": [''],
      'orderBy': "Date",
      "minExperience": 0,
      "maxExperience": 0,
      "state": '',
      "zipCode": '',
      "user":'EP',
      "pool_Id" :'',
      "Skillsearch": '',
      "Skillsearch1": '',
      "Skillsearch2": '',
      "Skillsearch3": '',
      "Skillsearch4": '',
      "Skillsearch5": '',
      "skillcounter": ''
    });
    this.paginator.pageSize=5;
    this.loadData('1');
    this.paginators.pageIndex = 0;
  }




}

import { Injectable } from '@angular/core';
// import { AuthenticationService } from './authentication.service';
import {AuthenticationService} from '../../app.service'

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StaffingAgencyServices {

  baseUrl = '';
  token = '';
  userData:any={};
  constructor(private API: AuthenticationService, private http: HttpClient,
    private AuthenticationService: AuthenticationService, private _router: Router) {
    this.baseUrl = this.API.url;
    this.token = localStorage.getItem('token');
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    if (this.token === null) {
      this._router.navigate['/auth/home'];
    }
  }

  getStaffingAgency_onType(code: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/getStaffingAgency_onType/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // countries API
  country() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/guestuser/countries`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  countries = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/guestuser/countries`;
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    return this.http.get(localurl, httpOptions);
}

  // state API
  state(searchText: string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/guestuser/states/${searchText}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  // City API
  city(stateId: any) {
    const localurl = `${this.baseUrl}/api/guestuser/cities/${stateId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  //update description
  updateDescription(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/admin/updatedescription`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  //get updated  description
  getupdatedDescription(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/admin/getupdateddescription`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

    //update jobdescription  for client request
    updateclientDescription(body) {
      this.token = localStorage.getItem("token");
      this.AuthenticationService.jwtTokenExpCheck();
      const localurl = `${this.baseUrl}/api/admin/updatejobdescription`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token
        })
      };
      return this.http.post(localurl, body, httpOptions);
    }
  
  resetPassword(body, Id:string,usertype:string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let url = `${this.baseUrl}/api/admin/resetpassword/${Id}/${usertype}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token 
      })
    };
    return this.http.post(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  sendvmsmail(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/admin/sendvmsmail`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  jobsListAPI = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    // let Option = new Http()
    let localurl = `${this.baseUrl}/api/staffingagency/browsejobs`;
    // console.log(localurl, body)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  invitationSendAPI = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/staffingagency/invitationSend`;
    // console.log(localurl, body)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  getjobseekerlistformail(jobId: any,jobseekerId:any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/getjobseekerlistformail/${jobId}/${jobseekerId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }


  invitationVerifiy(code: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/invitationVerifiyCode/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  invitationRejected(code: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/invitationRejectedCode/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  updateinvitationPost = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    // let Option = new Http()
    let localurl = `${this.baseUrl}/api/staffingagency/updateInvitation`;
    // console.log(localurl, body)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }
  UpdateSeekerAndStaffingDetails = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    // let Option = new Http()
    let localurl = `${this.baseUrl}/api/staffingagency/UpdateSeekerAndStaffingDetails`;
    // console.log(localurl, body)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(localurl, body, httpOptions);

  }
  


  JobAndSeekerDetails(code: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/JobAndSeekerDetails/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  updateinvitation_request(code: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/rejectBySeekerDetails/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(localurl, httpOptions);
  }
  updateinvitation_requestSA(code: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/rejectBySADetails/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(localurl, httpOptions);
  }


  GetbeelingdataForVms(code: any,type) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    if(type == '1'){
      var localurl = `${this.baseUrl}/api/staffingagency/getBeelingdataforVms/${code}`;
    }
    if(type == '2'){
      var localurl = `${this.baseUrl}/api/staffingagency/getenterpriseDataForJob/${code}`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }



  UpdateStafingStatus(code: any, rate: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/UpdateStafingStatus/${code}/${rate}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(localurl, httpOptions);
  }


  // assignJobseekerBySAVerify = (body) => {
  //   const localurl = `${this.baseUrl}/api/staffingagency/beeline/assignJobseekerBySAVerify`;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'authenticate': this.token
  //     })
  //   };
  //   return this.http.post(localurl, body, httpOptions);
  // }

  assignJobseekerBySAVerify = (body: any) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const url = `${this.baseUrl}/api/staffingagency/beeline/assignJobseekerBySAVerify`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(url, body, httpOptions);
  }
  GetSubscribeUnsubscribeData(emailid: any, topicid: any) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
  

    const localurl = `${this.baseUrl}/api/staffingagency/getSubscribeDetails/${emailid}/${topicid}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    // return this.http.get(localurl, httpOptions);
    return this.http.get<any>(localurl, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  GetUnsubscribeTopics() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/getUnsubscribetTopics`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(localurl, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  // insertUpdateSubsUnSubs() {
  //   const localurl = `${this.baseUrl}/api/staffingagency/insertUpdateSubsUnSubs`;
  //     const httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //       })
  //     };
  //     return this.http.get<any>(localurl, httpOptions).pipe(map(res => {
  //       return res;
  //   }));
  // }

  insertUpdateSubsUnSubs = (body: any) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    console.log('body', body);
    const url = `${this.baseUrl}/api/staffingagency/insertUpdateSubsUnSubs`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res
    }));
  }
  sendfeedbackmail = (body: any) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    console.log('body', body);
    const url = `${this.baseUrl}/api/staffingagency/SendmailtoAdmin`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res
    }));
  }



}

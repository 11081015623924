import { Component, OnInit } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
  selector: "app-enterprise",
  templateUrl: "./enterprise.component.html",
  styleUrls: ["./enterprise.component.css"]
})
export class EnterpriseComponent implements OnInit {
  constructor(private _titleService: Title ) { 
    // for set Page title 
    var newTitle = 'Verisource | Dashboard';
    this._titleService.setTitle( newTitle );
  }

  ngOnInit() { }
}

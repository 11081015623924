import { Component, OnInit, ViewContainerRef, ViewChild, ElementRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { RouterLink, Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
// import { staffingAgencyServices } from '../staffing-agency.services';
import { EnterpriseService } from '../enterprise.service';

import { DatePipe } from '@angular/common';
import { AuthenticationService } from '../../app.service';
import { reject } from 'q';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';
import { debug } from 'util';
import * as moment from 'moment';
import { ShareService } from 'src/app/share-module/share.service';
//import { AddionaldocumentPopUpComponent } from 'src/app/admin/staffing-agency/popup /addionaldocument-pop-up/addionaldocument-pop-up.component';
@Component({
  selector: 'app-candidate-profile-update',
  templateUrl: './candidate-profile-update.component.html',
  styleUrls: ['./candidate-profile-update.component.css']
})
export class CandidateProfileUpdateComponent implements OnInit {
  @ViewChild('candidateImageFile',{static:false}) myInputVariable: ElementRef;
  createdby:any;
  staffID:any;
  modifiedBy:any;
  isValueWorkAuth: boolean=  true;
  isValidTill: boolean =  true;
  isValueJobType: boolean = false;
  isUsDegree: any;
  country_Id: any = 0;
  state_Id: any = 0;
  allStateList: any = [];
  allCityList: any = [];
  stateLoader: false;
  cityLoader: false;
  isTrue: boolean = true;
  DataST: any = '';
  jobSeekerIds: any = [];
  jobSeekerData: any = [];
  jobData: any = [];
  a1: any;
  candidateListData: any;
  isDocToggle = false;
  todayDate: number;
  baseUrl = '';
  baseUrl2 = '';
  baseUrlDoc = '';
  isRequired = false;
  isWorkAuthRequired = false;
  fileresponse: any;
  imgSrc: any = [];
  docsList: any = [];
  activeTab = 4;
  editUploadProfile: FormGroup;
  htmlCode1 = `<ngx-img (onSelect)='onSelect($event)' (onReset)='onReset()'></ngx-img>`;
  optionsHTML = `<ngx-img (onSelect)='onSelect($event)' (onReset)='onReset()' [config]='options'></ngx-img>`;
  submitMsg = '';
  professionalForm: FormGroup;
  socialForm: FormGroup;
  educationalForm: FormGroup;
  skillsForm: FormGroup;
  submited = false;
  skSubmited = false;
  public shouldShow = true;
  jobseekerId = '';
  serverpth = '';
  docName = '';
  citizen = ['Antigua and Barbuda', 'Argentina', 'Barbados', 'Belize', 'Bolivia', 'Brazil',
    'Canada', 'Chile', 'Costa Rica', 'Cuba', 'Dominica', 'Ecuador', 'El Salvador', 'Fiji',
    'Grenada', 'Guatemala', 'Guyana', 'Honduras', 'Jamaica', 'Lesotho', 'Mexico', 'Nicaragua',
    'Pakistan', 'Panama', 'Paraguay', 'Peru', 'Saint Kits and Nevis', 'Saint Lucia',
    'Saint Vincent and the Grenadines', 'Tanzania', 'Trinidad and Tobago', 'Tuvalu',
    'United States', 'Uruguay', 'Venezuela'];
  visaTypeList = [
    { name: 'US Citizen', value: 'USCitizen' },
    { name: 'Green Card ', value: 'GreenCard ' },
    { name: 'H1B', value: 'H1B ' },
    { name: 'OPT', value: 'OPT EAD' },
    { name: 'CPT', value: 'CPT EAD' },
    { name: 'GC', value: 'GC EAD' },
    { name: 'L2', value: 'L2 EAD' },
    { name: 'H4', value: 'H4 EAD' },
    { name: 'TN', value: 'TN EAD' },
  ];

  jobType = [
    { id: 'W2 PERMANENT', name: 'FTE', selected: false },
    { id: 'CORP-CORP', name: 'CORP to CORP', selected: false },
    { id: 'W2 CONTRACT', name: 'W2 CONTRACT', selected: false },
    { id: '1099 CONTRACT', name: '1099 CONTRACT', selected: false },
    { id: 'NEED H1B', name: 'H1B', selected: false },
    { id: 'H1B TRANSFER', name: ' H1B TRANSFER', selected: false }
  ];


  EADList = [
    { name: 'OPT EAD', value: 'OPT EAD' },
    { name: 'CPT EAD', value: 'CPT EAD' },
    { name: 'GC EAD', value: 'GC EAD' },
    { name: 'L2 EAD', value: 'L2 EAD' },
    { name: 'H4 EAD', value: 'H4 EAD' },
    { name: 'TN EAD', value: 'TN EAD' },
  ];



  domainExperienceList = [
    { name: 'IT SOFTWARE ', value: 'IT SOFTWARE' },
    { name: 'GOVERNMENT', value: 'GOVERNMENT' },
    { name: 'RETAIL', value: 'RETAIL' },
    { name: 'HEALTHCARE', value: 'HEALTHCARE' },
    { name: 'TELECOM', value: 'TELECOM' },
    { name: 'DOT.COM', value: 'DOT.COM' },
    { name: 'DFINANCIAL', value: 'FINANCIAL' },
    { name: 'MANUFACTURING', value: 'MANUFACTURING' },
    { name: 'INSURANCE', value: 'INSURANCE' },
    { name: 'PHARMACEUTICALS', value: 'PHARMACEUTICALS' },
  ];



  prefrredEmpList = [
    { name: 'FTE', value: 'W2 PERMANENT' },
    { name: 'CORP to CORP', value: 'CORP-CORP' },
    { name: 'W2 CONTRACT', value: 'W2 CONTRACT' },
    { name: '1099 CONTRACT', value: '1099 CONTRACT' },
    { name: 'H1B', value: 'NEED H1B' },
    { name: 'H1B TRANSFER', value: 'H1B TRANSFER' },
  ];





  H1BVisaTypeList = [
    { name: 'B1/B2', value: 'B1/B2' },
    { name: 'L1', value: 'L1' },
    { name: 'F1', value: 'F1' },
    { name: 'F2', value: 'F2' },
    { name: 'J1', value: 'J1' },
    { name: 'OTHER VISA', value: 'OTHER VISA' },
  ];


  SecurityClear = [
    { name: 'Yes', value: 'Yes' },
    { name: 'No', value: 'No' },

  ];


  docList = [
    { name: 'Passport', value: 'Passport' },
    { name: 'Driver license', value: 'Driver license' },
    { name: 'State ID', value: 'State ID' },
    { name: 'Any Other', value: 'Any Other' }
  ];
  daysList = [
    { name: '01', value: '01' },
    { name: '02', value: '02' },
    { name: '03', value: '03' },
    { name: '04', value: '04' },
    { name: '05', value: '05' },
    { name: '06', value: '06' },
    { name: '07', value: '07' },
    { name: '08', value: '08' },
    { name: '09', value: '09' },
    { name: '10', value: '10' },
    { name: '11', value: '11' },
    { name: '12', value: '12' },
    { name: '13', value: '13' },
    { name: '14', value: '14' },
    { name: '15', value: '15' },
    { name: '16', value: '16' },
    { name: '17', value: '17' },
    { name: '18', value: '18' },
    { name: '19', value: '19' },
    { name: '20', value: '20' },
    { name: '21', value: '21' },
    { name: '22', value: '22' },
    { name: '23', value: '23' },
    { name: '24', value: '24' },
    { name: '25', value: '25' },
    { name: '26', value: '26' },
    { name: '27', value: '27' },
    { name: '28', value: '28' },
    { name: '29', value: '29' },
    { name: '30', value: '30' },
    { name: '31', value: '31' }
  ];
  yearList = [
    { name: '2020', value: '2020' },
    { name: '02', value: '02' },
    { name: '03', value: '03' },
    { name: '04', value: '04' },
    { name: '05', value: '05' },
    { name: '06', value: '06' },
    { name: '07', value: '07' },
    { name: '08', value: '08' },
    { name: '09', value: '09' },
    { name: '10', value: '10' },
    { name: '11', value: '11' },
    { name: '12', value: '12' },
    { name: '13', value: '13' },
    { name: '14', value: '14' },
    { name: '15', value: '15' },
    { name: '16', value: '16' },
    { name: '17', value: '17' },
    { name: '18', value: '18' },
    { name: '19', value: '19' },
    { name: '20', value: '20' },
    { name: '21', value: '21' },
    { name: '22', value: '22' },
    { name: '23', value: '23' },
    { name: '24', value: '24' },
    { name: '25', value: '25' },
    { name: '26', value: '26' },
    { name: '27', value: '27' },
    { name: '28', value: '28' },
    { name: '29', value: '29' },
    { name: '30', value: '30' },
    { name: '31', value: '31' }
  ];
  resumeName: string = '';
  voiceFileName: String = '';
  veriFileName: String = '';
  countryList: any = [];
  nationalityList: any = [];
  allNationality: any = [];
  allCountryList:any=[];
  stateList: any = [];
  cityList: any = [];
  today: String = new Date().toISOString().split('T')[0];
  mailError: boolean = false;
  uploadData: any;
  Epsubmitted = false;
  profileLoader = false;
  resImageData: any;
  imgProfile: string = '';
  profileImage: string = '';
  sync = false;
  resumeResponse: any;
  resumeLoader = false;
  sync1 = false;
  resumeResponse1: any;
  voiceLoader = false;
  sync2 = false;
  resumeResponse2: any;
  docsLoader = false;
  sync3 = false;
  resumeResponse3: any;
  eduLoader = false;
  isVisaToggle = false;
  mobileError = false;
  phoneError = false;
  nextButton = true;
  rateError = false;
  salaryError = false;
  isVisa = false;
  visaType = false;
  yearslist = [];
  isSubVisaToggl;
  //isEADToggle;
  //selectVisa;
  formInit;
  isvisibleEAD = true;
  isvisibleNeedNewH1B = true;
  checkWA = '';
  sub: any;
  secondseekerId: any;
  page: number;
  userData: any;
  interpriseId: any;
  dob_year: any;
  isShow: boolean = false;
  viewer = 'google';
  doc = "http://api.veriklick.in/Content/sampleresume.docx";
  isReset: boolean =true;
  abc: any;
  resumeFile: any;
  isResumeExits: boolean = false;
  imageName: any;
  isImgExits: boolean =false;
  charCodeValue: any;
  isvisaValue: any;
  // doc = "https://docs.google.com/viewerng/viewer?url=http://api.veriklick.in/Content/sampleresume.docx";
  constructor(
    private AuthenticationService: AuthenticationService,
    public dialog: MatDialog,
    private staffAPI: staffingAgencyServices,
    private _formBuilder: FormBuilder,
    private API: EnterpriseService,
    private toastr: ToastrManager,
    vcr: ViewContainerRef,
    private _router: Router,
    private datePipe: DatePipe,
    private appAPI: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private ActiveRouter: ActivatedRoute,
    private shareservice:ShareService
  ) {

    //for title set 
    var newTitle = 'Verisource | Update Jobseeker';
    this._titleService.setTitle(newTitle);
    this.interpriseId = localStorage.getItem("interpriseId");
    this.todayDate = Date.now();
    this.baseUrl = `${this.appAPI.url}/api/enterprise/image/resume/`;
    this.baseUrl2 = `${this.appAPI.url}/api/enterprise/image/verification/`;
    this.baseUrlDoc = `${this.appAPI.url}/api/enterprise/image/resume/`;
    this.jobseekerId = this.activatedRoute.snapshot.params['id'];
    this.abc = this.activatedRoute.snapshot.params['abc'];
    this.serverpth = `${this.appAPI.url}`;

    this.userData = JSON.parse(window.localStorage.getItem('udata'));

    // Get Job seeker Details
    this.API.candidatedetails(this.jobseekerId).subscribe((res: any) => {
      if (res.success) {
        console.log('res.data', res.data);
        this.setValueCandidate(res.data);
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
    this.initFormGroup();
    let currentYear = (new Date()).getFullYear();
    for (let i = 1950; i <= currentYear; i++) {
      this.yearslist.push(i);
    }

  }
  resetResume(){
    this.isReset = false;
  }
  ngOnInit() {

    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    console.log('{userData.data.credentials.userType}', this.userData.data.credentials.userType);
    this.sub = this.ActiveRouter
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.page = +params['project'] || 0;
        console.log('page=====>', this.page)
      });
  }
  get pu() {
    return this.editUploadProfile.controls;
  }
  get cp() {
    return this.professionalForm.controls;
  }
  get sk() {
    return this.skillsForm.controls;
  }
  get ot() {
    return this.socialForm.controls;
  }

  onSelect(event) {
    this.profileLoader = true;
    const formData = new FormData();
    formData.append('profileimage', event.target.files[0]);
    if (event.target.files[0].type === 'image/png'
      || event.target.files[0].type === 'image/jpg'
      || event.target.files[0].type === 'image/jpeg') {
      this.profileLoader = true;
      if (event.target.files[0].size < (2029410 / 2)) {
        this.API.staffJobseekerProfileImg(formData).subscribe(res => {
          this.resImageData = res;
          if (this.resImageData.success) {
            this.toastr.successToastr(this.resImageData.message, '', { position: 'bottom-right' });
            this.editUploadProfile.controls['profileImage'].setValue(this.resImageData.data.fileName);
            this.profileLoader = false;
            this.isImgExits = true;
            this.imgProfile = `${this.serverpth}/api/auth/image/${this.resImageData.data.fileName}`;
          
          } else {
            this.toastr.errorToastr(this.resImageData.message, '', { position: 'bottom-right' });
          }
        });
      }
      else {
        this.toastr.errorToastr('Profile image should be less than 1 MB.');
        this.profileLoader = false;
      }
    }
    else {
      this.toastr.errorToastr('Profile image should be in png or jpg format.', 'Error!');
    }
  }

  resumeUplaod(event) {
    this.resumeLoader = true;
    const formData = new FormData();
    formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
    formData.append('resume', event.target.files[0]);
    const type = event.target.files[0].name.split('.');
    console.log('type', type[1]);
    if (type[1] === 'docx'
      || type[1] === 'pdf'
      || type[1] === 'doc') {
      if (event.target.files[0].size < (2029410 / 2)) {
        this.API.staffJobseekerProfileImg(formData).subscribe(res => {
          this.resumeResponse = res;
          if (this.resumeResponse.success != true) {
            this.resumeLoader = false;
          }
          if (this.resumeResponse.success) {
            this.resumeLoader = false;
            this.isShow = true;
            this.isResumeExits = true;
            this.professionalForm.controls['resume'].setValue(this.resumeResponse.data.fileName);
            this.resumeName = this.resumeResponse.data.fileName;
            this.todayDate = Date.now();
            this.toastr.successToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
          } else {
            this.toastr.errorToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
          }
          this.sync = true;
        });
      } else {
        this.toastr.errorToastr('Resume file should be less than 1 MB.');
        this.resumeLoader = false;

      }
    } else {
      this.toastr.warningToastr('Only .pdf,.doc,.docx Support');
      this.resumeLoader = false;
    }
  }

  ResetNewH1B() {
    this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].reset();
  }
  ResetEAD() {
    this.editUploadProfile.controls['WorkAuthDropDownEAD'].reset();

  }
  voiceUpload(event) {
    //  console.log(event)
    this.voiceLoader = true;
    const formData = new FormData();
    formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
    formData.append('voicesample', event.target.files[0]);
    this.API.staffJobseekerProfileImg(formData).subscribe(res => {
      this.resumeResponse1 = res;
      if (this.resumeResponse1.success) {
        this.voiceLoader = false;
        this.toastr.successToastr(this.resumeResponse1.message, '', { position: 'bottom-right' });
        this.socialForm.controls['voiceSample'].setValue(this.resumeResponse1.data.fileName);
        this.voiceFileName = this.resumeResponse1.data.fileName;
        // document.getElementById('voiceList').innerHTML = `<b class='badge badge-info'>${this.resumeResponse1.data.fileName}</b>`
      } else {
        this.toastr.errorToastr(this.resumeResponse1.message, '', { position: 'bottom-right' });
      }
      this.sync1 = true;
    });
  }

  verificationDocs(event) {
    // console.log(event);
    this.docsLoader = true;
    const formData = new FormData();
    formData.append('verificationIdType', 'voterID');
    formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
    formData.append('verification', event.target.files[0]);
    this.API.staffJobseekerProfileImg(formData).subscribe(res => {
      this.resumeResponse2 = res;
      if (this.resumeResponse2.success) {
        this.docsLoader = false;
        this.toastr.successToastr(this.resumeResponse2.message, '', { position: 'bottom-right' });
        document.getElementById('docList').innerHTML = `<b class='badge badge-info'>${this.resumeResponse2.data.docName}</b>`;
        this.docName = this.resumeResponse2.data.docName;
        this.docsList.push(this.resumeResponse2.data.docId);
        this.socialForm.controls['docIds'].setValue(this.docsList);
      } else {
        this.toastr.errorToastr(this.resumeResponse2.message, '', { position: 'bottom-right' });
      }
      this.sync2 = true;

    });
  }

  qualificationUpload(event) {
    //  console.log(event);
    this.eduLoader = true;
    const formData = new FormData();
    formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
    formData.append('qualificationlevel', 'graduation');
    formData.append('qualification', event.target.files[0]);
    this.API.staffJobseekerProfileImg(formData).subscribe(res => {
      this.resumeResponse3 = res;
      if (this.resumeResponse3.success) {
        this.eduLoader = false;
        this.toastr.successToastr(this.resumeResponse3.message, '', { position: 'bottom-right' });
        this.docsList.push(this.resumeResponse2.data.docId);
        this.socialForm.controls['docIds'].setValue(this.docsList);
      } else {
        this.toastr.errorToastr(this.resumeResponse3.message, '', { position: 'bottom-right' });
      }
      this.sync3 = true;
    });
  }

  changeTab(i: number) {
    this.imgSrc = [];
    this.activeTab = i;
  }

  reset() {
    this.imgSrc = [];

  }
  professionalSubmit() {
    this.submited = true;
  }
  basicDetails() {
    this.editUploadProfile.valid;
    let b = this.editUploadProfile.controls['WorkAuth'].value;

    var aaaa = this.editUploadProfile.setValidators([Validators.required])
    this.editUploadProfile.updateValueAndValidity();

    if (this.jobSeekerIds.length <= 0) {
      this.Epsubmitted = false;
      this.toastr.warningToastr('Please select at least one Job Type', '');
    }
    else {
      if (this.editUploadProfile.controls['isVisa'].value == true) {

        if (!this.editUploadProfile.controls['WorkAuth'].value) {
          this.isWorkAuthRequired = true;
          this.editUploadProfile.controls['WorkAuth'].setValidators(Validators.required);
          this.toastr.warningToastr('Please select at least one Work Authorization', '');
        }
      }
      else if (this.editUploadProfile.controls['isVisa'].value == false) {

        if (!this.editUploadProfile.controls['WorkAuth'].value) {
          this.isWorkAuthRequired = false;
          this.editUploadProfile.controls['WorkAuth'].reset();
          this.editUploadProfile.controls['WorkAuth'].clearValidators();
          this.editUploadProfile.controls['WorkAuth'].updateValueAndValidity();
        }
      }

    }
    this.Epsubmitted = true;
  }
  skillsSubmit() {
    this.skSubmited = true;
  }

  onUpdateProfile() {
   
    this.AuthenticationService.jwtTokenExpCheck();   
    this.Epsubmitted = true;
    this.submited = true;
    this.skSubmited = true;
    this.Epsubmitted = true;
    this.submited = true;
    this.skSubmited = true;
    this.DataST = '';
    const EAD = this.editUploadProfile.controls['WorkAuthDropDownEAD'].value;
    const NewH1B = this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].value;
    const workAuthV = this.editUploadProfile.controls['WorkAuth'].value;

    if (workAuthV === 'EAD') {
      this.editUploadProfile.controls['visaType'].setValue(EAD);
    } else {
      if (workAuthV === 'NeedNewH1B') {
        this.editUploadProfile.controls['visaType'].setValue(NewH1B);
      } else {
        this.editUploadProfile.controls['visaType'].setValue(workAuthV);
      }
    }
    if (this.jobSeekerIds.length > 0) {
      for (var i = 0; i < this.jobSeekerIds.length; i++) {
        var a1 = this.jobSeekerIds[i].name;
        if (this.DataST !== '')
          this.DataST = this.DataST + ',' + a1;
        else
          this.DataST = a1;
      }
    }

    if (this.editUploadProfile.controls['validTill'].value == null) {
      this.editUploadProfile.controls['validTill'].markAsTouched();
    }
    if (this.jobSeekerIds.length <= 0) {
      this.isValueJobType = true;
     }else{
     this.isValueJobType = false}
     
    if (this.skillsForm.valid && this.professionalForm.valid && this.editUploadProfile.valid && this.socialForm.valid) {
      this.editUploadProfile.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
      if(this.professionalForm.controls["refDate"].value != "" || this.professionalForm.controls["refDate"].value != null || this.professionalForm.controls["refDate"].value != "Invalid date" || this.professionalForm.controls["refDate"].value != "1900-01-01"){
        this.professionalForm.controls["refDate"].setValue(this.shareservice.convertDateIntoUtc(this.professionalForm.controls["refDate"].value))
      }
      if(this.editUploadProfile.controls["validTill"].value != "" || this.editUploadProfile.controls["validTill"].value != null || this.editUploadProfile.controls["validTill"].value != "Invalid date" || this.editUploadProfile.controls["validTill"].value != "1900-01-01"){
        this.editUploadProfile.controls["validTill"].setValue(this.shareservice.convertDateIntoUtc(this.editUploadProfile.controls["validTill"].value));
      }
      
      if(this.professionalForm.controls["refDate"].value == ""){
        this.professionalForm.controls["refDate"].setValue(null);
      }
      if(this.editUploadProfile.controls["validTill"].value == ""){
        this.editUploadProfile.controls["validTill"].setValue(null);
      }

      
if (this.userData.data.credentials.userType === 'AD') {
  let userid=this.userData.data.credentials.userId
  this.editUploadProfile.controls['createdBy'].setValue(userid);
}
if (this.userData.data.credentials.userType === 'SA') {
  var ID=this.userData.data.credentials.staffingagencyId
  this.staffID=ID.substring(6)

  this.createdby = JSON.parse((localStorage.getItem('CreatedBy')));
  this.editUploadProfile.controls['createdBy'].setValue(this.createdby);

  this.editUploadProfile.controls['registerBy'].setValue(this.staffID);

  
}
if (this.userData.data.credentials.userType === 'EP') {
  var ID=this.userData.data.credentials.enterpriseId
  this.staffID=ID.substring(6)

  this.createdby = JSON.parse((localStorage.getItem('CreatedBy')));
  this.editUploadProfile.controls['createdBy'].setValue(this.createdby);

  this.editUploadProfile.controls['registerBy'].setValue(this.staffID);
}
var seekerID=this.jobseekerId.substring(6)
  console.log('seekerID',seekerID,'this.jobseekerId',this.jobseekerId);
     

      let body: any;
      if (this.userData.data.credentials.userType === 'SA') {
        body = {
          professional: this.professionalForm.value,
          profile: this.editUploadProfile.value,
          skills: this.skillsForm.value,
          other: this.socialForm.value,
          allJobtype: this.DataST,
          enterpriseId: this.interpriseId == null ? 'NULL' : this.interpriseId
        };
      } else {
        body = {
          professional: this.professionalForm.value,
          profile: this.editUploadProfile.value,
          skills: this.skillsForm.value,
          other: this.socialForm.value,
          allJobtype: this.DataST,
        };
      }

// this.editUploadProfile.controls['registerBy'].setValue(this.userData.data.credentials.staffingagencyId);
// this.editUploadProfile.controls['createdBy'].setValue('1020');
//       var seekerID=this.jobseekerId.substring(6)      

//       if (this.userData.data.credentials.userType === 'AD') {
        
//       }
//       if (this.userData.data.credentials.userType === 'SA') {
//         var ID=this.userData.data.credentials.staffingagencyId
//         this.modifiedBy=ID.substring(6)
//       }
//       if (this.userData.data.credentials.userType === 'EP') {

//       }



this.API.updatecandidateprofileVD(body,seekerID,this.modifiedBy).subscribe((res:any)=>{
  
  if(res.success){


this.API.updatecandidateprofile(body, this.jobseekerId).subscribe((res: any) => {
  
  if (res.success) {
    this.uploadData = res;
    this.toastr.successToastr(res.message, 'Succcess!', { position: 'top-right' });
    setTimeout(() => {

      if (this.userData.data.credentials.userType === 'AD') {
        this._router.navigate(['/ad/admin/jobseeker']);
      }
      if (this.userData.data.credentials.userType === 'SA') {
        this._router.navigate(['/st/staff/dashboard']);
      }
      if (this.userData.data.credentials.userType === 'EP') {
        if (this.page === 1) {
          this._router.navigateByUrl('/ep/enterprise/search_profile');
        }
        else if (this.page == 2) {
          this._router.navigateByUrl("/ep/enterprise/jobseeker-view/" + this.jobseekerId);
        }

        else if (this.page == 3) {
          this._router.navigateByUrl("/ep/enterprise/search_profile");
        }
        else {
          this._router.navigate(['/ep/enterprise/dashboard']);
        }
      }
      this._router.isActive;
    }, 500);
  } else {
    this.toastr.errorToastr(res.message);
    return false;
  }
});

  }else {
    

          this.toastr.errorToastr(res.message);
          return false;
        }

});


    } else {
      this.DataST = '';
      this.submitMsg = 'Invalid data please fill required  field.';
      this.toastr.errorToastr('Invalid data please fill required  field.', 'Error!', { position: 'bottom-right' });
      return false;
    }
  }



  onresumeSelect() {
  }

  resetUpdate() {
    // this._router.navigate(['/DashboardStaffing']);
    // this._router.navigate(['/ep/enterprise/dashboard']);
    if (this.userData.data.credentials.userType === 'AD') {
      this._router.navigate(['/ad/admin/jobseeker']);
    }
    if (this.userData.data.credentials.userType === 'SA') {
      this._router.navigate(['/st/staff/dashboard']);
    }
    if (this.userData.data.credentials.userType === 'EP') {
      this._router.navigate(['/ep/enterprise/dashboard']);
    }

  }
  uploadDoc(val) {
    document.getElementById(val).click();
  }

  refrencedate :any
  setValueCandidate(data) {
    if (data.docs.length > 0) {
      var docsname = data.docs[0].docName;
    }
    this.docsList = [];
    this.nationality().then(naData => {
      this.nationalityList = naData;
      if (data.details.citizenship != null && data.details.citizenship != '') {
        this.editUploadProfile.controls['citizenship'].setValue(data.details.citizenship);
      }
    });
    if (data.details.isVisa) {
      this.isVisaToggle = true;
    } else {
      this.isVisaToggle = false;
    }
    if (data.details.jobtype != "" && data.details.jobtype != null) {
      this.jobData = data.details.jobtype.split(',');
      for (var i = 0; i < this.jobData.length; i++) {
        this.jobSeekerIds.push({
          'name': this.jobData[i],
          'selected': true
        });
      }
      for (var i = this.jobType.length - 1; i >= 0; i--) {
        for (var j = 0; j < this.jobSeekerIds.length; j++) {
          if (this.jobType[i] && (this.jobType[i].name === this.jobSeekerIds[j].name)) {
            this.jobType.splice(i, 1);
          }
        }
      }

      for (var i = 0; i < this.jobData.length; i++) {
        this.jobType.push({
          'id': this.jobData[i],
          'name': this.jobData[i],
          'selected': true
        });
      }
    }

    this.isValueWorkAuth = false;
    if (this.jobData.length > 0) {
      this.editUploadProfile.controls['jobType'].clearValidators();
      this.editUploadProfile.controls['jobType'].updateValueAndValidity();
    }
    this.jobseekerId = data.details.jobseekerId;
    this.profileImage = data.details.profileImage;
    this.editUploadProfile.controls['firstName'].setValue(data.details.firstName);
    this.editUploadProfile.controls['middleName'].setValue(data.details.middleName);
    this.editUploadProfile.controls['lastName'].setValue(data.details.lastName);
    this.editUploadProfile.controls['emailId'].setValue(data.details.emailId);
    this.editUploadProfile.controls['phone_mobile'].setValue(data.details.phone_mobile);
    this.editUploadProfile.controls['ssnNo'].setValue(data.details.ssnNo);
   
    if (data.details.dob != null && data.details.dob != '') {
      this.editUploadProfile.controls['dob_month'].setValue(data.details.dob.split('-')[0]);
      this.editUploadProfile.controls['dob_day'].setValue(data.details.dob.split('-')[1]);
      this.editUploadProfile.controls['dob_year'].setValue(parseInt(data.details.dob.split('-')[2]));
      this.setDays(data.details.dob.split('-')[0]);

    }
    this.editUploadProfile.controls['phone_home'].setValue(data.details.phone_home);
    this.editUploadProfile.controls['extn_no'].setValue(data.details.extn_no);
    this.editUploadProfile.controls['phone_work'].setValue(data.details.phone_work);
    this.countryFn().then(res => {

      this.countryList = res;
      if (data.details.country != null && data.details.country != '') {
        this.editUploadProfile.controls['country'].setValue(data.details.country);

      }
      this.countryChange().then(data1 => {
        this.stateList = data1;
        if (data.details.state != null && data.details.state != '') {
          this.editUploadProfile.controls['state'].setValue(data.details.state);
        }
        this.stateChange().then(stData => {
          this.cityList = stData;
          if (data.details.city != null && data.details.city != '') {
            this.editUploadProfile.controls['city'].setValue(data.details.city);
          }
        });
      }).catch(err => {
        console.log(err);
      });
    }).catch(err => {
      console.log(err);
    });
    this.editUploadProfile.controls['zipCode'].setValue(data.details.zipCode);
    this.editUploadProfile.controls['streetName'].setValue(data.details.streetName);
    this.editUploadProfile.controls['profileImage'].setValue(data.details.profileImage);
    this.editUploadProfile.controls['isVisa'].setValue(data.details.isVisa);
    this.editUploadProfile.controls['visaType'].setValue(data.details.visaType);
    this.editUploadProfile.controls['WorkAuth'].setValue(data.details.visaType);
    if (this.checkWA != 'US Citizen' && this.checkWA != 'Green Card' && this.checkWA != 'H1B') {
      this.editUploadProfile.controls['WorkAuthDropDownEAD'].setValue(data.details.visaType);
      this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].setValue(data.details.visaType);
      const checkEAD = this.editUploadProfile.controls['visaType'].value;
      if (checkEAD == 'CPT EAD' || checkEAD == 'OPT EAD' || checkEAD == 'GC EAD' || checkEAD == 'L2 EAD' || checkEAD == 'H4 EAD' || checkEAD == 'TN EAD') {

        this.editUploadProfile.controls['WorkAuth'].setValue('EAD');
        this.isvisibleEAD = false;
      }
      if (checkEAD == 'B1/B2' || checkEAD == 'L1' || checkEAD == 'F1' || checkEAD == 'F2' || checkEAD == 'J1' || checkEAD == 'OTHER VISA') {

        this.editUploadProfile.controls['WorkAuth'].setValue('NeedNewH1B');
        this.isvisibleNeedNewH1B = false;
      }
    }
    else {
      this.editUploadProfile.controls['WorkAuthDropDownEAD'].reset();
      this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].reset();
      this.isvisibleEAD = false;
      this.isvisibleNeedNewH1B = false;
    }
    this.editUploadProfile.controls['SecurityClearance'].setValue(data.details.SecurityClearance);
    this.editUploadProfile.controls['validTill'].setValue(this.datePipe.transform(data.details.validTill, 'yyyy-MM-dd'));

    this.socialForm.controls['facebookUrl'].setValue(data.details.facebookUrl);
    this.socialForm.controls['twitterUrl'].setValue(data.details.twitterUrl);
    this.socialForm.controls['linkdinUrl'].setValue(data.details.linkdinUrl);
    this.socialForm.controls['blogUrl'].setValue(data.details.blogUrl);
    this.socialForm.controls['voiceSample'].setValue(data.details.voiceSample);
    this.voiceFileName = data.details.voiceSample;
    this.docName = docsname;
    if (docsname == '' || docsname == undefined) {
      this.docName = '';
    }
    this.socialForm.controls['docIds'].setValue(data.details.docIds);
    this.socialForm.controls['educationFile'].setValue(data.details.educationFile);

    this.skillsForm.controls['skill'].setValue(data.details.skill);
    this.skillsForm.controls['suggested_keywords'].setValue(data.details.suggested_keywords);
    this.skillsForm.controls['resumeSummary'].setValue(data.details.resumeSummary);
    this.isUsDegree = data.details.us_master_degree;
    if (this.isUsDegree == true) {
      this.professionalForm.controls['us_master_degree'].setValue("true");
    }
    else if (this.isUsDegree == false) {
      this.professionalForm.controls['us_master_degree'].setValue("false");
    }

    this.professionalForm.controls['highest_degree'].setValue(data.details.highest_degree);
    this.professionalForm.controls['us_master_degree'].setValue(data.details.us_master_degree);
    this.professionalForm.controls['expected_salary'].setValue(data.details.expected_salary);
    this.professionalForm.controls['rate'].setValue(data.details.rate);
    this.professionalForm.controls['current_job_title'].setValue(data.details.role);
    this.professionalForm.controls['total_experience'].setValue(data.details.total_experience);
    this.professionalForm.controls['resume_title'].setValue(data.details.resume_title);
    this.professionalForm.controls['resume'].setValue(data.details.resume);    
    this.isShow = true;
    console.log("resumename",data.details.resume);
    this.resumeName = data.details.resume;
    localStorage.setItem('resumefileName', data.details.resume);
    //check resume file exits    
    this.ResumeFileExits(data.details.resume);

    //  resume preview doc
    this.doc =  this.baseUrlDoc +  data.details.resume;   
    //this.doc = "https://api.veriklick.in/Content/Resume/fee0d3ac-695c-4565-8028-b064b5357f68.docx";
    console.log("doc=== ",this.doc);
    this.refrencedate = data.details.ref_date;
    this.professionalForm.controls['companyName'].setValue(data.details.companyName);
    this.professionalForm.controls['contact_person'].setValue(data.details.contact_person);
    this.professionalForm.controls['contact_phone'].setValue(data.details.contact_phone);
    this.professionalForm.controls['contact_email'].setValue(data.details.contact_email);
    this.professionalForm.controls['refDate'].setValue(this.datePipe.transform(data.details.ref_date, 'yyyy-MM-dd'));
    this.professionalForm.controls['jobTitle'].setValue(data.details.jobTitle);
    this.professionalForm.controls['re_location'].setValue(data.details.re_location);

    if (data.details.profileImage !== '') {
      this.imgProfile = `${this.serverpth}/api/staffingagency/image/profileimage/${data.details.profileImage}`;
      this.imageName = data.details.profileImage;
      this.ImageFileExits(data.details.profileImage);
    } else {
      this.imgProfile = '';
    }
    if (data.docs.length > 0) {
      data.docs.forEach(element => {
        this.docsList.push(element);
      });
    }
  }

  selectVissa() {
    const validTills = this.editUploadProfile.get('validTill');
    this.editUploadProfile.controls['isVisa'].value ?
      (
        this.isVisaToggle = true,
        this.nextButton = true,
        this.isRequired = true,
        validTills.setValidators(Validators.required),
        this.editUploadProfile.controls['visaType'].reset(),
        this.editUploadProfile.controls['validTill'].reset()
      ) :
      (
        this.isVisaToggle = false,
        this.isRequired = false,
        this.nextButton = true,
        validTills.clearValidators(),
        this.editUploadProfile.controls['visaType'].reset(),
        this.editUploadProfile.controls['validTill'].reset()
      );
    validTills.updateValueAndValidity();
  }

  countryChange() {
    let CountryId: any = 0;
    this.countryList.forEach(element => {
      if (this.editUploadProfile.controls['country'].value === element.name) {
        CountryId = element.Id;
        this.country_Id = element.Id;
      }
    });
    return new Promise((resolve, reject) => {
      this.API.state(CountryId).subscribe((res: any) => {
        if (res.success) {
          this.stateList = res.data;
          this.allStateList = res.data;
          resolve(res.data);
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
          reject('Error!');
        }
      });
    });
  }

  stateChange() {
    let stateId: any = 0;
    this.stateList.forEach(element => {
      if (this.editUploadProfile.controls['state'].value == element.name) {
        stateId = element.Id;
        this.state_Id = element.Id;
      }
    });
    return new Promise((resolve, reject) => {
      this.API.city(stateId).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
          this.cityList = res.data;
          this.allCityList = res.data;
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
          reject('Error!');
        }
      });
    });
  }

  initFormGroup() {
    
    this.editUploadProfile = this._formBuilder.group({
      'firstName': ['', [Validators.required]],
      'middleName': [''],
      'lastName': ['', [Validators.required]],
      'emailId': ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      'phone_mobile': ['', Validators.required],
      'ssnNo': ['', Validators.required],
      'dob_month': ['', Validators.required],
      'dob_day': ['', Validators.required],
      'dob_year': [''],
      'streetName': ['', [Validators.required]],
      'country': ['', Validators.required],
      'state': ['', Validators.required],
      'zipCode': ['', Validators.required],
      'city': ['', Validators.required],
      'phone_home': [''],
      'extn_no': ['110'],
      'phone_work': [''],
      'citizenship': [''],
      'isVisa': ['', Validators.required],
      'validTill': [''],
      'visaType': '',
      'profileImage': [''],
      'jobType': ['', Validators.required],
      'WorkAuth': [''],
      'WorkAuthDropDownEAD': [''],
      'WorkAuthDropDownNewH1B': [''],
      'SecurityClearance': [''],
      'createdDate':[''],
      'registerBy':[''],
      'createdBy':['']
    });

    this.skillsForm = this._formBuilder.group({
      skill: ['', [Validators.required]],
      suggested_keywords: ['', [Validators.required]],
      resumeSummary: ['', [Validators.required]]
    });

    this.educationalForm = this._formBuilder.group({

    });

    this.socialForm = this._formBuilder.group({
      facebookUrl: [''],
      twitterUrl: [''],
      linkdinUrl: [''],
      blogUrl: [''],
      voiceSample: [''],
      docIds: [this.docsList],
      educationFile: [''],
      verrifficationnDocs: [''],
    });

    this.professionalForm = this._formBuilder.group({
      highest_degree: ['', Validators.required],
      us_master_degree: 'true',
      expected_salary: ['', Validators.required],
      rate: ['', Validators.required],
      current_job_title: ['', [Validators.required,Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9#%$!@.,\\s()-]+$')]],
      total_experience: ['', Validators.required],
      resume_title: ['', [Validators.required]],
      resume: ['', Validators.required],
      companyName: [''],
      contact_person: [''],
      refDate: [''],
      jobTitle: [''],
      contact_phone: [''],
      contact_email: ['', Validators.email],
      re_location: 'false'
    });
  }


  countryFn() {
    return new Promise((resolve, reject) => {
      this.API.country().subscribe((res: any) => {
        if (res.success) {
          this.countryList = res.data;
          resolve(res.data);
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
          reject('error');
        }
      });
    });
  }

  nationality() {
    return new Promise((resolve, reject) => {
      this.API.nationality().subscribe((res: any) => {
        if (res.success) {
          this.nationalityList = res.data;
          this.allNationality = this.nationalityList;
          resolve(res.data);
        } else {
          reject('Error!');
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    });
  }

 
  OnChangePhoneNo(tab, e) {   
    let f_val = '';
    let r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
      e.value = e.target.value.replace(r, '');   
      let value = this.editUploadProfile.controls['phone_mobile'].value;
      let length = value.length;
      if(length >= 3){
       npa = e.value.substr(0, 3);
      }
       if(length >= 6){
        nxx = e.value.substr(3, 3);
      }
        last4 = e.value.substr(6, 4);
    if(npa)
    if(nxx)
    if (e.target.value != '' && e.keyCode != 8 ) {
      if (tab === 'EP') {
        this.editUploadProfile.controls['phone_mobile'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (tab === 'JS') {
        this.editUploadProfile.controls['phone_work'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (tab === 'SA') {
        this.editUploadProfile.controls['phone_home'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (tab === 'CP') {
        this.professionalForm.controls['contact_person'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (tab === 5) {
        this.professionalForm.controls['contact_phone'].setValue(npa + '-' + nxx + '-' + last4);
      }
    }
    else {     
      this.isRequired = false;
    }
  }

  resetAllForm() {
    this.initFormGroup();
    this.API.candidatedetails(this.jobseekerId).subscribe((res: any) => {
      if (res.success) {
        this.setValueCandidate(res.data);
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }
  emailValidation(e) {
    if (e.target.value !== '') {
      this.API.checkmail(this.jobseekerId, e.target.value).subscribe((res: any) => {
        if (res.success) {
          if (res.data.exists) {
            this.mailError = true;
            this.editUploadProfile.controls['emailId'].setErrors({ 'errors': true });
          } else {
            this.mailError = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.mailError = false;
    }
  }

  mobileValidation(e) {
    if (e.target.value != '') {
      this.API.checkmobile(this.jobseekerId, e.target.value).subscribe((res: any) => {
        if (res.success) {
          if (res.data.exists) {
            if (e.target.name == 'phone_mobile') {
              this.mobileError = true;
              this.editUploadProfile.controls['phone_mobile'].setErrors({ 'invalid': true });
            }
            if (e.target.name == 'phone_home') {
              this.phoneError = true;
              this.editUploadProfile.controls['phone_home'].setErrors({ 'invalid': true });
            }
          } else {
            this.mobileError = false;
            this.phoneError = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.mobileError = false;
      this.phoneError = false;
    }
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }



  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }


  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  salaryValidation(e) {
    if (e.target.value != '') {
      if (e.target.value != 0) {
        this.salaryError = false;
      }
      else {
        this.salaryError = true;
        this.professionalForm.controls['expected_salary'].setErrors({ 'invalid': true });
      }
    }
    else {
      this.salaryError = false;
    }
  }

  rateValidation(e) {
    if (e.target.value != '') {
      if (e.target.value != 0) {
        this.rateError = false;
      }
      else {
        this.rateError = true;
        this.professionalForm.controls['rate'].setErrors({ 'invalid': true });
      }
    }
    else {
      this.rateError = false;
    }
  }

  setDays(month: string) {
    if (month === '02') {
      this.daysList = [];
      for (let i = 1; i <= 29; i++) {
        if (i < 10) {
          this.daysList.push({ name: '0' + i, value: '0' + i });
        } else {
          this.daysList.push({ name: '' + i, value: '' + i });
        }

      }
    }

    if (month !== '02') {
      this.daysList = [];
      for (let i = 1; i <= 30; i++) {
        if (i < 10) {
          this.daysList.push({ name: '0' + i, value: '0' + i });
        } else {
          this.daysList.push({ name: '' + i, value: '' + i });
        }
      }
    }

    if (month === '01' || month === '03' || month === '05' || month === '07' || month === '08' || month === '10' || month === '12') {
      this.daysList = [];
      for (let i = 1; i <= 31; i++) {
        if (i < 10) {
          this.daysList.push({ name: '0' + i, value: '0' + i });
        } else {
          this.daysList.push({ name: '' + i, value: '' + i });
        }
      }
    }
  }



  selectDocName() {
    var verrifficationnDocs = this.socialForm.value.verrifficationnDocs
    if (verrifficationnDocs == "Any Other") {
      this.isDocToggle = true;
    }
    else
      this.isDocToggle = false;
  }

  searchValue = false;
  searchNationality(e) {
    if (e.target.value != '') {
      this.API.searchNationality(e.target.value).subscribe((res: any) => {
        if (res.data.length == 0) {
          this.searchValue = true;
        }
        if (res.success) {
          this.nationalityList = res.data;
          if (res.data.length != 0) {
            this.searchValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.nationalityList = this.allNationality;
      this.searchValue = false;
    }

  }




  searchStateValue = false;
  searchState(countryId, e) {

    console.log(this.stateList);
    if (e.target.value != '') {
      this.API.searchState(countryId, e.target.value).subscribe((res: any) => {

        if (res.data.length == 0) {
          this.searchStateValue = true;
        }
        if (res.success) {
          this.stateList = res.data;
          if (res.data.length != 0) {
            this.searchStateValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {

      this.stateList = this.allStateList;
      this.searchStateValue = false;
    }

  }



  searchCityValue = false;
  searchCity(stateId, e) {

    console.log(this.cityList);
    if (e.target.value != '') {
      this.API.searchCity(stateId, e.target.value).subscribe((res: any) => {

        if (res.data.length == 0) {
          this.searchCityValue = true;
        }
        if (res.success) {
          this.cityList = res.data;
          if (res.data.length != 0) {
            this.searchCityValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.cityList = this.allCityList;
      this.searchCityValue = false;
    }

  }




  selectJobType(data, event) {
    if (event.currentTarget.checked) {
      this.jobSeekerIds.push({
        'id': data.id,
        'name': data.name,
        'selected': true
      });
    }
    else {
      this.jobSeekerData.push({
        'id': data.id,
        'name': data.name,
        'selected': false
      });

      for (var i = this.jobSeekerIds.length - 1; i >= 0; i--) {
        for (var j = 0; j < this.jobSeekerData.length; j++) {
          if (this.jobSeekerIds[i] && (this.jobSeekerIds[i].name === this.jobSeekerData[j].name)) {
            this.jobSeekerIds.splice(i, 1);
          }
        }
      }
    }
   if (this.jobSeekerIds.length <= 0) {
      this.editUploadProfile.controls['jobType'].setErrors({ 'incorrect': true });
      this.isValueJobType = true;
    }
    else if (this.jobSeekerIds.length > 0){
      this.isValueJobType = false;
    }

  }

  checkedUnChecked(candidate: { selected: any; }, event) {
    candidate.selected = event.currentTarget.checked;
  }

  
  selectVisa() { 
    const validTills = this.editUploadProfile.get('validTill');
    this.isvisaValue =  this.editUploadProfile.controls['isVisa'].value ;
    // Akhilesh : 20200910, convert ternary check condition  in into if else
    if(this.isvisaValue == true)    {
      this.isVisaToggle = true;
      this.isRequired = true;
      this.isValueWorkAuth = true;
      validTills.setValidators(Validators.required);
      this.editUploadProfile.controls['visaType'].reset();
      this.editUploadProfile.controls['validTill'].reset();
    }
    else{
      this.isValueWorkAuth = false;
      this.isVisaToggle = false;
      this.isRequired = false;
      validTills.clearValidators(),
      this.editUploadProfile.controls['visaType'].reset();
      this.editUploadProfile.controls['validTill'].reset();
      this.editUploadProfile.controls['WorkAuth'].reset();
    }
    validTills.updateValueAndValidity();
    
    let a = this.editUploadProfile.controls['isVisa'].value;
    if (this.editUploadProfile.controls['isVisa'].value == true) {

      if (!this.editUploadProfile.controls['WorkAuth'].value) {
        this.isWorkAuthRequired = true;
        this.editUploadProfile.controls['WorkAuth'].setValidators(Validators.required);
        this.toastr.warningToastr('Please select at least one Work Authorization', '');
      }
    }
    else if (this.editUploadProfile.controls['isVisa'].value == false) {
      if (!this.editUploadProfile.controls['WorkAuth'].value) {
        this.isWorkAuthRequired = false;
        this.editUploadProfile.controls['WorkAuth'].reset();
        this.editUploadProfile.controls['WorkAuth'].clearValidators();
        this.editUploadProfile.controls['WorkAuth'].updateValueAndValidity();
      }
    }
    if (this.editUploadProfile.controls['validTill'].value == null) {
      this.editUploadProfile.controls['validTill'].markAsTouched();

    }
  }


  isEADToggle = false;
  isSubVisaToggle = false;
  selectSubVisaType(e) {
    this.editUploadProfile.controls['visaType'].value == 'EAD(OPT/CPT/GC/H4)' ? (this.isEADToggle = true, this.isSubVisaToggle = false) : (this.isEADToggle = true)
    this.editUploadProfile.controls['visaType'].value == 'Need New H1B' ? (this.isSubVisaToggle = true, this.isEADToggle = false) : (this.isSubVisaToggle = false)
  }

  deleteProfile(jobseekerId, profileImage) {
    if (profileImage == "") {
      this.imgProfile = '';
    }
    console.log('jobseekerId, profileImage', jobseekerId, profileImage);
    this.API.deteleProfileImage(jobseekerId, profileImage).subscribe((res: any) => {
      if (res.success) {
        this.imgProfile = '';
        this.toastr.successToastr(res.message, "", { position: "top-right" });
      }
    });
  }
//Akhilesh:20200912, file check exits on root folder
  ResumeFileExits(filename:any) { 
    this.resumeFile = localStorage.getItem("resumefileName");
    console.log("resumeFile ==", this.resumeFile);
    console.log("filename---",filename);
    this.API.fileExists("resume",filename).subscribe((res: any) => {
      console.log("filecheck", res);
      if (res.data == true) {       
        this.isResumeExits = true;
      }
    });
  }
//Akhilesh:20200912, file check exits on root folder
  ImageFileExits(imgname) {   
    console.log("imageName ==", this.imageName,imgname);
    this.API.fileExists("profileimage",imgname).subscribe((res: any) => {
      console.log("filecheckImg", res);
      if (res.data == true) {       
        this.isImgExits = true;
      }
    });
  }

//Akhilesh : 20200910, Validate special char and  alphabet
  omitSpecialChar(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }
  // 20200929: Akhilesh, Special charcter validation, jira no 70
  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;   
     if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91 
      || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
       this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
       return false } }



       workauthclick(){        
         this.isValueWorkAuth = false;
       }
//20201104:Akhilesh, Search country api
       searchCountryValue = false;
       searchCountry(e) {
         
         if (e.target.value != '') {
           this.API.searchCountry(e.target.value).subscribe((res: any) => {
             if (res.data.length == 0) {
               this.searchCountryValue = true;
             }
             if (res.success) {
               this.countryList = res.data;
               if (res.data.length != 0) {
                 this.searchCountryValue = false;
               }
             } else {
               this.toastr.errorToastr(res.message, 'Error!');
             }
           });
         }
         else {
           this.countryList = this.allCountryList;
           this.searchCountryValue = false;
         }
       }
}




